import { AnimatePresence, motion } from "framer-motion";
import { FieldValues, FormProvider, useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import { findInputError } from "routes/login/findInputError";
import { isFormInvalid } from "routes/login/isFormValid";
import { LoadingDots } from "utils/loadingAnimation";
import { getEmailLogin, requestUsernameLogin } from "services/loginRequests";
import { useEffect, useState } from "react";
import {
  emailValidation,
  passwordValidation,
  testEmailValidation,
  usernameValidation,
} from "./loginValidation";
import {
  MdLockOpen,
  MdOutlinePerson,
  MdOutlineVisibility,
  MdOutlineVisibilityOff,
} from "react-icons/md";

const LoginForm = () => {
  // States definition
  const [loginLoading, setLoginLoading] = useState<boolean>(false);
  const [showError, setShowError] = useState<boolean>(false);
  const [pwdVisibility, setPwdVisibility] = useState<boolean>(false);
  const [blockToggle, setBlockToggle] = useState(false);

  // Parameters
  const visibilityTransitionMs = 0.2;

  // Form hooks
  const methods = useForm({ mode: "onBlur" });
  // Redirect hook
  const navigate = useNavigate();

  /**
   * Function executed when clicking on toggle password visibility.
   * This mechanism is necessary to avoid UI problems when clicking
   * too fast this icon.
   */
  const toggleVisibility = () => {
    // Block the toggle button
    setBlockToggle(true);
  };

  /**
   * Function executed every time the toggle visibility has changed
   */
  useEffect(() => {
    if (blockToggle) {
      // Toggle the visibility
      setPwdVisibility(!pwdVisibility);

      // Delay the visibility update to allow the exit animation to finish
      setTimeout(() => {
        // And release toggle button
        setBlockToggle(false);
      }, visibilityTransitionMs * 1000 + 10); // delay exceeds the exit animation duration
    }
  }, [blockToggle]);

  /**
   * Function for submitting
   */
  const handleEmailLogin = methods.handleSubmit(async (programData: any) => {
    // Set animation state
    setShowError(false);
    setLoginLoading(true);

    // Send the login information for checking
    const response: {
      successful: boolean;
      message: string;
      twoFactorId?: string;
    } = await requestUsernameLogin(programData);

    // Stop the animation
    setLoginLoading(false);

    // If the response is successful
    if (!response.successful) {
      // Display an error message
      setShowError(true);
      return;
    }

    // Then go to the home page
    navigate("/home");

    // navigate(`/two-factor-authentication/${response.twoFactorId}`, {
    //   state: { fromApp: true, remember: programData.remember },
    // });
  });

  /**
   * Temporary test function
   */
  const handleTestLogin = methods.handleSubmit(
    async (programData: FieldValues) => {
      // Set animation state
      setShowError(false);
      setLoginLoading(true);

      // Get the defined role from the email field
      const role = programData.email;

      // Create a cookie with the role
      document.cookie = `role=${role}`;
      navigate("/home");

      // Stop the animation
      setLoginLoading(false);
    }
  );

  // Get the errors
  const usernameError: { [k: string]: any } = findInputError(
    methods.formState.errors,
    "username"
  );
  const passwordError: { [k: string]: any } = findInputError(
    methods.formState.errors,
    "password"
  );

  /**
   * Timer for the username and password mismatch
   */
  useEffect(() => {
    if (showError) {
      // Starting the timer
      const timer = setTimeout(() => {
        // Once finished, close the pop up
        setShowError(false);
        // And forget the last selected id
        // setLastSelectedContact(defaultContact);
      }, 5000);
      return () => clearTimeout(timer);
    }
  }, [showError]);

  return (
    <FormProvider {...methods}>
      <form
        id="login-form"
        className="w-full"
        onSubmit={(event) => event.preventDefault()}
        noValidate
        autoComplete="off"
      >
        <div className="self-stretch h-full flex-col justify-start items-start gap-6 flex">
          <div className="w-full">
            <div className="self-stretch h-[71px] flex-col justify-start items-start gap-1.5 flex">
              <div className="rounded justify-start items-start gap-2 inline-flex">
                <div className="text-neutral-500 text-xs font-normal font-sans uppercase leading-[14px] tracking-wide select-none">
                  Username
                </div>
              </div>
              <div className="w-full h-full rounded-lg bg-neutral-50 text-neutral-700 placeholder:text-neutral-300 text-base font-normal font-sans leading-[19px] flex items-center relative">
                <div className="absolute left-3.5 w-5 h-5 z-0">
                  <MdOutlinePerson className="w-full h-full text-neutral-500" />
                </div>
                <input
                  // {...methods.register("email", emailValidation)}
                  {...methods.register("username", usernameValidation)}
                  // {...methods.register("email", testEmailValidation)}
                  id="username"
                  type="text"
                  className="w-full h-full pl-[42px] rounded-lg bg-transparent text-neutral-700 placeholder:text-neutral-300 placeholder:select-none focus:outline-none text-base font-normal font-sans leading-[19px] z-10"
                  placeholder="Enter your username"
                  autoComplete="username"
                />
              </div>
            </div>
            {/* Error message */}
            <AnimatePresence mode="wait" initial={false}>
              {isFormInvalid(usernameError) && (
                <motion.div
                  className="w-full py-1 text-error-600 text-sm font-normal font-sans leading-[17px] select-none"
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                >
                  {usernameError.error.message}
                </motion.div>
              )}
            </AnimatePresence>
          </div>
          <div className="w-full">
            <div className="self-stretch h-[71px] flex-col justify-start items-start gap-1.5 flex">
              <div className="rounded justify-start items-start gap-2 inline-flex">
                <div className="text-neutral-500 text-xs font-normal font-sans uppercase leading-[14px] tracking-wide select-none">
                  Password
                </div>
              </div>
              <div className="w-full h-full rounded-lg bg-neutral-50 text-neutral-700 placeholder:text-neutral-300 text-base font-normal font-sans leading-[19px] flex items-center relative">
                <div className="absolute left-3.5 w-5 h-5 z-0">
                  <MdLockOpen className="w-full h-full text-neutral-500" />
                </div>
                <input
                  {...methods.register("password", passwordValidation)}
                  id="password"
                  type={pwdVisibility ? "text" : "password"}
                  className="w-full h-full pl-[42px] rounded-lg bg-transparent text-neutral-700 placeholder:text-neutral-300 placeholder:select-none focus:outline-none text-base font-normal font-sans leading-[19px] z-10"
                  placeholder="Enter your password"
                  autoComplete="password"
                />
                <div
                  className="absolute w-fit h-fit p-2 right-1.5 cursor-pointer z-30 rounded-full hover:bg-neutral-100 ease-in-out duration-150"
                  onClick={toggleVisibility}
                >
                  <AnimatePresence mode="wait">
                    {pwdVisibility ? (
                      <motion.div
                        key="visible"
                        initial={{ opacity: 0, scale: 0.9 }}
                        animate={{ opacity: 1, scale: 1 }}
                        exit={{ opacity: 0, scale: 0.9 }}
                        transition={{ duration: visibilityTransitionMs }}
                      >
                        <MdOutlineVisibility className="w-5 h-5 text-neutral-500" />
                      </motion.div>
                    ) : (
                      <motion.div
                        key="hidden"
                        initial={{ opacity: 0, scale: 0.9 }}
                        animate={{ opacity: 1, scale: 1 }}
                        exit={{ opacity: 0, scale: 0.9 }}
                        transition={{ duration: visibilityTransitionMs }}
                      >
                        <MdOutlineVisibilityOff className="w-5 h-5 text-neutral-500" />
                      </motion.div>
                    )}
                  </AnimatePresence>
                </div>
              </div>
            </div>
            {/* Error message */}
            <AnimatePresence mode="wait" initial={false}>
              {isFormInvalid(passwordError) && (
                <motion.div
                  className="w-full py-1 text-error-600 text-sm font-normal font-sans leading-[17px] select-none"
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                >
                  A valid password is required
                </motion.div>
              )}
            </AnimatePresence>
            <AnimatePresence mode="wait" initial={false}>
              {showError && (
                <motion.div
                  className="w-full py-1 text-error-600 text-sm font-normal font-sans leading-[17px]"
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                >
                  Username or password is incorrect. Please try again.
                </motion.div>
              )}
            </AnimatePresence>
          </div>
          <div className="self-stretch h-full flex-col justify-start items-start gap-3 flex">
            <div className="w-full h-full flex items-center">
              <div className="w-fit h-full flex justify-start items-center gap-1 text-lg">
                <input
                  {...methods.register("remember")}
                  type="checkbox"
                  id="remember"
                  name="remember"
                  className="flex-1 w-4 h-4 accent-neutral-700"
                />
                <label
                  htmlFor="remember"
                  className="text-neutral-700 text-base font-sans leading-[17px] select-none"
                >
                  Remember me
                </label>
              </div>
              <div className="flex-1" />
              <Link to="/reset-password">
                <div className="w-fit h-full items-center gap-2 flex">
                  <div className="text-neutral-700 text-base font-normal font-sans underline leading-[17px] select-none">
                    Forgot Password?
                  </div>
                </div>
              </Link>
            </div>
          </div>
          <button
            className="self-stretch px-5 py-3 bg-dc-secondary-600 hover:bg-dc-secondary-700 ease-in-out duration-100 rounded justify-center items-center gap-2 inline-flex"
            onClick={handleEmailLogin}
          >
            <div className="text-white text-lg font-semibold font-sans leading-snug select-none">
              {loginLoading ? <LoadingDots /> : "Sign in"}
            </div>
          </button>
        </div>
      </form>
    </FormProvider>
  );
};

export default LoginForm;
