import Cookies from "js-cookie";
import { Transition } from "@headlessui/react";
import { Fragment } from "react";
import { GoArrowLeft } from "react-icons/go";
import { useNavigate } from "react-router-dom";
import { logoutRequest } from "services/authRequests";

interface LogoutPopUpProps {
  showPopUp: boolean;
  setShowPopUp: React.Dispatch<React.SetStateAction<boolean>>;
}

const LogoutPopUp: React.FC<LogoutPopUpProps> = ({
  showPopUp,
  setShowPopUp,
}) => {
  // Navigate hook
  const navigate = useNavigate();

  /**
   * Handle logout
   */
  const handleLogout = async () => {
    // Remove the cookies
    Cookies.remove("accessToken", {
      domain: process.env.REACT_APP_DOMAIN || "localhost",
    });

    // Send the request to delete the refreshToken from the backend
    const response = await logoutRequest();

    // And go to the home page
    navigate("/");
  };

  return (
    <>
      <Transition
        show={showPopUp}
        as={Fragment}
        enter="transition-opacity ease-out duration-300"
        enterFrom="opacity-0 translate-y-1/2"
        enterTo="opacity-100 translate-y-0"
        leave="transition-opacity duration-200"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <div
          className="fixed inset-0 w-full h-full bg-neutral-500 bg-opacity-40 backdrop-blur-[2px] z-20 flex flex-col flex-grow"
          onClick={() => setShowPopUp(false)}
        />
      </Transition>
      {/* Add pop-up */}
      <Transition
        show={showPopUp}
        as={"div"} // Done this way because if we use fragments we won't be able to pass setStates methods.
        className="fixed w-[440px] h-fit left-0 right-0 ml-auto mr-auto bottom-4 p-6 bg-white rounded-3xl flex-col justify-start items-center gap-10 inline-flex z-30"
        enter="transition-opacity ease-out duration-300"
        enterFrom="opacity-0 translate-y-1/2"
        enterTo="opacity-100 translate-y-0"
        leave="transition-opacity duration-200"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <div className="self-stretch h-fit flex-col justify-start items-start gap-6 flex">
          <div className="self-stretch justify-center items-center gap-4 inline-flex">
            <button
              className="p-1.5 bg-neutral-50 rounded-full backdrop-blur-[32px] justify-center items-center gap-2 flex cursor-pointer hover:bg-neutral-100 ease-in-out duration-150"
              onClick={() => setShowPopUp(false)}
            >
              <div className="w-5 h-5 relative">
                <GoArrowLeft className="w-full h-full fill-dc-secondary-600" />
              </div>
            </button>
            <div className="grow shrink basis-0 text-neutral-700 text-[23px] font-semibold font-sans leading-7">
              Logout?
            </div>
          </div>
          <div className="self-stretch justify-start items-start gap-2 inline-flex">
            <div className="grow shrink basis-0 text-neutral-700 text-base font-normal font-sans leading-7">
              Are you sure you want to logout?
            </div>
          </div>
        </div>
        <button
          className="self-stretch h-fit px-5 py-3 rounded border text-dc-secondary-600 border-dc-secondary-600 hover:bg-dc-secondary-600 hover:text-white justify-center items-center gap-2 inline-flex cursor-pointer ease-in-out duration-150"
          onClick={handleLogout}
        >
          <div className="text-lg font-semibold font-sans leading-snug">
            Logout
          </div>
        </button>
      </Transition>
    </>
  );
};

export default LogoutPopUp;
