import { ContactListType } from "utils/interfaces";
import ContactCard from "./ContactCard";
import { useEffect, useState } from "react";
import { removeItem } from "utils/utils";
import { getCollectionRequest } from "services/apiRequests";
import EmptyViewPopUp from "../EmptyViewPopUp";
import { useNavigate } from "react-router-dom";
import { LoadingDots } from "utils/loadingAnimation";

interface ContactsCardsListProps {
  role: "customer admin" | "class admin";
  selectedContacts: ContactListType[];
  primaryTags?: boolean;
  justDisplay?: boolean;
  setShowSuccessPopUp: React.Dispatch<React.SetStateAction<boolean>>;
  setShowConfirmPrimaryPopUp?: React.Dispatch<React.SetStateAction<boolean>>;
  setSelectedContacts: React.Dispatch<React.SetStateAction<ContactListType[]>>;
  setLastSelectedContact: React.Dispatch<React.SetStateAction<ContactListType>>;
}

const ContactsCardsList: React.FC<ContactsCardsListProps> = ({
  role,
  selectedContacts,
  primaryTags = true,
  justDisplay = false,
  setShowSuccessPopUp,
  setShowConfirmPrimaryPopUp,
  setSelectedContacts,
  setLastSelectedContact,
}) => {
  // States definition
  const [contactData, setContactData] = useState<ContactListType[]>([]);
  const [selectedContactDataIds, setSelectedContactDataIds] = useState<
    string[]
  >([]);
  const [isLoadingData, setIsLoadingData] = useState(true);

  // Define a navigation hook
  const navigate = useNavigate();

  /**
   * Function to load the data
   */
  const getData = async () => {
    // First of all, start the animation
    setIsLoadingData(true);

    // If we are using justDisplay, show only the selected contacts
    if (justDisplay) {
      return selectedContacts;
    }

    const response = await getCollectionRequest(
      "/api/users",
      ["_id", "firstName", "lastName", "email", "phone"],
      { role: role }
    );

    // Check if the response is successful
    if (!response.successful) {
      // Check if it's not successful because of a token expiration
      if (response.logout) {
        // alert(response.message);
        navigate("/");
      }

      return [];
    }

    // Get the data
    const data = response.content;

    return data;
  };

  /**
   * Definition of the Add or remove action
   * @param id of the selected id in the pop up menu
   * @param action "add" or "remove" "selectedData._id" from "id"
   */
  const selectAction = (contact: ContactListType, action: "add" | "remove") => {
    // Add to the current selected contact data ids
    if (action === "add") {
      // Add to the ids list (and ensure prevent duplicated)
      setSelectedContactDataIds([
        ...new Set([...selectedContactDataIds, contact._id]),
      ]);

      // Define the last selected contact
      setLastSelectedContact(contact);

      // If there is at least one contact when adding, display the pop up window
      // to ask if the following one wants to be primary or additional
      // Warning 1: Remember that this "selectedContactDataIds" is before updating.
      // Warning 2: This only will work when setShowConfirmPrimaryPopUp is defined
      // as an input of this component, otherwise, it will not apply the action of
      // opening the pop up window
      if (selectedContactDataIds.length > 0 && setShowConfirmPrimaryPopUp) {
        setShowConfirmPrimaryPopUp(true);
      } else {
        // Otherwise, just display show pop up message
        setShowSuccessPopUp(true);
      }
    }
    // Or delete from it
    else if (action === "remove") {
      setSelectedContactDataIds(
        removeItem(selectedContactDataIds, contact._id)
      );

      // Force pop up message to dissapear
      setShowSuccessPopUp(false);
    }
  };

  /**
   * Definition of the dismiss action
   */
  // const dismissAction = () => {
  //   // Remove the last selected id
  //   setSelectedContactDataIds(
  //     removeItem(selectedContactDataIds, lastSelectedContact._id)
  //   );
  // };

  /**
   * Load class data when init
   */
  useEffect(() => {
    // Get the data
    getData().then((data) => {
      setContactData(data);

      // Define the current selected ids
      setSelectedContactDataIds(
        selectedContacts.map((elem: ContactListType) => elem._id)
      );

      // Stop the animation
      setIsLoadingData(false);
    });
  }, []);

  /**
   * Function to update the selected contacts state
   */
  useEffect(() => {
    // Define the output contacts based on the selected ids (keeping the order)
    const selectedContacts = selectedContactDataIds.map((contactId: string) => {
      return contactData.filter(
        (contact: ContactListType) => contact._id === contactId
      )[0];
    });

    // And update
    setSelectedContacts(selectedContacts);
  }, [selectedContactDataIds]);

  return (
    <>
      <div className="w-full max-h-[80vh] grow shrink justify-start items-start gap-[5px] inline-flex overflow-y-auto pop-up-scrollbar">
        <div className="w-full h-fit self-stretch flex-col justify-start items-start gap-4 inline-flex">
          {contactData && contactData.length > 0 ? (
            contactData.map((contact: ContactListType, index: number) => (
              <ContactCard
                key={index}
                contact={contact}
                selectedContacts={selectedContacts}
                selectAction={selectAction}
                justDisplay={justDisplay}
                setSelectedContacts={setSelectedContacts}
                primaryTag={primaryTags}
                type={"search-pop-up"}
              />
            ))
          ) : !isLoadingData ? (
            <EmptyViewPopUp />
          ) : (
            <div className="w-full h-[50vh] flex justify-center items-center">
              <LoadingDots bgColor="bg-neutral-300" />
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default ContactsCardsList;
