import {
  ImperativePanelGroupHandle,
  Panel,
  PanelGroup,
  PanelResizeHandle,
} from "react-resizable-panels";
import CodeEditorPane, { CodeFile } from "./CodeEditorPane";
import ConsolePane from "./ConsolePane";
import { motion } from "framer-motion";
import ResizeableSash from "assets/icons/editor/ResizeableSashIcon";
import React, {
  useCallback,
  useContext,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import smoothResize from "../utils/smoothResize";
import {
  EditorContext,
  EditorLeftPaneContext,
} from "components/main-view/utils/Contexts";

export interface LeftPaneType {
  editorPanelRef: React.RefObject<HTMLDivElement>;
  normalisedLeftWidth: number;
  filesState: CodeFile[];
  activeTab: string;
  language: string;
  code: string;
  consoleNotification: boolean;
  setActiveTab: React.Dispatch<React.SetStateAction<string>>;
  setFilesState: React.Dispatch<React.SetStateAction<CodeFile[]>>;
  setConsoleNotification: React.Dispatch<React.SetStateAction<boolean>>;
  setLogs: React.Dispatch<React.SetStateAction<any[]>>;
  handleAddFile: () => void;
  handleRunCode: () => void;
  handleEditorChange: (value: string | undefined) => void;
  consolePanelRef: React.RefObject<HTMLDivElement>;
  consoleBottomRef: React.RefObject<HTMLDivElement>;
  logs: any[];
  ref: any;
  leftRightSizes: number[];
  inputConsoleBool: boolean;
  setInputConsoleBool: React.Dispatch<React.SetStateAction<boolean>>;
  setShowOutputRunCode: React.Dispatch<React.SetStateAction<boolean>>;
  isResizing: boolean;
  setIsResizing: React.Dispatch<React.SetStateAction<boolean>>;
}

const LeftPane: React.FC<LeftPaneType> = React.forwardRef(
  (
    {
      filesState,
      activeTab,
      language,
      code,
      logs,
      consoleNotification,
      editorPanelRef,
      consolePanelRef,
      consoleBottomRef,
      normalisedLeftWidth,
      leftRightSizes,
      inputConsoleBool,
      handleAddFile,
      handleRunCode,
      handleEditorChange,
      setActiveTab,
      setFilesState,
      setConsoleNotification,
      setLogs,
      setInputConsoleBool,
      setShowOutputRunCode,
      isResizing,
      setIsResizing
    },
    ref
  ) => {
    /**
     * Parameters
     */
    const codeEditorHeight = window.innerHeight - 155 - 40; // Minus the header and the resizer
    const topDownScreenPercTrigger = 0.85; // Percentage of the screen size needed to trigger the smooth transition
    const consoleDelta = (50 / codeEditorHeight) * 100; // Amount of px that the console needs to increase when double folding
    const editorDelta = (75 / codeEditorHeight) * 100; // Amount of px that the console needs to increase when double folding

    // Get the limit pixel of the animation
    const startAnimPy = Math.round((1 - topDownScreenPercTrigger) * 100);
    const startAnimOpPy = (30 / codeEditorHeight) * 100;

    // Panels size limits
    const baseMinEditorHeight = (50 / codeEditorHeight) * 100; // minimum editor panel height in px
    const baseMinConsoleHeight = (50 / codeEditorHeight) * 100; // minimum console panel height in px
    const baseMaxConsoleHeight = (180 / codeEditorHeight) * 100; // maximum editor panel height in px, before applying the console animation
    const consoleEditorSashOpacityLimit = 0.3; // This value allows to define the amount of opacity fading on the console-editor sash when scrolling left-right

    // Get more parameters
    const { zeroThreshold } = useContext(EditorContext);

    // States definition
    const [consoleEditorSizes, setConsoleEditorSizes] = useState([
      100 - baseMinConsoleHeight,
      baseMinConsoleHeight,
    ]);
    const [normalisedEditorHeight, setNormalisedEditorHeight] = useState(1);
    const [normalisedConsoleHeight, setNormalisedConsoleHeight] = useState(1);
    const [minEditorHeight, setMinEditorHeight] = useState(baseMinEditorHeight); // Adjustable minimum editor panel height in px
    const [minConsoleHeight, setMinConsoleHeight] =
      useState(baseMinConsoleHeight); // Adjustable minimum console panel height in px
    const [maxConsoleHeight, setMaxConsoleHeight] =
      useState(baseMaxConsoleHeight); // Adjustable maximum console panel height in px
    const [isConsoleOpen, setIsConsoleOpen] = useState(false);
    const [consoleLabelTransition, setConsoleLabelTransition] = useState(
      "transform 0.05s ease-in-out"
    );

    // References definition
    const consoleEditorRef = useRef<ImperativePanelGroupHandle>(null);

    /*
     * To control the left pane reference to let it know to the <CodeEditor/> if the console is closed.
     */
    useImperativeHandle(ref, () => ({
      isConsoleClosed: () => {
        return normalisedConsoleHeight === 0;
      },
      isEditorClosed: () => {
        return normalisedEditorHeight === 0;
      },
    }));

    /**
     * Set the initial values of the panes
     */
    useEffect(() => {
      if (consoleEditorRef.current)
        consoleEditorRef.current.setLayout(consoleEditorSizes);
    }, []);

    /*
     * Every time we open the console, we ensure to clear the notification
     */
    useEffect(() => {
      if (normalisedConsoleHeight > 0) {
        setConsoleNotification(false);
      }
    }, [normalisedConsoleHeight]);

    /*
     * Calculate normalised editor and console height for close animations
     */
    useEffect(() => {
      // Calculate the normalised values
      let normEditorHeight = Math.min(
        Math.max((consoleEditorSizes[0] - minEditorHeight) / startAnimPy, 0),
        1
      );
      let normConsoleHeight = Math.min(
        Math.max((consoleEditorSizes[1] - minConsoleHeight) / startAnimPy, 0),
        1
      );

      // Make sure that under a threshold, the numbers are zero
      normEditorHeight =
        normEditorHeight < zeroThreshold ? 0 : normEditorHeight;
      normConsoleHeight =
        normConsoleHeight < zeroThreshold ? 0 : normConsoleHeight;

      // Set the states
      setNormalisedEditorHeight(normEditorHeight);
      setNormalisedConsoleHeight(normConsoleHeight);

      // Update the real values
      if (consoleEditorRef.current)
        consoleEditorRef.current.setLayout(consoleEditorSizes);
    }, [consoleEditorSizes]);

    /*
     * Re-define the minConsoleHeight and minEditorHeight based on the value of
     * the normalisedConsoleHeight for animation
     */
    useEffect(() => {
      // Define the new limits when the editor and console are closed
      if (normalisedLeftWidth < 1) {
        setMinEditorHeight(baseMinEditorHeight + editorDelta);
        setMinConsoleHeight(baseMinConsoleHeight + consoleDelta);
      } else {
        // Return to normal when the condition is not achieved
        setMinEditorHeight(baseMinEditorHeight);
        setMinConsoleHeight(baseMinConsoleHeight);
      }

      // Only apply this logic when the height of the console is closed or in the closing process
      if (normalisedConsoleHeight < 1) {
        // Define the amount of increasing
        const delta = (1 - normalisedLeftWidth) * consoleDelta;
        const modifiedMinConsoleHeight = baseMinConsoleHeight + delta;
        setMinConsoleHeight(modifiedMinConsoleHeight);
        const newSizes = [
          consoleEditorSizes[0] +
            consoleEditorSizes[1] -
            modifiedMinConsoleHeight,
          modifiedMinConsoleHeight,
        ];
        setConsoleEditorSizes(newSizes);
        // // Update the real values
        // if (consoleEditorRef.current){
        //   consoleEditorRef.current.setLayout(newSizes);
        // }
      }

      // Only apply this logic when the height of the editor is closed or in the closing process
      if (normalisedEditorHeight < 1) {
        // Define the amount of increasing
        const delta = (1 - normalisedLeftWidth) * editorDelta;
        const modifiedMinEditorHeight = baseMinEditorHeight + delta;
        setMinEditorHeight(modifiedMinEditorHeight);
        const newSizes = [
          modifiedMinEditorHeight,
          consoleEditorSizes[0] +
            consoleEditorSizes[1] -
            modifiedMinEditorHeight,
        ];
        setConsoleEditorSizes(newSizes);
        // // Update the real values
        // if (codeEditorRef.current){
        //   codeEditorRef.current.resize(modifiedMinEditorHeight);
        // }
      }
    }, [normalisedLeftWidth]);

    /**
     * Define if the run code button should appear on the output panel (after transitions)
     */
    useEffect(() => {
      setShowOutputRunCode(
        normalisedLeftWidth === 0 || normalisedEditorHeight === 0
      );
    }, [normalisedLeftWidth, normalisedEditorHeight]);

    const handleConsoleResizeEnd = useCallback(() => {
      // Aconditionate the numbers
      // 1. To correct from the bias of taking the current values
      // 2. To not exceed the limits
      const editorHeight = Math.max(baseMinEditorHeight, consoleEditorSizes[0]);
      const consoleHeight = Math.max(
        baseMinConsoleHeight,
        consoleEditorSizes[1]
      );

      // Create the current size array for the smoothResize function
      const currentSizes = [editorHeight, consoleHeight];

      // P.S.: the condition is achieved with the "baseMinHeight" because we
      // don't want to move the "trigger" of the animation. However, we
      // use the "baseMinHeight" because those are the base values of the
      // elements.
      if (
        editorHeight < baseMinEditorHeight + startAnimPy &&
        editorHeight !== baseMinEditorHeight
      ) {
        smoothResize(
          [minEditorHeight, editorHeight + consoleHeight - minEditorHeight],
          currentSizes,
          consoleEditorRef,
          100
        );
      }
      if (
        consoleHeight < baseMinConsoleHeight + startAnimPy &&
        consoleHeight >= baseMinConsoleHeight
      ) {
        smoothResize(
          [editorHeight + consoleHeight - minConsoleHeight, minConsoleHeight],
          currentSizes,
          consoleEditorRef,
          100
        );
        // And define that it's closed
        setIsConsoleOpen(false);
        setMaxConsoleHeight(baseMaxConsoleHeight);
      }

      if (
        !isConsoleOpen &&
        consoleHeight > baseMaxConsoleHeight - startAnimOpPy
      ) {
        setIsConsoleOpen(true);
        setMaxConsoleHeight(Infinity);
        setConsoleLabelTransition("transform 0.5s ease-in-out");

        // Add some pixels for the opening effect
        smoothResize(
          [
            editorHeight +
              consoleHeight -
              (baseMaxConsoleHeight + startAnimOpPy),
            baseMaxConsoleHeight + startAnimOpPy,
          ],
          currentSizes,
          consoleEditorRef,
          500
        );

        // After the resize is done, set the transition to the normal value
        setTimeout(
          () => setConsoleLabelTransition("transform 0.05s ease-in-out"),
          1000
        );
      }
    }, [
      smoothResize,
      consoleEditorSizes,
      minConsoleHeight,
      minEditorHeight,
      isConsoleOpen,
    ]);

    return (
      <EditorLeftPaneContext.Provider value={{ codeEditorHeight }}>
        <div className="flex w-full h-full">
          <PanelGroup
            ref={consoleEditorRef}
            direction="vertical"
            onLayout={(sizes) => setConsoleEditorSizes(sizes)}
          >
            <Panel
              className="w-full h-full overflow-visible relative"
              minSize={minEditorHeight}
            >
              <CodeEditorPane
                editorPanelRef={editorPanelRef}
                normalisedLeftWidth={normalisedLeftWidth}
                normalisedEditorHeight={normalisedEditorHeight}
                filesState={filesState}
                activeTab={activeTab}
                setActiveTab={setActiveTab}
                handleAddFile={handleAddFile}
                handleRunCode={handleRunCode}
                setFilesState={setFilesState}
                language={language}
                code={code}
                handleEditorChange={handleEditorChange}
                leftRightSizes={leftRightSizes}
                consoleEditorSizes={consoleEditorSizes}
                isResizing={isResizing}
              />
            </Panel>
            <PanelResizeHandle
              onDragging={(isDragging: boolean) => {
                // Set the dragging state
                setIsResizing(isDragging);

                if (!isDragging) handleConsoleResizeEnd();
              }}
            >
              <div className="ml-12 h-10 align-middle flex items-center justify-center debug">
                <motion.div
                  className="rotate-90"
                  style={{
                    opacity:
                      (1 - consoleEditorSashOpacityLimit) *
                        normalisedLeftWidth +
                      consoleEditorSashOpacityLimit,
                  }}
                >
                  <ResizeableSash></ResizeableSash>
                </motion.div>
              </div>
            </PanelResizeHandle>
            <Panel
              className="w-full h-full overflow-visible"
              minSize={minConsoleHeight}
              maxSize={maxConsoleHeight}
            >
              <ConsolePane
                normalisedLeftWidth={normalisedLeftWidth}
                normalisedConsoleHeight={normalisedConsoleHeight}
                consoleNotification={consoleNotification}
                consolePanelRef={consolePanelRef}
                consoleBottomRef={consoleBottomRef}
                logs={logs}
                setLogs={setLogs}
                isConsoleOpen={isConsoleOpen}
                consoleEditorSizes={consoleEditorSizes}
                consoleLabelTransition={consoleLabelTransition}
                inputConsoleBool={inputConsoleBool}
                isResizing={isResizing}
                setInputConsoleBool={setInputConsoleBool}
                setConsoleNotification={setConsoleNotification}
              />
            </Panel>
          </PanelGroup>
        </div>
      </EditorLeftPaneContext.Provider>
    );
  }
);

export default LeftPane;
