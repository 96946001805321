import { SchoolListType } from "utils/interfaces";
import ViewSearchBar from "./ViewSearchBar";
import ViewTabBar from "./ViewTabBar";
import { useEffect, useRef, useState } from "react";
import { ViewHeaderContext } from "../Contexts";

// Parameter definition
const WIDTH_LIMIT = 954; // px

interface ViewHeaderProps {
  type: "lessons" | "classes" | "quizzes" | "customers";
  userRole: string;
  activeItem: string;
  tabOptions: string[];
  userSchool?: SchoolListType | null;
  setActiveItem: React.Dispatch<React.SetStateAction<string>>;
  setSearchQuery: React.Dispatch<React.SetStateAction<string>>;
  setUserSchool?: React.Dispatch<React.SetStateAction<SchoolListType | null>>;
  headerRef: React.MutableRefObject<any>;
}

const ViewHeader: React.FC<ViewHeaderProps> = ({
  type,
  userRole,
  activeItem,
  tabOptions,
  userSchool,
  setActiveItem,
  setSearchQuery,
  setUserSchool,
  headerRef,
}) => {
  // States definition
  const [panelWidth, setPanelWidth] = useState(Infinity);
  const [format, setFormat] = useState<"expanded" | "shrinked">("expanded");

  // Boolean to define if the tab options should be included
  const tabOptionsExists =
    ["master admin", "customer admin"].includes(userRole) &&
    tabOptions.length > 0;

  // Reference to this element
  const divRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    // Set the initial value
    if (divRef.current) {
      setPanelWidth(divRef.current.offsetWidth);
    }

    // Optionally, add a resize event listener to update width on window resize
    const handleResize = () => {
      if (divRef.current) {
        setPanelWidth(divRef.current.offsetWidth);
      }
    };

    window.addEventListener("resize", handleResize);

    // Clean up the resize listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  /**
   * Function to define the format of the dropdown list based on the width
   */
  useEffect(() => {
    if (
      panelWidth < WIDTH_LIMIT &&
      type === "classes" &&
      userRole === "master admin"
    )
      setFormat("shrinked");
    else setFormat("expanded");
  }, [panelWidth]);

  return (
    <ViewHeaderContext.Provider value={{ format }}>
      <div
        ref={divRef}
        className="self-stretch justify-between items-center inline-flex gap-4"
      >
        {tabOptionsExists && (
          <ViewTabBar
            activeItem={activeItem}
            tabOptions={tabOptions}
            showClassesDropdown={
              userRole === "master admin" && type === "classes"
            }
            userSchool={userSchool}
            setActiveItem={setActiveItem}
            setUserSchool={setUserSchool}
            headerRef={headerRef}
          />
        )}
        <ViewSearchBar
          setSearchQuery={setSearchQuery}
          tabOptionsExists={tabOptionsExists}
          format={format}
        />
      </div>
    </ViewHeaderContext.Provider>
  );
};

export default ViewHeader;
