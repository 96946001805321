import { useEffect, useState } from "react";
import { MdDeleteOutline } from "react-icons/md";

interface ProjectPlanCardProps {
  filename: string;
  planSize: string;
  unit?: string;
  removePlan: () => void;
  onClickNameFunction: () => void;
}

const getFileTypeDisplay = (filename: string) => {
  if (filename.endsWith(".sb3")) {
    return "Scratch";
  } else if (filename.endsWith(".py")) {
    return "Python";
  } else if (filename.endsWith(".js")) {
    return "JavaScript";
  } else if (filename.endsWith(".pdf")) {
    return "PDF";
  }
  return filename;
};

const ProjectPlanCard: React.FC<ProjectPlanCardProps> = ({
  filename,
  planSize,
  unit,
  removePlan,
  onClickNameFunction,
}) => {
  // States definition
  const [openFile, setOpenFile] = useState(false);

  /**
   * Function used to define the behaviour of the
   * name displayed on this card
   */
  useEffect(() => {
    // Get the type of the file
    const filetype = getFileTypeDisplay(filename);

    // Set the state to define if it's opening a new window
    // when clicking the name
    if (filetype === "PDF") setOpenFile(true);
  }, []);

  /**
   * Function to apply on click name
   */
  const onClickName = () => {
    // Execute the function just if it's allowed
    if (openFile) {
      onClickNameFunction();
    }
  };

  return (
    <div className="self-stretch px-3 py-4 rounded-xl border border-Subtle justify-between items-center inline-flex">
      <div className="grow shrink basis-0 h-fit rounded justify-start items-start gap-2 flex">
        <div className="grow shrink basis-0 flex-col justify-start items-start gap-1 inline-flex">
          <div
            className={`w-[440px] text-neutral-700 text-base font-normal font-sans leading-[19px] overflow-hidden break-keep whitespace-nowrap text-ellipsis ${
              openFile ? "cursor-pointer hover:underline" : ""
            } `}
            onClick={onClickName}
          >
            {filename}
          </div>
          <div className="self-stretch justify-start items-start gap-2 inline-flex">
            <div className="justify-start items-start gap-1 flex">
              <div className="text-neutral-500 text-xs font-normal font-sans leading-[14px]">
                File type:
              </div>
              <div className="text-neutral-500 text-xs font-normal font-sans leading-[14px]">
                {getFileTypeDisplay(filename)}
              </div>
            </div>
            {parseFloat(planSize) > 0 && (
              <>
                <div className="w-px self-stretch justify-start items-start gap-2 flex">
                  <div className="w-px self-stretch bg-neutral-200" />
                </div>
                <div className="grow shrink basis-0 h-3.5 justify-start items-start gap-1 flex">
                  <div className="text-neutral-500 text-xs font-normal font-['Poppins'] leading-[14px]">
                    File size:
                  </div>
                  <div className="text-neutral-500 text-xs font-normal font-['Poppins'] leading-[14px]">
                    {planSize} {unit}
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
      <div
        className="p-2 bg-neutral-50 hover:bg-neutral-100 ease-in-out duration-150 rounded-full justify-start items-start gap-2 flex cursor-pointer"
        onClick={removePlan}
      >
        <div className="w-6 h-6 relative">
          <MdDeleteOutline className="w-full h-full fill-neutral-600" />
        </div>
      </div>
    </div>
  );
};

export default ProjectPlanCard;
