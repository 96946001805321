import {
  ClassListType,
  ContactListType,
} from "utils/interfaces";
import ClassCard from "./ClassCard";

interface ClassesListCardsProps {
  classes: ClassListType[];
  searchQuery: string;
  canEdit: boolean;
  setSelectedTeachers: React.Dispatch<React.SetStateAction<ContactListType[]>>;
  setShowTeachersList: React.Dispatch<React.SetStateAction<boolean>>;
  onCardClick: (card: ClassListType) => void;
}

const ClassesListCards: React.FC<ClassesListCardsProps> = ({
  classes,
  searchQuery,
  canEdit,
  setSelectedTeachers,
  setShowTeachersList,
  onCardClick,
}) => {
  return (
    <div className="w-full h-full flex-col justify-start items-start gap-4 inline-flex overflow-y-auto custom-scroll">
      {classes && 
        classes.length > 0 &&
        classes
          .filter((classItem: any) => {
            if (searchQuery.length === 0) return true;
            return classItem.name.includes(searchQuery);
          })
          .map((item: ClassListType, index: number) => (
            <ClassCard
              key={index}
              classItem={item}
              canEdit={canEdit}
              handleCardClick={onCardClick}
              setShowTeachersList={setShowTeachersList}
              setSelectedTeachers={setSelectedTeachers}
            />
          ))
      }
    </div>
  );
};

export default ClassesListCards;
