import {
  MdBolt,
  MdDeleteOutline,
  MdLaunch,
  MdOutlineQuiz,
} from "react-icons/md";
import { QuizListType } from "utils/interfaces";

interface QuizCardProps {
  quiz: QuizListType;
  quizzes: QuizListType[];
  setQuizzes: React.Dispatch<React.SetStateAction<QuizListType[]>>;
  setShowEditQuiz: React.Dispatch<React.SetStateAction<boolean>>;
  setSelectedData: React.Dispatch<React.SetStateAction<QuizListType | null>>;
}

const QuizCard: React.FC<QuizCardProps> = ({
  quiz,
  quizzes,
  setQuizzes,
  setShowEditQuiz,
  setSelectedData,
}) => {
  /**
   * Function to handle delete quiz
   */
  const handleDelete = () => {
    // Get the selected list, excluding this quiz
    const editedQuizzes = quizzes.map((quizItem: QuizListType) => {
      // Check if the quiz that represent this card is already in the database
      if (quiz._id){
        // If this is the case, remove based on the database _id
        if (quizItem._id === quiz._id) {
          quizItem.active = false;
        }
      } 
      // If not, remove based on the tempId 
      else {
        if (quizItem.tempId === quiz.tempId) {
          quizItem.active = false;
        }
      }
      
      return quizItem;
    });

    setQuizzes(editedQuizzes);
  };

  /**
   * Function used to operate on click of the edit/open button
   */
  const handleEdit = () => {
    if (setSelectedData && setShowEditQuiz) {
      // Select the current data
      setSelectedData(quiz);
      // And display the pop up
      setShowEditQuiz(true);
    }
  };

  return (
    <div className="w-full h-[92px] p-4 bg-white rounded-lg border border-Subtle justify-center items-center gap-3 inline-flex">
      <div className="grow shrink basis-0 h-[60px] justify-center items-center gap-4 flex">
        <div className="p-1.5 rounded border border-neutral-200 flex-col justify-start items-center gap-1 inline-flex">
          <div className="px-3 flex-col justify-start items-center gap-1 flex">
            <div className="w-8 h-8 relative">
              <MdOutlineQuiz className="w-full h-full fill-neutral-500" />
            </div>
            <div className="justify-center items-center gap-2 inline-flex">
              <div className="text-neutral-500 text-[10px] font-normal font-sans uppercase leading-3 tracking-wide select-none">
                Quiz
              </div>
            </div>
          </div>
        </div>
        <div className="grow shrink basis-0 flex-col justify-center items-start gap-2 inline-flex">
        <div className="self-stretch justify-start items-center gap-2 inline-flex">
          <div className="grow shrink basis-0 text-neutral-700 text-sm font-semibold font-sans leading-[17px]">
            {quiz.title}
          </div>
        </div>
        <div className="self-stretch justify-start items-center gap-3 inline-flex">
          <div className="justify-center items-center gap-1 flex">
            <div className="w-4 h-4 relative">
              <MdBolt className="w-full h-full fill-neutral-500" />
            </div>
            <div className="text-neutral-500 text-xs font-normal font-inter leading-[14px]">
              {5 * (quiz.questions ? quiz.questions.length : 0)}
            </div>
          </div>
          <div className="self-stretch justify-start items-start gap-2 flex">
            <div className="w-px self-stretch bg-neutral-200" />
          </div>
          <div className="grow shrink basis-0 h-4 justify-center items-center gap-1 flex">
            <div className="w-4 h-4 relative">
              <MdOutlineQuiz className="w-full h-full fill-neutral-500" />
            </div>
            <div className="grow shrink basis-0 text-neutral-500 text-xs font-normal font-inter leading-[14px]">
              {(quiz.questions ? quiz.questions.length : 0)}{" "}
              {(quiz.questions ? quiz.questions.length : 0) === 1 ? "question" : "questions"}
            </div>
          </div>
        </div>
      </div>
      </div>
      <button
        className="p-2.5 bg-neutral-50 hover:bg-neutral-100 ease-in-out duration-150 rounded-full justify-start items-center gap-2 flex cursor-pointer"
        onClick={handleDelete}
      >
        <div className="w-5 h-5 relative">
          <MdDeleteOutline className="w-full h-full fill-neutral-700" />
        </div>
      </button>
      <button
        className="p-2.5 bg-neutral-50 hover:bg-neutral-100 ease-in-out duration-150 rounded-full justify-start items-center gap-2 flex cursor-pointer"
        onClick={handleEdit}
      >
        <div className="w-5 h-5 relative">
          <MdLaunch className="w-full h-full fill-neutral-700" />
        </div>
      </button>
    </div>
  );
};

export default QuizCard;
