import React, { useState, useEffect } from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import { Select, MenuItem, FormControl, InputLabel } from "@mui/material";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { getCollectionRequest } from "services/apiRequests";
import { useNavigate } from "react-router-dom";
import { getLastDates } from "utils/utils";
import ChartTooltip from "./ChartTooltip";

const theme = createTheme({
  palette: {
    primary: {
      main: "#16a7b6",
    },
  },
});

interface ActivityChartParams {
  userClassId: string;
  hasStudents: boolean;
}

const ActivityChart: React.FC<ActivityChartParams> = ({
  userClassId,
  hasStudents,
}) => {
  // States definition
  const [data, setData] = useState<{ day: string; value: number }[]>([]);
  const [chartData, setChartData] = useState<{ day: string; value: number }[]>(
    []
  );
  const [activityPeriod, setActivityPeriod] = useState("week");
  const [yAxisWidth, setYAxisWidth] = useState(0);
  const [yAxisLabel, setYAxisLabel] = useState("");

  /**
   * Function to define the max width of YAxis labels
   */
  useEffect(() => {
    // Find the longest Y-axis label width
    const labels = document.querySelectorAll(
      ".recharts-yAxis .recharts-cartesian-axis-tick-value tspan"
    );

    if (labels) {
      let maxWidth = 0;

      labels.forEach((label: any) => {
        const labelWidth = label.getBBox().width;
        if (labelWidth > maxWidth) {
          maxWidth = labelWidth;
        }
      });

      // Add some padding to the calculated width
      setYAxisWidth(maxWidth + 5);
    }
  }, [chartData, activityPeriod]);

  // Define a navigation hook
  const navigate = useNavigate();

  /**
   * Function used to get the data
   */
  useEffect(() => {
    fetchChartData();
  }, []);

  /**
   * Function used to update the display data if the activity period change
   */
  useEffect(() => {
    setChartData(data.slice(activityPeriod === "week" ? -7 : 0));
  }, [data, activityPeriod]);

  /**
   * Function used to get the chart data
   * @returns
   */
  const fetchChartData = async () => {
    // Get the data
    const response = await getCollectionRequest(
      `/api/usage?classId=${userClassId}`
    );

    // Check if the response is successful
    if (!response.successful) {
      // Check if it's not successful because of a token expiration
      if (response.logout) {
        navigate("/");
      }

      return [];
    }

    // Get the data
    const data = response.content;

    // Transform the query into an object to process the data later
    const dataObj: { [key: string]: number } = data.reduce(
      (
        output: { [key: string]: number },
        entry: {
          _id: string;
          avgTime: number;
        }
      ) => {
        // Assign the value
        output[entry._id] = entry.avgTime;
        return output;
      },
      {}
    );

    // Get the max seconds in this plot
    const maxSeconds = data.reduce(
      (
        currentMax: number,
        entry: {
          _id: string;
          avgTime: number;
        }
      ) => {
        // Assign the value
        if (entry.avgTime > currentMax) return entry.avgTime;
        return currentMax;
      },
      0
    );

    // Define the measure unit for the time
    let timeFactor = 1;
    if (maxSeconds < 60) {
      setYAxisLabel("Seconds");
    } else if (maxSeconds >= 60 && maxSeconds < 3600) {
      timeFactor = 60;
      setYAxisLabel("Minutes");
    } else {
      timeFactor = 3600;
      setYAxisLabel("Hours");
    }

    // Process data
    const lastMonth = getLastDates(30);
    // Create the chart data based on the current data
    const processedData = lastMonth.map((date: string) => {
      return { day: date, value: (dataObj[date] || 0) / timeFactor };
    });

    // Set the processed
    setData(processedData);
  };

  /**
   * Function used to change the activity period state once pressing
   * the dropdown menu
   * @param event
   */
  const handlePeriodChange = (event: any) => {
    setActivityPeriod(event.target.value);
  }

  return (
    <ThemeProvider theme={theme}>
      <div className="h-[417px] p-6 bg-neutral-25 rounded-3xl flex flex-col justify-start items-start gap-8">
        <div className="w-full flex justify-between items-center">
          <div className="flex flex-col justify-start items-start">
            <div className="text-neutral-600 text-lg font-normal font-sans leading-snug">
              Activity
            </div>
            <div className="text-neutral-400 text-[10px] font-normal font-sans leading-3 tracking-tight">
              Student Engagement in active <span className="font-bold">{yAxisLabel.toLowerCase()}</span>
            </div>
          </div>
          <FormControl
            variant="outlined"
            size="small"
            style={{ minWidth: 120 }}
          >
            <InputLabel>Period</InputLabel>
            <Select
              value={activityPeriod}
              onChange={handlePeriodChange}
              label="Period"
            >
              <MenuItem value="week">Week</MenuItem>
              <MenuItem value="month">Month</MenuItem>
            </Select>
          </FormControl>
        </div>
        <div className="w-full h-[200px]">
          <ResponsiveContainer width="100%" height="100%">
            <BarChart data={chartData}>
              <XAxis dataKey="day" type="category" fontSize={12} />
              <YAxis width={yAxisWidth} fontSize={12} allowDecimals={true} /*label={{value: yAxisLabel, angle: -90, position: "insideLeft"}}*//>
              <Tooltip content={<ChartTooltip unit={yAxisLabel} />} />
              <Bar barSize={25} radius={4} dataKey="value" fill="#16a7b6">
                {chartData.map((_, index: number) => (
                  <rect key={`bar-${index}`} className="bar-animation" />
                ))}
              </Bar>
            </BarChart>
          </ResponsiveContainer>
        </div>
        <div className="w-full flex flex-col justify-start items-start gap-2">
          <div className="text-neutral-500 text-xs font-normal font-sans uppercase leading-[14px] tracking-wide">
            Legend
          </div>
          <div className="text-neutral-500 text-[10px] font-normal font-sans leading-3 tracking-tight">
            Numeric units represent {yAxisLabel.toLowerCase()} which the students spent interacting
            with the learning content
          </div>
        </div>
      </div>
    </ThemeProvider>
  );
};

export default ActivityChart;
