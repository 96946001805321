import EmptyIcon from "assets/icons/admin-view/EmptyIcon";
import { MdAddCircleOutline } from "react-icons/md";

interface EmptyViewProps {
  activeClass: string;
  headerRef: React.MutableRefObject<any>;
  fromDashboard?: boolean;
}

const EmptyView: React.FC<EmptyViewProps> = ({ activeClass, headerRef, fromDashboard = false }) => {
  const handleCreateNew = () => {
    // Select create new based on the activeClass
    if (activeClass === "classes") {
      if (fromDashboard) headerRef.current.dashboard().pressCreateNewClass();
      else headerRef.current.classes().pressCreateNewClass();
    } else if (activeClass === "students") {
      headerRef.current.classes().pressCreateNewStudent();
    }
  };

  return (
    <div className="w-full h-full self-stretch grow shrink basis-0 flex-col justify-center items-center gap-10 flex">
      <div className="w-fit h-fit relative">
        <EmptyIcon width="309" height="231" />
      </div>
      <div className="self-stretch h-[59px] flex-col justify-start items-center gap-3 flex">
        <div className="self-stretch text-center text-neutral-600 text-[23px] font-semibold font-sans leading-7">
          This area is empty
        </div>
        {["students", "classes"].includes(activeClass) && (
          <div className="self-stretch text-center text-neutral-700 text-base font-normal font-sans leading-[19px]">
            Just click on the "Create New" button below, and let's get started
          </div>
        )}
      </div>
      {["students", "classes"].includes(activeClass) && (
        <div className="self-stretch justify-center items-center gap-2 inline-flex">
          <div
            className="w-[450px] h-[39px] px-[18px] py-2.5 bg-dc-secondary-600 hover:bg-dc-secondary-700 ease-in-out duration-150 rounded justify-center items-center gap-2 flex cursor-pointer"
            onClick={handleCreateNew}
          >
            <div className="w-4 h-4 relative">
              <MdAddCircleOutline className="w-full h-full fill-white" />
            </div>
            <div className="text-white text-base font-semibold font-sans leading-[19px]">
              Create new
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default EmptyView;
