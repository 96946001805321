import { useEffect, useState } from "react";
import { MdAdd, MdAddCircleOutline } from "react-icons/md";
import {
  ClassListType,
  ContactListType,
  SchoolListType,
} from "utils/interfaces";
import {
  createRecordRequest,
  getCollectionRequest,
} from "services/apiRequests";
import { formatUpdateClass } from "utils/formatUpdate";
import AddTeacherPanel from "components/main-view/utils/pop-up-panels/add-teacher-pop-up/AddTeacherPanel";
import TeacherCard from "components/main-view/utils/pop-up-panels/assign-class-pop-up/TeacherCard";
import SetPasswordPanel from "components/main-view/utils/pop-up-panels/assign-class-pop-up/SetPasswordPanel";

interface CreateNewClassProps {
  classes: ClassListType[];
  selectedClassToEdit?: ClassListType;
  contextSchool?: SchoolListType | null;
  popUpState: "main" | "create-new" | "add-teacher" | "set-password";
  setClasses: React.Dispatch<React.SetStateAction<ClassListType[]>>;
  setPopUpState: React.Dispatch<
    React.SetStateAction<"main" | "create-new" | "add-teacher" | "set-password">
  >;
  setShowPopUp: React.Dispatch<React.SetStateAction<boolean>>;
  afterCreateFunction: () => void;
}

const CreateNewClass: React.FC<CreateNewClassProps> = ({
  classes,
  selectedClassToEdit,
  contextSchool,
  popUpState,
  setClasses,
  setPopUpState,
  setShowPopUp,
  afterCreateFunction,
}) => {
  // States definition
  const [classTitle, setClassTitle] = useState("");
  const [selectedTeachers, setSelectedTeachers] = useState<ContactListType[]>(
    []
  );
  const [activateCreateButton, setActivateCreateButton] = useState(false);

  /**
   * If there is selected data, then prefill the defined fields
   */
  useEffect(() => {
    if (selectedClassToEdit) {
      setClassTitle(selectedClassToEdit.name);
      setSelectedTeachers(selectedClassToEdit.teachers as ContactListType[]);
    }
  }, []);

  /**
   * Function to activate or deactivate create parent button
   */
  useEffect(() => {
    // Check if they have content
    const condition = !!classTitle && selectedTeachers.length > 0;
    setActivateCreateButton(condition);
  }, [classTitle, selectedTeachers]);

  /**
   * Function that returns all the form data
   * @returns data
   */
  const provideData = () => {
    // Get the ids of the teachers selected
    const teacherIds = selectedTeachers.map(
      (teacher: ContactListType) => teacher._id
    ) as unknown as ContactListType[];

    // Return all the gathered data in the form
    const dataToProvide = {
      name: classTitle,
      teachers: teacherIds,
      active: true,
      organisations:
        contextSchool && contextSchool._id ? [contextSchool._id] : undefined,
    };

    return dataToProvide;
  };

  /**
   * Handle press on add a teacher button
   */
  const handleAddATeacher = () => {
    setPopUpState("add-teacher");
  };

  /**
   * Function to handle creating a contact when the button is pressed
   * @returns
   */
  const handleCreateClass = async () => {
    // Check if the button is already available
    if (!activateCreateButton) return;

    // Get all the data from the form
    const data = provideData();

    // Define the output of this process
    let newClass: typeof data & {_id?: string, tempId?: string};

    // If we are inside a created school, then create the class inside
    // this school
    if (contextSchool) {
      const response = await createRecordRequest(data, "/api/classes");
      console.log(response);
      // Check if the response is successful
      if (!response.successful) {
        // alert(response.message);
        return;
      }

      // Append the created _id to the new data
      newClass = {...data, _id: response._id};
    }
    // Otherwise, just create the class as a temporary record
    else {
      // Define a temporary id
      const tempId = classes.filter(
        (classItem: ClassListType) => classItem.tempId
      ).length;

      // And add it to the current data
      newClass = { ...data, tempId: tempId.toString() };
    }

    // Check if there is any information about teachers inside data
    if (newClass.teachers && newClass.teachers.length > 0) {
      // Then populate the field
      const responseTeachers = await getCollectionRequest(
        "/api/users",
        ["firstName", "middleName", "lastName", "phone", "email"],
        { _id: { $in: newClass.teachers } }
      );

      // Check the request
      if (!responseTeachers.successful) return;

      // Update the teachers value
      newClass.teachers = responseTeachers.content;
    }

    // And append the information to the end of the classes 
    setClasses([...classes, newClass as ClassListType]);

    // Execute the defined actions after the creation
    afterCreateFunction();
  };

  /**
   * Function used when clicking the set password button
   */
  const handleSetPassword = () => {
    setPopUpState("set-password");
  };

  /**
   * Function that
   * @param contact
   */
  const deleteAction = (contact: ContactListType) => {
    // Deleting the contact with the current id
    const contactsFiltered = selectedTeachers.filter(
      (currentContact: ContactListType) => currentContact._id !== contact._id
    );
    setSelectedTeachers(contactsFiltered);
  };

  return (
    <>
      {popUpState !== "add-teacher" && popUpState !== "set-password" && (
        <>
          <div className="w-full h-[595px] max-h-[90%] flex-col justify-start items-start gap-8 inline-flex overflow-y-auto pop-up-scrollbar">
            <div className="self-stretch h-fit flex-col justify-start items-start gap-4 flex">
              <div className="self-stretch text-neutral-500 text-base font-semibold font-sans leading-[19px]">
                Basic details
              </div>
              <div className="self-stretch h-fit flex-col justify-start items-start gap-1.5 flex">
                <div className="text-neutral-500 text-xs font-normal font-sans uppercase leading-[14px] tracking-wide">
                  Class Name*
                </div>
                <div className="self-stretch bg-neutral-50 rounded-lg justify-start items-center gap-2 inline-flex">
                  <input
                    type="text"
                    value={classTitle}
                    onChange={(event) => setClassTitle(event.target.value)}
                    className="w-full h-full p-3 text-neutral-700 placeholder:text-neutral-300 text-base font-sans leading-[19px] bg-transparent border-none focus:outline-none"
                    placeholder="Enter name of the class"
                  />
                </div>
              </div>
            </div>
            <div className="self-stretch h-fit flex-col justify-start items-start gap-4 flex">
              <div className="self-stretch text-neutral-500 text-base font-semibold font-sans leading-[19px]">
                Teachers
              </div>
              {selectedTeachers.length === 0 ? (
                <button
                  className="self-stretch px-2 py-3 rounded-lg border border-dashed border-neutral-300 hover:bg-neutral-25 ease-in-out duration-150 justify-center items-center gap-2 inline-flex cursor-pointer"
                  onClick={handleAddATeacher}
                >
                  <div className="w-6 h-6 relative">
                    <MdAdd className="w-full h-full fill-neutral-500" />
                  </div>
                  <div className="text-neutral-500 text-base font-normal font-sans leading-[19px]">
                    Add a Teacher
                  </div>
                </button>
              ) : (
                <div className="w-full h-fit flex-col justify-start items-start gap-4 inline-flex">
                  {selectedTeachers.map(
                    (teacher: ContactListType, index: number) => (
                      <TeacherCard
                        key={index}
                        contact={teacher}
                        deleteAction={deleteAction}
                      />
                    )
                  )}
                  <button
                    className="group w-fit h-fit rounded justify-start items-center gap-2 flex cursor-pointer"
                    onClick={handleAddATeacher}
                  >
                    <div className="w-6 h-6 relative">
                      <MdAddCircleOutline className="w-full h-full fill-dc-secondary-600 group-hover:fill-dc-secondary-700" />
                    </div>
                    <div className="text-dc-secondary-600 group-hover:text-dc-secondary-700 text-lg font-semibold font-sans leading-snug">
                      Add additional teachers
                    </div>
                  </button>
                </div>
              )}
            </div>
            {!!selectedClassToEdit && (
              <div className="self-stretch h-fit flex-col justify-start items-start gap-4 flex">
                <div className="self-stretch text-neutral-500 text-base font-semibold font-sans leading-[19px]">
                  Class password
                </div>
                <div
                  className="self-end w-full h-fit px-5 py-3 border border-dc-secondary-600 hover:bg-neutral-50 ease-in-out duration-150 rounded justify-center items-center gap-2 inline-flex cursor-pointer"
                  onClick={handleSetPassword}
                >
                  <div className="text-dc-secondary-600 text-lg font-semibold font-sans leading-snug">
                    Set password
                  </div>
                </div>
              </div>
            )}
          </div>
          <button
            className={
              activateCreateButton
                ? "self-end w-full h-fit px-5 py-3 bg-dc-secondary-600 hover:bg-dc-secondary-700 ease-in-out duration-150 rounded justify-center items-center gap-2 inline-flex cursor-pointer"
                : "self-end w-full h-fit px-5 py-3 bg-Subtle rounded justify-center items-center gap-2 inline-flex cursor-not-allowed"
            }
            onClick={handleCreateClass}
          >
            <div
              className={
                activateCreateButton
                  ? "text-white text-lg font-semibold font-sans leading-snug"
                  : "text-neutral-400 text-lg font-semibold font-sans leading-snug"
              }
            >
              {selectedClassToEdit ? "Save class" : "Create class"}
            </div>
          </button>
        </>
      )}
      {popUpState === "add-teacher" && (
        <AddTeacherPanel
          contextSchool={contextSchool}
          selectedTeachers={selectedTeachers}
          setSelectedTeachers={setSelectedTeachers}
          setShowPopUp={setShowPopUp}
          externalSetPopUpState={setPopUpState}
        />
      )}
      {popUpState === "set-password" && (
        <SetPasswordPanel
          selectedData={selectedClassToEdit}
          externalSetPopUpState={setPopUpState}
        />
      )}
    </>
  );
};

export default CreateNewClass;
