import { AnimatePresence, motion } from "framer-motion";
import { Message } from "./Assistant";
import Markdown from "react-markdown";
import remarkGfm from "remark-gfm";
import AIAvatarLittleC from "assets/imgs/AIAvatar_LittleC.png";
import AIAvatarJakita from "assets/imgs/AIAvatar_Jakita.png";
import AIAvatarCody from "assets/imgs/AIAvatar_Cody.svg";
import ScratchBlocks from "scratchblocks-react"
import { GoCheckCircleFill } from "react-icons/go";
import { MdArrowBack, MdArrowForward } from "react-icons/md";

const CompleteMessage: React.FC<{
  messageText: string,
  returnToIDE?: () => void,
  moveToNextLesson?: () => void
}> = ({
  messageText,
  returnToIDE,
  moveToNextLesson,
}) => {
  return (
    <>
      <div className="self-stretch text-neutral-800 text-base font-normal font-['Plus Jakarta Sans'] leading-7">Thank you for your patience.</div>
      <div className="self-stretch justify-start items-center gap-1.5 inline-flex">
        <div className="w-6 h-6 relative">
          <GoCheckCircleFill className="w-full h-full fill-success-700" />
        </div>
        <div className="grow shrink basis-0 text-neutral-800 text-base font-normal font-['Plus Jakarta Sans'] leading-7">Your assignment has been marked as completed.</div>
      </div>
      <div className="w-full text-neutral-800 text-base font-normal font-['Plus Jakarta Sans'] leading-7">Here is some additional feedback:</div>
      <div className="w-full">
        {messageText}
      </div>
      <div className="self-stretch justify-start items-start gap-2 inline-flex">
        <div
          className="grow shrink basis-0 h-[39px] px-[18px] py-2.5 rounded border border-dc-secondary-600 justify-center items-center gap-2 flex cursor-pointer select-none"
          onClick={returnToIDE}
        >
          <div className="w-4 h-4 relative">
            <MdArrowBack className="w-full h-full text-dc-secondary-600"/>
          </div>
          <div className="text-dc-secondary-600 text-base font-semibold font-['Poppins'] leading-[19px]">Return to IDE</div>
        </div>
        <div
          className="grow shrink basis-0 h-[39px] px-[18px] py-2.5 rounded border border-dc-secondary-600 justify-center items-center gap-2 flex cursor-pointer select-none"
          onClick={moveToNextLesson}
        >
          <div className="text-dc-secondary-600 text-base font-semibold font-['Poppins'] leading-[19px]">Move the next lesson</div>
          <div className="w-4 h-4 relative">
            <MdArrowForward className="w-full h-full text-dc-secondary-600"/>
          </div>
        </div>
      </div>
    </>
  );
}

interface ChatMessageParams {
  index: number;
  AIAssistant: "littleC" | "jakita" | "cody";
  message?: Message | any;
  strikethrough?: boolean;
  returnToIDE?: () => void;
  moveToNextLesson?: () => void;
  language: string;
  nameInitials: string;
}

const ChatMessage: React.FC<ChatMessageParams> = ({
  index,
  AIAssistant,
  message,
  strikethrough = false,
  returnToIDE,
  moveToNextLesson,
  language,
  nameInitials
}) => {
  return (
    <>
      {!strikethrough && (
        <motion.div className="pb-6 flex w-full">
          {message.role === "user" && (
            <div className="w-[72px] h-[72px] rounded-full bg-dc-accent-50 border-dc-accent-300 border flex items-center justify-center flex-none">
              <span className="text-4xl font-bold text-dc-secondary-600">{nameInitials}</span>
            </div>
          )}
          <div
            className={`p-4 h-fit border rounded-2xl ${
              message.role === "assistant"
                ? "w-full mr-4 bg-dc-secondary-50 border border-dc-primary-300 rounded-tr-none"
                : "w-[calc(100%-165px)] ml-4 mr-[175px] bg-dc-accent-50 border border-dc-accent-300 rounded-tl-none"
            }`}
          >
            <AnimatePresence>
              {message.content !== undefined ? (
                <>
                  <motion.div
                    className={`min-h-6 break-words flex flex-col gap-3 ${
                      strikethrough ? "line-through" : ""
                    }`}
                    id={`chat-message-${index}`}
                    key={`chat-message-${index}`}
                    initial={{ height: 84, opacity: 0 }}
                    animate={{ height: "auto", opacity: 1 }}
                    exit={{ height: 0, opacity: 0 }}
                    transition={{ duration: 0.1 }}
                  >
                    {message.role === "assistant" ? (
                      message.complete ? (
                        <CompleteMessage
                          messageText={message.content}
                          returnToIDE={returnToIDE}
                          moveToNextLesson={moveToNextLesson}
                        />
                      ): (
                        <Markdown
                          className="chat-md"
                          components={{
                            code({ node, className, children, ...props }) {
                              return language !== "scratch" ? (
                                <code
                                  className="chat-message-code"
                                  {...props}
                                >
                                  {children}
                                </code>
                              ) : (
                                <ScratchBlocks>
                                  {children}
                                </ScratchBlocks>
                              );
                            },
                          }}
                          remarkPlugins={[remarkGfm]}
                        >
                          {message.content}
                        </Markdown>
                      )
                    ) : (
                      <>{message.content}</>
                    )}
                  </motion.div>
                </>
              ) : (
                <>
                  <motion.div
                    id={`chat-message-${index}`}
                    key={`chat-message-${index}`}
                    initial={{ height: 84, opacity: 0 }}
                    animate={{ height: "auto", opacity: 1 }}
                    exit={{ height: 0, opacity: 0 }}
                    transition={{ duration: 0.1 }}
                  >
                    <div className="h-5 w-full bg-dc-secondary-200/80 rounded animate-pulse duration-1000" />
                    <div className="h-5 w-full bg-dc-secondary-200/80 rounded my-3 animate-pulse duration-1000 delay-100" />
                    <div className="w-3/4 h-5 bg-dc-secondary-200/80 rounded animate-pulse duration-1000 delay-200" />
                  </motion.div>
                </>
              )}
            </AnimatePresence>
          </div>
          {message.role === "assistant" && (
            <div className="w-fit h-fit rounded-full bg-dc-secondary-50 border border-dc-primary-300 flex items-center justify-center flex-none relative overflow-hidden">
              <img
                src={AIAssistant === "jakita" ? AIAvatarJakita : AIAssistant === "littleC" ? AIAvatarLittleC : AIAvatarCody}
                alt="AI Avatar"
                className={
                  AIAssistant === "jakita"
                    ? "w-[72px] h-[72px]"
                    : "w-[72px] h-[72px]"
                }
              />
            </div>
          )}
        </motion.div>
      )}
    </>
  );
};

export default ChatMessage;
