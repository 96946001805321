import {
  useEffect,
  useRef,
  useState,
} from "react";
import MainHeader from "components/main-view/utils/main-header/MainHeader";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Cookies from "js-cookie";
import { Course, QuizListType } from "utils/interfaces";
import { getCollectionRequest } from "services/apiRequests";
import TakeQuiz from "components/main-view/quizzes/TakeQuiz";
import { AnimatePresence, motion } from "framer-motion";
import quizWatermark1 from 'assets/imgs/quizWatermark1.png';
import quizWatermark2 from 'assets/imgs/quizWatermark2.png';
import ActivityTimer from "components/activity-timer/ActivityTimer";
import { platform } from "config/platform";

const Quiz: React.FC = () => {
    const {quizId} = useParams();

    // States definition
    const [userRole, setUserRole] = useState("student");
    const [data, setData] = useState<QuizListType | undefined>(undefined);
    
    // refs
    const headerRef: any = useRef();
    const quizzesRef = useRef();
    const lessonsRef = useRef();
    const classesRef = useRef();
    const customersRef = useRef();
    const dashboardRef = useRef();
    const subHeaderRef = useRef();

    // Define a navigator state
    const navigate = useNavigate();
    const { state } = useLocation();

    const _courseidx = state && state.navRegister ? state.navRegister.findIndex((elem: any) => elem.lessonListToShow === "courses") : -1;
    let course: Course | undefined = undefined;
    if (_courseidx > 0)
      course = state.navRegister[_courseidx].record;

    /**
     * Assign the role based on the specified in the login view
     */
    useEffect(() => {
      const roleCookie = Cookies.get("role");
      setUserRole(roleCookie || "");

      getData();
      if (course) {
        headerRef.current.setHeaderTitle(course.title + ": Quick Check");
      } else {
        headerRef.current.setHeaderTitle("Quizzes");
      }
      headerRef.current.setShowBackButton(true);
    }, []);

    /**
     * Function to get the data depending on the class
     * @returns data
     */
    const getData = async () => {
      const response = await getCollectionRequest(
        "/api/quizzes",
        [ ],
        {_id: [quizId]},
      );

        // Check if the response is successful
        if (!response.successful) {
          // Check if it's not successful because of a token expiration
          if (response.logout) {
            // alert(response.message);
            navigate("/")
          }
          
          return [];  
        }
        
        // Get the data
        const data = response.content;

      if (data.length === 1) {
        setData(data[0]);
        return data[0]
      } else {
        console.error('malformed data quiz:', data);
        goToDashboard();
      }
    };

    // Return to home url
    const goToDashboard = () => {
      // return 
      if (state) {
        navigate("/home", { state });
      } else {
        navigate("/home", { state: {
          activeItem: userRole === "master admin" ? "quizzes" : "lessons"
        }})
      }
    };

    // /**
    //  * Define the tab title and description once opened
    //  */
    useEffect(() => {
      if (headerRef.current && data !== undefined) {
        headerRef.current.setHeaderDescription(data.title);
      }
    }, [data]);

    return (
      <div className="w-screen h-screen p-8 flex flex-col gap-4">
        <ActivityTimer
          type="quizzes"
          itemId={quizId}
          intervalMs={30000}
        />
        <MainHeader
          ref={headerRef}
          userRole={userRole}
          userFirstName=""
          userLastName=""
          activeItem={"quizzes"}
          backPressedToggle={false}
          setBackPressedToggle={() => goToDashboard()}
          setShowSideBarMenu={() => {}}
          setActiveItem={() => {}}
          lessonsRef={lessonsRef}
          classesRef={classesRef}
          customersRef={customersRef}
          dashboardRef={dashboardRef}
          subHeaderRef={subHeaderRef}
        />
        <AnimatePresence>
          {data !== undefined && platform === "deadly-coders" && (
            <motion.div
              className="w-0 h-0 overflow-visible"
              initial={{
                opacity: 0
              }}
              animate={{
                opacity:0.2
              }}
              exit={{
                opacity:0
              }}
            >
              <img alt="watermark1" className="w-[192px] h-[230px] right-4 top-4 absolute z-1000" src={quizWatermark1} />
              <img alt="watermark2" className="w-[360px] h-[161px] left-0 bottom-4 absolute z-1000 rotate-[60deg] translate-y-[-70.7%]" src={quizWatermark2} />
            </motion.div>
          )}
        </AnimatePresence>
        {data !== undefined && (
          <TakeQuiz
            quiz={data}
            exitQuiz={goToDashboard}
          />
        )}
      </div>
    );
  };

export default Quiz;
