import AnimatedBackButton from "components/main-view/utils/components/AnimatedBackButton";
import ViewSearchBar from "components/main-view/utils/view-header/ViewSearchBar";
import { AnimatePresence, motion } from "framer-motion";
import { forwardRef, useEffect, useImperativeHandle, useState } from "react";

interface SubHeaderBarProps {
  ref?: any;
  activeItem: string;
  dashboardRef: React.MutableRefObject<any>;
}

const SubHeaderBar: React.FC<SubHeaderBarProps> = forwardRef(
  ({ activeItem, dashboardRef }, ref) => {
    // States definition
    const [show, setShow] = useState(false);
    const [searchQuery, setSearchQuery] = useState("");
    const [showBackButton, setShowBackButton] = useState(false);
    const [subHeaderTitle, setSubHeaderTitle] = useState<string>("Overview");

    /**
     * Define the list of functions that can be used from external components
     * using a reference
     */
    useImperativeHandle(ref, () => ({
      setSubHeaderTitle(title: string) {
        setSubHeaderTitle(title);
      },
      setShowBackButton(bool: boolean) {
        setShowBackButton(bool);
      },
      setShow(bool: boolean) {
        setShow(bool);
      },
    }));

    /**
     * Handle back button loading the desired function
     */
    const handleBackClick = () => {
      // When click, call the reference
      if (dashboardRef.current) dashboardRef.current.pressBackButtonSubHeader();
    };

    /**
     * Every time the search query is changed, update the dashboard
     */
    useEffect(() => {
      // When click, call the reference
      if (dashboardRef.current)
        dashboardRef.current.setCustomerAdminSearchQuery(searchQuery);
    }, [searchQuery]);

    return (
      <AnimatePresence initial={false}>
        {show && activeItem === "dashboard" && (
          <motion.div
            initial={{
              height: 0,
              opacity: 0,
            }}
            animate={{
              height: "auto",
              opacity: 1,
              transition: {
                duration: 0.1,
                ease: "easeInOut",
              },
            }}
            exit={{
              height: 0,
              opacity: 0,
              transition: {
                duration: 0.1,
                ease: "easeInOut",
              },
            }}
            className="inline-flex flex-row w-full h-fit items-center p-0 pl-[8.5rem]"
          >
            <AnimatedBackButton
              show={showBackButton}
              width={60}
              onClick={handleBackClick}
            />
            <div className="flex-grow text-lg font-sans font-normal leading-snug inline-flex overflow-hidden items-center">
              <AnimatePresence initial={false}>
                {subHeaderTitle === "Overview" && (
                  <motion.div
                    className="absolute text-neutral-600"
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                  >
                    {subHeaderTitle}
                  </motion.div>
                )}
              </AnimatePresence>
              <AnimatePresence initial={false}>
                {subHeaderTitle !== "Overview" && (
                  <motion.div
                    className="absolute text-neutral-600"
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                  >
                    {subHeaderTitle}
                  </motion.div>
                )}
              </AnimatePresence>
            </div>
            <div className="w-80 flex-col justify-start items-start inline-flex">
              <ViewSearchBar
                setSearchQuery={setSearchQuery}
                tabOptionsExists={true}
              />
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    );
  }
);

export default SubHeaderBar;
