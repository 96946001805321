import { CustomersContext } from "components/main-view/utils/Contexts";
import {
  getProgressBarColor,
} from "components/main-view/utils/progressUtils";
import React, { useContext, useEffect, useState } from "react";
import {
  MdAlternateEmail,
  MdOutlineContacts,
  MdOutlineEdit,
  MdOutlineHowToReg,
  MdOutlineLocationOn,
  MdOutlinePhoneAndroid,
} from "react-icons/md";
import { formatPhoneNumber } from "utils/utils";
import { BACKEND_URL } from "config/getUrl";
import { SchoolListType } from "utils/interfaces";

interface SchoolCardProps {
  school: SchoolListType;
  handleCardClick: (card: SchoolListType) => (event: React.MouseEvent) => void;
  handleClickEdit: (card: SchoolListType) => (event: React.MouseEvent) => void;
  aptitude: number;
  averageProgress: number;
}

const SchoolCard: React.FC<SchoolCardProps> = ({
  school,
  handleCardClick,
  handleClickEdit,
  aptitude,
  averageProgress,
}) => {
  // States definition
  const [enrolmentRate, setEnrolmentRate] = useState(0);
  const [stundentsEnrolled, setStundentsEnrolled] = useState(0);

  // Getting the values of the context
  const { version } = useContext(CustomersContext);

  /**
   * Routine to calculate the enrolmentRate
   */
  useEffect(() => {
    // Get the number of students
    const stundentsEnrolled = school && school.students ? school.students.length : 0;

    // Calculate relevant values
    const enrolmentRateValue =
      school.licenses !== 0
        ? Math.round(( stundentsEnrolled / school.licenses) * 100)
        : 0;

    // Setting the values
    setStundentsEnrolled(stundentsEnrolled);
    setEnrolmentRate(enrolmentRateValue)
  }, [school]);

  return (
    <div
      role="button"
      tabIndex={0}
      className="w-full h-48 p-6 bg-neutral-25 rounded-xl justify-between items-start inline-flex cursor-pointer hover:shadow-card ease-in-out duration-200"
      onClick={handleCardClick(school)}
    >
      <div className="w-[190px] self-stretch flex-col justify-between items-start inline-flex">
        <div className="self-stretch text-neutral-700 text-[19px] font-semibold font-sans leading-[23px]">
          {school.name}
        </div>
        <img
          alt=""
          src={`${BACKEND_URL}${school.logo}?v=${version}`}
          onError={({ currentTarget }) => {
            currentTarget.onerror = null;
            currentTarget.src = "/logo.svg";
          }}
          className="w-[48px] h-[48px] rounded-full"
        />
      </div>
      <div className="w-[190px] p-1 rounded flex-col justify-start items-start gap-2 inline-flex">
        <div className="self-stretch h-[75px] flex-col justify-start items-start gap-2 flex">
          <div className="p-2 bg-neutral-50 rounded-full justify-start items-end gap-2 inline-flex">
            <div className="w-8 h-8 relative">
              <MdOutlineContacts className="w-full h-full fill-neutral-700" />
            </div>
          </div>
          <div className="self-stretch text-neutral-600 text-base font-semibold font-sans leading-[19px] overflow-hidden break-keep whitespace-nowrap text-ellipsis">
            {school.contacts.length > 0 ? school.contacts[0].firstName : "-"}{" "}
            {school.contacts.length > 0 ? school.contacts[0].lastName : "-"}
          </div>
        </div>
        <div className="self-stretch h-[38px] flex-col justify-start items-start gap-1.5 flex">
          <div className="self-stretch justify-start items-center gap-1 inline-flex">
            <div className="w-4 h-4 relative">
              <MdAlternateEmail className="w-full h-full fill-neutral-500" />
            </div>
            <div className="w-[162px] text-start text-neutral-500 text-xs font-normal font-inter leading-[14px] overflow-hidden text-ellipsis">
              {school.contacts.length > 0 ? school.contacts[0].email : "-"}
            </div>
          </div>
          <div className=" justify-start items-center gap-1 inline-flex">
            <div className="w-4 h-4 relative">
              <MdOutlinePhoneAndroid className="w-full h-full fill-neutral-500" />
            </div>
            <div className="text-center text-neutral-500 text-xs font-normal font-inter uppercase leading-[14px] tracking-wide">
              {school.contacts.length > 0
                ? formatPhoneNumber(school.contacts[0].phone)
                : "-"}
            </div>
          </div>
        </div>
      </div>
      <div className="w-[190px] p-1 rounded flex-col justify-start items-start gap-3 inline-flex">
        <div className="self-stretch h-[88px] flex-col justify-start items-start gap-2 flex">
          <div className="p-2 bg-neutral-50 rounded-full justify-start items-end gap-2 inline-flex">
            <div className="w-8 h-8 relative">
              <MdOutlineHowToReg className="w-full h-full fill-neutral-700" />
            </div>
          </div>
          <div className="self-stretch justify-start items-end gap-1 inline-flex">
            <div className="w-fit text-right text-neutral-700 text-lg font-semibold font-sans leading-snug">
              {enrolmentRate}%
            </div>
            <div className="pb-[3px] flex-col justify-center items-end gap-2 inline-flex">
              <div className="justify-center items-end gap-2 inline-flex">
                <div className="text-center text-neutral-500 text-[10px] font-normal font-sans uppercase leading-3 tracking-wide">
                  Enrolment
                </div>
              </div>
            </div>
          </div>
          <div className="self-stretch h-0.5 bg-neutral-100 flex-col justify-start items-start gap-2 flex">
            <div
              className={`h-0.5 ${getProgressBarColor(enrolmentRate)}`}
              style={{ width: `${enrolmentRate}%` }}
            />
          </div>
        </div>
        <div className="flex-col justify-start items-start gap-0.5 flex">
          <div className="justify-start items-center gap-1 inline-flex">
            <div className="w-8 text-center text-neutral-500 text-sm font-semibold font-sans leading-[17px]">
              {stundentsEnrolled}
            </div>
            <div className="text-center text-neutral-500 text-xs font-normal font-inter uppercase leading-[14px] tracking-wide">
              Students enrolled
            </div>
          </div>
          <div className="justify-start items-center gap-1 inline-flex">
            <div className="w-8 text-center text-neutral-500 text-sm font-semibold font-sans leading-[17px]">
              {school.licenses || 0}
            </div>
            <div className="text-center text-neutral-500 text-xs font-normal font-inter uppercase leading-[14px] tracking-wide">
              Licenses
            </div>
          </div>
        </div>
      </div>
      <div className="w-[190px] p-1 rounded flex-col justify-start items-start gap-3 inline-flex">
        <div className="self-stretch h-[115px] flex-col justify-start items-start gap-2 flex">
          <div className="p-2 bg-neutral-50 rounded-full justify-start items-end gap-2 inline-flex">
            <div className="w-8 h-8 relative">
              <MdOutlineLocationOn className="w-full h-full fill-neutral-700" />
            </div>
          </div>
          <div className="self-stretch h-[59px] flex-col justify-start items-start gap-1 flex">
            <div className="self-stretch text-neutral-500 text-sm font-normal font-sans leading-[17px] overflow-hidden break-keep whitespace-nowrap text-ellipsis">
              {school.locations.length > 0 ? school.locations[0].address : "-"},{" "}
            </div>
            <div className="self-stretch text-neutral-500 text-sm font-normal font-sans leading-[17px]">
              {school.locations.length > 0 ? school.locations[0].city : "-"},{" "}
            </div>
            <div className="self-stretch text-neutral-500 text-sm font-normal font-sans leading-[17px]">
              {school.locations.length > 0 ? school.locations[0].state : "-"}{" "}
              {school.locations.length > 0 ? school.locations[0].postCode : "-"}
            </div>
          </div>
        </div>
      </div>
      <div className="w-12 self-stretch justify-center items-center gap-3 flex">
        <button
          className="p-3 bg-neutral-100 rounded-full justify-start items-start gap-2 flex cursor-pointer hover:bg-neutral-200 ease-in-out duration-150"
          onClick={handleClickEdit(school)}
        >
          <div className="w-6 h-6 relative">
            <MdOutlineEdit className="w-full h-full fill-neutral-700" />
          </div>
        </button>
      </div>
    </div>
  );
};

export default SchoolCard;
