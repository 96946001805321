import ProgressCard from "./ProgressCard";
import ActivityChart from "./ActivityChart";
import Leaderboard from "../utils/Leaderboard";
import { ClassListType } from "utils/interfaces";

interface ClassDashboardProps {
  classData: ClassListType;
}

const ClassDashboard: React.FC<ClassDashboardProps> = ({ classData }) => {
  return (
    <div className="w-full h-full justify-start flex-col inline-flex gap-8 overflow-y-auto overflow-x-hidden custom-scroll">
      <div className="w-full flex gap-8">
        <ProgressCard
          percentage={classData.enrolmentRate}
          progressType={"Enrolment rate"}
        >
          <span className="font-semibold">
            {classData.students ? classData.students.length : 0}
          </span>{" "}
          Students enrolled of{" "}
          <span className="font-semibold">
            {classData.licenses ? classData.licenses : 0}
          </span>{" "}
          licenses
        </ProgressCard>
        <ProgressCard
          percentage={classData.progressRate}
          progressType={"Progress"}
        >
          Average completion of assigned projects
        </ProgressCard>
        <ProgressCard
          percentage={classData.aptitudeRate}
          progressType={"Aptitude"}
        >
          Average score on completed projects
        </ProgressCard>
      </div>
      <div className="w-full flex gap-8">
        <Leaderboard userClassId={classData._id} showStatus={false} />
        <ActivityChart
          userClassId={classData._id}
          hasStudents={!!classData.students && classData.students.length > 0}
        />
      </div>
    </div>
  );
};

export default ClassDashboard;
