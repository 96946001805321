import React from 'react'
import { MdDownload, MdUploadFile } from 'react-icons/md';

interface ScratchFilePopupProps {
  setOpen?: React.Dispatch<React.SetStateAction<boolean>>;
  handleUpload: () => void;
  handleDownload: () => void;
}

const ScratchFilePopup: React.FC<ScratchFilePopupProps> = ({
  setOpen,
  handleUpload,
  handleDownload
}) => {
  return (
    <div
      className="absolute w-[154px] top-0 right-2 p-3 -mt-3 bg-white rounded-lg shadow-card flex-col justify-center items-center gap-2 inline-flex cursor-pointer z-30"
    >
      <div
        className="w-[138px] p-3 bg-white rounded-lg justify-start items-center gap-2 inline-flex text-neutral-700 hover:bg-neutral-700 hover:text-white"
        onClick={() => {
          setOpen?.(false);
          handleUpload();
        }}
      >
        <div className="w-6 h-6 relative fill-neutral-700">
          <MdUploadFile className="w-full h-full" />
        </div>
        <div className="text-base font-normal font-sans leading-7">
          Upload
        </div>
      </div>
      <div
        className="w-[138px] p-3 bg-white rounded-lg justify-start items-center gap-2 inline-flex text-neutral-700 hover:bg-neutral-700 hover:text-white"
        onClick={() => {
          setOpen?.(false);
          handleDownload();
        }}
      >
        <div className="w-6 h-6 relative fill-neutral-700">
          <MdDownload className="w-full h-full" />
        </div>
        <div className="text-base font-normal font-sans leading-7">
          Download
        </div>
      </div>
    </div>
  )
}

export default ScratchFilePopup;