import { GoArrowLeft } from "react-icons/go";
import OptionsCard from "./OptionsCard";

interface OptionsPanelProps {
  options: string[];
  setShowPopUp: React.Dispatch<React.SetStateAction<boolean>>;
  setOptions: React.Dispatch<React.SetStateAction<string>>;
}

const OptionsPanel: React.FC<OptionsPanelProps> = ({options, setShowPopUp, setOptions }) => {
  // Handling the selected level
  const handleClickOnOptions = (option: string) => {
    return () => {
      // Set the option
      setOptions(option);
      // And close the pop up
      setShowPopUp(false);
    };
  };

  return (
    <>
      <div className="self-stretch justify-between items-center inline-flex">
        <div className="justify-start items-center gap-3 flex">
          <button
            className="p-1.5 bg-neutral-50 hover:bg-neutral-100 ease-in-out duration-150 rounded-full backdrop-blur-[32px] justify-center items-center flex cursor-pointer"
            onClick={() => setShowPopUp(false)}
          >
            <div className="w-5 h-5 relative ">
              <GoArrowLeft className="w-full h-full fill-dc-secondary-600" />
            </div>
          </button>
          <div className="text-neutral-600 text-lg font-normal font-sans leading-snug">
            Pick an option
          </div>
        </div>
      </div>
      <div className="w-[403px] flex-col justify-start items-start gap-4 inline-flex">
        {options.map((option: string, index: number) => (
          <OptionsCard key={index} option={option} handleOnClick={handleClickOnOptions(option)} />
        ))}
      </div>
    </>
  );
};

export default OptionsPanel;
