import colors from "tailwindcss/colors";
type PlayIconType = { colour: string };

const PlayIcon: React.FC<PlayIconType> = ({ colour = "#B54810" }) => {
  // Color transformation logic
  let colourFormatted: string = '';

  if (colour[0] !== "#") {
    try {
      // Split the string
      let [baseColor, numberColor] = colour.split('-');

      // Get the hex color code using the colors table
      let colourList = colors[baseColor as keyof typeof colors] ;
      colourFormatted = colourList[numberColor as keyof typeof colourList];
    } catch (error) {
      console.log(error);
    }
  } else {
    colourFormatted = colour;
  }

  return (
    <svg
      className="z-50"
      width="33"
      height="32"
      viewBox="0 0 33 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="play_arrow">
        <path
          id="Vector"
          d="M9.73657 9.09302V22.9064C9.73657 23.9597 10.8966 24.5997 11.7899 24.0264L22.6432 17.1197C23.4699 16.5997 23.4699 15.3997 22.6432 14.8664L11.7899 7.97302C10.8966 7.39969 9.73657 8.03969 9.73657 9.09302Z"
          fill={colourFormatted}
        />
      </g>
    </svg>
  );
};

export default PlayIcon;
