import { MdAdd } from "react-icons/md";
import { LocationListType } from "utils/interfaces";

interface LocationCardProps {
  location: LocationListType;
  selectAction: (location: LocationListType) => void;
}

const LocationCard: React.FC<LocationCardProps> = ({
  location,
  selectAction
}) => {
  
  /**
   * Handle onClick this card
   */
  const handleOnClick = () => {
    // Provide the current information
    selectAction(location);
  }

  return (
    <div
      className="self-stretch p-3 bg-neutral-25 rounded-lg justify-center items-center gap-1 inline-flex cursor-pointer"
      onClick={handleOnClick}
    >
      <div className="grow shrink basis-0 flex-col justify-center items-center gap-1 inline-flex">
        <div className="self-stretch text-neutral-600 text-base font-normal font-sans leading-[19px]">
          {location.address}
          {", "}
        </div>
        <div className="self-stretch text-neutral-500 text-xs font-normal font-sans leading-[14px]">
          {location.city}
          {", "}
        </div>
        <div className="self-stretch text-neutral-500 text-xs font-normal font-sans leading-[14px]">
          {location.state} {location.postCode}
        </div>
      </div>
      <div
        className="p-2 bg-neutral-200 rounded-full justify-start items-start gap-2 flex cursor-pointer"
        onClick={() => {}}
      >
        <div className="w-4 h-4 relative">
          <MdAdd className="w-full h-full fill-neutral-500" />
        </div>
      </div>
    </div>
  );
};

export default LocationCard;
