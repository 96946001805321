import { useContext, useEffect, useState } from "react";
import { MdSearch } from "react-icons/md";
import { ViewHeaderContext } from "../Contexts";

interface ViewSearchBarProps {
  setSearchQuery: React.Dispatch<React.SetStateAction<string>>;
  tabOptionsExists: boolean;
  format?: "expanded" | "shrinked"
}

const ViewSearchBar: React.FC<ViewSearchBarProps> = ({
  setSearchQuery,
  tabOptionsExists,
  format="expanded"
}) => {
  // States definition
  const [text, setText] = useState("");

  /**
   * Send the current text as a search query on Enter key down
   * @param event
   */
  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      setSearchQuery(text);
    }
  };

  

  return (
    <>
      {!tabOptionsExists && <div className="w-[20%]" />}
      <div
        className={
          tabOptionsExists
            ? `${format === "expanded" ? "w-80" : "w-60"} flex-col justify-start items-start inline-flex`
            : "w-full flex-col justify-start items-start inline-flex"
        }
      >
        <div className="w-full h-[51px] bg-neutral-50 rounded-lg justify-start items-center gap-2 inline-flex relative">
          <div
            className="absolute mx-1.5 w-8 h-8 flex justify-center items-center cursor-pointer hover:bg-neutral-200 ease-in-out duration-150 rounded-full"
            onClick={() => setSearchQuery(text)}
          >
            <MdSearch className="w-5 h-5 fill-neutral-500" />
          </div>
          <input
            type="text"
            value={text}
            onChange={(event) => setText(event.target.value)}
            onKeyDown={handleKeyPress}
            className="w-full h-full p-3 pl-10 bg-transparent focus:outline-none text-neutral-600 placeholder:text-neutral-300 text-base font-normal font-sans leading-[19px]"
            placeholder="Search"
          />
        </div>
      </div>
      {!tabOptionsExists && <div className="w-[20%]" />}
    </>
  );
};

export default ViewSearchBar;
