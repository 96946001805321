import { Transition } from "@headlessui/react";
import { Fragment } from "react";
import { MdTaskAlt } from "react-icons/md";

interface SuccessPopUpMessageProps {
  lessonToAdd: string;
  showSuccessPopUp: boolean;
  successPopUpText: string;
  addOrRemove: "add" | "remove";
  setShowSuccessPopUp: React.Dispatch<React.SetStateAction<boolean>>;
  dismissAction?: () => Promise<void>;
}

const SuccessPopUpMessage: React.FC<SuccessPopUpMessageProps> = ({
  lessonToAdd,
  showSuccessPopUp,
  successPopUpText,
  addOrRemove,
  setShowSuccessPopUp,
  dismissAction
}) => {
  // Definition of the dismiss button on click
  const handleDismissOnClick = () => {
    // Undo the action
    if (dismissAction) dismissAction()

    // And remove the pop up window
    setShowSuccessPopUp(false);
  }

  return (
    <Transition
      show={showSuccessPopUp}
      as={Fragment}
      enter="transition-opacity ease-out duration-300"
      enterFrom="opacity-0 translate-y-1/2"
      enterTo="opacity-100 translate-y-0"
      leave="transition-opacity duration-300"
      leaveFrom="opacity-100"
      leaveTo="opacity-0"
    >
      <div
        className={
          addOrRemove === "add"
            ? "absolute bottom-6 w-[403px] h-12 p-3 bg-success-500 rounded-2xl justify-start items-center gap-3 inline-flex"
            : "absolute bottom-6 w-[403px] h-12 p-3 bg-error-500 rounded-2xl justify-start items-center gap-3 inline-flex"
        }
      >
        <div className="grow shrink basis-0 h-6 justify-center items-center gap-1.5 flex">
          <div className="w-6 h-6 relative">
            <MdTaskAlt className="w-full h-full fill-white" />
          </div>
          <div className="grow shrink basis-0 text-white text-sm font-sans leading-[17px]">
            <span className="font-normal">{addOrRemove === "add" ? "Added to" : "Removed from"} {lessonToAdd} </span>
            <span className="font-semibold">{successPopUpText}</span>
          </div>
        </div>
        <div
          className={
            addOrRemove === "add"
              ? "px-2 py-1 bg-success-600 hover:bg-success-700 ease-in-out duration-150 rounded justify-center items-center gap-2 flex cursor-pointer"
              : "px-2 py-1 bg-error-600 hover:bg-error-800 ease-in-out duration-150 rounded justify-center items-center gap-2 flex cursor-pointer"
          }
          onClick={handleDismissOnClick}
        >
          <div className="text-white text-xs font-normal font-inter uppercase leading-[14px] tracking-wide">
            Dismiss
          </div>
        </div>
      </div>
    </Transition>
  );
};

export default SuccessPopUpMessage;
