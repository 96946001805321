import { ClassListType, SchoolListType } from "utils/interfaces";
import AssignClassCard from "./AssignClassCard";
import { useEffect, useState } from "react";
import { removeItem } from "utils/utils";
import EmptyViewPopUp from "../EmptyViewPopUp";
import {
  getCollectionRequest,
  updateRecordRequest,
} from "services/apiRequests";
import { LoadingDots } from "utils/loadingAnimation";
import SuccessPopUpMessage from "../SuccessPopUpMessage";
import { useNavigate } from "react-router-dom";

interface ClassesCardsListProps {
  contextSchool?: SchoolListType | null;
  selectedClasses: ClassListType[];
  closeOnceClickOnCard: boolean;
  selectedLesson?: string;
  lessonIdToAssign?: string;
  setShowPopUp: React.Dispatch<React.SetStateAction<boolean>>;
  setSelectedClasses: React.Dispatch<React.SetStateAction<ClassListType[]>>;
}

const ClassesCardsList: React.FC<ClassesCardsListProps> = ({
  contextSchool,
  selectedClasses,
  closeOnceClickOnCard,
  selectedLesson,
  lessonIdToAssign,
  setShowPopUp,
  setSelectedClasses,
}) => {
  // States definition
  const [classData, setClassData] = useState<ClassListType[]>([]);
  const [selectedClassDataIds, setSelectedClassDataIds] = useState<string[]>(
    []
  );
  const [showSuccessPopUp, setShowSuccessPopUp] = useState(false);
  const [isLoadingData, setIsLoadingData] = useState(true);
  const [currentClassName, setCurrentClassName] = useState("");

  // Define a navigation hook
  const navigate = useNavigate();

  /**
   * Function to load the data
   */
  const getData = async () => {
    // First of all, start the animation
    setIsLoadingData(true);

    // Check if there is contextSchool (for edition)
    if (!(contextSchool && contextSchool._id)) {
      return []
    };

    // Get request
    const response = await getCollectionRequest(
      "/api/classes",
      ["_id", "name", "teachers"],
      { organisations: { $all: [contextSchool && contextSchool._id] }, active: true },
      [{ path: "teachers", select: ["firstName", "lastName"] }]
    );

    // Check if the response is successful
    if (!response.successful) {
      // Check if it's not successful because of a token expiration
      if (response.logout) {
        // alert(response.message);
        navigate("/");
      }

      return [];
    }

    // Get the data
    const data = response.content;

    return data;
  };

  /**
   * Definition of the Add or remove action
   * @param id of the selected id in the pop up menu
   * @param action "add" or "remove" "selectedData._id" from "id"
   */
  const selectAction = async (
    classItem: ClassListType,
    action: "add" | "remove"
  ) => {
    // Send the update request if we are on the assign lesson to class
    if (selectedLesson && lessonIdToAssign) {
      // And add or remove the data depending on the case
      const response = await updateRecordRequest(
        {
          classId: classItem._id,
          lessonId: lessonIdToAssign,
          lesson: selectedLesson,
          action: action,
        },
        "/api/classes/assign-lesson"
      );

      // Check if the response was successful
      if (!response.successful) {
        alert(response.message);
        return false;
      }
    }

    // Add to the current selected contact data ids
    if (action === "add") {
      // Add to the ids list
      setSelectedClassDataIds([
        ...new Set([...selectedClassDataIds, classItem._id]),
      ]);

      // And close the panel
      if (closeOnceClickOnCard) setShowPopUp(false);
      // Or send the signal to show the pop up message
      else {
        setShowSuccessPopUp(true);
        setCurrentClassName(classItem.name);
      }
    }
    // Or delete from it
    else if (action === "remove") {
      setSelectedClassDataIds(removeItem(selectedClassDataIds, classItem._id));

      // And force the close of the success message
      setShowSuccessPopUp(false);
    }
  };

  /**
   * Load class data when init
   */
  useEffect(() => {
    // Get the data
    getData().then((data) => {
      // Set the data
      setClassData(data);

      // Define the current selected ids
      setSelectedClassDataIds(
        selectedClasses.map((elem: ClassListType) => elem._id)
      );

      // Stop the animation
      setIsLoadingData(false);
    });
  }, []);

  /**
   * Function to update the selected classes state
   */
  useEffect(() => {
    // Define the output classes based on the selected ids
    const selectedClasses = classData.filter((classElem: ClassListType) =>
      selectedClassDataIds.includes(classElem._id)
    );

    // And update
    setSelectedClasses(selectedClasses);
  }, [selectedClassDataIds]);

  /**
   * Timer for the warning pop up message
   */
  useEffect(() => {
    if (showSuccessPopUp) {
      // Starting the timer
      const timer = setTimeout(() => {
        // Once finished, close the pop up
        if (setShowSuccessPopUp && setCurrentClassName) {
          setShowSuccessPopUp(false);
          setCurrentClassName("");
        }
      }, 5000);


      return () => clearTimeout(timer);
    }
  }, [showSuccessPopUp]);

  return (
    <div className="w-full max-h-[70vh] grow shrink justify-start items-start gap-[5px] inline-flex overflow-y-auto pop-up-scrollbar">
      <div className="w-full h-fit self-stretch flex-col justify-start items-start gap-4 inline-flex">
        {classData.length > 0 ? (
          classData.map((item: ClassListType, index: number) => (
            <AssignClassCard
              key={index}
              classItem={item}
              selectedClassDataIds={selectedClassDataIds}
              selectAction={selectAction}
            />
          ))
        ) : !isLoadingData ? (
          <EmptyViewPopUp />
        ) : (
          <div className="w-full h-[50vh] flex justify-center items-center">
            <LoadingDots bgColor="bg-neutral-300" />
          </div>
        )}
      </div>
      <SuccessPopUpMessage
        type="class"
        role="master admin"
        name={currentClassName}
        showSuccessPopUp={showSuccessPopUp}
        setShowSuccessPopUp={setShowSuccessPopUp}
        dismissAction={() => {}}
      />
    </div>
  );
};

export default ClassesCardsList;
