import ClassDetailsFormCardBase from "components/main-view/utils/components/ClassDetailsFormCardBase";
import { ClassListType } from "utils/interfaces";

interface ClassDetailsFormCardProps {
  thisClass: ClassListType;
  setClasses: React.Dispatch<React.SetStateAction<ClassListType[]>>;
}

const ClassDetailsFormCard: React.FC<ClassDetailsFormCardProps> = ({
  thisClass,
  setClasses,
}) => {
  // Handle delete button
  const handleDelete = () => {
    // Update the active field of the clicked class
    setClasses((currentList) =>
      currentList.map((classItem: ClassListType) => {
        // First of all, check if this class has _id or tempId
        if (thisClass._id) {
          // Compare by _id
          if (classItem._id === thisClass._id) {
            classItem.active = false;
          }
        } else {
          // Otherwise, compare by tempId
          if (classItem.tempId === thisClass.tempId) {
            classItem.active = false;
          }
        }
        
        return classItem;
      })
    );
  };

  return (
    <ClassDetailsFormCardBase thisClass={thisClass} onClickDelete={handleDelete} />
  );
};

export default ClassDetailsFormCard;
