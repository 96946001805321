import InputBadgeImage from "components/main-view/utils/placeholders/InputBadgeImage";
import {
  MdAdd,
  MdOutlineArrowCircleLeft,
  MdOutlineArrowCircleRight,
  MdAddCircleOutline,
} from "react-icons/md";
import {
  Course,
  Module,
  Program,
  Project,
  QuizListType,
} from "utils/interfaces";
import PopUpPanel from "../pop-up-panels/PopUpPanel";
import { useEffect, useState } from "react";
import QuizCard from "../cards/QuizCard";

interface AdditionalDetailsProps {
  selectedLesson: string;
  description: string;
  additionalAttachs: boolean;
  quizzes: QuizListType[];
  badgeImage: string | null;
  selectedData: Project | Program | Course | Module | null;
  setDescription: React.Dispatch<React.SetStateAction<string>>;
  setQuizzes: React.Dispatch<React.SetStateAction<QuizListType[]>>;
  setBadgeImage: React.Dispatch<React.SetStateAction<string | null>>;
  setStage: React.Dispatch<React.SetStateAction<number>>;
  onSave: () => void;
}

const AdditionalDetails: React.FC<AdditionalDetailsProps> = ({
  selectedLesson,
  description,
  additionalAttachs,
  quizzes,
  badgeImage,
  selectedData,
  setDescription,
  setQuizzes,
  setBadgeImage,
  setStage,
  onSave,
}) => {
  // States definition
  const [selectedDataToEdit, setSelectedDataToEdit] =
    useState<QuizListType | null>(null);
  const [selectedQuizzes, setSelectedQuizzes] = useState<QuizListType[]>([]);
  // Pop up states
  const [showQuizPopUp, setShowQuizPopUp] = useState(false);
  const [showEditQuiz, setShowEditQuiz] = useState(false);

  /**
   * Handle add quiz
   */
  const handleAddQuiz = () => {
    setShowQuizPopUp(true);
  };

  /**
   * Go to the next stage
   */
  const handleNext = () => {
    setStage(3);
  };

  /**
   * Go to the previous stage
   */
  const handlePrev = () => {
    setStage(1);
  };

  /**
   * Define the selected quizzes
   */
  useEffect(() => {
    setSelectedQuizzes(quizzes.filter((quiz: QuizListType) => quiz.active));
  }, [quizzes]);

  return (
    <div className="w-[562px] h-fit p-6 bg-white rounded-3xl border-neutral-200 justify-start items-start gap-2 inline-flex">
      <div className="w-full flex-col justify-start items-start gap-12 inline-flex">
        <div className="self-stretch h-7 flex-col justify-start items-start flex text-neutral-700 text-[23px] font-semibold font-sans leading-7">
          Additional details
        </div>
        <div className="self-stretch h-fit flex-col justify-start items-start gap-8 flex">
          <InputBadgeImage
            text="Upload a badge image"
            badgeImage={badgeImage}
            setBadgeImage={setBadgeImage}
          />
          <div className="self-stretch h-fit flex-col justify-start items-start gap-1.5 flex">
            <div className="rounded justify-start items-start gap-2 inline-flex">
              <div className="text-neutral-500 text-xs font-normal font-sans uppercase leading-[14px] tracking-wide">
                {selectedLesson.slice(0, -1)} Description*
              </div>
            </div>
            <div className="self-stretch grow shrink basis-0 bg-neutral-50 rounded-lg flex-col justify-start items-start flex">
              <div className="w-full h-[151px] p-3 justify-start items-start gap-2 inline-flex">
                <textarea
                  value={description}
                  onChange={(event) => setDescription(event.target.value)}
                  className="grow basis-0 self-stretch bg-neutral-50 text-neutral-700 text-base font-normal font-sans leading-[19px] resize-none placeholder:text-neutral-300 focus:outline-none custom-scroll"
                  placeholder="Provide a brief description about this program"
                />
              </div>
            </div>
          </div>
          {/* Add quizes when creating courses */}
          {selectedLesson === "courses" && (
            <div className="w-full h-fit flex-col justify-start items-start gap-3 inline-flex">
              {selectedQuizzes.length === 0 && (
                <button
                  className="w-full h-12 px-2 py-3 rounded-lg border border-dashed border-neutral-300 hover:bg-neutral-25 ease-in-out duration-150 justify-center items-center gap-2 inline-flex cursor-pointer"
                  onClick={handleAddQuiz}
                >
                  <div className="w-6 h-6 relative">
                    <MdAdd className="w-full h-full fill-neutral-450" />
                  </div>
                  <div className="text-neutral-500 text-base font-normal font-sans leading-[19px]">
                    Add a quiz
                  </div>
                </button>
              )}
              {selectedQuizzes.length > 0 && (
                <div className="self-stretch h-fit flex-col justify-start items-start gap-4 flex">
                  <div className="rounded justify-start items-start gap-2 inline-flex">
                    <div className="text-neutral-500 text-base font-semibold font-sans leading-[19px]">
                      Quizzes
                    </div>
                  </div>
                  {selectedQuizzes.map((quiz: QuizListType, index: number) => (
                    <QuizCard
                      key={index}
                      quiz={quiz}
                      quizzes={quizzes}
                      setQuizzes={setQuizzes}
                      setShowEditQuiz={setShowEditQuiz}
                      setSelectedData={setSelectedDataToEdit}
                    />
                  ))}
                  <div className="h-6 justify-between items-center inline-flex">
                    <button
                      className="group rounded justify-center items-center gap-2 flex cursor-pointer"
                      onClick={handleAddQuiz}
                    >
                      <div className="w-6 h-6 relative">
                        <MdAddCircleOutline className="w-full h-full text-dc-secondary-600 group-hover:text-dc-secondary-700" />
                      </div>
                      <div className="text-dc-secondary-600 group-hover:text-dc-secondary-700 text-lg font-semibold font-sans leading-snug">
                        Add another quiz
                      </div>
                    </button>
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
        <div className="flex flex-row justify-between items-center w-full">
          <button
            className="group w-fit h-fit flex rounded justify-center items-center gap-2 cursor-pointer"
            onClick={handlePrev}
          >
            <div className="w-6 h-6 relative">
              <MdOutlineArrowCircleLeft className="w-full h-full fill-dc-secondary-600 group-hover:fill-dc-secondary-700" />
            </div>
            <div className="text-dc-secondary-600 group-hover:text-dc-secondary-700 text-lg font-semibold font-sans">
              Prev
            </div>
          </button>
          {additionalAttachs ? (
            <button
              className="group w-fit h-fit flex rounded justify-center items-center gap-2 cursor-pointer"
              onClick={handleNext}
            >
              <div
                className={
                  description
                    ? "text-dc-secondary-600 group-hover:text-dc-secondary-700 text-lg font-semibold font-sans"
                    : "text-neutral-200 text-lg font-semibold font-sans leading-snug"
                }
              >
                Next
              </div>
              <div className="w-6 h-6 relative">
                <MdOutlineArrowCircleRight
                  className={
                    description
                      ? "w-full h-full fill-dc-secondary-600 group-hover:fill-dc-secondary-700"
                      : "w-full h-full fill-neutral-200"
                  }
                />
              </div>
            </button>
          ) : description ? (
            <button
              className="w-[219px] h-[46px] px-5 py-3 bg-dc-secondary-600 hover:bg-dc-secondary-700 ease-in-out duration-150 flex rounded justify-center items-center gap-2 cursor-pointer"
              onClick={onSave}
            >
              <div className="text-white text-lg font-semibold font-sans leading-snug">
                Save
              </div>
            </button>
          ) : (
            <div className="w-[219px] h-[46px] px-5 py-3 bg-Subtle flex rounded justify-center items-center gap-2 cursor-not-allowed">
              <div className="text-neutral-400 text-lg font-semibold font-sans leading-snug">
                Save
              </div>
            </div>
          )}
        </div>
      </div>
      <PopUpPanel
        type="quiz"
        selectedData={selectedData}
        selectedLesson={selectedLesson}
        quizzes={quizzes}
        showPopUp={showQuizPopUp}
        setShowPopUp={setShowQuizPopUp}
        setQuizzes={setQuizzes}
      />
      <PopUpPanel
        type="edit-quiz"
        selectedData={selectedData}
        selectedQuizToEdit={selectedDataToEdit as QuizListType}
        quizzes={quizzes}
        setQuizzes={setQuizzes}
        showPopUp={showEditQuiz}
        setShowPopUp={setShowEditQuiz}
      />
    </div>
  );
};

export default AdditionalDetails;
