import SideIndicator from "assets/icons/admin-view/SideIndicator";
import { motion } from "framer-motion";
import { MdCheckCircle } from "react-icons/md";

interface ProgressMapProps {
  stage: number;
  additionalAttachs?: boolean;
}

const ProgressMap: React.FC<ProgressMapProps> = ({
  stage,
  additionalAttachs = false,
}) => {
  return (
    <div className="absolute h-fit items-start justify-center mt-auto mb-auto left-[75%] top-1/3 flex flex-col ">
      <div className="w-[150px] h-fit rounded-3xl flex-col flex gap-12">
        <div className="flex items-center">
          <div className="px-1">
            {stage === 1 ? <SideIndicator /> : <div className="px-1" />}
          </div>
          <div
            className={
              stage === 1
                ? "w-[150px] h-[84px] p-3 rounded-3xl flex-col justify-start items-center gap-2 flex bg-neutral-700"
                : "w-[150px] h-[84px] p-3 rounded-3xl flex-col justify-start items-center gap-2 flex bg-white"
            }
          >
            <div
              className={
                stage === 1
                  ? "w-[33px] h-[33px] px-[13.50px] pt-[9px] pb-2 bg-white rounded-full flex-col justify-center items-center gap-2 flex"
                  : "w-[33px] h-[33px] px-[13.50px] pt-[9px] pb-2 bg-neutral-50 rounded-full flex-col justify-center items-center gap-2 flex"
              }
            >
              <div
                className={
                  stage === 1
                    ? "text-base font-semibold font-sans leading-[19px] text-neutral-700"
                    : "text-base font-semibold font-sans leading-[19px] text-neutral-400"
                }
              >
                1
              </div>
              {stage > 1 && (
                <motion.div
                  className="absolute w-5 h-5"
                  style={{ translateX: 15, translateY: -15 }}
                >
                  <MdCheckCircle className="w-full h-full fill-success-500 rounded-full bg-white" />
                </motion.div>
              )}
            </div>
            <div className="justify-center items-center gap-2 inline-flex">
              <div
                className={
                  stage === 1
                    ? "grow shrink basis-0 text-center text-base font-normal font-sans leading-[19px] text-white"
                    : "grow shrink basis-0 text-center text-base font-normal font-sans leading-[19px] text-neutral-700"
                }
              >
                Basic details
              </div>
            </div>
          </div>
        </div>
        <div className="flex items-center">
          <div className="px-1">
            {stage === 2 ? <SideIndicator /> : <div className="px-1" />}
          </div>
          <div
            className={`h-[103px] p-3 rounded-3xl flex-col justify-start items-center gap-2 flex ${
              stage === 2 ? "bg-neutral-700" : "bg-white"
            }`}
          >
            <div
              className={`w-[33px] h-[33px] px-[13.50px] pt-[9px] pb-2 ${
                stage === 2 ? "bg-white" : "bg-neutral-50"
              } rounded-full flex-col justify-center items-center gap-2 flex`}
            >
              <div
                className={`text-base font-semibold font-sans leading-[19px] ${
                  stage === 2 ? "text-neutral-700" : "text-neutral-400"
                }`}
              >
                2
              </div>
              {stage > 2 && (
                <motion.div
                  className="absolute w-5 h-5"
                  style={{ translateX: 15, translateY: -15 }}
                >
                  <MdCheckCircle className="w-full h-full fill-success-500 rounded-full bg-white" />
                </motion.div>
              )}
            </div>
            <div className="justify-center items-center gap-2 inline-flex">
              <div
                className={`grow shrink basis-0 text-center text-base font-normal font-sans leading-[19px] ${
                  stage === 2 ? "text-white" : "text-neutral-700"
                }`}
              >
                Additional details
              </div>
            </div>
          </div>
        </div>
        {additionalAttachs && (
          <div className="flex items-center">
            <div className="px-1">
              {stage === 3 ? <SideIndicator /> : <div className="px-1" />}
            </div>
            <div
              className={`h-[103px] p-3 rounded-3xl flex-col justify-start items-center gap-2 flex ${
                stage === 3 ? "bg-neutral-700" : "bg-white"
              }`}
            >
              <div
                className={`w-[33px] h-[33px] px-[13.50px] pt-[9px] pb-2 ${
                  stage === 3 ? "bg-white" : "bg-neutral-50"
                } rounded-full flex-col justify-center items-center gap-2 flex`}
              >
                <div
                  className={`text-base font-semibold font-sans leading-[19px] ${
                    stage === 3 ? "text-neutral-700" : "text-neutral-400"
                  }`}
                >
                  3
                </div>
              </div>
              <div className="justify-center items-center gap-2 inline-flex">
                <div
                  className={`grow shrink basis-0 text-center text-base font-normal font-sans leading-[19px] ${
                    stage === 3 ? "text-white" : "text-neutral-700"
                  }`}
                >
                  Additional attachments
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ProgressMap;
