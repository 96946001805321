import { LessonsContext } from "components/main-view/utils/Contexts";
import { useContext } from "react";
import {
  MdArrowForward,
  MdBolt,
  MdOutlineCollectionsBookmark,
  MdOutlineEdit,
} from "react-icons/md";
import { BACKEND_URL } from "config/getUrl";
import { Course, Program } from "utils/interfaces";
import { platform } from "config/platform";

export interface ProgramCardProps {
  userRole: string;
  program: Program;
  handleExpand: () => void;
  handleEdit: (event: React.MouseEvent) => void;
  handleAddToClasses: (event: React.MouseEvent) => void;
}

const ProgramCard: React.FC<ProgramCardProps> = ({
  userRole,
  program,
  handleExpand,
  handleEdit,
  handleAddToClasses,
}) => {
  // Definition of the maximum number of courses to show on the card
  const maxCoursesToShow = 3;

  // Get the context to prevent caching
  const { version } = useContext(LessonsContext);

  return (
    <div
      role="button"
      tabIndex={0}
      className="self-stretch p-6 bg-neutral-25 rounded-xl justify-between items-center inline-flex cursor-pointer hover:shadow-card ease-in-out duration-150"
      onClick={handleExpand}
    >
      {platform === "deadly-coders" && (
        <div className="w-[190px] h-[170px] rounded-xl relative overflow-hidden flex items-end">
          <img
            src={`${BACKEND_URL}${program.headerImage}?v=${version}`}
            onError={({ currentTarget }) => {
              currentTarget.onerror = null;
              currentTarget.src = "/logo.svg";
            }}
            alt={program.title}
            className="absolute w-full h-full object-cover"
          />
          <div className="w-full h-fit p-3 bg-gradient-to-t from-white to-transparent rounded-xl flex-col justify-start items-start gap-1 inline-flex z-10">
            <div className="self-stretch text-neutral-700 text-base font-semibold leading-[19px]">
              {program.title || "-"}
            </div>
            <div className="self-stretch text-neutral-600 text-[10px] font-normal leading-3 tracking-tight">
              {program.description || "-"}
            </div>
          </div>
        </div>
      )}
      {platform === "codeflix" && (
        <div className="w-[292px] h-[122px] justify-start items-center gap-3 inline-flex">
          <div className="w-[122px] h-[122px] justify-center items-center flex">
            <img
              src={`${BACKEND_URL}${program.headerImage}?v=${version}`}
              onError={({ currentTarget }) => {
                currentTarget.onerror = null;
                currentTarget.src = "/logo.svg";
              }}
              alt={program.title}
              className="w-[122px] h-[122px] relative"
            />
          </div>
          <div className="grow shrink basis-0 flex-col justify-start items-start gap-2 inline-flex">
            <div className="self-stretch text-neutral-700 text-base font-semibold font-sans leading-tight">
              {program.title || "-"}
            </div>
            <div className="self-stretch text-neutral-600 text-xs font-normal font-sans leading-[18px]">
              {program.description || "-"}
            </div>
          </div>
        </div>
      )}
      <div className="p-1 rounded flex-col justify-start items-start gap-3 inline-flex">
        <div className="self-stretch h-[99px] flex-col justify-center items-center gap-1 flex">
          <div className="w-8 h-8 relative">
            <MdBolt className="w-full h-full fill-neutral-700" />
          </div>
          <div className="flex-col justify-center items-center gap-1 flex">
            <div className="w-[41px] text-center text-neutral-700 text-lg font-semibold leading-snug">
              {program.experiencePoints || 0}
            </div>
            <div className="pb-[3px] flex-col justify-center items-end gap-2 flex">
              <div className="justify-center items-end gap-2 inline-flex">
                <div className="text-center text-neutral-500 text-[10px] font-normal uppercase leading-3 tracking-wide">
                  Knowledge points
                </div>
              </div>
            </div>
          </div>
          {/* <div className="self-stretch h-0.5 bg-neutral-200 flex-col justify-start items-start gap-2 flex">
            <div
              className={`w-[79px] h-0.5 ${getProgressBarColor(expPoints)}`}
              style={{ width: `${expPoints / 10}%` }}
            />
          </div> */}
        </div>
      </div>
      <div className="w-[165px] p-1 rounded flex-col justify-start items-start gap-1 inline-flex">
        <div className="self-stretch h-[66px] flex-col justify-center items-center gap-1 flex">
          <div className="p-2 rounded-full justify-start items-end gap-2 inline-flex">
            <div className="w-8 h-8 relative">
              <MdOutlineCollectionsBookmark className="w-full h-full fill-neutral-700" />
            </div>
          </div>
          <div className="self-stretch justify-start items-center gap-2 inline-flex">
            <div className="grow shrink basis-0 text-center text-neutral-500 text-xs font-bold uppercase leading-[14px] tracking-wide">
              Courses
            </div>
          </div>
          <div className="self-stretch h-[30px] flex-col justify-start items-start gap-0.5 flex">
            <div className="self-stretch justify-center items-center flex-col inline-flex">
              {program.courses && program.courses.length > 0 ? (
                program.courses.map((course: Course, index: number) => {
                  if (index <= maxCoursesToShow) {
                    return (
                      <div
                        key={index}
                        className="grow shrink basis-0 text-center text-neutral-500 text-xs font-normal font-sans leading-[14px] whitespace-nowrap"
                      >
                        {index === maxCoursesToShow ? "..." : course.title}
                      </div>
                    );
                  }
                })
              ) : (
                <div className="grow shrink basis-0 text-center text-neutral-500 text-xs font-normal font-sans leading-[14px]">
                  No courses defined yet
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      {userRole === "master admin" && (
        <div className="w-12 self-stretch justify-center items-center gap-3 flex">
          <button
            className="p-3 bg-neutral-100 rounded-full justify-start items-start gap-2 flex cursor-pointer z-20 hover:bg-neutral-200 ease-in-out duration-300"
            onClick={handleEdit}
          >
            <div className="w-6 h-6">
              <MdOutlineEdit className="w-full h-full fill-neutral-700" />
            </div>
          </button>
        </div>
      )}
      {/* {["customer admin", "class admin"].includes(userRole) && (
        <div className="w-12 self-stretch justify-center items-center gap-3 flex">
          <div
            className="p-3 bg-neutral-100 rounded-full justify-start items-start gap-2 flex cursor-pointer"
            onClick={handleAddToClasses}
          >
            <div className="w-6 h-6">
              <MdOutlineAddToPhotos className="w-full h-full fill-neutral-700" />
            </div>
          </div>
        </div>
      )} */}
      {["parent", "student"].includes(userRole) && (
        <div className="w-12 self-stretch justify-center items-center gap-3 flex">
          <button
            className="p-3 bg-neutral-100 rounded-full justify-start items-start gap-2 flex cursor-pointer z-20 hover:bg-neutral-200 ease-in-out duration-300"
            onClick={() => {}}
          >
            <div className="w-6 h-6">
              <MdArrowForward className="w-full h-full fill-neutral-700" />
            </div>
          </button>
        </div>
      )}
    </div>
  );
};

export default ProgramCard;
