import React from "react";
import { QuizListType } from "utils/interfaces";
import QuizImage from "assets/imgs/QuizImage.svg";

interface StartQuizProps {
  quiz: QuizListType;
  onStartClick: () => void;
  onBackClick: () => void;
}

const StartQuizView: React.FC<StartQuizProps> = ({
  quiz,
  onStartClick,
  onBackClick,
}) => {
  return (
    <div className="w-full h-full flex-col justify-center items-center gap-10 inline-flex">
      <div className="w-[145.10px] h-64 relative">
        <img alt="quiz" src={QuizImage} className="w-full h-full" />
      </div>
      <div className="justify-start items-center gap-6 inline-flex">
        <div className="opacity-0 flex-col justify-start items-center gap-1 inline-flex">
          <div className="p-3 bg-Canvas rounded-full backdrop-blur-[32px] flex-col justify-start items-start gap-6 flex">
            <div className="w-8 h-8 px-[11.06px] py-2 justify-center items-center inline-flex" />
          </div>
        </div>
        <div className="flex-col justify-center items-start gap-1 inline-flex">
          <div className="self-stretch text-center text-neutral-600 text-[23px] font-bold font-['Plus Jakarta Sans'] leading-7">
            {quiz.title}
          </div>
          <div className="self-stretch justify-center items-center gap-3 inline-flex">
            <div className="text-center text-neutral-600 text-base font-normal font-sans leading-[19px]">
              {quiz.questions ? quiz.questions.length : 0} question
              {(quiz.questions ? quiz.questions.length : 0) > 1 ? "s" : ""}
            </div>
          </div>
        </div>
        <div className="opacity-0 flex-col justify-start items-center gap-1 inline-flex">
          <div className="p-3 bg-Canvas rounded-full backdrop-blur-[32px] flex-col justify-start items-start gap-6 flex">
            <div className="w-8 h-8 px-[11.06px] py-2 justify-center items-center inline-flex" />
          </div>
        </div>
      </div>
      <div className="self-stretch h-[94px] flex-col justify-start items-start gap-4 flex">
        <div className="self-stretch justify-center items-center gap-2 inline-flex">
          <div
            className="grow shrink basis-0 h-[39px] max-w-[450px] px-[18px] py-2.5 bg-dc-secondary-600 hover:bg-dc-secondary-700 ease-in-out duration-150 rounded justify-center items-center gap-2 flex cursor-pointer"
            onClick={onStartClick}
          >
            <div className="text-white text-base font-semibold font-sans leading-[19px]">
              Start quiz now
            </div>
          </div>
        </div>
        <div className="self-stretch justify-center items-center gap-2 inline-flex">
          <div className="grow shrink basis-0 h-[39px] px-[18px] py-2.5 rounded justify-center items-center gap-2 flex">
            <div
              className="text-dc-secondary-600 hover:underline text-base font-semibold font-sans leading-[19px] cursor-pointer"
              onClick={onBackClick}
            >
              I'll do it later
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StartQuizView;
