import React from 'react'

interface WideChevronProps {
  isLight?: boolean;
}

const WideChevron: React.FC<WideChevronProps> = ({isLight=false}) => {
  return (
    <svg
        width="36"
        height="7"
        viewBox="0 0 36 7"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className="m-auto"
    >
        <path
        id="Polygon 1"
        d="M18.2774 6.91994C18.0962 6.97224 17.9039 6.97224 17.7227 6.91994L1.40962 2.21077C0.297043 1.8896 0.528961 0.25 1.68697 0.25L34.313 0.25C35.471 0.25 35.703 1.8896 34.5904 2.21077L18.2774 6.91994Z"
        fill={isLight? "#fff" : "#353E47"}
        />
    </svg>
  )
}

export default WideChevron