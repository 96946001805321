/**
 * isFormValid.ts
 * --------------
 * Function that check if the form contains or not error.
 * Used for display purposes.
 * @param err 
 * @returns boolean
 */
export const isFormInvalid = (err: any) => {
  if (Object.keys(err).length > 0) return true;
  return false;
}