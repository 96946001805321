import { MdAddCircleOutline } from "react-icons/md";
import { GoArrowLeft } from "react-icons/go";
import { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import { ClassListType, SchoolListType } from "utils/interfaces";
import ClassesCardsList from "./ClassesCardsList";
import CreateNewClass from "./CreateNewClass";
import WarningPopUpMessage from "../WarningPopUpMessage";
import SavedSuccessfulPanel from "../saved-successful-pop-up/SavedSuccessfulPanel";

interface AssignClassPanelProps {
  ref?: any;
  selectedData?: ClassListType;
  contextSchool?: SchoolListType | null;
  selectedClasses?: ClassListType[];
  closeOnceClickOnCard?: boolean;
  justCreateNew?: boolean;
  showWarningMessage?: boolean;
  selectedLesson?: string;
  lessonIdToAssign?: string;
  setShowPopUp: React.Dispatch<React.SetStateAction<boolean>>;
  setSelectedClasses?: React.Dispatch<React.SetStateAction<ClassListType[]>>;
  setShowWarningMessage?: React.Dispatch<React.SetStateAction<boolean>>;
  getData?: () => Promise<any>;
}

/**
 * Element that represent the pop up panel for adding/creating a class
 * @param selectedData Object that contains a selected class. It's used to load the information
 *                     when editing a class.
 * @param contextSchool Reference of the school where the list of classes should be found.
 * @param selectedClasses List of selected classes (based on external decisions. i.e: selected to be assigned
 *                        for lessons, for organisations, for students, etc.).
 * @param closeOnceClickOnCard (optional) Indicates if the pop up should be closed once the card is selected.
 *                             By default is `true`.
 * @param justCreateNew (optional) Indicates if the pop up should behave just from the "Create New Classes"
 *                      view. This is used on "Create" button in the header of users view. By default is `false`.
 * @param showWarningMessage Indicates if showing the warning message when click out the pop up window.
 *                           By default is `false`.
 * @param selectedLesson Indicates to what type of lesson the classes should be assigned when pressing the
 *                       assign lesson button on lesson card in the customers admin/lessons view. If it's
 *                       undefined means that is part of a form.
 * @param lessonIdToAssign _id of the lesson to assign based on the selection of the `selectedLesson` parameter.
 * @param setShowPopUp Function used to define if the pop should be showed or closed.
 * @param setSelectedClasses Function used to set the selected classes.
 * @param setShowWarningMessage Function used to define the warning message when click out the
 *                              pop up window.
 * @param getData Function used to refresh an external data after creating a class.
 * @returns
 */
const AssignClassPanel: React.FC<AssignClassPanelProps> = forwardRef(
  (
    {
      selectedData,
      contextSchool,
      selectedClasses,
      closeOnceClickOnCard = true,
      justCreateNew = false,
      showWarningMessage = false,
      selectedLesson,
      lessonIdToAssign,
      setShowPopUp,
      setSelectedClasses,
      setShowWarningMessage,
      getData,
    },
    ref
  ) => {
    // States definition
    // -> Start from "create-new" if we just want to use it for create
    const [popUpState, setPopUpState] = useState<
      "main" | "create-new" | "add-teacher" | "set-password"
    >(justCreateNew ? "create-new" : "main");
    const [showSaveSuccessPopUp, setShowSaveSuccessPopUp] = useState(false);

    /**
     * Define the list of functions that can be used from external components
     * using a reference
     */
    useImperativeHandle(ref, () => ({
      getPopUpState() {
        return popUpState;
      },
      getShowSaveSuccessPopUp() {
        return showSaveSuccessPopUp;
      },
      setPopUpState(
        state: "main" | "create-new" | "add-teacher" | "set-password"
      ) {
        setPopUpState(state);
      },
    }));

    /**
     * Definition of the function when pressed back button
     */
    const handlePressBack = () => {
      // If create new was pressed, then come back to the regular option
      if (popUpState === "add-teacher") setPopUpState("create-new");
      else if (popUpState === "create-new") {
        // If we are using the create new option, then close the pop up
        // once clicked (we will not want to watch the assign menu when
        // just creating)
        if (justCreateNew) closePopUpAction();
        else setPopUpState("main");
      }
      // Otherwise, close the pop up
      else closePopUpAction();
    };

    const closePopUpAction = () => {
      // Ensure the warning message is closed
      if (setShowWarningMessage) setShowWarningMessage(false);

      // Close the pop up
      setShowPopUp(false);
    };

    /**
     * Definition of the function executed after creating a parent
     * in the database
     */
    const afterCreateClass = () => {
      // Come back to the regular view (depending on the justCreateNew option)
      if (justCreateNew) setPopUpState("create-new");
      else setPopUpState("main");

      // And display the success message (if it has been created, not when editing)
      if (!selectedData) setShowSaveSuccessPopUp(true);
      // But if editing, then close the pop up
      else setShowPopUp(false);

      // And refresh the view if we are creating data from the main view
      if (justCreateNew && getData) getData();
    };

    /**
     * Function to execute once clicked "Create another"
     */
    const handleCreateAnother = () => {
      // Hide the success pop up
      setShowSaveSuccessPopUp(false);

      // And go to create-new state in the pop up
      setPopUpState("create-new");
    };

    /**
     * Timer for the warning pop up message
     */
    useEffect(() => {
      if (showWarningMessage) {
        // Starting the timer
        const timer = setTimeout(() => {
          // Once finished, close the pop up
          if (setShowWarningMessage) setShowWarningMessage(false);
        }, 5000);
        return () => clearTimeout(timer);
      }
    }, [showWarningMessage]);

    /**
     * Adding a listener for keyboard shortcuts
     */
    useEffect(() => {
      // Function to use on escape
      const handleKeyDown = (event: KeyboardEvent) => {
        if (event.key === "Escape") {
          handlePressBack();
        }
      };

      // Adding the lister
      document.addEventListener("keydown", handleKeyDown);

      // Cleanup
      return () => {
        document.removeEventListener("keydown", handleKeyDown);
      };
    }, [popUpState]);

    return (
      <div className="relative w-full h-fit max-h-[80vh] flex-col justify-start items-start gap-6 inline-flex">
        {!showSaveSuccessPopUp && (
          <>
            {popUpState !== "add-teacher" && popUpState !== "set-password" && (
              <div className="self-stretch justify-between items-center inline-flex">
                <div className="justify-start items-center gap-3 flex">
                  <button
                    className="p-1.5 bg-neutral-50 rounded-full backdrop-blur-[32px] justify-center items-center flex cursor-pointer hover:bg-neutral-100 ease-in-out duration-150"
                    onClick={handlePressBack}
                  >
                    <div className="w-5 h-5 justify-center items-center flex">
                      <div className="w-5 h-5 relative">
                        <GoArrowLeft className="w-full h-full fill-dc-secondary-600" />
                      </div>
                    </div>
                  </button>
                  <div className="text-neutral-600 text-lg font-normal font-sans leading-snug">
                    {popUpState === "main"
                      ? selectedLesson && lessonIdToAssign
                        ? "Assign to class"
                        : "Classes"
                      : selectedData
                      ? "Edit Class"
                      : popUpState === "create-new"
                      ? "Create new class"
                      : ""}
                  </div>
                </div>
                {popUpState === "main" && (
                  <button
                    className="group rounded justify-center items-center gap-2 flex cursor-pointer"
                    onClick={() => setPopUpState("create-new")}
                  >
                    <div className="w-6 h-6 relative">
                      <MdAddCircleOutline className="w-full h-full fill-dc-secondary-600 group-hover:fill-dc-secondary-700" />
                    </div>
                    <div className="text-dc-secondary-600 group-hover:text-dc-secondary-700 text-lg font-semibold font-sans">
                      Create new
                    </div>
                  </button>
                )}
              </div>
            )}
            {popUpState === "main" && (
              <ClassesCardsList
                contextSchool={contextSchool}
                selectedClasses={selectedClasses || []}
                closeOnceClickOnCard={closeOnceClickOnCard}
                selectedLesson={selectedLesson}
                lessonIdToAssign={lessonIdToAssign}
                setShowPopUp={setShowPopUp}
                setSelectedClasses={
                  setSelectedClasses ? setSelectedClasses : () => {}
                }
              />
            )}
            {["create-new", "add-teacher", "set-password"].includes(
              popUpState
            ) && (
              <CreateNewClass
                selectedData={selectedData}
                contextSchool={contextSchool}
                popUpState={popUpState}
                setPopUpState={setPopUpState}
                setShowPopUp={setShowPopUp}
                afterCreateFunction={afterCreateClass}
              />
            )}
          </>
        )}
        {showSaveSuccessPopUp && (
          <SavedSuccessfulPanel
            title="Class Created Successfully"
            paragraphs={["The new class has been successfully created."]}
            createText="Create another"
            onCreateAnother={handleCreateAnother}
            onClose={() => {
              justCreateNew
                ? setShowPopUp(false)
                : setShowSaveSuccessPopUp(false);
            }}
          />
        )}
        {setShowWarningMessage && (
          <WarningPopUpMessage
            showWarningMessage={showWarningMessage}
            setShowWarningMessage={setShowWarningMessage}
          />
        )}
      </div>
    );
  }
);

export default AssignClassPanel;
