import { useState } from "react";
import {
  MdBolt,
  MdCheckBox,
  MdCheckBoxOutlineBlank,
  MdOutlineStackedBarChart,
  MdTerminal,
} from "react-icons/md";

interface AddRemoveCardProps {
  id: string;
  title: string;
  level: string;
  points: number;
  language: string;
  previouslyAdded: boolean;
  selectAction: (id: string, action: "add" | "remove") => void;
}

const AddRemoveCard: React.FC<AddRemoveCardProps> = ({
  id,
  title,
  level,
  points,
  language,
  previouslyAdded = false,
  selectAction,
}) => {
  // States definition
  const [selected, setSelected] = useState(previouslyAdded);

  /**
   * Function used to operate on click of the checkbox
   */
  const handleOnClick = async () => {
    // If it's not currently selected, then add it
    if (!selected) selectAction(id, "add");
    // And if it's currently selected, then remove it
    else selectAction(id, "remove");

    // And toggle the state
    setSelected(!selected);
  };

  return (
    <div
      className={
        selected
          ? "self-stretch p-3 bg-neutral-25 hover:bg-neutral-50 ease-in-out duration-100 rounded-xl border border-neutral-600 justify-start items-center gap-3 inline-flex cursor-pointer"
          : "self-stretch p-3 bg-neutral-25 hover:bg-neutral-50 ease-in-out duration-100 rounded-xl border border-transparent justify-start items-center gap-3 inline-flex cursor-pointer"
      }
      onClick={handleOnClick}
    >
      <div className="grow shrink basis-0 flex-col justify-center items-start gap-3 inline-flex">
        <div className="self-stretch justify-start items-start gap-3 inline-flex">
          <div className="grow shrink basis-0 text-neutral-700 text-sm font-normal font-sans leading-[17px] select-none">
            {title}
          </div>
          <div className="w-6 h-6 relative">
            {selected ? (
              <MdCheckBox className="w-full h-full fill-neutral-600" />
            ) : (
              <MdCheckBoxOutlineBlank className="w-full h-full fill-neutral-600" />
            )}
          </div>
        </div>
        <div className="self-stretch justify-between items-center inline-flex">
          <div className="w-48 self-stretch justify-start items-center gap-2.5 flex">
            <div className="justify-start items-center gap-0.5 flex">
              <div className="w-4 h-4 relative">
                <MdOutlineStackedBarChart className="w-full h-full fill-neutral-450" />
              </div>
              <div className="text-neutral-500 text-xs font-normal font-inter leading-[14px] select-none">
                {level}
              </div>
            </div>
            <div className="w-px self-stretch justify-start items-start gap-2 flex">
              <div className="w-px self-stretch bg-neutral-200" />
            </div>
            <div className="justify-center items-center gap-0.5 flex">
              <div className="w-4 h-4 relative">
                <MdBolt className="w-full h-full fill-neutral-450" />
              </div>
              <div className="text-neutral-500 text-xs font-normal font-inter leading-[14px] select-none">
                {points}
              </div>
            </div>
            {!!language && (
              <>
                <div className="w-px self-stretch justify-start items-start gap-2 flex">
                  <div className="w-px self-stretch bg-neutral-200" />
                </div>
                <div className="justify-center items-center gap-0.5 flex">
                  <div className="w-4 h-4 relative">
                    <MdTerminal className="w-full h-full fill-neutral-450" />
                  </div>
                  {
                    <div className="text-neutral-500 text-xs font-normal font-inter leading-[14px] select-none">
                      {language}
                    </div>
                  }
                </div>
              </>
            )}
          </div>
          {previouslyAdded && (
            <div className="px-1 py-0.5 bg-neutral-400 rounded-sm justify-start items-center gap-2 flex">
              <div className="text-white text-[10px] font-normal font-sans uppercase leading-3 tracking-wide select-none">
                Previously added
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default AddRemoveCard;
