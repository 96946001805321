/**
 * Function that maps the activeLesson to the child and parent information
 * @returns: object with the parent and the child lesson
 */
const lessonInformation = (activeLesson: string) => {
  switch (activeLesson) {
    case "programs":
      return { child: "courses", parent: "programs" };
    case "courses":
      return { child: "projects", parent: "programs" };
    case "projects":
      return { child: "modules", parent: "courses" };
    case "modules":
      return { child: "", parent: "projects" };
    default:
      return { child: "", parent: "modules" };
  }
};

export default lessonInformation