import { useEffect, useState } from "react";
import { GoArrowLeft } from "react-icons/go";
import { updateRecordRequest } from "services/apiRequests";
import { ClassListType } from "utils/interfaces";

interface SetPasswordPanelProps {
  selectedData?: ClassListType;
  externalSetPopUpState?: React.Dispatch<
    React.SetStateAction<"main" | "create-new" | "add-teacher" | "set-password">
  >;

}

const SetPasswordPanel: React.FC<SetPasswordPanelProps> = ({
  selectedData,
  externalSetPopUpState,
}) => {
  // States definition
  const [password, setPassword] = useState("");
  const [rePassword, setRePassword] = useState("");
  const [passwordCheck, setPasswordCheck] = useState(false);

  /**
   * Function used to check if the pair of passwords are the same
   * and pass the security tests
   */
  useEffect(() => {
    setPasswordCheck(!! password && !!rePassword && password === rePassword)
  }, [password, rePassword])

  /**
   * Definition of the function when pressed back button
   */
  const handlePressBack = () => {
    if (externalSetPopUpState) externalSetPopUpState("create-new");
  };

  /**
   * Function used to set the password to the whole class
   */
  const handleSavePassword = async () => {
    // Send the password for every student in this class
    const response = await updateRecordRequest({
      classId: selectedData ? selectedData._id : "",
      newPassword: password
    }, "/api/classes/set-passwords");

    // Check if the request
    if (!response.successful){
      alert(response.message);
      return;
    }

    // And close the pop up
    if (externalSetPopUpState) externalSetPopUpState("create-new");
  }

  return (
    <div className="relative w-full h-fit max-h-[80vh] flex-col justify-start items-start gap-6 inline-flex">
      <div className="self-stretch justify-between items-center inline-flex">
        <div className="justify-start items-center gap-3 flex">
          <div
            className="p-1.5 bg-neutral-50 rounded-full backdrop-blur-[32px] justify-center items-center flex cursor-pointer hover:bg-neutral-100 ease-in-out duration-300"
            onClick={handlePressBack}
          >
            <div className="w-5 h-5 justify-center items-center flex">
              <div className="w-5 h-5 relative">
                <GoArrowLeft className="w-full h-full fill-dc-secondary-600" />
              </div>
            </div>
          </div>
          <div className="text-neutral-600 text-lg font-normal font-sans leading-snug">
            Set a password for this class
          </div>
          
        </div>
      </div>
      <div className="self-stretch h-fit flex-col justify-start items-start gap-4 flex">
        <div className="self-stretch h-fit flex-col justify-start items-start gap-1.5 flex">
          <div className="text-neutral-500 text-xs font-normal font-sans uppercase leading-[14px] tracking-wide">
            Password
          </div>
          <div className="self-stretch bg-neutral-50 rounded-lg justify-start items-center gap-2 inline-flex">
            <input
              type="password"
              value={password}
              onChange={(event) => setPassword(event.target.value)}
              className="w-full h-full p-3 text-neutral-700 placeholder:text-neutral-300 text-base font-sans leading-[19px] bg-transparent border-none focus:outline-none"
              placeholder="Password"
            />
          </div>
        </div>
        <div className="self-stretch h-fit flex-col justify-start items-start gap-1.5 flex">
          <div className="text-neutral-500 text-xs font-normal font-sans uppercase leading-[14px] tracking-wide">
            Confirm password
          </div>
          <div className="self-stretch bg-neutral-50 rounded-lg justify-start items-center gap-2 inline-flex">
            <input
              type="password"
              value={rePassword}
              onChange={(event) => setRePassword(event.target.value)}
              className="w-full h-full p-3 text-neutral-700 placeholder:text-neutral-300 text-base font-sans leading-[19px] bg-transparent border-none focus:outline-none"
              placeholder="Repeat the password"
            />
          </div>
        </div>
      </div>
      <div
        className={
          passwordCheck
            ? "self-end w-full h-fit px-5 py-3 bg-dc-secondary-600 hover:bg-dc-secondary-700 ease-in-out duration-150 rounded justify-center items-center gap-2 inline-flex cursor-pointer"
            : "self-end w-full h-fit px-5 py-3 bg-Subtle rounded justify-center items-center gap-2 inline-flex cursor-not-allowed"
        }
        onClick={handleSavePassword}
      >
        <div
          className={
            passwordCheck
              ? "text-white text-lg font-semibold font-sans leading-snug"
              : "text-neutral-400 text-lg font-semibold font-sans leading-snug"
          }
        >
          Save Password
        </div>
      </div>
    </div>
  );
};

export default SetPasswordPanel;
