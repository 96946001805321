// Parameters
const minCharacters = 10;

/**
 * Regex to validate whether the phone number format adheres
 * to the Australian style guidelines.
 * Reference: https://www.stylemanual.gov.au/grammar-punctuation-and-conventions/numbers-and-measurements/telephone-numbers
 */
export const phoneRegex = /^(0(?:\s*\d\s*){9}|13(?:\s*\d\s*){4})$/;

/**
 * Regex to validate whether the email format adheres to the required style guidelines.
   * Check if the email:
   * 1. Has one or more characters that can be letters (a-z, A-Z), digits (0-9), underscores (_), dots (.), plus signs (+), or hyphens (-).
   * 2. Has the at symbol.
   * 3. Has a domain name.
   * 4. Has a dot.
   * 5. Has a top level domain.
   */
export const emailRegex = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/;

/**
 * Regex to validate if the string has at least one uppercase letter
 */
export const haveUppercaseRegex = /(?=.*[A-Z])/;

/**
 * Regex to validate if the string has at least one lowercase letter
 */
export const haveLowercaseRegex = /(?=.*[a-z])/;

/**
 * Regex to validate if the string has at least one digit
 */
export const haveDigitRegex = /(?=.*\d)/;

/**
 * Regex to define the minimum length of the string
 */
export const minimumCharactersRegex = new RegExp(
  `[A-Za-z\d@$!%*?&\-_]{${minCharacters},}`
);

// Regex expresions
export const passWordConditions = `The password must have at least ${minCharacters} characters, one uppercase letter, one lowercase letter, one number and one special character (@$!%*?&-_)`;
export const passWordRegex = new RegExp(
  `(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&\-_])[A-Za-z\d@$!%*?&\-_]{${minCharacters},}`
);
export const passWordRegexHookForm = /(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&\-_])[A-Za-z\d@$!%*?&\-_]{10,}/;
