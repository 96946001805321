import { BACKEND_URL } from "config/getUrl";
import { validateClient } from "./authRequests";
import Cookies from "js-cookie";
import { createRecordRequest, updateRecordRequest } from "./apiRequests";
import { ClassListType } from "utils/interfaces";

export const getReports = async (classId: any, format: "csv" | "pdf") => {
  // Validate the tokens
  const validateResponse = await validateClient();

  // Check if the user is still connected
  if (!validateResponse.connected) {
    return {
      message: "It was not possible to find an access or refresh token.",
      connected: false,
    };
  }

  // Get the accessToken
  const accessToken = Cookies.get("accessToken");

  // Check that the access token is not undefined
  if (!accessToken) {
    return {
      message: "It was not possible to find an access token.",
      connected: false,
    };
  }
  // Set up the options for the request
  const options: any = {
    method: "POST",
    headers: {
      "Authorization": `Bearer ${accessToken}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      classId: classId._id,
      format: format
    })
  };
  if (format === "csv") {
    options.headers.Accept = "text/csv"
  } else if (format === "pdf") {
    options.headers.Accept = "application/pdf"
  }
  try {
    // Make the request to get the CSV file
    const response = await fetch(`${BACKEND_URL}/api/classes/report`, options);

    // Check if the response is OK
    if (!response.ok) {
      throw new Error('Failed to fetch the report.');
    }

    // Extract filename from the Content-Disposition header
    const contentDisposition = response.headers.get('Content-Disposition');
    let filename = `report.${format}`; // Default filename

    if (contentDisposition) {
      // Match both quoted and unquoted filenames and decode if necessary
      const matches = contentDisposition.match(/filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/);
      if (matches && matches[1]) {
        // Trim quotes if they exist and decode the filename
        filename = decodeURIComponent(matches[1].replace(/['"]/g, ''));
      }
    }

    // Convert the response to a Blob
    const blob = await response.blob();

    // Create a URL for the blob
    const url = window.URL.createObjectURL(blob);

    // Create a link element
    const link = document.createElement('a');
    link.href = url;
    link.download = filename; // Set the download filename

    // Append link to body, click it to start download, and then remove it
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);

    // Revoke the blob URL after the download is triggered
    window.URL.revokeObjectURL(url);

    return { message: "Report downloaded successfully.", connected: true };
  } catch (error) {
    console.error('Error fetching the report:', error);
    return { message: "Failed to download the report.", connected: false };
  }
}

/**
 * Function used to create and update classes in the context of
 * creating/editing an organisation. This creates the classes if they
 * are being created inside a "Create New School", and update
 * which class is active inside "Editing School".
 * @param organisationId _id of the organisation that contains these classes.
 * @param classes List of classes to be created/updated.
 * @param baseSelectedClasses In the "Editing School" context, the classes that were selected originally.
 */
export const createUpdateClassesInOrganisation = (
  organisationId: string,
  classes: ClassListType[],
  baseSelectedClasses: { [key: string]: boolean }
) => {
  // Get all the new classes to be created and send them to be created
  classes
    .filter((classItem: ClassListType) => classItem.tempId)
    .forEach(async (quiz: ClassListType) => {
      // Get the quiz data
      const { _id, ...classData } = quiz;

      // Add the course _id to the quiz
      classData.organisations = [ organisationId ];

      // Send the request to be created
      const classResponse = await createRecordRequest(classData, "/api/classes");

      // If the response is successful, then show the pop up message
      if (!classResponse.successful) {
        alert(classResponse.message);
      }
    });
  
  // And for the currently created ones, check if the activate has changed
  classes
    .filter((classItem: ClassListType) => classItem._id)
    .forEach(async (classItem: ClassListType) => {
      // Compare if the activate field has changed
      if (classItem.active !== baseSelectedClasses[classItem._id]) {
        // Then update the class activate status
        const classResponse = await updateRecordRequest(
          { _id: classItem._id, toUpdate: { active: classItem.active } },
          "/api/classes"
        );

        // If the response is successful, then show the pop up message
        if (!classResponse.successful) {
          alert(classResponse.message);
        }
      }
    });
};
