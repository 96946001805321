import {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import {
  MdCheckBox,
  MdCheckBoxOutlineBlank,
  MdCheckCircleOutline,
  MdOutlineCancel,
} from "react-icons/md";
import { createRecordRequest, updateRecordRequest } from "services/apiRequests";
import { allowOnlyNumbers } from "utils/utils";
import { formatUpdateContact } from "utils/formatUpdate";
import { ContactListType, SchoolListType } from "utils/interfaces";
import { phoneRegex } from "utils/regex";
import { AnimatePresence, motion } from "framer-motion";

export interface CreateNewContactProps {
  ref?: any;
  role: "student" | "parent" | "class admin" | "customer admin";
  contextSchool?: SchoolListType | null;
  contactToEdit?: ContactListType | null;
  selectedContacts: ContactListType[];
  confirmButtonText: string;
  showPrimaryOption?: boolean;
  setSelectedContacts: React.Dispatch<React.SetStateAction<ContactListType[]>>;
  setLastSelectedContact?: React.Dispatch<
    React.SetStateAction<ContactListType>
  >;
  afterCreateEditContact: () => void;
}

const CreateNewContact: React.FC<CreateNewContactProps> = forwardRef(
  (
    {
      role,
      contextSchool,
      contactToEdit,
      selectedContacts,
      confirmButtonText,
      showPrimaryOption = true,
      setSelectedContacts,
      setLastSelectedContact,
      afterCreateEditContact,
    },
    ref
  ) => {
    // States definition
    const [firstName, setFirstName] = useState("");
    const [middleName, setMiddleName] = useState("");
    const [lastName, setLastName] = useState("");
    const [email, setEmail] = useState("");
    const [phoneNumber, setPhoneNumber] = useState("");
    const [addAsPrimaryContact, setAddAsPrimaryContact] = useState(
      selectedContacts.length === 0
    );
    const [activateCreateButton, setActivateCreateButton] = useState(false);
    // Validation states
    const [emailValidation, setEmailValidation] = useState<boolean | null>(
      null
    );
    const [phoneValidation, setPhoneValidation] = useState<boolean | null>(
      null
    );

    // Ref to access the email input element
    const emailInputRef = useRef<HTMLInputElement>(null);

    /**
     * Functions to externalise using refs
     */
    useImperativeHandle(ref, () => ({
      // Function to present all the gathered data in the form
      provideData() {
        return provideData();
      },
    }));

    /**
     * If there is selected data, then prefill the defined fields
     */
    useEffect(() => {
      if (contactToEdit) {
        setFirstName(contactToEdit.firstName);
        setMiddleName(contactToEdit.middleName ? contactToEdit.middleName : "");
        setLastName(contactToEdit.lastName);
        setEmail(contactToEdit.email);
        setPhoneNumber(contactToEdit.phone);

        // Wait until email and phone are defined to validate them
        if (contactToEdit.email) validateEmailExternally();
        if (contactToEdit.phone) validatePhoneNumber(contactToEdit.phone);
      }
    }, []);

    /**
     * Function to activate or deactivate create contact button
     */
    useEffect(() => {
      // Check if they have content
      const condition = !!(
        firstName &&
        lastName &&
        email &&
        phoneNumber &&
        emailValidation &&
        phoneValidation
      );
      setActivateCreateButton(condition);
    }, [
      firstName,
      lastName,
      email,
      phoneNumber,
      emailValidation,
      phoneValidation,
    ]);

    /**
     * Function used to validate the email by something different than an event
     */
    const validateEmailExternally = () => {
      if (emailInputRef.current)
        setEmailValidation(emailInputRef.current.checkValidity());
    };

    /**
     * Function used to validate the current email
     */
    const validateEmail = (event: React.ChangeEvent<HTMLInputElement>) => {
      // Check if the form is valid using HTML5 checkValidity
      // Reference: https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input/email#validation
      setEmailValidation(!!event.target.value && event.target.checkValidity());
    };

    /**
     * Function used to validate the current phone number
     */
    const validatePhoneNumber = (phoneToValidate: string) => {
      setPhoneValidation(phoneRegex.test(phoneToValidate));
    };

    /**
     * Function that returns all the form data
     * @returns data
     */
    const provideData = () => {
      // Define the provided data
      const dataToProvide: {
        firstName: string;
        middleName: string;
        lastName: string;
        email: string;
        phone: string;
        role: string;
        addAsPrimaryContact: boolean;
        organisations?: string[];
      } = {
        firstName: firstName,
        middleName: middleName,
        lastName: lastName,
        email: email,
        phone: phoneNumber,
        role: role,
        addAsPrimaryContact: addAsPrimaryContact,
      };

      // Check the context if it's possible to add the organisation
      if (contextSchool && contextSchool._id) {
        dataToProvide.organisations = [contextSchool._id];
      }

      // Return all the gathered data in the form
      return [dataToProvide, formatUpdateContact(contactToEdit, dataToProvide)];
    };

    const handleNumberChange = (text: string) => {
      // Prevent from inputing letters and symbols but "+"
      setPhoneNumber(allowOnlyNumbers(text));
    };

    /**
     * Function to handle the "Add as primary contact" checkbox
     */
    const handleAddAsPrimaryContact = () => {
      setAddAsPrimaryContact(!addAsPrimaryContact);
    };

    /**
     * Function to handle creating a contact when the button is pressed
     * @returns
     */
    const handleSaveContact = async () => {
      // Check if the button is already available
      if (!activateCreateButton) return;

      // Get all the data from the form
      const [data, formattedData] = provideData();
      const { addAsPrimaryContact, ...processedData } = formattedData;

      let response = { successful: false, _id: "", message: "" };
      // Request for editing a contact
      if (contactToEdit) {
        // Update in the database
        response = await updateRecordRequest(
          { _id: contactToEdit._id, toUpdate: processedData },
          "/api/users"
        );

        // But also, update the list of selected contacts (to display the update 
        // in the cards)
        const updatedSelectedContacts = selectedContacts.map((contact: ContactListType) => {
          // For the contact of interest to be edited, apply the update
          if (contact._id === contactToEdit._id) {
            return {...data, _id: contact._id};
          }
          // Otherwise just return the original contact
          return contact;
        });

        console.log(updatedSelectedContacts);

        // Set the new selectedContacts
        setSelectedContacts(updatedSelectedContacts as ContactListType[]);
      }
      // Request for creating new contact
      else {
        response = await createRecordRequest(processedData, "/api/users");
      }

      // Check if the response is successful
      if (!response.successful) {
        // alert(response.message);
        return;
      }

      // And add as a new contact (execute just if we are creating)
      if (!contactToEdit) {
        const dataOut = {
          _id: response._id,
          firstName: data.firstName,
          lastName: data.lastName,
          email: data.email,
          phone: data.phone,
        };

        // Put first if it's added as first contact
        if (addAsPrimaryContact && showPrimaryOption) {
          setSelectedContacts([dataOut].concat(selectedContacts));
        } else {
          setSelectedContacts(selectedContacts.concat([dataOut]));
        }

        // Add information for the success message
        if (setLastSelectedContact) setLastSelectedContact(dataOut);
      }

      // Execute the defined actions after the edition or creation
      afterCreateEditContact(); 
    };

    return (
      <>
        <div className="w-full h-full overflow-y-auto pop-up-scrollbar flex-col justify-start items-start gap-8 inline-flex">
          <div className="self-stretch h-[280px] flex-col justify-start items-start gap-4 flex">
            <div className="self-stretch text-neutral-500 text-base font-semibold font-sans leading-[19px]">
              Basic details
            </div>
            <div className="self-stretch h-fit flex-col justify-start items-start gap-1.5 flex">
              <div className="rounded justify-start items-start gap-2 inline-flex text-neutral-500 text-xs font-normal font-sans uppercase leading-[14px] tracking-wide">
                First Name*
              </div>
              <div className="self-stretch bg-neutral-50 rounded-lg justify-start items-center gap-2 inline-flex">
                <input
                  type="text"
                  value={firstName}
                  onChange={(event) => setFirstName(event.target.value)}
                  className="w-full h-full p-3 text-neutral-700 placeholder:text-neutral-300 text-base font-sans leading-[19px] bg-transparent border-none focus:outline-none"
                  placeholder="Enter first name"
                />
              </div>
            </div>
            <div className="self-stretch h-fit flex-col justify-start items-start gap-1.5 flex">
              <div className="rounded justify-start items-start gap-2 inline-flex text-neutral-500 text-xs font-normal font-sans uppercase leading-[14px] tracking-wide">
                Middle Name (Optional)
              </div>
              <div className="self-stretch bg-neutral-50 rounded-lg justify-start items-center gap-2 inline-flex">
                <input
                  type="text"
                  value={middleName}
                  onChange={(event) => setMiddleName(event.target.value)}
                  className="w-full h-full p-3 text-neutral-700 placeholder:text-neutral-300 text-base font-sans leading-[19px] bg-transparent border-none focus:outline-none"
                  placeholder="Enter middle name"
                />
              </div>
            </div>
            <div className="self-stretch h-fit flex-col justify-start items-start gap-1.5 flex">
              <div className="rounded justify-start items-start gap-2 inline-flex text-neutral-500 text-xs font-normal font-sans uppercase leading-[14px] tracking-wide">
                Last Name*
              </div>
              <div className="self-stretch bg-neutral-50 rounded-lg justify-start items-center gap-2 inline-flex">
                <input
                  type="text"
                  value={lastName}
                  onChange={(event) => setLastName(event.target.value)}
                  className="w-full h-full p-3 text-neutral-700 placeholder:text-neutral-300 text-base font-sans leading-[19px] bg-transparent border-none focus:outline-none"
                  placeholder="Enter last name"
                />
              </div>
            </div>
          </div>
          <div className="self-stretch h-[236px] flex-col justify-start items-start gap-4 flex">
            <div className="self-stretch text-neutral-500 text-base font-semibold font-sans leading-[19px]">
              Contact information
            </div>

            <div className="self-stretch h-fit flex-col justify-start items-start gap-1.5 flex">
              <div className="rounded justify-start items-start gap-2 inline-flex text-neutral-500 text-xs font-normal font-sans uppercase leading-[14px] tracking-wide">
                Email*
              </div>
              <div
                className={`self-stretch bg-neutral-50 border ${
                  emailValidation === null
                    ? "border-transparent"
                    : !emailValidation
                    ? "border-error-500"
                    : "border-success-500"
                } rounded-lg justify-start items-center gap-2 inline-flex relative`}
              >
                <input
                  ref={emailInputRef}
                  type="email"
                  value={email}
                  onBlur={validateEmail}
                  onChange={(event) => setEmail(event.target.value)}
                  className="w-full h-full p-3 text-neutral-700 placeholder:text-neutral-300 text-base font-sans leading-[19px] bg-transparent border-none focus:outline-none z-10"
                  placeholder="Enter email"
                />
                {emailValidation && (
                  <motion.div
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    transition={{ ease: "easeInOut", duration: 0.2 }}
                    className="absolute right-2 w-6 h-6 flex justify-center items-center"
                  >
                    <MdCheckCircleOutline className="w-full h-full fill-success-500" />
                  </motion.div>
                )}
                {emailValidation !== null && !emailValidation && (
                  <motion.div
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    transition={{ ease: "easeInOut", duration: 0.2 }}
                    className="absolute right-2 w-6 h-6 flex justify-center items-center"
                  >
                    <MdOutlineCancel className="w-full h-full fill-error-500" />
                  </motion.div>
                )}
              </div>
              <AnimatePresence mode="wait" initial={false}>
                {emailValidation !== null && !emailValidation && (
                  <motion.div
                    className="w-full text-error-600 text-xs font-normal font-sans leading-[17px]"
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                  >
                    The email format is incorrect
                  </motion.div>
                )}
              </AnimatePresence>
            </div>
            <div className="self-stretch h-fit flex-col justify-start items-start gap-1.5 flex relative">
              <div className="rounded justify-start items-start gap-2 inline-flex text-neutral-500 text-xs font-normal font-sans uppercase leading-[14px] tracking-wide">
                Phone number - Mobile*
              </div>
              <div
                className={`self-stretch bg-neutral-50 border ${
                  phoneValidation === null
                    ? "border-transparent"
                    : !phoneValidation
                    ? "border-error-500"
                    : "border-success-500"
                } rounded-lg justify-start items-center gap-2 inline-flex`}
              >
                <input
                  type="tel"
                  value={phoneNumber}
                  onBlur={() => validatePhoneNumber(phoneNumber)}
                  onChange={(event) => handleNumberChange(event.target.value)}
                  className="w-full h-full p-3 text-neutral-700 placeholder:text-neutral-300 text-base font-sans leading-[19px] bg-transparent border-none focus:outline-none z-10"
                  placeholder="Enter phone number"
                />
                {phoneValidation && (
                  <motion.div
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    transition={{ ease: "easeInOut", duration: 0.2 }}
                    className="absolute right-2 w-6 h-6 flex justify-center items-center"
                  >
                    <MdCheckCircleOutline className="w-full h-full fill-success-500" />
                  </motion.div>
                )}
                {phoneValidation !== null && !phoneValidation && (
                  <motion.div
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    transition={{ ease: "easeInOut", duration: 0.2 }}
                    className="absolute right-2 w-6 h-6 flex justify-center items-center"
                  >
                    <MdOutlineCancel className="w-full h-full fill-error-500" />
                  </motion.div>
                )}
              </div>
              <AnimatePresence mode="wait" initial={false}>
                {phoneValidation !== null && !phoneValidation && (
                  <motion.div
                    className="w-full text-error-600 text-xs font-normal font-sans leading-[17px]"
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                  >
                    The phone number must follow the format: 0123456789 or
                    130000
                  </motion.div>
                )}
              </AnimatePresence>
            </div>
            {showPrimaryOption && (
              <div
                className="w-fit h-[27px] flex-col justify-start items-start gap-3 flex cursor-pointer"
                onClick={handleAddAsPrimaryContact}
              >
                <div className="self-stretch rounded-lg justify-start items-center gap-3 inline-flex">
                  <div className="w-6 h-6 relative">
                    {addAsPrimaryContact ? (
                      <MdCheckBox className="w-full h-full fill-neutral-300" />
                    ) : (
                      <MdCheckBoxOutlineBlank className="w-full h-full fill-neutral-300" />
                    )}
                  </div>
                  <div className="grow shrink basis-0 h-[27px] py-1 justify-start items-start gap-2 flex text-neutral-700 text-base font-normal font-sans leading-[19px] select-none">
                    Add as primary contact
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
        <button
          className={
            activateCreateButton
              ? "w-full h-[46px] px-5 py-3 bg-dc-secondary-600 hover:bg-dc-secondary-700 ease-in-out duration-150 rounded justify-center items-center gap-2 inline-flex cursor-pointer"
              : "w-full h-[46px] px-5 py-3 bg-Subtle rounded justify-center items-center gap-2 inline-flex cursor-not-allowed"
          }
          onClick={handleSaveContact}
        >
          <div
            className={
              activateCreateButton
                ? "text-white text-lg font-semibold font-sans leading-snug select-none"
                : "text-neutral-400 text-lg font-semibold font-sans leading-snug select-none"
            }
          >
            {confirmButtonText}
          </div>
        </button>
      </>
    );
  }
);

export default CreateNewContact;
