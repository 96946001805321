import { MdCheckCircle } from "react-icons/md";

interface SavedSuccessfulPanelProps {
  title: string;
  paragraphs: string[];
  createText: string;
  onCreateAnother: () => void;
  onClose: () => void;
}

const SavedSuccessfulPanel: React.FC<SavedSuccessfulPanelProps> = ({
  title,
  paragraphs,
  createText,
  onCreateAnother,
  onClose,
}) => {
  return (
    <>
      <div className="self-stretch px-3.5 py-9 justify-between items-start inline-flex">
        <div className="w-8 h-8 p-[7.21px] opacity-0 justify-center items-center flex" />
        <div className="w-[108px] h-[108px] relative">
          <MdCheckCircle className="w-full h-full fill-success-500" />
        </div>
        <div className="w-8 h-8 p-[7.21px] opacity-0 justify-center items-center flex" />
      </div>
      <div className="w-full h-fit flex-col justify-start items-start gap-6 flex">
        <div className="self-stretch h-fit flex-col justify-start items-start gap-6 flex">
          <div className="self-stretch h-7 flex-col justify-start items-start gap-3 flex">
            <div className="self-stretch justify-start items-start gap-2 inline-flex">
              <div className="grow shrink basis-0 text-neutral-700 text-[23px] font-semibold font-sans leading-7">
                {title}
              </div>
            </div>
          </div>
          {paragraphs &&
            paragraphs.length > 0 &&
            paragraphs.map((paragraph: string, index: number) => (
              <div
                key={index}
                className="self-stretch justify-start items-start gap-2 inline-flex"
              >
                <div className="grow shrink basis-0 text-neutral-700 text-base font-normal font-sans leading-7">
                  {paragraph}
                </div>
              </div>
            ))}
        </div>
        <div
          className="self-stretch h-[46px] px-5 py-3 bg-dc-secondary-600 hover:bg-dc-secondary-700 ease-in-out duration-150 rounded justify-center items-center gap-2 inline-flex cursor-pointer"
          onClick={onCreateAnother}
        >
          <div className="text-white text-lg font-semibold font-sans leading-snug select-none">
            {createText}
          </div>
        </div>
        <div className="self-stretch h-[46px] px-5 py-3 rounded justify-center items-center gap-2 inline-flex">
          <div
            className="text-dc-secondary-600 hover:underline text-lg font-semibold font-sans leading-snug cursor-pointer select-none"
            onClick={onClose}
          >
            Close
          </div>
        </div>
      </div>
    </>
  );
};

export default SavedSuccessfulPanel;
