import Cookies from "js-cookie";
import { BACKEND_URL } from "config/getUrl";
import { validateClient } from "./authRequests";

export const logUsage = async (
  url: string,
  usageTimeSeconds: number,
  type?: string,
  itemId?: string
) => {
  const usageData = {
    url,
    usageTimeSeconds,
    type,
    itemId,
  };

  // Validate the tokens
  const validateResponse = await validateClient();

  // Check if the user is still connected
  if (!validateResponse.connected) {
    return {
      message: "It was not possible to find an access or refresh token.",
      successful: false,
      logout: true,
    };
  }

  // Get the accessToken
  const accessToken = Cookies.get("accessToken");

  // Check that the access token is not undefined
  if (!accessToken) {
    return {
      message: "It was not possible to find an access token.",
      successful: false,
      logout: true,
    };
  }

  // Sending to the server
  const options = {
    method: "PUT",
    headers: {
      "Authorization": `Bearer ${accessToken}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(usageData),
  };

  // put the data
  try {
    const response = await fetch(`${BACKEND_URL}/api/usage`, options);
    if (response.status === 200) {
      // Get the response
      const data = await response.json();
      return data.content;
    }
  } catch (error) {
    console.log(error);
  }
};
