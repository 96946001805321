import logo from "assets/imgs/logo.svg";
import backgroundImg from "assets/imgs/uluru.jpeg";
import bcrypt from "bcryptjs";
import { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  IoCheckmarkCircleOutline,
  IoCloseCircleOutline,
} from "react-icons/io5";
import { resetPasswordCheckResetId, setupPasswordRequest } from "services/authRequests";
import { FormProvider, useForm } from "react-hook-form";
import {
  haveDigitRegex,
  haveLowercaseRegex,
  haveUppercaseRegex,
  minimumCharactersRegex,
} from "utils/regex";
import { LoadingDots } from "utils/loadingAnimation";
import { MdLockOpen, MdOutlineVisibility } from "react-icons/md";

const SetupPassword: React.FC = () => {
  // States definition
  const [email, setEmail] = useState("");
  const [pwdVisibility, setPwdVisibility] = useState<boolean>(false);
  const [uppercaseCheck, setUppercaseCheck] = useState<boolean>(false);
  const [lowercaseCheck, setLowercaseCheck] = useState<boolean>(false);
  const [digitCheck, setDigitCheck] = useState<boolean>(false);
  const [minCharactersCheck, setMinCharactersCheck] = useState<boolean>(false);
  const [password, setPassword] = useState<string>("");
  const [passwordRepeat, setPasswordRepeat] = useState<string>("");
  const [setupLoading, setSetupLoading] = useState<boolean>(false);
  const [showError, setShowError] = useState<boolean>(false);

  // Navigate definition
  const navigate = useNavigate();

  // Form hooks
  const methods = useForm({ mode: "onChange" });

  // Get the resetTokenId
  const { resetTokenId } = useParams();

  // Check if the resetTokenId is valid, if not send again to resetPassword
  useEffect(() => {
    resetPasswordCheckResetId(resetTokenId || "").then((response) => {
      // If the response it's not successful, send to reset-password again
      if (!response.successful) {
        navigate("/reset-password");
      }

      // Set the email of the reset token
      setEmail(response.email);
    });
  }, []);

  // Define the password validation on every change
  useEffect(() => {
    // Set every state
    setMinCharactersCheck(minimumCharactersRegex.test(password));
    setUppercaseCheck(haveUppercaseRegex.test(password));
    setLowercaseCheck(haveLowercaseRegex.test(password));
    setDigitCheck(haveDigitRegex.test(password));
  }, [password, passwordRepeat]);

  // Function for submitting
  const handlePasswordSubmit = methods.handleSubmit(async () => {
    // Set animation state
    setSetupLoading(true);

    // Encrypt the password
    const salt = await bcrypt.genSalt(15);
    const hashedPassword = await bcrypt.hash(password, salt);

    // Define the object to send
    const dataToSend = {
      resetTokenId: resetTokenId || "", 
      password: hashedPassword,
    };

    // Send the reset password information for checking
    let response: { success: boolean; message: string } =
      await setupPasswordRequest(dataToSend);

    // Stop the animation
    setSetupLoading(false);

    // If the response is successful
    if (response.success) {
      // Then go to the home page
      setShowError(false);
      navigate("/success-setup-password", { state: { fromApp: true } });
    } else {
      // Display an error message
      setShowError(true);
    }
  });

  return (
    <div className="w-full h-full relative">
      <div className="fixed w-full h-screen min-w-[1436px] z-0">
        <img
          className="absolute grow basis-0 size-full flex z-0 resize-none"
          src={backgroundImg}
        />
      </div>
      <div className="w-full h-full p-14 z-10 flex justify-center">
        <div className="w-[628px] p-[72px] bg-neutral-100 bg-opacity-70 rounded-2xl shadow backdrop-blur-[32px] justify-start items-center gap-24 inline-flex">
          <div className="grow shrink basis-0 flex-col justify-center items-center gap-12 inline-flex">
            <div className="self-stretch justify-start items-center gap-2 inline-flex">
              <Link to="/">
                <img className="w-fit h-fit p-2 rounded-xl bg-neutral-50" src={logo} />
              </Link>
            </div>
            <div className="self-stretch h-[531px] flex-col justify-start items-start gap-8 flex">
              <div className="self-stretch justify-start items-center gap-2 inline-flex">
                <div className="grow shrink basis-0">
                  <span className="text-neutral-700 text-base font-normal font-sans leading-normal">
                    Please set your password for:{" "}
                  </span>
                  <span className="font-bold">{email}</span>
                </div>
              </div>
              <div className="self-stretch h-[183px] flex-col justify-center items-start gap-4 flex">
                <div className="self-stretch justify-start items-start gap-2 inline-flex">
                  <div className="grow shrink basis-0 text-neutral-700 text-base font-normal font-sans leading-[19px]">
                    Your password must have at least:
                  </div>
                </div>
                <div className="self-stretch h-[148px] flex-col justify-start items-start gap-3 flex">
                  <div className="self-stretch justify-start items-center gap-2 inline-flex">
                    <div className="w-5 h-5 justify-center items-center flex">
                      {minCharactersCheck ? (
                        <IoCheckmarkCircleOutline />
                      ) : (
                        <IoCloseCircleOutline />
                      )}
                    </div>
                    <div className="h-[19px] justify-start items-start gap-2 flex">
                      <div className="grow shrink basis-0 text-neutral-700 text-base font-normal font-sans leading-[19px]">
                        10 Characters
                      </div>
                    </div>
                  </div>
                  <div className="self-stretch justify-start items-center gap-2 inline-flex">
                    <div className="w-5 h-5 justify-center items-center flex">
                      {uppercaseCheck ? (
                        <IoCheckmarkCircleOutline />
                      ) : (
                        <IoCloseCircleOutline />
                      )}
                    </div>
                    <div className="h-[19px] justify-start items-start gap-2 flex">
                      <div className="grow shrink basis-0 text-neutral-700 text-base font-normal font-sans leading-[19px]">
                        1 uppercase character
                      </div>
                    </div>
                  </div>
                  <div className="self-stretch justify-start items-center gap-2 inline-flex">
                    <div className="w-5 h-5 justify-center items-center flex">
                      {lowercaseCheck ? (
                        <IoCheckmarkCircleOutline />
                      ) : (
                        <IoCloseCircleOutline />
                      )}
                    </div>
                    <div className="h-[19px] justify-start items-start gap-2 flex">
                      <div className="grow shrink basis-0 text-neutral-700 text-base font-normal font-sans leading-[19px]">
                        1 lowercase character
                      </div>
                    </div>
                  </div>
                  <div className="self-stretch justify-start items-center gap-2 inline-flex">
                    <div className="w-5 h-5 justify-center items-center flex">
                      {digitCheck ? (
                        <IoCheckmarkCircleOutline />
                      ) : (
                        <IoCloseCircleOutline />
                      )}
                    </div>
                    <div className="h-[19px] justify-start items-start gap-2 flex">
                      <div className="grow shrink basis-0 text-neutral-700 text-base font-normal font-sans leading-[19px]">
                        1 digit
                      </div>
                    </div>
                  </div>
                  <div className="self-stretch justify-start items-center gap-2 inline-flex">
                    <div className="w-5 h-5 justify-center items-center flex">
                      {password === passwordRepeat ? (
                        <IoCheckmarkCircleOutline />
                      ) : (
                        <IoCloseCircleOutline />
                      )}
                    </div>
                    <div className="h-[19px] justify-start items-start gap-2 flex">
                      <div className="grow shrink basis-0 text-neutral-700 text-base font-normal font-sans leading-[19px]">
                        Passwords must match
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <FormProvider {...methods}>
                <form
                  id="setup-password-form"
                  className="w-full"
                  onSubmit={(event) => event.preventDefault()}
                  noValidate
                  autoComplete="off"
                >
                  <div className="self-stretch h-[236px] flex-col justify-start items-start gap-6 flex">
                    <div className="self-stretch h-[71px] flex-col justify-start items-start gap-1.5 flex">
                      <div className="rounded justify-start items-start gap-2 inline-flex">
                        <div className="text-neutral-500 text-xs font-normal font-sans uppercase leading-[14px] tracking-wide select-none">
                          Password
                        </div>
                      </div>
                      <div className="w-full h-full px-3.5 rounded-lg bg-neutral-50 text-neutral-700 placeholder:text-neutral-300 text-base font-normal font-sans leading-[19px] flex items-center">
                        <div className="flex justify-center items-center">
                          <MdLockOpen className="w-5 h-5 text-neutral-500"/>
                        </div>
                        <input
                          id="password"
                          type={pwdVisibility ? "text" : "password"}
                          className="w-full h-full px-2 rounded-lg bg-neutral-50 text-neutral-700 placeholder:text-neutral-300 focus:outline-none text-base font-normal font-sans leading-[19px]"
                          placeholder="Enter your password"
                          autoComplete="password"
                          onChange={(event) => setPassword(event.target.value)}
                        />
                        <div
                          className="flex justify-center items-center cursor-pointer"
                          onClick={() => setPwdVisibility(!pwdVisibility)}
                        >
                          <MdOutlineVisibility className="w-5 h-5 text-neutral-500"/>
                        </div>
                      </div>
                    </div>
                    <div className="self-stretch h-[71px] flex-col justify-start items-start gap-1.5 flex">
                      <div className="rounded justify-start items-start gap-2 inline-flex">
                        <div className="text-neutral-500 text-xs font-normal font-sans uppercase leading-[14px] tracking-wide select-none">
                          Repeat Password
                        </div>
                      </div>
                      <div className="w-full h-full px-3.5 rounded-lg bg-neutral-50 text-neutral-700 placeholder:text-neutral-300 text-base font-normal font-sans leading-[19px] flex items-center">
                        <div className="flex justify-center items-center">
                          <MdLockOpen className="w-5 h-5 text-neutral-500"/>
                        </div>
                        <input
                          id="password-repeat"
                          type={pwdVisibility ? "text" : "password"}
                          className="w-full h-full px-2 rounded-lg bg-neutral-50 text-neutral-700 placeholder:text-neutral-300 focus:outline-none text-base font-normal font-sans leading-[19px]"
                          placeholder="Repeat the password"
                          autoComplete="password"
                          onChange={(event) =>
                            setPasswordRepeat(event.target.value)
                          }
                        />
                        <div
                          className="flex justify-center items-center cursor-pointer"
                          onClick={() => setPwdVisibility(!pwdVisibility)}
                        >
                          <MdOutlineVisibility className="w-5 h-5 text-neutral-500"/>
                        </div>
                      </div>
                    </div>
                    {lowercaseCheck &&
                    uppercaseCheck &&
                    digitCheck &&
                    minCharactersCheck &&
                    password === passwordRepeat ? (
                      <button
                        className="self-stretch px-5 py-3 bg-dc-secondary-600 rounded justify-center items-center gap-2 inline-flex"
                        onClick={handlePasswordSubmit}
                      >
                        <div className="text-white text-lg font-semibold font-sans leading-snug">
                          {setupLoading ? <LoadingDots /> : "Reset Password"}
                        </div>
                      </button>
                    ) : (
                      <button
                        className="self-stretch px-5 py-3 bg-Subtle rounded justify-center items-center gap-2 inline-flex"
                        disabled
                      >
                        <div className="text-neutral-400 text-lg font-semibold font-sans leading-snug">
                          Save Password
                        </div>
                      </button>
                    )}
                  </div>
                </form>
              </FormProvider>
            </div>
            <div className="self-stretch h-[66px] flex-col justify-center items-center gap-8 flex">
              <div className="self-stretch justify-center items-center gap-2 inline-flex">
                <div className="text-neutral-700 text-base font-normal font-sans leading-[17px]">
                  Need help?
                </div>
                <Link to="/contact-us">
                  <div className="text-center text-cyan-800 text-base font-bold font-sans underline leading-[17px]">
                    Contact us
                  </div>
                </Link>
              </div>
              <div className="self-stretch justify-center items-start gap-2 inline-flex">
                <Link to="/terms-and-conditions#setup-password">
                  <div className="grow shrink basis-0 text-center text-cyan-800 text-base font-bold font-sans underline leading-[17px]">
                    Terms and Conditions
                  </div>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SetupPassword;
