interface PopUpMenuProps {
  setShowPopUpMenu: React.Dispatch<React.SetStateAction<boolean>>;
  options: { icon: JSX.Element; text: string; onClickFunction: () => void }[];
}

const PopUpMenu: React.FC<PopUpMenuProps> = ({ setShowPopUpMenu, options }) => {
  return (
    <>
      <div
        className="fixed top-0 left-[1px] w-screen h-screen bg-transparent z-20"
        onClick={() => setShowPopUpMenu(false)}
      />
      <div className="absolute w-fit bottom-[78px] left-1 p-3 bg-white rounded-lg shadow flex-col justify-center items-center gap-2 inline-flex cursor-pointer z-30">
        {options &&
          options.length > 0 &&
          options.map((option, index: number) => (
            <button 
              key={index}
              className="w-full p-3 bg-white rounded-lg justify-start items-center gap-2 inline-flex text-neutral-700 hover:bg-neutral-700 hover:text-white"
              onClick={option.onClickFunction}
            >
              <div className="w-6 h-6 relative fill-neutral-700">
                {option.icon}
              </div>
              <div className="text-base font-normal font-sans leading-7 whitespace-nowrap">
                {option.text}
              </div>
            </button>
          ))}
      </div>
    </>
  );
};

export default PopUpMenu;
