export const formatDate = (date: Date) => {
  if (!date) return "";

  const formatter = new Intl.DateTimeFormat("en-US", {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
  });

  return formatter.format(date);
};

export const formatDateString = (date: string) => {
  if (!date) return "";
  
  // If we have a string
  return date.split("T")[0];
};

