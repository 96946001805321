import {
  MdAddCircleOutline,
  MdOutlineArrowCircleLeft,
  MdOutlineArrowCircleRight,
  MdOutlineGroupAdd,
  MdOutlineMeetingRoom,
} from "react-icons/md";
import ClassDetailsFormCard from "components/main-view/classes/cards/ClassDetailsFormCard";
import { ClassListType, ContactListType, SchoolListType } from "utils/interfaces";
import ContactCard from "components/main-view/utils/pop-up-panels/add-contact-pop-up/ContactCard";
import { useState } from "react";
import PopUpPanel from "../pop-up-panels/PopUpPanel";

interface ClassParentDetailsProps {
  userSchool?: SchoolListType | null;
  selectedClasses: ClassListType[];
  selectedContacts: ContactListType[];
  showAddClassesPopUp: boolean;
  showAddParentsPopUp: boolean;
  setStage: React.Dispatch<React.SetStateAction<number>>;
  setShowAddClassesPopUp: React.Dispatch<React.SetStateAction<boolean>>;
  setShowAddParentsPopUp: React.Dispatch<React.SetStateAction<boolean>>;
  setSelectedClasses: React.Dispatch<React.SetStateAction<ClassListType[]>>;
  setSelectedContacts: React.Dispatch<React.SetStateAction<ContactListType[]>>;
  headerRef: React.MutableRefObject<any>;
}

const ClassParentDetails: React.FC<ClassParentDetailsProps> = ({
  userSchool,
  selectedClasses,
  selectedContacts,
  showAddClassesPopUp,
  showAddParentsPopUp,
  setStage,
  setShowAddClassesPopUp,
  setShowAddParentsPopUp,
  setSelectedClasses,
  setSelectedContacts,
  headerRef
}) => {
  // States definition
  const [showEditParent, setShowEditParent] = useState(false);
  const [selectedData, setSelectedData] = useState<ContactListType | null>(null);

  // Define handle previous button
  const handlePrev = () => {
    setStage(1);
  };

  // Define handle next button
  const handleNext = () => {
    setStage(3);
  };

  // Definition of add class action
  const handleAddClass = () => {
    setShowAddClassesPopUp(true);
  };

  // Definition of add parent action
  const handleAddParent = () => {
    setShowAddParentsPopUp(true);
  };

  return (
    <div className="w-[562px] h-full p-6 bg-white rounded-3xl border-neutral-200 justify-start items-start gap-12 flex-col inline-flex">
      <div className="self-stretch h-7 flex-col justify-start items-start flex text-neutral-700 text-[23px] font-semibold font-sans leading-7">
        Class and Parent details
      </div>
      <div className="self-stretch h-fit flex-col justify-start items-start gap-5 flex">
        <div className="self-stretch h-[19px] flex-col justify-start items-start flex text-neutral-500 text-base font-semibold font-sans leading-[19px]">
          Class details
        </div>
        {/* Show the add class button, or the list of possible classes */}
        {selectedClasses.length === 0 ? (
          <button
            className="w-full px-2 py-3 rounded-lg border border-dashed border-neutral-300 hover:bg-neutral-25 ease-in-out duration-150 justify-center items-center gap-2 inline-flex cursor-pointer"
            onClick={handleAddClass}
          >
            <div className="w-6 h-6 relative">
              <MdOutlineMeetingRoom className="w-full h-full fill-neutral-500" />
            </div>
            <div className="text-neutral-500 text-base font-normal font-sans leading-[19px]">
              Add a class
            </div>
          </button>
        ) : (
          <>
            {selectedClasses.map((classElem: ClassListType, index: number) => (
              <ClassDetailsFormCard
                key={index}
                thisClass={classElem}
                setSelectedClasses={setSelectedClasses}
              />
            ))}
            <div className="w-[514px] h-6 justify-between items-center inline-flex">
              <button
                className="group rounded justify-center items-center gap-2 flex cursor-pointer"
                onClick={handleAddClass}
              >
                <div className="w-6 h-6 relative">
                  <MdAddCircleOutline className="w-full h-full fill-dc-secondary-600 group-hover:fill-dc-secondary-700" />
                </div>
                <div className="text-dc-secondary-600 group-hover:text-dc-secondary-700 text-lg font-semibold font-sans leading-snug">
                  Add additional class
                </div>
              </button>
            </div>
          </>
        )}
      </div>
      <div className="self-stretch h-fit flex-col justify-start items-start gap-5 flex">
        <div className="self-stretch h-[19px] flex-col justify-start items-start flex text-neutral-500 text-base font-semibold font-sans leading-[19px]">
          Parent and Guardian details
        </div>
        {selectedContacts.length === 0 ? (
          <button
            className="w-full px-2 py-3 rounded-lg border border-dashed border-neutral-300 hover:bg-neutral-25 ease-in-out duration-150 justify-center items-center gap-2 inline-flex cursor-pointer"
            onClick={handleAddParent}
          >
            <div className="w-6 h-6 relative">
              <MdOutlineGroupAdd className="w-full h-full fill-neutral-500" />
            </div>
            <div className="text-neutral-500 text-base font-normal font-sans leading-[19px]">
              Add a parent/guardian
            </div>
          </button>
        ) : (
          <>
            {selectedContacts.map((contact: ContactListType, index: number) => (
              <ContactCard
                key={index}
                contact={contact}
                selectedContacts={selectedContacts}
                setSelectedContacts={setSelectedContacts}
                setSelectedData={setSelectedData}
                setShowEditContact={setShowEditParent}
                type="inside-form"
              />
            ))}
            <div className="w-[514px] h-6 justify-between items-center inline-flex">
              <button
                className=" group rounded justify-center items-center gap-2 flex cursor-pointer"
                onClick={handleAddParent}
              >
                <div className="w-6 h-6 relative">
                  <MdAddCircleOutline className="w-full h-full fill-dc-secondary-600 group-hover:fill-dc-secondary-700" />
                </div>
                <div className="text-dc-secondary-600 group-hover:text-dc-secondary-700 text-lg font-semibold font-sans leading-snug">
                  Add additional Parent/Guardian
                </div>
              </button>
            </div>
          </>
        )}
      </div>
      <div className="h-[46px] flex flex-row justify-between items-center w-full">
        <button
          className="group w-fit h-fit flex rounded justify-center items-center gap-2 cursor-pointer"
          onClick={handlePrev}
        >
          <div className="w-6 h-6 relative">
            <MdOutlineArrowCircleLeft className="w-full h-full fill-dc-secondary-600 group-hover:fill-dc-secondary-700" />
          </div>
          <div className="text-dc-secondary-600 group-hover:text-dc-secondary-700 text-lg font-semibold font-sans">
            Prev
          </div>
        </button>
        <button
          className="group w-fit h-fit flex rounded justify-center items-center gap-2 cursor-pointer"
          onClick={handleNext}
        >
          <div
            className={
              true
                ? "text-dc-secondary-600 group-hover:text-dc-secondary-700 text-lg font-semibold font-sans"
                : "text-neutral-200 text-lg font-semibold font-sans"
            }
          >
            Next
          </div>
          <div className="w-6 h-6 relative">
            <MdOutlineArrowCircleRight
              className={
                true
                  ? "w-full h-full fill-dc-secondary-600 group-hover:fill-dc-secondary-700"
                  : "w-full h-full fill-neutral-200"
              }
            />
          </div>
        </button>
      </div>
      <PopUpPanel
        type="assign-class"
        showPopUp={showAddClassesPopUp}
        setShowPopUp={setShowAddClassesPopUp}
        selectedClasses={selectedClasses}
        setSelectedClasses={setSelectedClasses}
        contextSchool={userSchool}
        headerRef={headerRef}
      />
      <PopUpPanel
        type="add-parents"
        showPopUp={showAddParentsPopUp}
        setShowPopUp={setShowAddParentsPopUp}
        selectedContacts={selectedContacts}
        contextSchool={userSchool}
        setSelectedContacts={setSelectedContacts}
      />
      <PopUpPanel
        type="edit-parent"
        selectedData={selectedData}
        showPopUp={showEditParent}
        setShowPopUp={setShowEditParent}
        selectedContacts={selectedContacts}
        setSelectedContacts={setSelectedContacts}
      />
    </div>
  );
};

export default ClassParentDetails;
