import { useEffect, useState } from "react";
import { Payload } from "recharts/types/component/DefaultTooltipContent";

interface ChartTooltipProps {
  unit: string;
  active?: boolean;
  payload?: Payload<number, string>[];
}

const ChartTooltip: React.FC<ChartTooltipProps> = ({unit, active, payload}) => {
  // States definition
  const [date, setDate] = useState("");
  const [value, setValue] = useState("");

  /**
   * Function used to format the number on the tooltip and
   * prevent to display long decimal number
   * @param num 
   * @returns 
   */
  const formatNumber = (num: number) => {
    return Number.isInteger(num) ? num.toString() : num.toFixed(1);
  }

  useEffect(() => {
    if (active && payload && payload.length) {
      setDate(payload[0] && payload[0].payload.day);
      setValue(payload[0].value ? formatNumber(payload[0].value) : "0");
    }
  }, [payload]);

  return (
    <div className="bg-white border border-neutral-300 rounded-md p-4 last:flex-col justify-start items-start gap-2 flex">
      <div className="justify-start items-center gap-2 inline-flex">
        <div className="text-neutral-500 text-[13px] font-semibold font-sans leading-3 tracking-tight">
          {date}
        </div>
      </div>
      <div className="justify-start items-center text-end gap-1 inline-flex">
        <div className="text-[#16a7b6] text-[12px] font-normal font-sans tracking-tight">
          Value: <span className="font-medium">{value}</span>
        </div>
        <div className="text-[#16a7b6] text-[10px] font-bold font-sans tracking-tight">
          {unit}
        </div>
      </div>
    </div>
  );
};

export default ChartTooltip;
