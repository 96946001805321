import { Transition } from "@headlessui/react";
import { Fragment } from "react";
import { MdInfoOutline } from "react-icons/md";

interface WarningPopUpMessageProps {
  showWarningMessage: boolean;
  setShowWarningMessage: React.Dispatch<React.SetStateAction<boolean>>;
}

const WarningPopUpMessage: React.FC<WarningPopUpMessageProps> = ({
  showWarningMessage,
  setShowWarningMessage,
}) => {
  /**
   * Definition of the on click dismiss button
   */
  const handleOnClickDismiss = () => {
    // And remove the pop up window
    setShowWarningMessage(false);
  };

  return (
    <Transition
      show={showWarningMessage}
      as={Fragment}
      enter="transition-opacity ease-out duration-300"
      enterFrom="opacity-0 translate-y-1/2"
      enterTo="opacity-100 translate-y-0"
      leave="transition-opacity duration-300"
      leaveFrom="opacity-100"
      leaveTo="opacity-0"
    >
      <div className="absolute -bottom-3 w-full h-[126px] p-3 bg-warning-500 rounded-2xl justify-start items-center gap-3 inline-flex">
        <div className="grow shrink basis-0 h-[102px] justify-center items-start gap-1.5 flex">
          <div className="w-6 h-6 relative">
            <MdInfoOutline className="w-full h-full fill-white" />
          </div>
          <div className="grow shrink basis-0 text-white text-sm font-normal font-sans leading-[17px]">
            Your changes are not saved yet.
            <br />
            <br />
            Once you have made your changes, please click the back button then
            use
            <br />
            the buttons within the window to save
            <br />
            or cancel.
          </div>
        </div>
        <div
          className="px-2 py-1 bg-warning-600 hover:bg-warning-700 ease-in-out duration-150 rounded justify-center items-center gap-2 flex cursor-pointer"
          onClick={handleOnClickDismiss}
        >
          <div className="text-white text-xs font-normal font-inter uppercase leading-[14px] tracking-wide">
            Dismiss
          </div>
        </div>
      </div>
    </Transition>
  );
};

export default WarningPopUpMessage;
