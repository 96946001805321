import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import { guardrailCatchMessage, Message } from "./Assistant";
import { motion } from "framer-motion";
import AIAvatarLittleC from "assets/imgs/AIAvatar_LittleC.png";
import AIAvatarJakita from "assets/imgs/AIAvatar_Jakita.png";
import AIAvatarCody from "assets/imgs/AIAvatar_Cody.svg";
import ChatMessage from "./ChatMessage";

interface ChatMessagesParams {
  messages: Message[];
  showLoading: boolean;
  AIAssistant: "littleC" | "jakita" | "cody";
  returnToIDE: () => void;
  moveToNextLesson: () => void;
  language: string;
  nameInitials: string;
}

const MULTIPLE_MESSAGES_TRANSITION = {
  duration: 0.5,
  ease: "linear",
};

const ChatMessages: React.FC<ChatMessagesParams> = ({
  messages,
  showLoading,
  AIAssistant,
  returnToIDE,
  moveToNextLesson,
  language,
  nameInitials
}) => {
  const widthRef = useRef<HTMLDivElement>(null);
  const chatEndRef = useRef<HTMLDivElement>(null);
  const [width, setWidth] = useState(767);
  const [multipleMessages, setMultipleMessages] = useState(false);

  useEffect(() => {
    setMultipleMessages(messages.length > 1);
  }, [messages.length]);

  useEffect(() => {
    requestAnimationFrame(() => {
      if (chatEndRef.current)
        chatEndRef?.current.scrollIntoView({ behavior: "smooth" });
    });
  }, [showLoading, setMultipleMessages, messages]);

  useLayoutEffect(() => {
    const handleResize = () => {
      if (widthRef.current) {
        const { width } = widthRef.current.getBoundingClientRect();
        if (width !== undefined && width !== 0) {
          setWidth(width);
        }
      }
    };
    handleResize();

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const shouldStrikethrough = (index: number) => {
    if (index + 1 >= messages.length || index < 0) return false;
    return messages[index + 1].content === guardrailCatchMessage;
  };

  return (
    <div className="w-fit h-full flex flex-col overflow-y-auto custom-scroll overflow-x-hidden relative rounded-sm">
      {messages.map((message, index) => (
        <React.Fragment key={index}>
          {index === 0 ? (
            <div className="pb-6 flex">
              {/* text box*/}
              <div ref={widthRef} className="w-full h-auto relative">
                <motion.div
                  key={`spacer-${index}`}
                  initial={{
                    height: multipleMessages ? 0 : 268 + 24,
                    paddingBottom: multipleMessages ? 0 : 24,
                  }}
                  animate={{
                    height: multipleMessages ? 0 : 268 + 24,
                    transition: MULTIPLE_MESSAGES_TRANSITION,
                    paddingBottom: multipleMessages ? 0 : 24,
                  }}
                />
                <motion.div
                  key={`message-${index}`}
                  className={`w-fit p-4 bg-dc-secondary-50 border border-dc-primary-300 h-fit-content rounded-tl-3xl rounded-bl-3xl rounded-br-3xl`}
                  initial={{
                    marginLeft: 0,
                    marginRight: multipleMessages ? 84 : 0,
                  }}
                  animate={{
                    marginLeft: 0,
                    marginRight: multipleMessages ? 84 : 0,
                    borderTopRightRadius: multipleMessages ? 2 : 24,
                    transition: MULTIPLE_MESSAGES_TRANSITION,
                  }}
                >
                  <div className="leading-10 gap-3 h-fit w-full">
                    <div className="self-stretch text-neutral-800 text-[23px] font-semibold leading-7">
                      {AIAssistant === "cody" ? "Hi there!" : "Jingeri!"}
                    </div>
                    <div className="self-stretch text-neutral-800 text-base font-normal leading-7 my-3">
                      {["jakita", "littleC"].includes(AIAssistant) && (
                        <>
                          {AIAssistant === "jakita"
                            ? "I'm Big J and I'm a Yugumbeh girl"
                            : "I'm Little C and I'm a Yugumbeh boy"}
                          . Jingeri is how we say welcome on my country. I'm
                          here to help you become a Deadly Coder!
                        </>
                      )}
                      {AIAssistant === "cody" &&
                        "I'm Cody, your friendly AI coding buddy. Whether you're just starting out or you're knee-deep in code, I'm here to help you navigate the exciting world of programming."}
                    </div>
                    <div className="self-stretch text-neutral-800 text-base font-normal leading-7">
                      Pick an option below or enter your question to get started
                    </div>
                  </div>
                </motion.div>
              </div>
              {/* image */}
              <motion.div
                className="rounded-full flex items-center justify-center absolute mx-auto"
                initial={{
                  width: 268,
                  height: 268,
                  right: (width - 268) / 2,
                  top: 24,
                }}
                animate={{
                  width: multipleMessages ? 72 : 268,
                  height: multipleMessages ? 72 : 268,
                  right: multipleMessages ? 0 : (width - 268) / 2,
                  top: multipleMessages ? 0 : 24,
                  transition: MULTIPLE_MESSAGES_TRANSITION,
                  padding:
                    AIAssistant === "jakita" ? (multipleMessages ? 6 : 24) : 0,
                }}
              >
                <motion.div
                  key={`background-${index}`}
                  className="w-full h-full rounded-full bg-dc-secondary-50 border border-dc-primary-300 absolute"
                  initial={{ opacity: 0 }}
                  animate={{
                    opacity: multipleMessages ? 1 : 0,
                    transition: MULTIPLE_MESSAGES_TRANSITION,
                  }}
                />
                <div className="w-full h-full relative overflow-hidden">
                  <img
                    key={`img-${index}`}
                    src={
                      AIAssistant === "jakita"
                        ? AIAvatarJakita
                        : AIAssistant === "littleC"
                        ? AIAvatarLittleC
                        : AIAvatarCody
                    }
                    alt="AI Avatar"
                    className="w-full h-full"
                  />
                </div>
              </motion.div>
            </div>
          ) : (
            // Actual chat message
            <ChatMessage
              index={index}
              AIAssistant={AIAssistant}
              message={message}
              strikethrough={shouldStrikethrough(index)}
              returnToIDE={returnToIDE}
              moveToNextLesson={moveToNextLesson}
              language={language}
              nameInitials={nameInitials}
            />
          )}
        </React.Fragment>
      ))}
      {showLoading && (
        <ChatMessage
          index={messages.length}
          message={{ role: "assistant" }}
          AIAssistant={AIAssistant}
          language={language}
          nameInitials={nameInitials}
        />
      )}
      <div ref={chatEndRef} className="w-full h-0" />
    </div>
  );
};

export default ChatMessages;
