const CheckSignIcon = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Clip path group">
        <mask
          id="mask0_2557_2565"
          maskUnits="userSpaceOnUse"
          x="0"
          y="0"
          width="20"
          height="20"
        >
          <g id="clip0_3456_7083">
            <path id="Vector" d="M20 0H0V20H20V0Z" fill="white" />
          </g>
        </mask>
        <g mask="url(#mask0_2557_2565)">
          <g id="Group">
            <path
              id="Vector_2"
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M17.955 4.25867C18.4431 4.74684 18.4431 5.5383 17.955 6.02646L8.58579 15.3956C8.06512 15.9163 7.22087 15.9163 6.7002 15.3956L2.04508 10.7405C1.55691 10.2524 1.55691 9.46088 2.04508 8.97271C2.53321 8.48455 3.32466 8.48455 3.81283 8.97271L7.643 12.8029L16.1872 4.25867C16.6754 3.77055 17.4669 3.77055 17.955 4.25867Z"
              fill="white"
            />
          </g>
        </g>
      </g>
    </svg>
  );
};

export default CheckSignIcon;
