import CodeOutput from "./CodeOutput";

interface OutputViewProps {
  srcDoc: string;
  iframeRef: React.RefObject<HTMLIFrameElement>;
  iframeReloadTrigger: number;
  isVisible: boolean;
  isResizing: boolean;
}

const OutputView: React.FC<OutputViewProps> = ({
  srcDoc,
  iframeRef,
  iframeReloadTrigger,
  isVisible,
  isResizing,
}) => {
  return (
    <div
      className={`${
        isVisible ? "w-full h-full p-4 pt-0" : "w-0 h-0 p-0 invisible"
      } flex-1 flex flex-col rounded-xl`}
    >
      <div className="h-full">
        <CodeOutput
          srcDoc={srcDoc}
          isPaning={isResizing}
          ref={iframeRef}
          key={iframeReloadTrigger}
        />
      </div>
    </div>
  );
};

export default OutputView;
