import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import BasicDetails from "./BasicDetails";
import ProgressMap from "components/main-view/utils/ProgressMap";
import ClassParentDetails from "./ClassParentDetails";
import AdditionalDetails from "./AdditionalDetails";
import {
  ClassListType,
  ContactListType,
  SchoolListType,
} from "utils/interfaces";
import { createRecordRequest, updateRecordRequest } from "services/apiRequests";
import { formatDateString } from "utils/formatDate";
import { capitaliseFirstChar } from "utils/utils";
import { formatUpdateStudent } from "utils/formatUpdate";

interface AddNewStudentProps {
  ref?: any;
  userSchool: SchoolListType | null;
  selectedData?: any;
  afterCreateFunction: () => void;
  headerRef: React.MutableRefObject<any>;
  from?: "dashboard" | "classes";
}

const AddNewStudent: React.FC<AddNewStudentProps> = forwardRef(
  (
    {
      selectedData,
      userSchool,
      afterCreateFunction,
      headerRef,
      from = "classes",
    },
    ref
  ) => {
    // States definition
    const [firstName, setFirstName] = useState("");
    const [middleName, setMiddleName] = useState("");
    const [lastName, setLastName] = useState("");
    const [dob, setDob] = useState("");
    const [gender, setGender] = useState("");
    const [selectedClasses, setSelectedClasses] = useState<ClassListType[]>([]);
    const [selectedContacts, setSelectedContacts] = useState<ContactListType[]>(
      []
    );
    const [
      isAboriginalOrTorresStraitIslander,
      setIsAboriginalOrTorresStraitIslander,
    ] = useState(true);
    const [notes, setNotes] = useState("");
    // Flow control states
    const [stage, setStage] = useState(1);
    const [showGenderPopUp, setShowGenderPopUp] = useState(false);
    const [showAddClassesPopUp, setShowAddClassesPopUp] = useState(false);
    const [showAddParentsPopUp, setShowAddParentsPopUp] = useState(false);

    /**
     * Functions to externalise using refs
     */
    useImperativeHandle(ref, () => ({
      // Function to present all the gathered data in the form
      provideData() {
        return provideData();
      },
      handleSaveNewStudent() {
        handleSave();
      },
    }));

    /**
     * Function to activate saveButton on header
     */
    useEffect(() => {
      // Set a different behaviour when editing
      const disabledCondition = selectedData
        ? !(firstName && lastName && dob && gender)
        : !(firstName && lastName && dob && gender && stage === 3);

      // Condition definition
      if (from === "classes") {
        headerRef.current.classes().setSaveButton({
          show: true,
          disabled: disabledCondition,
        });
      } else {
        headerRef.current.dashboard().setSaveButton({
          show: true,
          disabled: disabledCondition,
        });
      }
    }, [firstName, lastName, dob, gender, stage]);

    /**
     * If there is selected data, then prefill the defined fields
     */
    useEffect(() => {
      if (selectedData) {
        setFirstName(selectedData.firstName || "");
        setMiddleName(selectedData.middleName || "");
        setLastName(selectedData.lastName || "");
        setDob(formatDateString(selectedData.dateOfBirth) || "");
        setGender(selectedData.gender ? capitaliseFirstChar(selectedData.gender) : "");
        setSelectedClasses(selectedData.classes || "");
        setSelectedContacts(selectedData.parents || "");
        setIsAboriginalOrTorresStraitIslander(selectedData.heritage || false);
        setNotes(selectedData.notes ? selectedData.notes.join("\n\n") : "");
      }
    }, []);

    /**
     * Function to handle save pressing
     */
    const handleSave = async () => {
      // Get the data from the settings form
      const data = provideData();

      // Request for creating new student
      let response = { successful: false, message: "" };
      if (!selectedData) {
        response = await createRecordRequest(data, "/api/users");
      } else {
        response = await updateRecordRequest(
          { _id: selectedData._id, toUpdate: data },
          "/api/users"
        );
      }

      // Check if the response is successful
      if (!response.successful) {
        // alert(response.message);
        return;
      }

      // Execute the function to use after create/edit
      afterCreateFunction();
    };

    /**
     * Function that returns all the form data
     * @returns data
     */
    const provideData = () => {
      // Get just the ids from the classes
      const classIds = selectedClasses
        ? selectedClasses.map((item: ClassListType) => item._id)
        : [];
      // Get just the ids from the contacts
      const contactIds = selectedContacts
        ? selectedContacts.map((contact: ContactListType) => contact._id)
        : [];

      // Return all the gathered data in the form
      const dataToProvide = {
        firstName: firstName,
        middleName: middleName,
        lastName: lastName,
        dateOfBirth: dob,
        gender: gender.toLowerCase(),
        classes: classIds,
        parents: contactIds,
        heritage: isAboriginalOrTorresStraitIslander,
        notes: [notes],
        role: "student",
        progressRate: 5,
        aptitudeRate: 5,
        averageScore: 5,
        badges: [],
      };

      // Check if the school of the user is defined
      if (userSchool && userSchool._id) {
        (dataToProvide as any).organisations = [userSchool._id];
      }

      // Formatting the data to be sent as a request
      const formattedData = formatUpdateStudent(selectedData, dataToProvide);

      return formattedData;
    };

    return (
      <div className="w-full h-full flex p-4 justify-center items-start gap-[88px] overflow-x-auto custom-scroll">
        {stage === 1 ? (
          <BasicDetails
            firstName={firstName}
            middleName={middleName}
            lastName={lastName}
            dob={dob}
            gender={gender}
            showGenderPopUp={showGenderPopUp}
            setFirstName={setFirstName}
            setMiddleName={setMiddleName}
            setLastName={setLastName}
            setDob={setDob}
            setGender={setGender}
            setStage={setStage}
            setShowGenderPopUp={setShowGenderPopUp}
          />
        ) : stage === 2 ? (
          <ClassParentDetails
            userSchool={userSchool}
            selectedClasses={selectedClasses}
            selectedContacts={selectedContacts}
            showAddClassesPopUp={showAddClassesPopUp}
            showAddParentsPopUp={showAddParentsPopUp}
            setStage={setStage}
            setShowAddClassesPopUp={setShowAddClassesPopUp}
            setShowAddParentsPopUp={setShowAddParentsPopUp}
            setSelectedClasses={setSelectedClasses}
            setSelectedContacts={setSelectedContacts}
            headerRef={headerRef}
          />
        ) : (
          <AdditionalDetails
            isAboriginalOrTorresStraitIslander={
              isAboriginalOrTorresStraitIslander
            }
            notes={notes}
            setIsAboriginalOrTorresStraitIslander={
              setIsAboriginalOrTorresStraitIslander
            }
            setNotes={setNotes}
            setStage={setStage}
            handleSave={handleSave}
          />
        )}
        <ProgressMap
          stage={stage}
          firstText="Basic details"
          secondText="Class and Parent details"
          thirdText="Additional details"
        />
      </div>
    );
  }
);

export default AddNewStudent;
