import {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import ViewHeader from "../utils/view-header/ViewHeader";
import { capitaliseFirstChar } from "utils/utils";
import ClassesList from "./ClassesList";
import { SchoolListType } from "utils/interfaces";

interface ClassesProps {
  ref: any;
  userRole: string;
  backPressedToggle: boolean;
  userSchool: SchoolListType | null;
  headerRef: React.MutableRefObject<any>;
  setUserSchool?: React.Dispatch<React.SetStateAction<SchoolListType | null>>;
}

const Classes: React.FC<ClassesProps> = forwardRef(
  (
    { userRole, backPressedToggle, userSchool, headerRef, setUserSchool },
    ref
  ) => {
    // States definition
    const [activeClass, setActiveClass] = useState("classes");
    const [searchQuery, setSearchQuery] = useState("");
    const [showTabSearchBar, setShowTabSearchBar] = useState(true);

    // References definition
    const classesListRef = useRef();

    /**
     * Define the list of functions that can be used from external components
     * using a reference
     */
    useImperativeHandle(ref, () => ({
      // Outer function to change the tab
      setShowTabSearchBar(bool: boolean) {
        setShowTabSearchBar(bool);
      },
      getActiveClass() {
        return activeClass;
      },
      setCreateNewStudent(bool: boolean) {
        let currentHandler: any;
        if (classesListRef.current) currentHandler = classesListRef.current;
        currentHandler.setCreateNewStudent(bool);
      },
      setShowCreateNewClass(bool: boolean) {
        let currentHandler: any;
        if (classesListRef.current) currentHandler = classesListRef.current;
        currentHandler.setShowCreateNewClass(bool);
      },
      pressSaveButton() {
        let currentHandler: any;
        if (classesListRef.current) currentHandler = classesListRef.current;
        currentHandler.handleSaveNewStudent();
      },
    }));

    /**
     * Define the tab title and description once opened
     */
    useEffect(() => {
      headerRef.current.setHeaderTitle(capitaliseFirstChar(activeClass));
      headerRef.current.setHeaderDescription(`List of current ${activeClass}`);
    }, []);

    return (
      <div className="w-full self-stretch flex-col justify-start items-start gap-4 inline-flex">
        {showTabSearchBar && (
          <ViewHeader
            type="classes"
            userRole={userRole}
            activeItem={activeClass}
            tabOptions={["Classes", "Teachers", "Students", "Parents"]}
            userSchool={userSchool}
            setActiveItem={setActiveClass}
            setSearchQuery={setSearchQuery}
            setUserSchool={setUserSchool}
            headerRef={headerRef}
          />
        )}
        <ClassesList
          ref={classesListRef}
          userSchool={userSchool}
          activeClass={activeClass}
          searchQuery={searchQuery}
          backPressedToggle={backPressedToggle}
          headerRef={headerRef}
        />
      </div>
    );
  }
);

export default Classes;
