import { ArrayElement, Course } from "utils/interfaces";
import JourneyBadge from "./JourneyBadge";
import { useEffect, useState } from "react";

interface MyLearningJourneyPanelProps {
  course: Course;
}

const MyLearningJourneyPanel: React.FC<MyLearningJourneyPanelProps> = ({
  course,
}) => {
  // States definition
  const [uncompletedIndexes, setUncompletedIndexes] = useState<number[]>([]);
  const [hereIndex, setHereIndex] = useState<number | null>(null);

  /**
   * Function to define the 
   */
  useEffect(() => {
    // Define the current index
    if (uncompletedIndexes.length > 0) {
      setHereIndex(Math.min(...uncompletedIndexes));
    } else {
      setHereIndex(null);
    }
  }, [uncompletedIndexes])

  return (
    <div className="grow-0 shrink-0 w-full min-w-0 flex flex-col gap-2">
      <div className="self-stretch text-neutral-500 text-xs font-normal font-sans leading-[14px] select-none">
        {course.title || "..."}
      </div>
      <div className="w-full h-full pt-4 custom-scroll overflow-x-hidden overflow-y-auto z-10"> {/*overflow-y-auto*/}
        <div className="w-full justify-items-center grid grid-cols-3 gap-6 gap-y-12">
          {course.projects && course.projects.length > 0 ? (
            course.projects.map(
              (
                project: ArrayElement<typeof course.projects>,
                index: number
              ) => (
                <JourneyBadge
                  key={index}
                  index={index}
                  project={project as any}
                  toComplete={index === hereIndex}
                  setUncompletedIndexes={setUncompletedIndexes}
                />
              )
            )
          ) : (
            <></>
          )}
        </div>
      </div>
    </div>
  );
};

export default MyLearningJourneyPanel;
