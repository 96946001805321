import { useEffect, forwardRef } from "react";

interface CodeOutputProps {
  srcDoc: string;
  isPaning: boolean;
}

const CodeOutput = forwardRef<HTMLIFrameElement, CodeOutputProps>(
  ({ srcDoc, isPaning }, ref) => {
    /*
     * inject iframe's console commands back to parent window
     */
    useEffect(() => {
      const scriptToInject = `
      <script>
        (function() {
          const serialize = (arg) => {
            if (typeof arg === 'function') {
              return 'Function: ' + arg.name;
            } else if (typeof arg === 'object') {
              try {
                return JSON.stringify(arg);
              } catch (error) {
                return 'Unserializable Object';
              }
            }
            return arg; // Return the argument directly if it's not an object or function
          };

          const originalConsole = {
            log: console.log,
            debug: console.debug,
            info: console.info,
            warn: console.warn,
            error: console.error,
            table: console.table,
            clear: console.clear,
            time: console.time,
            timeEnd: console.timeEnd,
            count: console.count,
            assert: console.assert
          };

          const overrideConsoleMethod = (methodName) => {
            console[methodName] = function(...args) {
              const serializedArgs = args.map(arg => serialize(arg));
              window.parent.postMessage({ type: 'IFRAME_CONSOLE', method: methodName, args: serializedArgs }, '*');
              // originalConsole[methodName].apply(console, args);
            };
          };

          const methodsToOverride = ['log', 'debug', 'info', 'warn', 'error', 'table', 'clear', 'time', 'timeEnd', 'count', 'assert'];
          methodsToOverride.forEach(methodName => overrideConsoleMethod(methodName));
        })();
      </script>
    `;

      const fullSrcDoc = scriptToInject + srcDoc;

      if (ref && "current" in ref && ref.current) {
        ref.current.srcdoc = fullSrcDoc;
      }
    }, [srcDoc, ref]);

    return (
      <iframe
        id="output-iframe"
        title="sketch preview"
        sandbox="allow-forms allow-modals allow-popups allow-scripts allow-downloads"
        allow="accelerometer; autoplay; bluetooth; camera; encrypted-media; geolocation; gyroscope; hid; microphone; magnetometer; midi; payment; usb; serial; xr-spatial-tracking"
        scrolling="auto"
        className={`flex w-full h-full none min-w-full min-h-full relative overflow-hidden z-0 ${
          isPaning ? "pointer-events-none" : ""
        }`}
        allowFullScreen
        ref={ref}
      ></iframe>
    );
  }
);

export default CodeOutput;
