import React, { useEffect, useState } from 'react'
import { MdArrowForward, MdCheck } from 'react-icons/md';
import { QuizType } from 'utils/interfaces';
import image from 'assets/imgs/PLACEHOLDERquizIcon.png'
import { checkResultComplete } from 'services/resultsRequests';

interface QuizCardProps {
  userRole: string;
  quiz: QuizType;
  handleExpand: (event: React.MouseEvent) => void;
  handleEdit: (event: React.MouseEvent) => void;
  handleAddToClasses: (event: React.MouseEvent) => void;
}

const QuizCard: React.FC<QuizCardProps> = ({
  userRole,
  quiz,
  handleExpand,
  handleEdit,
  handleAddToClasses,
}) => {
  const [isComplete, setIsComplete] = useState();
  const updateComplete = async () => {
    if (quiz._id && (['student', 'parent'].includes(userRole))) {
      setIsComplete(await checkResultComplete("quizzes", quiz._id));
    }
  }
  useEffect(() => {
    updateComplete();
  }, [quiz._id]);
  return (
    <div
      className={`w-full h-[108px] px-6 py-5 bg-neutral-25 rounded-xl justify-between items-center inline-flex hover:shadow-card ease-in-out duration-150 relative cursor-pointer ${
        isComplete ? "border-2 border-success-500": "border border-neutral-100"
      }`}
      onClick={handleExpand}
    >
      {isComplete && (
        <div className="absolute left-0 top-0 w-6 h-6">
          <div className="relative w-full h-full p-1 rounded-tl-lg rounded-br-xl bg-success-500">
            <MdCheck className="w-full h-full text-white"/>
          </div>
        </div>
      )}
      <div className="h-[68px] justify-start items-center gap-3 flex">
        <img
          src={image}
          alt={quiz.title || ""}
          className="w-[68px] h-[68px] rounded-full"
        />
        <div className="grow shrink basis-0 flex-col justify-center items-start gap-1 inline-flex">
          <div className="self-stretch text-neutral-700 text-lg font-semibold font-['Poppins'] leading-snug">{quiz.title}</div>
          <div className="self-stretch text-neutral-600 text-xs font-normal font-['Poppins'] leading-[18px]">{(quiz.questions ? quiz.questions.length : 0)} question{(quiz.questions ? quiz.questions.length : 0) > 1 && "s"}</div>
        </div>
      </div>
      <div className="flex justify-end items-center gap-5">
        {/* {userRole === "master admin" && (
          <div className="w-12 self-stretch justify-center items-center gap-3 flex">
            <div
              className="p-3 bg-neutral-100 rounded-full justify-start items-start gap-2 flex cursor-pointer z-20"
              onClick={handleEdit}
            >
              <div className="w-6 h-6">
                <MdOutlineEdit className="w-full h-full fill-neutral-700" />
              </div>
            </div>
          </div>
        )} */}
        {/* {["customer admin", "class admin", "master admin"].includes(userRole) && (
          <div className="w-12 self-stretch justify-center items-center gap-3 flex">
            <div
              className="p-3 bg-neutral-100 rounded-full justify-start items-start gap-2 flex cursor-pointer"
              onClick={handleAddToClasses}
            >
              <div className="w-6 h-6">
                <MdOutlineAddToPhotos className="w-full h-full fill-neutral-700" />
              </div>
            </div>
          </div>
        )} */}
        {["parent", "student", "master admin"].includes(userRole) && (
          <div className="w-fit self-stretch justify-center items-center gap-3 flex">
            <div
              className="p-3 bg-neutral-100 rounded-full justify-start items-start gap-2 flex cursor-pointer hover:bg-neutral-200 ease-in-out duration-150"
              onClick={handleExpand}
            >
              <div className="w-6 h-6">
                <MdArrowForward className="w-full h-full fill-neutral-700" />
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

export default QuizCard