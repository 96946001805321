/**
 * findInputError.ts
 * -----------------
 * This function allows to map, from all the errors
 * presented in the "react-hook-form" context, the error
 * asociated with the component with name "name".
 * @param errors
 * @param name
 * @returns error
 */
export function findInputError(errors: any, name: string) {
  const filtered = Object.keys(errors)
    .filter((key) => key === name)
    .reduce((cur, key) => {
      return Object.assign(cur, { error: errors[key] });
    }, {});

  return filtered;
}
