import backgroundImgCodeflix from "assets/imgs/CodeflixLoginBg.jpg";
import backgroundImgDC from "assets/imgs/uluru.jpeg";
import { platform } from "config/platform";

interface LandingPageBackgroundProps {
  children?: React.ReactNode;
}

const LandingPageBackground: React.FC<LandingPageBackgroundProps> = ({children}) => {
  return (
    <div className="w-full h-full min-h-screen flex relative">
      <img
        className="fixed min-w-[1436px] min-h-[900px] w-full h-screen size-full flex z-0 resize-none"
        src={platform === "codeflix" ? backgroundImgCodeflix : backgroundImgDC}
      />
      {children}
    </div>
  );
};

export default LandingPageBackground;
