import React from "react";
import { useState, useEffect } from "react";
import { MdCheck, MdOutlineAdd, MdOutlineSchool } from "react-icons/md";
import { ClassListType, ContactListType } from "utils/interfaces";

interface AssignClassCardProps {
  index: number;
  classItem: ClassListType;
  selectAction: (index: number, action: "add" | "remove") => void;
}

const AssignClassCard: React.FC<AssignClassCardProps> = ({
  index,
  classItem,
  selectAction,
}) => {
  // States definition
  const [selected, setSelected] = useState(false);

  /**
   * Define the select status of this card
   */
  useEffect(() => {
    setSelected(classItem.active);
  }, [classItem.active]);

  /**
   * Function used to operate on click of the checkbox
   */
  const handleOnClick = () => {
    if (selectAction) {
      // If it is currently not selected, then add to the array of selected data, otherwise, delete from the list
      selectAction(index, !selected ? "add" : "remove");
    }
  };

  return (
    <button
      className={`self-stretch p-3 bg-neutral-25 hover:bg-neutral-50 ease-in-out duration-150 border text-start ${
        selected ? "border-neutral-600" : "border-transparent"
      } rounded-xl justify-start items-center gap-3 inline-flex cursor-pointer`}
      onClick={handleOnClick}
    >
      <div className="min-w-0 grow shrink basis-0 flex-col justify-center items-start gap-2 inline-flex">
        <div className="self-stretch justify-start items-center gap-2 inline-flex">
          <div className="grow shrink basis-0 text-neutral-700 text-sm font-semibold font-sans leading-[17px]">
            {classItem.name}
          </div>
        </div>
        <div className="self-stretch justify-start items-center gap-3 inline-flex overflow-hidden">
          <div className="self-stretch justify-start items-center inline-flex gap-1">
            <div className="w-4 h-4 relative">
              <MdOutlineSchool className="w-full h-full fill-neutral-500" />
            </div>
            <div className="self-stretch justify-start items-center gap-3 inline-flex overflow-hidden text-ellipsis">
              {classItem.teachers.map(
                (teacher: ContactListType, index: number) => (
                  <React.Fragment key={index}>
                    {index > 0 && (
                      <div className="self-stretch justify-start items-start gap-2 flex">
                        <div className="w-px self-stretch bg-neutral-200" />
                      </div>
                    )}
                    <div className="text-neutral-500 text-xs font-normal font-inter leading-[14px] whitespace-nowrap">
                      {`${teacher.firstName} ${teacher.lastName}`}
                    </div>
                  </React.Fragment>
                )
              )}
            </div>
          </div>
        </div>
      </div>
      <div
        className={
          selected
            ? "p-2 bg-neutral-700 rounded-full justify-start items-start gap-2 flex flex-shrink-0"
            : "p-2 bg-neutral-200 rounded-full justify-start items-start gap-2 flex flex-shrink-0"
        }
      >
        <div className="w-4 h-4 relative">
          {selected ? (
            <MdCheck className="w-full h-full fill-neutral-50" />
          ) : (
            <MdOutlineAdd className="w-full h-full fill-neutral-500" />
          )}
        </div>
      </div>
    </button>
  );
};

export default AssignClassCard;
