const turtleScreenAttributes = ['setup', 'addshape', 'register_shape', 'getshapes', 'tracer', 'delay', 'setworldcoordinates', 'clearscreen', 'clear', 'update', 'resetscreen', 'reset', 'window_width', 'window_height', 'turtles', 'bgpic', 'bgcolor', 'done', 'mainloop', 'bye', 'exitonclick', 'onclick', 'title', 'listen', 'onkey', 'onscreenclick', 'ontimer'];
const turtleAttributes = ["degrees", "radians", "pos", "position", "towards", "distance", "heading", "xcor", "ycor", "fd", "forward", "undo", "undobufferentries", "setundobuffer", "bk", "back", "backward", "setposition", "setpos", "goto", "setx", "sety", "home", "rt", "right", "lt", "left", "seth", "setheading", "circle", "pu", "up", "penup", "pd", "down", "pendown", "isdown", "speed", "pencolor", "fillcolor", "color", "fill", "begin_fill", "end_fill", "stamp", "dot", "write", "width", "pensize", "st", "showturtle", "ht", "hideturtle", "isvisible", "shape", "colormode", "window_width", "window_height", "tracer", "update", "delay", "reset", "done", "mainloop", "clear", "onclick", "onrelease", "ondrag", "getscreen", "clone", "getpen", "getturtle", "bye", "title", "Turtle"] ;

const turtleProxy = `import turtle as _t
def __gtr(k):
    a = getattr(_t.Screen(), k, getattr(_t, k, None))
    return a if not callable(a) else lambda *ar, **kw: a(*ar, **kw)
__tp = type('__type', (), {'__getattr__': staticmethod(__gtr)})()`;

type PatternType = {
  regex: RegExp;
  replace: string | ((_: any, p1: string) => string);
}

type ToImportType = {
  alias: string;
  item: string;
};

const addImport = (list: ToImportType[], alias: string, item: string) => {
  if (!list.some((e: ToImportType) => e.alias === alias)) {
    list.push({alias, item});
  }
}

const languageSpecificScript = (code: string, language: string) => {
  switch (language) {
    case "javascript":
      return code;
    case "python":
      let importFix = '';

      const patterns: PatternType[] = [
        { regex: /^import turtle\s*$/gm, replace: 'turtle = __tp' },
        { regex: /^import turtle as (\w+)\s*$/gm, replace: (match: any, p1: string) => `${p1} = __tp` as string }
      ];
      
      let importProxy = false;
      const screenImports: ToImportType[] = [];
      const turtleImports: ToImportType[] = [];
      patterns.forEach(({regex, replace}) => {
        if (code.match(regex)) {
          importProxy = true;
          code = code.replace(regex, replace as string);
        }
      });

      if (code.match(/^from turtle import \*$/gm)) {
        importProxy = true;
        code = code.replace(/^from turtle import \*$/gm, '# from turtle import *')
        turtleScreenAttributes.forEach(item => addImport(screenImports, item, item));
        turtleAttributes.forEach(item => addImport(turtleImports, item, item));
      }

      const fromTurtleImportAsPattern = /^from turtle import ((?:\w+(?:\s+as\s+\w+)?(?:,\s*)?)*)/gm;
      const matches = code.match(fromTurtleImportAsPattern);

      if (matches) {
        importProxy = true;
        matches.forEach(match => {
          const thisLineNormalImports: ToImportType[] = [];
          const imports = match.replace(/^from turtle import /, '').split(/\s*,\s*/)
          imports.forEach(item => {
            const [method, alias = method] = item.split(/\s+as\s+/);
            if (turtleScreenAttributes.includes(method)) {
              addImport(screenImports, alias, method);
            } else {
              addImport(thisLineNormalImports, alias, method);
            }
          })
          if (thisLineNormalImports.length > 0) {
            code = code.replace(match, `from turtle import ${
              thisLineNormalImports
              .map(({ alias, item }) =>
                alias === item ? alias : `${item} as ${alias}`,
              )
              .join(", ")
            }`);
          } else {
            code = code.replace(match, `# ${match}`);
          }
        })
      }
      
      if (importProxy) {
        importFix += turtleProxy;
      }
      importFix += turtleImports.map(({alias, item}) => `\n${alias} = __tp.${item}`).join('');
      importFix += screenImports.map(({alias, item}) => `\n${alias} = lambda *args, **kwargs: __tp.${item}(*args, **kwargs)`).join('');

      return `
        function outf(text) {
            console.log(text);
        } 
        function builtinRead(x) {
            if (Sk.builtinFiles === undefined || Sk.builtinFiles["files"][x] === undefined)
                    throw "File not found: '" + x + "'";
            return Sk.builtinFiles["files"][x];
        }
        function inputf(prompt) {
          // Send the signal
          window.parent.postMessage({
            type: "SELECT_INPUT",
            prompt: prompt
          }, "*");
          return new Promise((resolve) => {
            window.addEventListener('message', function(event) {
              // Resolve with the message received from the parent
              resolve(event.data);
            }); 
          })
        }
        function runit() { 
          var prog = \`${code}\`; 
          var mypre = document.getElementById("output"); 
          mypre.innerHTML = ''; 
          Sk.pre = "output";
          Sk.configure({output:outf, read:builtinRead, inputfun:inputf, inputfunTakesPrompt: true, retainGlobals: true}); 
          (Sk.TurtleGraphics || (Sk.TurtleGraphics = {})).target = 'mycanvas';
          var myPromise = Sk.misceval.asyncToPromise(function() {
              Sk.importBuiltinWithBody("", false, \`${importFix}\`, true);
              return Sk.importMainWithBody("", false, prog, true);
          });
          myPromise.then(function(mod) {
              // console.log('success');
          },
          function(err) {
              console.log(err.toString());
          });
        }

        setTimeout(function() {runit()}, 0);
      `;
    default:
      // Placeholder for other languages or a default message indicating unsupported language.
      return `console.error("Unsupported language: ${language}");`;
  }
};

export default languageSpecificScript;
