import { useEffect, useState } from "react";
import { Payload } from "recharts/types/component/DefaultTooltipContent";
import { capitaliseFirstChar } from "utils/utils";

interface ChartTooltipProps {
  userName: string;
  active?: boolean;
  payload?: Payload<number, string>[];
}

const ChartTooltip: React.FC<ChartTooltipProps> = ({active, userName, payload}) => {
  // States definition
  const [userValue, setUserValue] = useState("0");
  const [classValue, setClassValue] = useState("0");

  /**
   * Function used to format the number on the tooltip and
   * prevent to display long decimal number
   * @param num 
   * @returns 
   */
  const formatNumber = (num: number) => {
    return Number.isInteger(num) ? num.toString() : num.toFixed(1);
  }

  useEffect(() => {
    if (active && payload && payload.length) {
      setUserValue(payload[0].value ? formatNumber(payload[0].value) : "0");
      setClassValue(payload[1].value ? formatNumber(payload[1].value) : "0");
    }
  }, [payload]);

  return (
    <div className="bg-white border border-neutral-300 rounded-md p-4 last:flex-col justify-start items-start gap-2 flex">
      <div className="justify-start items-center gap-2 inline-flex">
        <div className="w-6 h-1.5 relative bg-dc-accent-500 rounded" />
        <div className="text-neutral-500 text-[10px] font-normal font-sans leading-3 tracking-tight">
          {capitaliseFirstChar(userName)}: <span className="font-medium">{userValue}</span>
        </div>
      </div>
      <div className="justify-start items-center gap-2 inline-flex">
        <div className="w-6 h-1.5 relative bg-neutral-500 rounded" />
        <div className="text-neutral-500 text-[10px] font-normal font-sans leading-3 tracking-tight">
          Class: <span className="font-medium">{classValue}</span>
        </div>
      </div>
    </div>
  );
};

export default ChartTooltip;
