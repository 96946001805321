import {
  MdAddCircleOutline,
  MdOutlineAddBusiness,
  MdOutlineArrowCircleLeft,
} from "react-icons/md";
import { ClassListType, SchoolListType } from "utils/interfaces";
import PopUpPanel from "../pop-up-panels/PopUpPanel";
import { useEffect, useState } from "react";
import ClassDetailsFormCard from "./ClassDetailsFormCard";

interface ClassDetailsProps {
  selectedData?: SchoolListType | null;
  activateSaveButton: boolean;
  classes: ClassListType[];
  handleSave: () => Promise<void>;
  setStage: React.Dispatch<React.SetStateAction<number>>;
  setClasses: React.Dispatch<React.SetStateAction<ClassListType[]>>;
  headerRef: React.MutableRefObject<any>;
}

const ClassDetails: React.FC<ClassDetailsProps> = ({
  selectedData,
  activateSaveButton,
  classes,
  handleSave,
  setStage,
  setClasses,
  headerRef,
}) => {
  // States definition
  const [showAddClassesPopUp, setShowAddClassesPopUp] = useState(false);
  const [selectedClasses, setSelectedClasses] = useState<ClassListType[]>([]);

  /**
   * Define handle previous button
   */
  const handlePrev = () => {
    setStage(2);
  };

  /**
   * Definition of add class action
   */
  const handleAddClass = () => {
    setShowAddClassesPopUp(true);
  };

  /**
   * Define the selected classes
   */
  useEffect(() => {
    setSelectedClasses(classes.filter((classItem: ClassListType) => classItem.active));
  }, [classes]);

  return (
    <div className="w-[562px] h-full p-6 bg-white rounded-3xl border-neutral-200 justify-start items-start gap-2 inline-flex">
      <div className="w-full flex-col justify-start items-start gap-12 inline-flex">
        <div className="self-stretch h-7 flex-col justify-start items-start gap-12 flex">
          <div className="self-stretch text-neutral-700 text-[23px] font-semibold font-sans leading-7">
            Class details
          </div>
        </div>
        <div className="self-stretch h-fit flex-col justify-start items-start gap-5 flex">
          {/* Show the add class button, or the list of possible classes */}
          {selectedClasses.length === 0 ? (
            <button
              className="w-full px-2 py-3 rounded-lg border border-dashed border-neutral-300 hover:bg-neutral-25 ease-in-out duration-150 justify-center items-center gap-2 inline-flex cursor-pointer"
              onClick={handleAddClass}
            >
              <div className="w-6 h-6 relative">
                <MdOutlineAddBusiness className="w-full h-full fill-neutral-500" />
              </div>
              <div className="text-neutral-500 text-base font-normal font-sans leading-[19px]">
                Add a class
              </div>
            </button>
          ) : (
            <>
              {selectedClasses.map(
                (classElem: ClassListType, index: number) => (
                  <ClassDetailsFormCard
                    key={index}
                    thisClass={classElem}
                    setClasses={setClasses}
                  />
                )
              )}
              <div className="w-full h-6 justify-between items-center inline-flex">
                <button
                  className="group rounded justify-center items-center gap-2 flex cursor-pointer"
                  onClick={handleAddClass}
                >
                  <div className="w-6 h-6 relative">
                    <MdAddCircleOutline className="w-full h-full fill-dc-secondary-600 group-hover:fill-dc-secondary-700" />
                  </div>
                  <div className="text-dc-secondary-600 group-hover:text-dc-secondary-700 text-lg font-semibold font-sans leading-snug">
                    Add additional class
                  </div>
                </button>
              </div>
            </>
          )}
        </div>
        <div className="self-stretch h-[46px] justify-between items-center inline-flex">
          <button
            className="group rounded justify-center items-center gap-2 flex cursor-pointer"
            onClick={handlePrev}
          >
            <div className="w-6 h-6 relative">
              <MdOutlineArrowCircleLeft className="w-full h-full fill-dc-secondary-600 group-hover:fill-dc-secondary-700" />
            </div>
            <div className="text-dc-secondary-600 group-hover:text-dc-secondary-700 text-lg font-semibold font-sans">
              Prev
            </div>
          </button>
          <button
            className={
              activateSaveButton
                ? "w-[219px] h-full px-5 py-3 bg-dc-secondary-600 hover:bg-dc-secondary-700 ease-in-out duration-150 rounded justify-center items-center gap-2 flex cursor-pointer"
                : "w-[219px] h-full px-5 py-3 bg-Subtle rounded justify-center items-center gap-2 flex cursor-not-allowed"
            }
            onClick={handleSave}
          >
            <div
              className={
                activateSaveButton
                  ? "text-white text-lg font-semibold font-sans leading-snug"
                  : "text-neutral-400 text-lg font-semibold font-sans leading-snug"
              }
            >
              Save and create
            </div>
          </button>
        </div>
      </div>
      <PopUpPanel
        type="assign-class"
        contextSchool={selectedData}
        showPopUp={showAddClassesPopUp}
        setShowPopUp={setShowAddClassesPopUp}
        classes={classes}
        setClasses={setClasses}
        headerRef={headerRef}
      />
    </div>
  );
};

export default ClassDetails;
