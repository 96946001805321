/**
 * Definition of the OR division with rules
 * @returns OrDivision HTML element
 */
const OrDivision = () => {
  return (
    <div className="self-stretch justify-center items-center gap-4 inline-flex">
      <hr className="flex-1 border-neutral-100" />
      <div className="justify-start items-start gap-2 flex">
        <div className="text-neutral-700 text-xs font-normal font-inter uppercase leading-[14px] tracking-wide">
          or
        </div>
      </div>
      <hr className="flex-1 border-neutral-100" />
    </div>
  );
};

export default OrDivision
