import CreateNewPopUp from "components/main-view/classes/utils/CreateNewPopUp";
import { forwardRef, ReactElement, useImperativeHandle, useState } from "react";
import {
  MdOutlineSave,
  MdAdd,
  MdOutlinePerson,
  MdClose,
  MdOutlineExpandMore,
} from "react-icons/md";
import { capitaliseFirstChar } from "utils/utils";

interface ClassesHeaderOptionsProps {
  ref: any;
  userRole: string;
  setShowSideBarMenu: React.Dispatch<React.SetStateAction<boolean>>;
  setTitle: React.Dispatch<React.SetStateAction<string>>;
  setDescription: React.Dispatch<React.SetStateAction<ReactElement | string>>;
  setShowBackButton: React.Dispatch<React.SetStateAction<boolean>>;
  classesRef: React.MutableRefObject<any>;
}

const ClassesHeaderOptions: React.FC<ClassesHeaderOptionsProps> = forwardRef(
  (
    {
      userRole,
      setShowSideBarMenu,
      setTitle,
      setDescription,
      setShowBackButton,
      classesRef,
    },
    ref
  ) => {
    // States definition
    const [showCreateNewPopUp, setShowCreateNewPopUp] = useState(false);
    const [showCreateNew, setShowCreateNew] = useState(true);
    const [saveButton, setSaveButton] = useState<{
      show: boolean;
      disabled: boolean;
    }>({ show: false, disabled: true });

    /**
     * Define the list of functions that can be used from external components
     * using a reference
     */
    useImperativeHandle(ref, () => ({
      setShowCreateNewButton(bool: boolean) {
        setShowCreateNew(bool);
      },
      setSaveButton(options: { show: boolean; disabled: boolean }) {
        setSaveButton(options);
      },
      pressCreateNewClass() {
        onCreateClass();
      },
      pressCreateNewStudent() {
        onCreateEditStudent();
      },
      pressEditStudent(title = "", description = "") {
        onCreateEditStudent(title, description);
      },
      onDiscard() {
        onDiscard();
      },
    }));

    /**
     * CreateNewPopUp functions definition
     */
    // Create class
    const onCreateClass = () => {
      // Close the pop up panel menu
      setShowCreateNewPopUp(false);

      // And open the create class pop up window
      classesRef.current.setShowCreateNewClass(true);
    };
    // Create Student
    const onCreateEditStudent = (
      title = "New Student",
      description = "Student"
    ) => {
      // Regarding the header
      setShowCreateNewPopUp(false);
      setShowBackButton(true);
      setShowSideBarMenu(false);
      setShowCreateNew(false);
      setSaveButton({ show: true, disabled: true });
      setTitle(title);
      setDescription(
        <div className="w-fit flex gap-1">
          <MdOutlinePerson />
          <div>{description}</div>
        </div>
      );

      // Hide the tab and search bar
      classesRef.current.setShowTabSearchBar(false);
      // And set the status
      classesRef.current.setCreateNewStudent(true);
    };

    /**
     * Function that defines the root view
     */
    const onDiscard = () => {
      // Get the active class
      const activeClass = classesRef.current.getActiveClass();
      // Show the main tab
      classesRef.current.setShowTabSearchBar(true);
      // And set the status
      classesRef.current.setCreateNewStudent(false);

      // Activate the side bar menu
      setShowSideBarMenu(true);

      // Change the tab name
      setTitle(capitaliseFirstChar(activeClass));
      setDescription(`List of current ${activeClass}`);
      // Delete back button
      setShowBackButton(false);
      // And turn off the other buttons
      setSaveButton({ show: false, disabled: true });
      // Activating the create new button
      setShowCreateNew(true);
    };

    const handleSave = () => {
      // If the save button is available, then make it work
      if (!saveButton.disabled) classesRef.current.pressSaveButton();
    };

    return (
      <>
        {["master admin", "customer admin", "class admin"].includes(userRole) &&
          showCreateNew && (
            <div className="p-3 rounded-full flex-col justify-center items-center gap-2 inline-flex relative">
              <div
                className="justify-center items-center gap-0.5 inline-flex cursor-pointer"
                onClick={() => {
                  if (!showCreateNewPopUp)
                    setShowCreateNewPopUp(!showCreateNewPopUp);
                }}
              >
                <div className="p-3 bg-neutral-50 rounded-full backdrop-blur-[32px] justify-center items-center gap-2 flex hover:bg-neutral-100 ease-in-out duration-300">
                  <div className="w-6 h-6 relative">
                    <MdAdd className="w-full h-full fill-dc-secondary-600" />
                  </div>
                </div>
                <div className="w-4 h-4 relative">
                  <MdOutlineExpandMore className="w-4 h-4 text-dc-secondary-600" />
                </div>
              </div>
              <div className="justify-center items-center gap-0.5 inline-flex">
                <div className="text-center text-dc-secondary-600 text-xs font-semibold font-sans uppercase leading-[14px] tracking-wide whitespace-nowrap select-none">
                  Create
                </div>
                <div className="w-4 h-4 px-[4.28px] opacity-0 justify-center items-center flex" />
              </div>
              {showCreateNewPopUp && (
                <CreateNewPopUp
                  setShowCreateNewPopUp={setShowCreateNewPopUp}
                  onCreateClass={onCreateClass}
                  onCreateEditStudent={() => onCreateEditStudent()}
                />
              )}
            </div>
          )}
        {saveButton.show && (
          <div className="justify-start items-start gap-1 flex">
            <div className="p-3 rounded-full flex-col justify-center items-center gap-2 inline-flex">
              <div className="justify-center items-center gap-0.5 inline-flex">
                <div
                  className="p-3 bg-neutral-50 rounded-full backdrop-blur-[32px] justify-center items-center gap-2 flex cursor-pointer hover:bg-neutral-100 ease-in-out duration-300"
                  onClick={onDiscard}
                >
                  <div className="w-6 h-6 relative">
                    <MdClose className="w-full h-full fill-dc-secondary-600" />
                  </div>
                </div>
              </div>
              <div className="justify-center items-center gap-0.5 inline-flex">
                <div className="text-center text-dc-secondary-600 text-xs font-semibold font-sans uppercase leading-[14px] tracking-wide">
                  Discard
                </div>
              </div>
            </div>
            <div className="p-3 rounded-full flex-col justify-center items-center gap-2 inline-flex">
              <div
                className={
                  saveButton.disabled
                    ? "justify-center items-center gap-0.5 inline-flex"
                    : "justify-center items-center gap-0.5 inline-flex cursor-pointer"
                }
                onClick={handleSave}
              >
                <div className={
                      saveButton.disabled
                        ? "p-3 bg-neutral-50 rounded-full backdrop-blur-[32px] justify-center items-center gap-2 flex"
                        : "p-3 bg-neutral-50 rounded-full backdrop-blur-[32px] justify-center items-center gap-2 flex hover:bg-neutral-100 ease-in-out duration-300"
                    }>
                  <div className="w-6 h-6 relative">
                    <MdOutlineSave
                      className={
                        saveButton.disabled
                          ? "w-full h-full fill-neutral-300"
                          : "w-full h-full fill-dc-secondary-600"
                      }
                    />
                  </div>
                </div>
              </div>
              <div className="justify-center items-center gap-0.5 inline-flex">
                <div
                  className={
                    saveButton.disabled
                      ? "text-center text-neutral-300 text-xs font-semibold font-sans uppercase leading-[14px] tracking-wide whitespace-nowrap"
                      : "text-center text-dc-secondary-600 text-xs font-semibold font-sans uppercase leading-[14px] tracking-wide whitespace-nowrap"
                  }
                >
                  Save
                </div>
              </div>
            </div>
          </div>
        )}
      </>
    );
  }
);

export default ClassesHeaderOptions;
