import { BACKEND_URL } from "config/getUrl";
import { SchoolListType } from "utils/interfaces";
import { LoadingDots } from "utils/loadingAnimation";

interface ClassesTabPopUpProps {
  schoolData: SchoolListType[];
  setShowSchoolListTab: React.Dispatch<React.SetStateAction<boolean>>;
  setUserSchool?: React.Dispatch<React.SetStateAction<SchoolListType | null>>;
}

interface TabOptionProps {
  school: SchoolListType;
  setUserSchool?: React.Dispatch<React.SetStateAction<SchoolListType | null>>;
}

const TabOption: React.FC<TabOptionProps> = ({ school, setUserSchool }) => {
  /**
   * Function used when clicking option
   */
  const handleClickOption = () => {
    if (setUserSchool) setUserSchool(school);
  };

  return (
    <button
      className="w-full p-3 rounded-lg justify-start text-start items-center gap-2 inline-flex bg-white text-neutral-700 hover:bg-neutral-700 hover:text-white cursor-pointer"
      onClick={handleClickOption}
    >
      <div className="w-6 h-6 min-w-6 min-h-6 bg-white rounded-full overflow-hidden">
        <img
          alt=""
          src={`${BACKEND_URL}/imgs/organisations/logo/${school?._id}`}
          onError={({ currentTarget }) => {
            currentTarget.onerror = null;
            currentTarget.src = "/logo.svg";
          }}
          className="w-full h-full"
        />
      </div>
      <div className="w-full text-base font-normal font-sans leading-7 overflow-hidden break-keep whitespace-nowrap text-ellipsis">
        {school.name}
      </div>
    </button>
  );
};

const ClassesTabPopUp: React.FC<ClassesTabPopUpProps> = ({
  schoolData,
  setShowSchoolListTab,
  setUserSchool,
}) => {
  return (
    <>
      <div
        className="fixed top-0 left-0 w-screen h-screen bg-transparent z-20"
        onClick={() => setShowSchoolListTab(false)}
      />
      <div className="absolute w-[250px] top-[40px] left-0 p-3 bg-white rounded-lg shadow justify-center items-center z-30">
        <div className="relative w-full h-fit max-h-[300px] flex-col gap-2 inline-flex overflow-y-auto pop-up-scrollbar">
          {schoolData && schoolData.length > 0 ? (
            schoolData.map((school: SchoolListType, index: number) => (
              <TabOption
                key={index}
                school={school}
                setUserSchool={setUserSchool}
              />
            ))
          ) : (
            <div className="w-full h-fit flex justify-center items-center">
              <LoadingDots bgColor="bg-neutral-300" />
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default ClassesTabPopUp;
