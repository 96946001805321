/**
 * Function used to check if thean object has no content
 * @param objectName
 * @returns
 */
export const isObjectEmpty = (obj: object) => {
  return obj && Object.keys(obj).length === 0 && obj.constructor === Object;
};

/**
 * Function used to format the number display on a card
 * References: https://www.stylemanual.gov.au/grammar-punctuation-and-conventions/numbers-and-measurements/telephone-numbers
 * @param phoneNumber
 * @returns
 */
export const formatPhoneNumber = (phoneNumber: string) => {
  // First of all, check if the number has ten numbers (to be defined...)
  if (phoneNumber)
    return `${phoneNumber.slice(0, 4)} ${phoneNumber.slice(
      4,
      7
    )} ${phoneNumber.slice(7, 10)}`;
  else return "";
};

/**
 * Function used to transform the time into a clock format
 * @param time Number of seconds to transform
 * @param padMinutes Boolean to decide if padding the minutes if the number is less than 10
 * @returns
 */
export const clockify = (time: number, padMinutes: boolean = true) => {
  if (time <= 0) {
    return "00:00";
  }

  // Define the minutes and seconds
  const minutes = Math.floor(time / 60);
  const seconds = time % 60;

  // Add '0' when minutes and seconds are less than 10
  // const zeroSec = seconds < 10 ? '0': '';
  // const zeroMin = minutes < 10 ? '0': '';
  const minutesStr = padMinutes
    ? minutes.toString().padStart(2, "0")
    : minutes.toString();
  const secondsStr = seconds.toString().padStart(2, "0");

  return `${minutesStr}:${secondsStr}`;
};

/**
 * Function to get the date in ISO format as a string, but
 * with the time of the current zone
 * @param date Date to transform
 * @returns
 */
export const toLocalISOTime = (date: Date) => {
  const timezoneOffsetInMinutes = date.getTimezoneOffset();
  const adjustedDate = new Date(
    date.getTime() - timezoneOffsetInMinutes * 60 * 1000
  );
  return adjustedDate.toISOString(); // Remove the 'Z' at the end
};

/**
 * Function used to get an array of the last n days.
 * @param days Number of last days to include from today.
 * @returns
 */
export const getLastDates = (days: number) => {
  const dates = [];
  for (let i = 0; i < days; i++) {
    const date = new Date();
    date.setDate(date.getDate() - i);
    dates.push(date.toISOString().split("T")[0]); // Format the date as YYYY-MM-DD
  }
  return dates.reverse();
};

/**
 * Function used to capitalise the first character of a string
 * @param str
 * @returns
 */
export const capitaliseFirstChar = (str: string) => {
  const firstCharCap = str.charAt(0).toUpperCase();
  const restChars = str.slice(1);
  return firstCharCap + restChars;
};

/**
 * Function used to transform a continuos string into a string
 * separated by hyphens ("-") separating spaces, underscores and
 * capital letters.
 * @param str
 * @returns
 */
export const toKebabCase = (str: string) => {
  // Get matches to check if matches are not null (for TypeScript conflicts)
  const matches = str.match(
    /[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/g
  );
  return matches ? matches.join("-").toLowerCase() : "";
};

/**
 * Function used to allow just numbers and + character
 * when inputting numbers on an input change.
 * @param text: phone number to be entered
 * @returns text with just numbers and + character.
 */
export const allowOnlyNumbers = (text: string) => {
  return text.replace(/[^0-9\+\s]/g, "");
};

/**
 * Function utilised for redirecting to an external website.
 * @param url: string. URL to be redirected.
 */
export const externalNavigate = (url: string) => {
  window.location.href = url;
};

/**
 * Function used to remove a specific element in an array.
 * @param array
 * @param element
 * @returns
 */
export const removeItem = (array: any, element: any) => {
  return array.filter((item: any) => item !== element);
};
