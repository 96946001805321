import { BACKEND_URL } from "config/getUrl";

/**
 * loginRequests.ts
 * -----------------
 * This file contains the requests needed to perform
 * the class creation, queries, updates, among others.
 */
const API_URL = BACKEND_URL

type userToLoginType = {
  email?: string;
  username?: string;
  password: string;
  remember: boolean;
};

export const getEmailLogin = async (userToLogin: userToLoginType) => {
  // Sending to the server
  const options: RequestInit = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    credentials: "include",
    body: JSON.stringify({
      email: userToLogin.email,
      password: userToLogin.password,
    }),
  };

  // Post the user to login
  const response = await fetch(`${API_URL}/auth/login`, options);

  if (response.status === 200) {
    // Get the response
    return await response.json();
  }
};

export const requestUsernameLogin = async (userToLogin: userToLoginType) => {
  // Sending to the server
  const options: RequestInit = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    credentials: "include",
    body: JSON.stringify({
      username: userToLogin.username,
      password: userToLogin.password,
      remember: userToLogin.remember
    }),
  };

  // Post the user to login
  const response = await fetch(`${API_URL}/auth/login`, options);

  if (response.status === 200) {
    // Get the response
    return await response.json();
  }
};

export const getOAuthLogin = async (provider: "google" | "microsoft") => {
  // Sending the request to the server based on the provider
  const response = await fetch(`${API_URL}/auth/${provider}`);

  if (response.status === 200) {
    // Get the response
    let data = await response.json();
    return data.url;
  }
};
