import { ReactElement } from "react";
import { MdChevronRight } from "react-icons/md";

interface SideBarOptionProps {
  text: string;
  icon: ReactElement<any, any>;
  active: boolean;
  setActiveItem: React.Dispatch<React.SetStateAction<string>>;
}

const SideBarOption: React.FC<SideBarOptionProps> = ({
  text,
  icon,
  active,
  setActiveItem,
}) => {
  return (
    <button
      className="self-stretch h-fit  flex-col justify-center items-center flex cursor-pointer"
      onClick={() => setActiveItem(text.toLowerCase())}
    >
      <div
        className={
          active
            ? "self-stretch p-2 bg-white rounded-[20px] justify-between items-center inline-flex"
            : "self-stretch p-2 rounded-[20px] justify-between items-center inline-flex hover:bg-neutral-100 ease-in-out duration-300"
        }
      >
        <div className="w-4 h-4 py-[4.28px] opacity-0 justify-center items-center flex" />
        <div className="justify-start items-start gap-2 flex">
          <div className="w-8 h-8 relative">{icon}</div>
        </div>
        <div className="w-4 h-4 justify-center items-center flex">
          {active ? (
            <MdChevronRight className="w-full h-full fill-neutral-700" />
          ) : (
            <></>
          )}
        </div>
      </div>
      <div
        className={
          active
            ? "text-center text-neutral-700 text-sm font-semibold font-sans leading-[21px] select-none"
            : "text-center text-neutral-700 text-sm font-normal font-sans leading-[21px] select-none"
        }
      >
        {text}
      </div>
    </button>
  );
};

export default SideBarOption;
