import logo from "assets/imgs/logo.svg";
import backgroundImg from "assets/imgs/uluru.jpeg";
import { useEffect, useState } from "react";
import { MdOutlineQrCode } from "react-icons/md";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { sendNewCodeRequest, twoFactorCheckId, twoFactorValidateCode } from "services/authRequests";
import { clockify } from "utils/utils";

const TwoFactorAuthentication = () => {
  // Paramters definition
  const defaultTimer = 180; // Seconds
  const codeLength = 6; // Numbers

  // States definition
  const [email, setEmail] = useState("");
  const [countDown, setCountDown] = useState<number>(defaultTimer);
  const [showError, setShowError] = useState<boolean>(false);
  const [inputDisabled, setInputDisabled] = useState<boolean>(false);

  // Navigate definition
  const navigate = useNavigate();
  // Use the location to avoid enter this page from any other place
  const { state } = useLocation();

  // Get the resetTokenId
  const { twoFactorId } = useParams();

  /**
   * Check if the page is accessed by a redirection
   */
  if (!state?.fromApp) {
    navigate('/url-error');
  }

  /**
   * Check if the resetTokenId is valid, if not send again to resetPassword
   */
  useEffect(() => {
    twoFactorCheckId(twoFactorId || "").then((response) => {
      // If the response it's not successful, send to login again
      if (!response.successful) {
        navigate("/login");
      }

      // Set the email of the reset token
      setEmail(response.email);
    });
  }, []);

  /**
   * Counterdown routine
   */
  useEffect(() => {
    // Just perform the countdown while the timer is greater than zero
    if (countDown > 0) {
      let interval = setInterval(() => {
        setCountDown(countDown - 1);
      }, 1000);

      // Clearing the interval
      return () => clearInterval(interval);
    }
  }, [countDown]);

  /**
   * Function to send a new code to authenticate
   */
  const sendNewCode = async () => {
    // Send the request
    let response = await sendNewCodeRequest(twoFactorId, email);

    // If there is any error with this, then try again with the login
    if (!response.successful){
      navigate("/login");
    }

    // And set the timer again
    setCountDown(defaultTimer);
  };

  /**
   * Function to track the code input to check if the input code is right
   */
  const codeInputTracking = async (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.value.length === codeLength && countDown > 0) {
      // Disable the input and the error messages while validating
      setShowError(false);
      setInputDisabled(true);

      // Check if the input code is valid
      let response = await twoFactorValidateCode(twoFactorId || "", event.target.value, state.remember);
      
      // Check the response from the server
      if (response.success) {
        navigate("/")
      } else {
        // Show the error message
        setShowError(true);
      }
    }
    
    // Return to the regular input behaviour
    setInputDisabled(false);
  };

  return (
    <div className="w-full h-screen">
      <div className="absolute w-full h-screen min-w-[1436px] z-0">
        <img
          className="absolute grow basis-0 size-full flex z-0 resize-none"
          src={backgroundImg}
        />
      </div>
      <div className="w-full h-full p-14 z-10 flex justify-center">
        <div className="basis-0 w-[628px] min-w-[628px] p-24 bg-neutral-100 bg-opacity-70 rounded-2xl shadow backdrop-blur-[32px] justify-start items-center gap-24 inline-flex">
          <div className="grow  basis-0 flex-col justify-center items-center gap-12 inline-flex">
            <div className="self-stretch justify-start items-center gap-2 inline-flex">
              <img className="w-[188px] h-[91px] rounded-xl" src={logo} />
            </div>
            <div className="self-stretch h-[34px] flex-col justify-start items-start gap-6 flex">
              <div className="self-stretch justify-start items-center gap-2 inline-flex">
                <div className="grow shrink basis-0 text-neutral-700 text-[33px] font-semibold font-sans leading-[34px]">
                  Two-factor authentication
                </div>
              </div>
            </div>
            <div className="self-stretch h-48 flex-col justify-start items-start gap-8 flex">
              <div className="self-stretch justify-start items-center gap-2 inline-flex">
                <div className="grow shrink basis-0">
                  <span className="text-neutral-700 text-base font-normal font-sans leading-normal">
                    We just sent you a verification code to:{" "}
                  </span>
                  <span className="text-neutral-700 text-base font-bold font-sans leading-normal">
                    {email}
                  </span>
                </div>
              </div>
              <div className="self-stretch h-fit flex-col justify-start items-start gap-6 flex">
                <div className="self-stretch justify-start items-start gap-2 inline-flex">
                  <div className="grow shrink basis-0 h-[17px] text-neutral-700 text-base font-normal font-sans leading-[17px]">
                    <span>Code expires in </span>
                    <span className="font-bold">{clockify(countDown)}</span>
                  </div>
                  <div
                    className="grow shrink basis-0 text-right text-cyan-800 text-base font-bold font-sans underline leading-[17px] cursor-pointer"
                    onClick={sendNewCode}
                  >
                    Send a new code
                  </div>
                </div>
                <div className="self-stretch h-fit flex-col justify-start items-start gap-1.5 flex">
                  <div className="rounded justify-start items-start gap-2 inline-flex">
                    <div className="text-neutral-500 text-xs font-normal font-sans uppercase leading-[14px] tracking-wide">
                      2FA code
                    </div>
                  </div>
                  <div className={inputDisabled ? 
                  "w-full h-[51px] px-3.5 rounded-lg bg-neutral-200 text-neutral-700 placeholder:text-neutral-300 text-base font-normal font-sans leading-[19px] flex items-center" : 
                  "w-full h-[51px] px-3.5 rounded-lg bg-neutral-50 text-neutral-700 placeholder:text-neutral-300 text-base font-normal font-sans leading-[19px] flex items-center"}>
                    <div className="flex justify-center items-center">
                      <MdOutlineQrCode className="w-5 h-5 text-neutral-500" />
                    </div>
                    <input
                      id="2fa"
                      type="text"
                      className={
                        inputDisabled
                          ? "w-full h-full px-2 rounded-lg bg-neutral-200 text-neutral-300 placeholder:text-neutral-300 focus:outline-none text-base font-normal font-sans leading-[19px]"
                          : "w-full h-full px-2 rounded-lg bg-neutral-50 text-neutral-700 placeholder:text-neutral-300 focus:outline-none text-base font-normal font-sans leading-[19px]"
                      }
                      placeholder="Enter the 2FA code sent to your email"
                      onChange={codeInputTracking}
                      maxLength={codeLength}
                      disabled={inputDisabled}
                    />
                  </div>
                {showError && <div className="text-error-600 text-sm font-normal font-sans">
                  Error: The Two-factor authentication code is not matching
                </div>}
                </div>
              </div>
            </div>
            <Link to="/login">
              <div className="self-stretch justify-start items-start gap-2 inline-flex">
                <div className="grow shrink basis-0 text-center text-cyan-800 text-base font-bold font-sans underline leading-[17px]">
                  Back to Login
                </div>
              </div>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TwoFactorAuthentication;
