import { useEffect, useState } from "react";
import {
  MdAdd,
  MdCheck,
  MdLaunch,
  MdMailOutline,
  MdOutlineDelete,
  MdOutlineLocalPhone,
} from "react-icons/md";
import { formatPhoneNumber } from "utils/utils";
import { ContactListType } from "utils/interfaces";
import ContactCardWrapper from "./ContactCardWrapper";

interface ContactCardProps {
  contact: ContactListType;
  type: "search-pop-up" | "inside-form";
  selectedContacts: ContactListType[];
  primaryTag?: boolean;
  justDisplay?: boolean;
  selectAction?: (contact: ContactListType, action: "add" | "remove") => void;
  setSelectedContacts: React.Dispatch<React.SetStateAction<ContactListType[]>>;
  setSelectedData?: React.Dispatch<
    React.SetStateAction<ContactListType | null>
  >;
  setShowEditContact?: React.Dispatch<React.SetStateAction<boolean>>;
}

const ContactCard: React.FC<ContactCardProps> = ({
  type,
  contact,
  selectedContacts,
  primaryTag = true,
  justDisplay = false,
  selectAction,
  setSelectedContacts,
  setSelectedData,
  setShowEditContact,
}) => {
  // States definition
  const [selected, setSelected] = useState(false);
  const [isPrimary, setIsPrimary] = useState(false);

  /**
   * Define the select status of this card
   */
  useEffect(() => {
    // Get the selected data
    const selectedContactDataIds = selectedContacts.map(
      (contact: ContactListType) => contact._id
    );

    // Set selected if it's included in the list
    if (selectedContactDataIds.includes(contact._id)) {
      setSelected(true);

      // And set as primary if there is the first element in the list
      // and if the option is selected to display the primary tag
      if (primaryTag) setIsPrimary(selectedContactDataIds[0] === contact._id);
    } else {
      // Otherwise, select force them to be false
      setSelected(false);
      // Set if the option is selected to display the primary tag
      if (primaryTag) setIsPrimary(false);
    }
  }, [selectedContacts]);

  /**
   * Function used to operate on click of the checkbox
   */
  const handleOnClick = () => {
    if (type === "search-pop-up" && selectAction && !justDisplay) {
      // If it is currently not selected, then add to the array of selected data, otherwise, delete from the list
      selectAction(contact, !selected ? "add" : "remove");
    }
  };

  /**
   * Function used to operate on click of the delete button
   */
  const handleDelete = () => {
    // Deleting the contact with the current id
    const contactsFiltered = selectedContacts.filter(
      (currentContact: ContactListType) => currentContact._id !== contact._id
    );
    if (setSelectedContacts) setSelectedContacts(contactsFiltered);
  };

  /**
   * Function used to operate on click of the edit/open button
   */
  const handleEdit = () => {
    // Deleting the contact with the current id
    if (setSelectedData && setShowEditContact) {
      // Select the current data
      setSelectedData(contact);
      // And display the pop up
      setShowEditContact(true);
    }
  };

  return (
    <>
      {contact && (
        <ContactCardWrapper
          type={type}
          selected={selected}
          justDisplay={justDisplay}
          handleOnClick={handleOnClick}
        >
          <div className="grow shrink basis-0 flex-col justify-center items-start gap-2 inline-flex">
            <div className="w-[335px] h-fit justify-start items-center gap-2 inline-flex">
              <div className="overflow-hidden self-stretch break-keep whitespace-nowrap text-ellipsis text-neutral-700 text-sm font-semibold font-sans leading-[17px]">
                {contact.firstName + " " + contact.lastName}
              </div>
              {isPrimary && (
                <div className="w-[59px] px-1.5 py-0.5 bg-dc-accent-500 rounded justify-center items-center gap-2 flex">
                  <div className="text-white text-[10px] font-normal font-sans uppercase leading-3 tracking-wide">
                    Primary
                  </div>
                </div>
              )}
            </div>
            <div className="self-stretch justify-start items-center gap-3 inline-flex">
              <div className="self-stretch justify-between items-center gap-1 flex">
                <div className="w-4 h-4 justify-center items-center flex">
                  <MdOutlineLocalPhone className="w-full h-full fill-neutral-500" />
                </div>
                <div className="text-neutral-500 text-xs font-normal font-inter leading-[14px]">
                  {formatPhoneNumber(contact.phone)}
                </div>
              </div>
              <div className="w-px self-stretch justify-start items-start gap-2 flex">
                <div className="w-px self-stretch bg-neutral-200" />
              </div>
              <div className="grow shrink basis-0 h-4 justify-start items-center gap-1 flex">
                <div className="w-4 h-4 justify-center items-center flex">
                  <MdMailOutline className="w-full h-full fill-neutral-500" />
                </div>
                <div className="w-[181px] text-neutral-500 text-xs font-normal font-inter leading-[14px] overflow-hidden text-ellipsis">
                  {contact.email}
                </div>
              </div>
            </div>
          </div>
          {type === "search-pop-up" && !justDisplay && (
            <div
              className={
                selected
                  ? "p-2 bg-neutral-700 rounded-full justify-start items-start gap-2 flex"
                  : "p-2 bg-neutral-100 rounded-full justify-start items-start gap-2 flex"
              }
            >
              <div className="w-4 h-4 relative">
                {selected ? (
                  <MdCheck className="w-full h-full fill-neutral-50" />
                ) : (
                  <MdAdd className="w-full h-full fill-neutral-500" />
                )}
              </div>
            </div>
          )}
          {type === "inside-form" && (
            <>
              <button
                className="p-2.5 bg-neutral-50 rounded-full justify-start items-start gap-2 flex cursor-pointer hover:bg-neutral-100 ease-in-out duration-300"
                onClick={handleDelete}
              >
                <div className="w-5 h-5 relative">
                  <MdOutlineDelete className="w-full h-full fill-neutral-700" />
                </div>
              </button>
              <button
                className="p-2.5 bg-neutral-50 rounded-full justify-start items-center gap-2 flex cursor-pointer hover:bg-neutral-100 ease-in-out duration-300"
                onClick={handleEdit}
              >
                <div className="w-5 h-5 relative">
                  <MdLaunch className="w-full h-full fill-neutral-700" />
                </div>
              </button>
            </>
          )}
        </ContactCardWrapper>
      )}
    </>
  );
};

export default ContactCard;
