const CouponLineIcon: React.FC = () => {
  return (
    <svg
      width="33"
      height="32"
      viewBox="0 0 33 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="&#233;&#161;&#181;&#233;&#157;&#162;-1">
        <g id="Business">
          <g id="coupon_line">
            <path
              id="&#229;&#189;&#162;&#231;&#138;&#182;"
              d="M26.0837 5.33325C28.214 5.33325 29.9552 6.99848 30.0769 9.09822L30.0837 9.33325V11.7561C30.0837 12.5646 29.6139 13.1938 29.049 13.5288L28.9061 13.6063C28.0211 14.043 27.417 14.9522 27.417 15.9999C27.417 16.9728 27.9379 17.8263 28.7208 18.2928L28.9061 18.3935C29.4842 18.6787 29.9984 19.2676 30.0741 20.0455L30.0837 20.2438V22.6666C30.0837 24.7969 28.4185 26.5381 26.3187 26.6598L26.0837 26.6666H7.41699C5.28676 26.6666 3.54544 25.0014 3.42378 22.9016L3.41699 22.6666V20.2438C3.41699 19.4353 3.88677 18.8061 4.4516 18.4711L4.59457 18.3935C5.47954 17.9569 6.08366 17.0477 6.08366 15.9999C6.08366 15.027 5.56276 14.1736 4.77979 13.707L4.59457 13.6063C4.0165 13.3211 3.50226 12.7322 3.42656 11.9544L3.41699 11.7561V9.33325C3.41699 7.20302 5.08222 5.4617 7.18196 5.34004L7.41699 5.33325H26.0837ZM26.0837 7.99992H7.41699C6.73322 7.99992 6.16965 8.51464 6.09263 9.17776L6.08366 9.33325V11.3804C7.67553 12.3011 8.75033 14.0239 8.75033 15.9999C8.75033 17.8719 7.78569 19.5167 6.33063 20.4675L6.08366 20.6195V22.6666C6.08366 23.3504 6.59838 23.9139 7.2615 23.9909L7.41699 23.9999H26.0837C26.7675 23.9999 27.331 23.4852 27.408 22.8221L27.417 22.6666V20.6195C25.8251 19.6987 24.7503 17.9759 24.7503 15.9999C24.7503 14.1279 25.715 12.4831 27.17 11.5323L27.417 11.3804V9.33325C27.417 8.64948 26.9023 8.08591 26.2392 8.00889L26.0837 7.99992ZM14.0837 11.9999C14.7675 11.9999 15.331 12.5146 15.408 13.1778L15.417 13.3333V18.6666C15.417 19.403 14.8201 19.9999 14.0837 19.9999C13.3999 19.9999 12.8363 19.4852 12.7593 18.8221L12.7503 18.6666V13.3333C12.7503 12.5969 13.3473 11.9999 14.0837 11.9999Z"
              fill="#09244B"
            />
          </g>
        </g>
      </g>
    </svg>
  );
};

export default CouponLineIcon;
