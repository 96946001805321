import { useState } from "react";
import {
  MdCheck,
  MdOutlineAdd,
  MdOutlineAutoStories,
  MdStackedBarChart,
} from "react-icons/md";
import lessonInformation from "components/main-view/lessons/utils/lessonInformation";
import { Course, Module, Program, Project } from "utils/interfaces";

interface AssignLessonCardProps {
  selectedLesson: string;
  lesson: Program | Course | Project | Module;
  numElements: number;
  selectedInit: boolean;
  selectAction: (
    lesson: Program | Course | Project | Module,
    action: "add" | "remove",
    newNumElements: number,
    setSelected: React.Dispatch<React.SetStateAction<boolean>>,
    setDynamicNumElements: React.Dispatch<React.SetStateAction<number>>
  ) => Promise<boolean>;
}

const AssignLessonCard: React.FC<AssignLessonCardProps> = ({
  selectedLesson,
  lesson,
  numElements,
  selectedInit,
  selectAction,
}) => {
  // States definition
  const [selected, setSelected] = useState(selectedInit);
  const [dynamicNumElements, setDynamicNumElements] = useState(numElements);

  /**
   * Function used to operate on click of the checkbox
   */
  const handleOnClick = async () => {
    let successful: boolean;
    // If it is currently not selected, then add to the array of selected data, otherwise, delete from the list
    if (!selected) {
      const newNumElements = dynamicNumElements + 1;
      setDynamicNumElements(newNumElements);
      successful = await selectAction(
        lesson,
        "add",
        newNumElements,
        setSelected,
        setDynamicNumElements
      );
    } else {
      const newNumElements = dynamicNumElements - 1;
      setDynamicNumElements(newNumElements);
      successful = await selectAction(
        lesson,
        "remove",
        newNumElements,
        setSelected,
        setDynamicNumElements
      );
    }

    // Don't allow to change if it's not successful
    if (!successful) return;

    // And toggle the state
    setSelected(!selected);
  };

  return (
    <div
      className={
        selected
          ? "self-stretch p-3 bg-neutral-25 hover:bg-neutral-50 ease-in-out duration-150 border border-neutral-600 rounded-xl justify-start items-center gap-3 inline-flex cursor-pointer"
          : "self-stretch p-3 bg-neutral-25 hover:bg-neutral-50 ease-in-out duration-150 border border-transparent rounded-xl justify-start items-center gap-3 inline-flex cursor-pointer"
      }
      onClick={handleOnClick}
    >
      <div className="grow shrink basis-0 flex-col justify-center items-start gap-2 inline-flex">
        <div className="self-stretch justify-start items-center gap-2 inline-flex">
          <div className="grow shrink basis-0 text-neutral-700 text-sm font-semibold font-sans leading-[17px]">
            {lesson.title}
          </div>
        </div>
        <div className="self-stretch justify-start items-center gap-3 inline-flex">
          {!(lessonInformation(selectedLesson).parent === "courses") && (
            <>
              <div className="justify-start items-center gap-1 flex">
                <div className="w-4 h-4 relative">
                  <MdStackedBarChart className="w-full h-full fill-neutral-500" />
                </div>
                <div className="text-neutral-500 text-xs font-normal font-inter leading-[14px]">
                  {lesson.level}
                </div>
              </div>
              <div className="w-px self-stretch justify-start items-start gap-2 flex">
                <div className="w-px self-stretch bg-neutral-200" />
              </div>
            </>
          )}
          <div className="grow shrink basis-0 h-4 justify-center items-center gap-1 flex">
            <div className="w-4 h-4 relative">
              <MdOutlineAutoStories className="w-full h-full fill-neutral-500" />
            </div>
            <div className="grow shrink basis-0 text-neutral-500 text-xs font-normal font-inter leading-[14px]">
              {dynamicNumElements} {lessonInformation(selectedLesson).parent}
            </div>
          </div>
        </div>
      </div>
      <div
        className={
          selected
            ? "p-2 bg-neutral-700 rounded-full justify-start items-start gap-2 flex"
            : "p-2 bg-neutral-200 rounded-full justify-start items-start gap-2 flex"
        }
      >
        <div className="w-4 h-4 relative">
          {selected ? (
            <MdCheck className="w-full h-full fill-neutral-50" />
          ) : (
            <MdOutlineAdd className="w-full h-full fill-neutral-500" />
          )}
        </div>
      </div>
    </div>
  );
};

export default AssignLessonCard;
