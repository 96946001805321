/*
  This page facilitates communication with an AI assistant.
*/
import React, { useState, useCallback, useEffect, useContext } from "react";
import watermarkImage1 from "../../assets/imgs/assistantWatermark1.png";
import watermarkImage2 from "../../assets/imgs/assistantWatermark2.png";
import { useParams } from "react-router-dom";
import { AssistantAllButtonTypes, CodeFile } from "utils/interfaces";
import ChatMessages from "./ChatMessages";
import ChatMessageInput from "./ChatMessageInput";
import { markCodeAssistant, queryAssistant } from "services/assistantRequests";
import { getChatMessages } from "services/apiRequests";
import { EditorContext } from "components/main-view/utils/Contexts";

export interface Message {
  content: string;
  role: "user" | "assistant";
  complete?: true;
}

interface AssistantParams {
  files?: CodeFile[] | undefined;
  AIAssistant: "littleC" | "jakita" | "cody";
  setActiveItem: React.Dispatch<React.SetStateAction<"editor" | "assistant">>;
  returnToIDE: () => void;
  moveToNextLesson: () => void;
  newMessage: string | undefined;
  setNewMessage: React.Dispatch<React.SetStateAction<AssistantAllButtonTypes>>;
  language: string;
}

const defaultMessages = [
  "I'm stuck, Can you help me?",
  "Please check my code",
  // "How can I run the code",
  "I don't understand",
  "I can see an error",
];

export const guardrailCatchMessage =
  "Let's stay on topic. I'm here to help with coding questions or any issues you're facing. How can I assist you today?";

const Assistant: React.FC<AssistantParams> = ({
  files,
  AIAssistant,
  setActiveItem,
  returnToIDE,
  moveToNextLesson,
  newMessage,
  setNewMessage,
  language,
}) => {
  // States definition
  const [waitingForAiResponse, setWaitingForAiResponse] = useState(false);
  const [messages, setMessages] = useState<Message[]>([]);
  const [inputMessage, setInputMessage] = useState("");
  const [showLoading, setShowLoading] = useState(false);
  const [nameInitials, setNameInitials] = useState("-");

  // Get the context element
  const { userFirstName, userLastName } = useContext(EditorContext);

  /**
   * Function used to update the initials for chat messages
   */
  useEffect(() => {
    // Check if the elements exist
    if (userFirstName && userLastName) {
      // Set the initials
      setNameInitials(userFirstName[0] + userLastName[0]);
    } else setNameInitials("-");
  }, []);

  const contentStart =
    AIAssistant === "jakita"
      ? "## Jingeri!\n\nI'm Big J and I'm a Yugumbeh girl"
      : "## Jingeri!\n\nI'm Little C and I'm a Yugumbeh boy";

  const firstMessage: Message = {
    content: `${
      AIAssistant === "cody"
        ? ""
        : contentStart +
          ". Jingeri is how we say welcome on my country. I'm here to help you become a Deadly Coder!\n\nPick an option below or enter your question to get started"
    }`,
    role: "assistant",
  };

  // Get the url parameters
  const { moduleOrProject, id } = useParams();

  const updateMessages = (messages: Message[]) => {
    setMessages([firstMessage, ...messages]);
  };

  const fetchMessages = async () => {
    // Content start
    if (
      (moduleOrProject === "modules" || moduleOrProject === "projects") &&
      id
    ) {
      const [error, existingMessages] = await getChatMessages(
        moduleOrProject,
        id
      );
      if (existingMessages) {
        console.log("em", existingMessages);
        updateMessages([...existingMessages]);
      }
    } else {
      updateMessages([]);
    }
    if (newMessage && newMessage !== "chat") {
      handleUserMessage(newMessage);
      setNewMessage("chat");
    }
  };

  useEffect(() => {
    fetchMessages();
  }, []);

  const getAiMarks = useCallback(async () => {
    const [error, messages] = await markCodeAssistant(
      "Please check my code",
      files === undefined || files.length === 0 ? "" : files[0].content,
      id || "",
      moduleOrProject || "",
      language
    );
    if (messages) updateMessages(messages);
    // if (complete) {

    //   setMessages((prev) => [...prev, {
    //     content: message || "An error has occurred...",
    //     role: "assistant",
    //     complete: true
    //   }]);
    // } else {
    //   setMessages((prev) => [...prev, {
    //     content: message || "An error has occurred...",
    //     role: "assistant"
    //   }]);
    // }
  }, [files, messages, id]);

  const getAiResponse = useCallback(
    async (message: string) => {
      const [error, newMessages] = await queryAssistant(
        message,
        files === undefined ? "" : files[0].content,
        id || "",
        moduleOrProject || "",
        language
      );
      if (newMessages) updateMessages(newMessages);
      // setMessages((prev) => [...prev, {
      //   content: text || "An error has occurred...",
      //   role: "assistant"
      // }]);
    },
    [files, messages, id]
  );

  useEffect(() => {
    if (waitingForAiResponse) {
      const timer = setTimeout(() => {
        if (waitingForAiResponse) {
          setShowLoading(true);
        }
      }, 750);
      return () => clearTimeout(timer);
    } else {
      setShowLoading(false);
    }
  }, [waitingForAiResponse]);

  // For sending messages via the conversation starters
  const handleUserMessage = async (text: string) => {
    if (waitingForAiResponse) {
      return;
    }
    setWaitingForAiResponse(true);

    setMessages((prev) => [...prev, { content: text, role: "user" }]);
    // updateMessages([...messages.slice(1), {content: text, role: "user"}]);
    setInputMessage("");
    if (text === "Please check my code") {
      await getAiMarks();
    } else {
      await getAiResponse(text);
    }
    setWaitingForAiResponse(false);
    setShowLoading(false);
  };

  return (
    <div className="max-w-[767px] h-full flex flex-col mx-auto bg-white z-10">
      {/* Watermarks */}
      {["jakita", "littleC"].includes(AIAssistant) && (
        <>
          <img
            src={watermarkImage1}
            alt="Watermark 1"
            className="absolute top-0 right-0 w-72 h-72 opacity-30 -rotate-90 -z-10"
          />
          <img
            src={watermarkImage2}
            alt="Watermark 2"
            className="absolute bottom-0 left-0 h-[300px] opacity-30 -rotate-[15deg] -z-10"
          />
        </>
      )}
      <ChatMessages
        messages={messages}
        showLoading={showLoading}
        AIAssistant={AIAssistant}
        returnToIDE={returnToIDE}
        moveToNextLesson={moveToNextLesson}
        language={language}
        nameInitials={nameInitials}
      />
      <div className="flex-none h-fit w-full pb-12">
        <div className="flex space-x-2 overflow-x-hidden py-4">
          {defaultMessages.map((text, index) => (
            <div
              key={index}
              onClick={() => handleUserMessage(text)}
              className={`group px-3 py-1 bg-neutral-50 hover:bg-neutral-100 ease-in-out duration-150 rounded-xl border border-dc-secondary-600 flex-shrink-0 whitespace-nowrap select-none ${
                waitingForAiResponse ? "cursor-default" : "cursor-pointer"
              }`}
            >
              <div className="text-dc-secondary-600 text-xs font-semibold font-['Poppins']">
                {text}
              </div>
            </div>
          ))}
        </div>
        <ChatMessageInput
          inputEnabled={waitingForAiResponse}
          handleSend={handleUserMessage}
        />
      </div>
    </div>
  );
};

export default Assistant;
