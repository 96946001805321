/**
 * Element used to define the behaviour of the contact card
 * depending on the context used
 */
interface ContactCardWrapperProps {
  children?: React.ReactNode;
  type: string;
  selected: boolean;
  justDisplay: boolean;
  handleOnClick: () => void;
}

const ContactCardWrapper: React.FC<ContactCardWrapperProps> = ({
  children,
  type,
  selected,
  justDisplay,
  handleOnClick,
}) => {
  return (
    <>
      {type === "inside-form" ? (
        <div className="self-stretch text-start p-3 bg-white border border-Subtle rounded-xl justify-start items-center gap-3 inline-flex">
          {children}
        </div>
      ) : (
        <button
          className={`self-stretch text-start p-3 bg-neutral-25 border ${
            selected && !justDisplay ? "border-neutral-700" : "border-transparent"
          } rounded-xl justify-start items-center gap-3 inline-flex ${
            justDisplay
              ? "cursor-default"
              : "cursor-pointer hover:bg-neutral-50"
          } ease-in-out duration-150`}
          onClick={handleOnClick}
        >
          {children}
        </button>
      )}
    </>
  );
};

export default ContactCardWrapper;
