import Cookies from "js-cookie";
import { BACKEND_URL } from "config/getUrl";

// Get API URL
const API_URL = BACKEND_URL;

export const presentAccessToken = async () => {
  // Get the accessToken
  const accessToken = Cookies.get("accessToken");

  // Check that the access token is not undefined
  if (!accessToken) {
    return {
      message: "It was not possible to find an access token.",
      connected: false,
    };
  }

  // Sending to the server
  const options: RequestInit = {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  };

  // Post the user to login
  const response = await fetch(`${API_URL}/validate/access-token`, options);

  // Get the response
  return response.json();
};

export const presentRefreshToken = async () => {
  // Sending to the server
  const options: RequestInit = {
    method: "GET",
    credentials: "include",
  };

  // Post the user to login
  const response = await fetch(`${API_URL}/validate/refresh-token`, options);

  // Set the cookies
  return await response.json();
};

export const logoutRequest = async () => {
  // Sending to the server
  const options: RequestInit = {
    method: "GET",
    credentials: "include",
  };

  // Post the user to login
  const response = await fetch(`${API_URL}/auth/logout`, options);

  // Get the response
  return response.json();
};

export const validateClient = async (
  attempt: number = 0
): Promise<{ connected: boolean; navigateTo: string }> => {
  // Check what attempt is being used
  if (attempt > 3) {
    return { connected: false, navigateTo: "/login" };
  }

  // First check if the client has the access token
  const accessTokenResponse = await presentAccessToken();

  // If it is connected, return everything ok
  if (accessTokenResponse.successful) {
    return { connected: true, navigateTo: "" };
  }

  // Otherwise, ask for the refresh token
  const refreshTokenResponse = await presentRefreshToken();

  // If we have the refreshToken and we were able to generate the new token,
  // without problems, then execute the function again
  if (refreshTokenResponse.successful) {
    return await validateClient(attempt + 1);
  }

  // Otherwise, navigate to login site
  return { connected: false, navigateTo: "/login" };
};

export const resetPasswordRequest = async (userInfo: {
  email: string;
  role: string;
}) => {
  // Sending to the server
  const options: RequestInit = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(userInfo),
  };

  // Post the user to login
  const response = await fetch(`${API_URL}/auth/reset-password`, options);

  return response.json();
};

export const resetPasswordCheckResetId = async (resetTokenId?: string) => {
  // Check that the reset token is not undefined
  if (!resetTokenId) {
    return {
      message: "It was not possible to find a reset token.",
      successful: false,
    };
  }

  // Sending to the server
  const options: RequestInit = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ id: resetTokenId }),
  };

  // Post the reset token
  const response = await fetch(`${API_URL}/auth/check-reset-token`, options);

  return response.json();
};

export const setupPasswordRequest = async (userInfo: {
  resetTokenId: string;
  password: string;
}) => {
  // Sending to the server
  const options: RequestInit = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(userInfo),
  };

  // Post the user to login
  const response = await fetch(`${API_URL}/auth/setup-password`, options);

  return response.json();
};

export const twoFactorCheckId = async (twoFactorId?: string) => {
  // Check that the reset token is not undefined
  if (!twoFactorId) {
    return {
      message: "It was not possible to find a two factor authentication code.",
      successful: false,
    };
  }

  // Sending to the server
  const options: RequestInit = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ id: twoFactorId }),
  };

  // Post the reset token
  const response = await fetch(`${API_URL}/auth/check-2FA-token`, options);

  return response.json();
};

export const twoFactorValidateCode = async (
  twoFactorId: string,
  inputCode: string,
  remember: boolean
) => {
  // Sending to the server
  const options: RequestInit = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    credentials: "include",
    body: JSON.stringify({
      id: twoFactorId,
      code: inputCode,
      remember: remember,
    }),
  };

  // Post the reset token
  const response = await fetch(`${API_URL}/auth/validate-2FA-code`, options);

  return response.json();
};

export const sendNewCodeRequest = async (
  twoFactorId?: string,
  email?: string
) => {
  // Check that the reset token is not undefined
  if (!twoFactorId) {
    return {
      message: "It was not possible to find a two factor authentication code.",
      successful: false,
    };
  }

  if (!email) {
    return {
      message: "It was not possible to find an email for this request.",
      successful: false,
    };
  }

  // Sending to the server
  const options: RequestInit = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    credentials: "include",
    body: JSON.stringify({ id: twoFactorId, email: email }),
  };

  // Post the reset token
  const response = await fetch(`${API_URL}/auth/send-new-code`, options);

  return response.json();
};
