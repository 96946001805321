import { useEffect, useState } from "react";
import { MdOutlineEdit } from "react-icons/md";
import { formatPhoneNumber } from "utils/utils";
import {
  ClassListType,
  ContactListType,
  ParentListType,
  StudentListType,
} from "utils/interfaces";

interface ParentCardProps {
  parent: ParentListType;
  handleCardClick: (
    card: ClassListType | ContactListType | ParentListType | any
  ) => void;
}

const ParentCard: React.FC<ParentCardProps> = ({ parent, handleCardClick }) => {
  // States definition
  const [studentNames, setStudentNames] = useState("");

  /**
   * Get the name of the student based on the value of the record
   */
  useEffect(() => {
    // First of all, check if students is defined
    if (parent.students) {
      // Get the names from the student list attached to the parent
      const names = parent.students.map(
        (student: StudentListType) => student.firstName + " " + student.lastName
      );
      // And separate these names by a newline character
      const studentNames = names.join(", ");

      setStudentNames(studentNames);
    }
  }, []);

  return (
    <div className="w-full h-[65px] px-6 py-4 bg-neutral-25 rounded-xl justify-between items-center inline-flex">
      <div className="w-[190px] h-full flex-col justify-center items-center inline-flex">
        <div className="self-stretch text-neutral-700 text-lg font-semibold font-sans leading-snug overflow-hidden break-keep whitespace-nowrap text-ellipsis">
          {`${parent.firstName} ${parent.lastName}` || "-"}
        </div>
      </div>
      <div className="w-[169px] flex-col justify-center items-center gap-1 inline-flex">
        <div className="text-center text-neutral-500 text-sm font-normal font-sans leading-[17px]">
          {studentNames || "-"}
        </div>
        <div className="text-center text-neutral-500 text-[10px] font-normal font-sans uppercase leading-3 tracking-wide">
          Parent/guardian to
        </div>
      </div>
      <div className="flex-col justify-center items-center gap-1 inline-flex">
        <div className="text-center text-neutral-500 text-sm font-normal font-sans leading-[17px]">
          {parent.phone ? formatPhoneNumber(parent.phone) : "-"}
        </div>
        <div className="text-center text-neutral-500 text-[10px] font-normal font-sans uppercase leading-3 tracking-wide">
          Phone number
        </div>
      </div>
      <div className="w-[239px] flex-col justify-center items-center gap-1 inline-flex">
        <div className="self-stretch text-center text-neutral-500 text-sm font-normal font-sans leading-[17px]">
          {parent.email || "-"}
        </div>
        <div className="text-center text-neutral-500 text-[10px] font-normal font-sans uppercase leading-3 tracking-wide">
          Email address
        </div>
      </div>
      <div className="self-stretch justify-center items-center gap-3 flex">
        <button
          className="p-3 bg-neutral-100 rounded-full justify-start items-start gap-2 flex cursor-pointer hover:bg-neutral-200 ease-in-out duration-300"
          onClick={() => handleCardClick(parent)}
        >
          <div className="w-6 h-6 relative">
            <MdOutlineEdit className="w-full h-full fill-neutral-700" />
          </div>
        </button>
      </div>
    </div>
  );
};

export default ParentCard;
