import React from "react";
import {
  MdOutlineSupervisedUserCircle,
  MdPersonAddAlt,
} from "react-icons/md";

interface CreateNewPopUpProps {
  setShowCreateNewPopUp: React.Dispatch<React.SetStateAction<boolean>>;
  onCreateClass: () => void;
  onCreateEditStudent: () => void;
}

const CreateNewPopUp: React.FC<CreateNewPopUpProps> = ({
  setShowCreateNewPopUp,
  onCreateClass,
  onCreateEditStudent,
}) => {
  return (
    <>
      <div
        className="fixed top-0 left-0 w-screen h-screen bg-transparent z-20"
        onClick={() => setShowCreateNewPopUp(false)}
      />
      <div
        className="absolute w-[154px] top-[90px] right-2 p-3 bg-white rounded-lg shadow flex-col justify-center items-center gap-2 inline-flex z-30"
      >
        <div
          className="w-[138px] p-3 bg-white rounded-lg justify-start items-center gap-2 inline-flex text-neutral-700 hover:bg-neutral-700 hover:text-white cursor-pointer"
          onClick={onCreateClass}
        >
          <div className="w-6 h-6 relative fill-neutral-700">
            <MdOutlineSupervisedUserCircle className="w-full h-full" />
          </div>
          <div className="text-base font-normal font-sans leading-7">
            Class
          </div>
        </div>
        <div
          className="w-[138px] p-3 bg-white rounded-lg justify-start items-center gap-2 inline-flex text-neutral-700 hover:bg-neutral-700 hover:text-white cursor-pointer"
          onClick={onCreateEditStudent}
        >
          <div className="w-6 h-6 relative fill-neutral-700">
            <MdPersonAddAlt className="w-full h-full" />
          </div>
          <div className="text-base font-normal font-sans leading-7">
            Student
          </div>
        </div>
      </div>
    </>
  );
};

export default CreateNewPopUp;
