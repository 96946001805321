import { useState, useRef, useEffect } from "react";
import { MdSearch } from "react-icons/md";
import ContactCard from "./ContactCard";
import { ContactListType, SchoolListType } from "utils/interfaces";
import { removeItem } from "utils/utils";
import OrDivision from "./OrDivision";
import { getCollectionRequest } from "services/apiRequests";
import { useNavigate } from "react-router-dom";
import { LoadingDots } from "utils/loadingAnimation";

interface SearchAndCardsListProps {
  contextSchool?: SchoolListType | null;
  role: "parent" | "customer admin";
  numberSearch: string;
  showSuccessPopUp: boolean;
  selectedContacts: ContactListType[];
  lastSelectedContact: ContactListType;
  isLoadingData: boolean;
  setNumberSearch: React.Dispatch<React.SetStateAction<string>>;
  setSelectedContacts: React.Dispatch<React.SetStateAction<ContactListType[]>>;
  setLastSelectedContact: React.Dispatch<React.SetStateAction<ContactListType>>;
  setShowSuccessPopUp: React.Dispatch<React.SetStateAction<boolean>>;
  setShowConfirmPopUp: React.Dispatch<React.SetStateAction<boolean>>;
  setIsLoadingData: React.Dispatch<React.SetStateAction<boolean>>;
}

export const defaultContact = {
  _id: "",
  firstName: "",
  lastName: "",
  email: "",
  phone: "",
};

const SearchAndCardsList: React.FC<SearchAndCardsListProps> = ({
  contextSchool,
  role,
  numberSearch,
  showSuccessPopUp,
  selectedContacts,
  lastSelectedContact,
  isLoadingData,
  setNumberSearch,
  setSelectedContacts,
  setLastSelectedContact,
  setShowSuccessPopUp,
  setShowConfirmPopUp,
  setIsLoadingData,
}) => {
  // States definition
  const [contactsData, setContactsData] = useState<ContactListType[]>([]);
  const [selectedContactDataIds, setSelectedContactDataIds] = useState<
    string[]
  >([]);
  const [inputNumber, setInputNumber] = useState("");

  // Define a navigation hook
  const navigate = useNavigate();

  // Input reference for focus
  const searchNumberInputRef = useRef<HTMLInputElement>(null);

  // And definition of the focus function
  const handleFocus = () => {
    if (searchNumberInputRef.current) {
      searchNumberInputRef.current.focus();
    }
  };

  /**
   * Load class data when init
   */
  const getData = async () => {
    // First, start the animation
    setIsLoadingData(true);

    // Get the parents from the database record
    const response = await getCollectionRequest(
      "/api/users",
      ["_id", "firstName", "lastName", "email", "phone"],
      { role: role, organisations: { $all: [contextSchool && contextSchool._id] } }
    );

    // Check if the response is successful
    if (!response.successful) {
      // Check if it's not successful because of a token expiration
      if (response.logout) {
        // alert(response.message);
        navigate("/");
      }

      return [];
    }

    // Get the data
    const data = response.content;

    return data;
  };

  /**
   * Definition of the Add or remove action
   * @param id of the selected id in the pop up menu
   * @param action "add" or "remove" "selectedData._id" from "id"
   */
  const selectAction = (contact: ContactListType, action: "add" | "remove") => {
    // Add to the current selected contact data ids
    if (action === "add") {
      // Add to the ids list
      setSelectedContactDataIds([
        ...new Set(selectedContactDataIds.concat([contact._id])),
      ]);

      // Define the last selectedId
      setLastSelectedContact(contact);

      // If there is at least one contact when adding, display the pop up window
      // to ask if the following one wants to be primary or additional
      // Warning: Remember that this "selectedContactDataIds" is before updating
      if (selectedContactDataIds.length > 0) {
        setShowConfirmPopUp(true);
      } else {
        // Otherwise, just display show pop up message
        setShowSuccessPopUp(true);
      }
    }
    // Or delete from it
    else if (action === "remove") {
      setSelectedContactDataIds(
        removeItem(selectedContactDataIds, contact._id)
      );

      // Force pop up message to dissapear
      setShowSuccessPopUp(false);
    }
  };

  /**
   * Definition of the dismiss action
   */
  const dismissAction = () => {
    // Remove the last selected id
    setSelectedContactDataIds(
      removeItem(selectedContactDataIds, lastSelectedContact._id)
    );
  };

  /**
   * Load class data when init
   */
  useEffect(() => {
    // Get the data
    getData().then((data: ContactListType[]) => {
      // Set the data
      setContactsData(data);

      // Define the current selected ids
      setSelectedContactDataIds(
        selectedContacts.map((contact: ContactListType) => contact._id)
      );

      // Finally, turn off the animation
      setIsLoadingData(false);
    });
  }, []);

  /**
   * Function to update the selected contacts state
   */
  useEffect(() => {
    // Define the output contacts based on the selected ids (keeping the order)
    const selectedContacts = selectedContactDataIds.map(
      (id: string) =>
        contactsData.filter(
          (classElem: ContactListType) => classElem._id === id
        )[0]
    );

    // And update
    setSelectedContacts(selectedContacts);
  }, [selectedContactDataIds]);

  /**
   * Timer for the success pop up message
   */
  useEffect(() => {
    // Starting the timer
    const timer = setTimeout(() => {
      // Once finished, close the pop up
      setShowSuccessPopUp(false);
      // And forget the last selected id
      // setLastSelectedContact(defaultContact);
    }, 5000);
    return () => clearTimeout(timer);
  }, [showSuccessPopUp]);

  /**
   * Send the current number as a search query on Enter key down
   * @param event
   */
  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      setNumberSearch(inputNumber);
    }
  };

  return (
    <>
      {!isLoadingData ? (
        <div className="w-full h-fit flex-col justify-start items-start gap-4 inline-flex">
          {selectedContacts && selectedContacts.length > 0 && (
            <>
              {selectedContacts
                .filter((contact: ContactListType) =>
                  selectedContactDataIds.includes(contact._id)
                )
                .map((contact: ContactListType, index: number) => (
                  <ContactCard
                    key={index}
                    contact={contact}
                    selectedContacts={selectedContacts}
                    selectAction={selectAction}
                    setSelectedContacts={setSelectedContacts}
                    type="search-pop-up"
                  />
                ))}
              <OrDivision />
            </>
          )}
          <div className="self-stretch h-fit flex-col justify-start items-start gap-1.5 flex">
            <div className="rounded justify-start items-start gap-2 inline-flex">
              <div className="text-neutral-500 text-xs font-normal font-sans uppercase leading-[14px] tracking-wide">
                Search existing parent/guardian*
              </div>
            </div>
            <div className="w-full h-[51px] bg-neutral-50 rounded-lg justify-start items-center gap-2 inline-flex relative">
              <input
                ref={searchNumberInputRef}
                type="text"
                value={inputNumber}
                onChange={(event) => setInputNumber(event.target.value)}
                onKeyDown={handleKeyPress}
                className="w-full h-full p-3 pr-9 bg-transparent focus:outline-none text-neutral-700 placeholder:text-neutral-300 text-base font-normal font-sans leading-[19px]"
                placeholder="Search by mobile number"
              />
              <div
                className="absolute right-0.5 mx-1.5 w-8 h-8 flex justify-center items-center cursor-pointer hover:bg-neutral-200 ease-in-out duration-300 rounded-full"
                onClick={() => setNumberSearch(inputNumber)}
              >
                <MdSearch className="w-5 h-5 fill-neutral-600" />
              </div>
            </div>
          </div>
          {numberSearch &&
            contactsData
              .filter((contact: ContactListType) =>
                contact.phone.toLowerCase().includes(numberSearch.toLowerCase())
              )
              .filter(
                (contact: ContactListType) =>
                  !selectedContactDataIds.includes(contact._id)
              )
              .map((contact: ContactListType, index: number) => (
                <ContactCard
                  key={index}
                  contact={contact}
                  selectedContacts={selectedContacts}
                  selectAction={selectAction}
                  setSelectedContacts={setSelectedContacts}
                  type="search-pop-up"
                />
              ))}
        </div>
      ) : (
        <div className="w-full h-[50vh] flex justify-center items-center">
          <LoadingDots bgColor="bg-neutral-300" />
        </div>
      )}
    </>
  );
};

export default SearchAndCardsList;
