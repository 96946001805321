import React, {
  forwardRef,
  useContext,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import ProgressMap from "./ProgressMap";
import {
  Course,
  LessonListType,
  Module,
  Program,
  Project,
  QuizListType,
} from "utils/interfaces";
import BasicDetails from "./BasicDetails";
import AdditionalDetails from "./AdditionalDetails";
import AdditionalAttachments from "./AdditionalAttachments";
import { formatUpdateLesson } from "utils/formatUpdate";
import lessonInformation from "../utils/lessonInformation";
import { updateRecordRequest, createRecordRequest } from "services/apiRequests";
import { createUpdateQuizzesInCourses } from "services/quizRequests";
import { LessonsContext } from "components/main-view/utils/Contexts";

interface SettingsProps {
  ref: any;
  selectedLesson: string;
  lessonListToShow: string;
  selectedData: Project | Program | Course | Module | null;
  linkedId: string;
  linkedLesson: string;
  afterCreateLessons: (successfulLesson: string) => void;
  headerRef: React.MutableRefObject<any>;
}

const Settings: React.FC<SettingsProps> = forwardRef(
  (
    {
      selectedLesson,
      lessonListToShow,
      selectedData,
      linkedId,
      linkedLesson,
      afterCreateLessons,
      headerRef,
    },
    ref
  ) => {
    // States definition
    const [stage, setStage] = useState(1);
    const [additionalAttachs, setAdditionalAttachs] = useState(false);
    const [activateSaveButton, setActivateSaveButton] = useState(false);

    // Page control states
    const [name, setName] = useState("");
    const [level, setLevel] = useState("");
    const [description, setDescription] = useState("");
    const [expPoints, setExpPoints] = useState(0);
    const [videoUrl, setVideoUrl] = useState("");
    const [codingLanguage, setCodingLanguage] = useState("");
    const [quizzes, setQuizzes] = useState<QuizListType[]>([]);

    // Uploaded files
    const [headerImage, setHeaderImage] = useState<string | null>(null);
    const [badgeImage, setBadgeImage] = useState<string | null>(null);
    const [planDocument, setPlanDocument] = useState<string | null>(null);
    const [initialCode, setInitialCode] = useState<string | null>(null);
    const [finalCode, setFinalCode] = useState<string | null>(null);
    
    // Plan States definition
    const [planName, setPlanName] = useState("");
    const [planSize, setPlanSize] = useState("0");
    const [initialCodeName, setInitialCodeName] = useState("");
    const [initialCodeSize, setInitialCodeSize] = useState("0");
    const [finalCodeName, setFinalCodeName] = useState("");
    const [finalCodeSize, setFinalCodeSize] = useState("0");

    // Get the context
    const { setVersion } = useContext(LessonsContext);

    // Auxiliar state for change of selected quizzes
    const [baseSelectedQuizzes, setBaseSelectedQuizzes] = useState<{
      [key: string]: boolean;
    }>({});

    /**
     * Functions to externalise using refs
     */
    useImperativeHandle(ref, () => ({
      // Function to present all the gathered data in the form
      provideData() {
        return provideData();
      },
      handleSaveLesson() {
        handleSave();
      },
    }));

    /**
     * If the settings tab is opened from an edit action (selectedData),
     * then prefill the fields
     */
    useEffect(() => {
      if (selectedData) {
        // Check if the selected data contains a document to get the size
        const planSize = selectedData.planDocument
          ? Buffer.from(selectedData.planDocument, "base64").length
          : 0;

        // Set the values
        setName(selectedData.title || "");
        setHeaderImage(selectedData.headerImage || null);
        setBadgeImage(selectedData.badgeImage || null);
        setLevel(selectedData.level || "");
        setDescription(selectedData.description || "");
        setExpPoints(selectedData.experiencePoints || 0);
        setPlanDocument(selectedData.planDocument || null);
        setPlanName("planName" in selectedData ? selectedData.planName : "");
        setPlanSize(`${(planSize / 1024 / 1024).toFixed(1)}`);
        setInitialCodeName("initialCodeName" in selectedData ? selectedData.initialCodeName : "");
        setFinalCodeName("finalCodeName" in selectedData ? selectedData.finalCodeName : "");
        setVideoUrl("videoUrl" in selectedData ? selectedData.videoUrl : "");
        setCodingLanguage(
          "language" in selectedData ? selectedData.language : ""
        );
        setQuizzes(
          selectedData && "quizzes" in selectedData && selectedData.quizzes
            ? [...selectedData.quizzes]
            : []
        );
        // And set the original status for the selected quizzes
        setBaseSelectedQuizzes(
          selectedData && "quizzes" in selectedData && selectedData.quizzes
            ? selectedData.quizzes.reduce(
                (obj: { [key: string]: boolean }, quiz: QuizListType) => {
                  obj[quiz._id] = quiz.active;
                  return obj;
                },
                {}
              )
            : {}
        );
      }
    }, [selectedData]);

    /**
     * Check what is the selected lessons to know if it's a 2 or 3 stage settings
     */
    useEffect(() => {
      if (selectedLesson === "programs" || selectedLesson === "courses") {
        setAdditionalAttachs(false);
      } else if (
        selectedLesson === "projects" ||
        selectedLesson === "modules"
      ) {
        setAdditionalAttachs(true);
      }
    }, [selectedLesson]);

    /**
     * Send signal to header save button when all the form is completed
     */
    useEffect(() => {
      // Define the condition for two or three steps logic
      const toSave =
        selectedLesson === "modules"
          ? !!(name && level && description && videoUrl && codingLanguage)
          : ["courses", "projects"].includes(selectedLesson)
          ? !!(name && level && description)
          : !!(name && description);

      // Set show save button state
      setActivateSaveButton(toSave);

      // Set if to save or not
      toSave
        ? headerRef.current
            .lessons()
            .setSaveButton({ show: true, disabled: false })
        : headerRef.current
            .lessons()
            .setSaveButton({ show: true, disabled: true });
    }, [
      name,
      level,
      description,
      videoUrl,
      codingLanguage,
      headerRef,
      selectedLesson,
    ]);

    /**
     * Function to handle save pressing
     */
    const handleSave = async () => {
      // Everytime this function is called, update the version
      setVersion((currentVersion: number) => currentVersion + 1);

      // Get the data
      const data = provideData();

      // Define the current lesson to be checked
      // (This is because the lessonListToShow indicates the list of the "content")
      // tab. So the "current" one should be the parent of this. We are using this
      // instead of "activeLesson" because this is the general situation when we are
      // clicking edit consecutively in the cards.
      // The border case is the module one, when it's not showing nothing.
      const lessonToAdd = lessonListToShow
        ? lessonInformation(lessonListToShow).parent
        : "modules";

      // If there is a selectedData, then we are editing
      let response = { successful: false, message: "", _id: "" };
      if (selectedData) {
        response = await updateRecordRequest(
          { _id: selectedData._id, toUpdate: data },
          `/api/${lessonToAdd}`
        );
      } else {
        // Send the request to be created
        response = await createRecordRequest(
          { linkedLesson, linkedId, ...data },
          `/api/${lessonToAdd}`
        );
      }

      // If the response is not successful, then show the pop up message
      if (!response.successful) {
        alert(response.message);
        return;
      }

      // In the case of the course create/edit, it is necessary to create
      // the new quizzes as well
      if (lessonToAdd === "courses") {
        createUpdateQuizzesInCourses(
          response._id as string,
          quizzes,
          baseSelectedQuizzes
        );
      }

      // Execute the defined actions after the creation
      afterCreateLessons(lessonInformation(lessonListToShow).parent);
    };

    const provideData = () => {
      // Return all the gathered data depending on the active lesson to be created
      let data: LessonListType = {
        title: "",
        headerImage: "",
        badgeImage: "",
        description: "",
      };

      if (selectedLesson === "programs") {
        data = {
          title: name,
          headerImage: headerImage,
          badgeImage: badgeImage,
          description: description,
        };
      } else if (selectedLesson === "courses") {
        data = {
          title: name,
          headerImage: headerImage,
          badgeImage: badgeImage,
          level: level,
          description: description,
        };
      } else if (selectedLesson === "projects") {
        data = {
          title: name,
          headerImage: headerImage,
          badgeImage: badgeImage,
          level: level,
          experiencePoints: expPoints,
          description: description,
          planDocument: planDocument,
          planName: planName,
          videoUrl: videoUrl,
          language: codingLanguage,
          initialCode: initialCode,
          finalCode: finalCode,
          initialCodeName: initialCodeName,
          finalCodeName: finalCodeName,
        };
      } else if (selectedLesson === "modules") {
        data = {
          title: name,
          badgeImage: badgeImage,
          level: level,
          experiencePoints: expPoints,
          description: description,
          planDocument: planDocument,
          planName: planName,
          videoUrl: videoUrl,
          language: codingLanguage,
          initialCode: initialCode,
          finalCode: finalCode,
          initialCodeName: initialCodeName,
          finalCodeName: finalCodeName,
        };
      }

      // Formatting the data to be sent as a request
      const formattedData = formatUpdateLesson(selectedData, data);

      return formattedData;
    };

    return (
      <div className="w-full h-full flex justify-center p-4 overflow-x-auto custom-scroll">
        <div className="flex justify-between items-start w-full max-w-lg">
          <div className="flex flex-col items-start">
            {stage === 1 ? (
              <BasicDetails
                selectedLesson={selectedLesson}
                name={name}
                level={level}
                expPoints={expPoints}
                headerImage={headerImage}
                selectedData={selectedData}
                setName={setName}
                setLevel={setLevel}
                setExpPoints={setExpPoints}
                setStage={setStage}
                setHeaderImage={setHeaderImage}
              />
            ) : stage === 2 ? (
              <AdditionalDetails
                selectedLesson={selectedLesson}
                description={description}
                additionalAttachs={additionalAttachs}
                quizzes={quizzes}
                badgeImage={badgeImage}
                selectedData={selectedData}
                setDescription={setDescription}
                setQuizzes={setQuizzes}
                setBadgeImage={setBadgeImage}
                setStage={setStage}
                onSave={handleSave}
              />
            ) : (
              <AdditionalAttachments
                selectedLesson={selectedLesson}
                selectedData={selectedData}
                activateSaveButton={activateSaveButton}
                videoUrl={videoUrl}
                codingLanguage={codingLanguage}
                planDocument={planDocument}
                planName={planName}
                planSize={planSize}
                setVideoUrl={setVideoUrl}
                setCodingLanguage={setCodingLanguage}
                setPlanDocument={setPlanDocument}
                setPlanName={setPlanName}
                setPlanSize={setPlanSize}
                setInitialCodeName={setInitialCodeName}
                setInitialCodeSize={setInitialCodeSize}
                setFinalCodeName={setFinalCodeName}
                setFinalCodeSize={setFinalCodeSize}
                initialCodeName={initialCodeName}
                initialCodeSize={initialCodeSize}
                finalCodeName={finalCodeName}
                finalCodeSize={finalCodeSize}
                setStage={setStage}
                initialCode={initialCode}
                finalCode={finalCode}
                setInitialCode={setInitialCode}
                setFinalCode={setFinalCode}
                onSave={handleSave}
              />
            )}
          </div>
          <ProgressMap stage={stage} additionalAttachs={additionalAttachs} />
        </div>
      </div>
    );
  }
);

export default Settings;
