import { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import BasicDetails from "./BasicDetails";
import {
  ClassListType,
  ContactListType,
  LocationListType,
  SchoolListType,
} from "utils/interfaces";
import ProgressMap from "components/main-view/utils/ProgressMap";
import LocationDetails from "./LocationDetails";
import ClassDetails from "./ClassDetails";
import { createRecordRequest, updateRecordRequest } from "services/apiRequests";
import { formatDataSchool } from "utils/formatUpdate";
import { createUpdateClassesInOrganisation } from "services/classesRequests";

interface AddNewSchoolProps {
  ref: any;
  userRole: string;
  selectedData?: SchoolListType | null;
  getData: () => Promise<void>;
  setShowSavedSuccessfully: React.Dispatch<React.SetStateAction<boolean>>;
  setVersion: React.Dispatch<React.SetStateAction<number>>;
  headerRef: React.MutableRefObject<any>;
  from?: "main" | "dashboard";
}

const AddNewSchool: React.FC<AddNewSchoolProps> = forwardRef(
  (
    {
      userRole,
      selectedData,
      getData,
      headerRef,
      setShowSavedSuccessfully,
      setVersion,
      from = "dashboard",
    },
    ref
  ) => {
    // States definition
    const [stage, setStage] = useState(1);
    const [schoolName, setSchoolName] = useState("");
    const [logo, setLogo] = useState<string | null>("");
    const [assignedLicenses, setAssignedLicenses] = useState(0);
    const [selectedContacts, setSelectedContacts] = useState<ContactListType[]>(
      []
    );
    const [locations, setLocations] = useState<LocationListType[]>([]);
    const [classes, setClasses] = useState<ClassListType[]>([]);
    // Pop up states
    const [activateSaveButton, setActivateSaveButton] = useState(false);

    // Auxiliar state for change of selected classes
    const [baseSelectedClasses, setBaseSelectedClasses] = useState<{
      [key: string]: boolean;
    }>({});

    /**
     * Functions to externalise using refs
     */
    useImperativeHandle(ref, () => ({
      // Function to present all the gathered data in the form
      provideData() {
        return provideData();
      },
      handleSaveSchool() {
        handleSave();
      },
    }));

    /**
     * If there is selected data, then prefill the defined fields
     */
    useEffect(() => {
      if (selectedData) {
        setSchoolName(selectedData.name || "");
        setLogo(selectedData.logo || "");
        setAssignedLicenses(selectedData.licenses || 0);
        setSelectedContacts(selectedData.contacts || "");
        setLocations(selectedData.locations || []);
        setClasses(
          selectedData && "classes" in selectedData && selectedData.classes
            ? [...selectedData.classes]
            : []
        );
        // And set the original status for the selected classes
        setBaseSelectedClasses(
          selectedData && "classes" in selectedData && selectedData.classes
            ? selectedData.classes.reduce(
                (obj: { [key: string]: boolean }, classItem: ClassListType) => {
                  obj[classItem._id] = classItem.active;
                  return obj;
                },
                {}
              )
            : {}
        );
      }
    }, []);

    /**
     * Function to activate saveButtons
     */
    useEffect(() => {
      // Define the current handler
      let currentHandler;
      if (from === "main") {
        currentHandler = headerRef.current.customers();
      } else if (from === "dashboard") {
        currentHandler = headerRef.current.dashboard();
      }

      // Condition definition
      currentHandler.setSaveButton({
        show: true,
        disabled: !(schoolName && selectedContacts.length > 0),
      });
      setActivateSaveButton(!!(schoolName && selectedContacts.length > 0));
    }, [schoolName, selectedContacts]);

    /**
     * Function to handle save pressing
     */
    const handleSave = async () => {
      // First of all, check if the save button should be working
      if (!activateSaveButton) return;

      // Everytime this function is called, update the version
      setVersion((currentVersion) => currentVersion + 1);

      // Get the data from the settings form
      const data = provideData();

      // Request for create/edit new school
      let response = { successful: false, message: "", _id: "" };
      if (selectedData) {
        response = await updateRecordRequest(
          { _id: selectedData._id, toUpdate: data },
          "/api/organisations"
        );
      } else {
        // Create a new school
        response = await createRecordRequest(data, "/api/organisations");
      }

      // Check if the response is successful
      if (!response.successful) {
        // alert(response.message);
        return;
      }

      // Routine to create or update classes inside an organisation
      createUpdateClassesInOrganisation(
        response._id as string,
        classes,
        baseSelectedClasses
      );

      // Define the current handler
      let currentHandler;
      if (from === "main") {
        currentHandler = headerRef.current.customers();
      } else if (from === "dashboard") {
        currentHandler = headerRef.current.dashboard();
      }

      // Once pressed, come back to the root view
      currentHandler.onDiscard();
      setShowSavedSuccessfully(true);

      // And refresh the data
      getData();
    };

    /**
     * Function that returns all the form data
     * @returns data
     */
    const provideData = () => {
      // Get just the ids from the contacts
      const contactIds = selectedContacts.map(
        (contact: ContactListType) => contact._id
      );
      // // Get just the ids from the classes
      // const classIds = classes.map((item: ClassListType) => item._id);

      // Return all the gathered data in the form
      const dataToProvide = {
        name: schoolName,
        logo: logo,
        licenses: assignedLicenses,
        contacts: contactIds,
        locations: locations,
      };

      // Formatting the data to be sent as a request
      const formattedData = formatDataSchool(selectedData, dataToProvide);

      return formattedData;
    };

    return (
      <div className="w-full h-full flex justify-center overflow-y-auto custom-scroll">
        {stage === 1 ? (
          <BasicDetails
            userRole={userRole}
            schoolName={schoolName}
            logo={logo}
            assignedLicenses={assignedLicenses}
            selectedContacts={selectedContacts}
            setSchoolName={setSchoolName}
            setLogo={setLogo}
            setAssignedLicenses={setAssignedLicenses}
            setSelectedContacts={setSelectedContacts}
            setStage={setStage}
          />
        ) : stage === 2 ? (
          <LocationDetails
            locations={locations}
            setLocations={setLocations}
            setStage={setStage}
          />
        ) : (
          <ClassDetails
            selectedData={selectedData}
            activateSaveButton={activateSaveButton}
            classes={classes}
            handleSave={handleSave}
            setStage={setStage}
            setClasses={setClasses}
            headerRef={headerRef}
          />
        )}
        <ProgressMap
          stage={stage}
          firstText="Basic details"
          secondText="Location details"
          thirdText="Class details"
        />
      </div>
    );
  }
);

export default AddNewSchool;
