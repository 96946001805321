import {
  getIconText,
  getProgressBarColor,
  getStatusIcon,
} from "components/main-view/utils/progressUtils";
import React, { useEffect, useState } from "react";
import { MdOutlineEdit } from "react-icons/md";
import { getCollectionRequest } from "services/apiRequests";
import {
  ClassListType,
  ContactListType,
  StudentListType,
} from "utils/interfaces";

interface StudentCardProps {
  student: StudentListType;
  handleCardClick: (card: ClassListType | ContactListType | any) => void;
}

const StudentCard: React.FC<StudentCardProps> = ({
  student,
  handleCardClick,
}) => {
  // States definition
  const [badges, setBadges] = useState(0);
  const [courseCompleted, setCourseCompleted] = useState(0);
  const [avgQuizScore, setAvgQuizScore] = useState(0);

  /**
   * Function used to get the result for this student
   * @returns 
   */
  const getResults = async () => {
    // Get the completed results
    const response = await getCollectionRequest(
      `/api/results/student?id=${student._id}`
    );

    // Check if the request was successful
    if (!response.successful) {
      return;
    }

    // Get the data
    const data = response.content;

    // Set the states
    setBadges(data.badges);
    setCourseCompleted(Math.min(data.courseCompleted, 100));
    setAvgQuizScore(Math.min(data.avgQuizScore, 100));
  };

  useEffect(() => {
    getResults();
  }, []);

  return (
    <div className="w-full h-fit px-6 py-4 bg-neutral-25 rounded-xl justify-between items-center inline-flex">
      <div className="w-[190px] self-stretch flex-col justify-center items-center inline-flex">
        <div className="self-stretch text-neutral-700 text-lg font-semibold font-sans leading-snug overflow-hidden break-keep whitespace-nowrap text-ellipsis">
          {`${student.firstName} ${student.lastName}` || "-"}
        </div>
      </div>
      <div className="flex-col justify-center items-center gap-1 inline-flex">
        <div className="text-center text-neutral-500 text-lg font-semibold font-sans leading-snug">
          {/* {student.progressRate || 0}% */}
          {courseCompleted}%
        </div>
        <div className="text-center text-neutral-500 text-xs font-normal font-inter uppercase leading-[14px] tracking-wide">
          Course Completed
        </div>
        <div className="self-stretch h-1.5 bg-neutral-200 rounded-sm flex-col justify-between items-start flex">
          <div
            className={`h-1.5 ${getProgressBarColor(courseCompleted)} rounded-tl-sm rounded-bl-sm`}
            // style={{ width: `${student.progressRate}%` }}
            style={{ width: `${courseCompleted}%` }}
          />
        </div>
      </div>
      <div className="flex-col justify-center items-center gap-1 inline-flex">
        <div className="text-center text-neutral-500 text-lg font-semibold font-sans leading-snug">
          {/* {student.averageScore || 0}% */}
          {avgQuizScore}%
        </div>
        <div className="text-center text-neutral-500 text-xs font-normal font-inter uppercase leading-[14px] tracking-wide">
          Avg. Quiz Score
        </div>
        <div className="self-stretch h-1.5 bg-neutral-200 rounded-sm flex-col justify-between items-start flex">
          <div
            className={`h-1.5 ${getProgressBarColor(avgQuizScore)} rounded-tl-sm rounded-bl-sm`}
            // style={{ width: `${student.averageScore}%` }}
            style={{ width: `${avgQuizScore}%` }}
          />
        </div>
      </div>
      <div className="flex-col justify-center items-center gap-1 inline-flex">
        <div className="text-center text-neutral-500 text-lg font-semibold font-sans leading-snug">
          {/* {student.badges ? student.badges.length : 0} */}
          {badges}
        </div>
        <div className="text-center text-neutral-500 text-xs font-normal font-inter uppercase leading-[14px] tracking-wide">
          Bagdes Earned
        </div>
        <div className="self-stretch h-1.5 opacity-0 bg-neutral-200 rounded-sm flex-col justify-between items-start flex">
          <div className="w-24 h-1.5 bg-success-500 rounded-tl-sm rounded-bl-sm" />
        </div>
      </div>
      <div className="w-[45px] self-stretch justify-center items-center flex">
        <div className="flex-col justify-start items-center gap-1 inline-flex">
          <div className="w-8 h-8 relative">
            {getStatusIcon(student.progressRate || 0)}
          </div>
          <div className="justify-center items-center gap-2 inline-flex">
            <div className="text-neutral-500 text-xs font-semibold font-sans leading-[14px]">
              {getIconText(student.progressRate || 0)}
            </div>
          </div>
        </div>
      </div>
      <div className="self-stretch justify-center items-center gap-3 flex">
        <button
          className="p-3 bg-neutral-100 rounded-full justify-start items-start gap-2 flex cursor-pointer hover:bg-neutral-200 ease-in-out duration-300"
          onClick={() => handleCardClick(student)}
        >
          <div className="w-6 h-6 relative">
            <MdOutlineEdit className="w-full h-full fill-neutral-700" />
          </div>
        </button>
      </div>
    </div>
  );
};

export default StudentCard;
