import { ClassListType, SchoolListType } from "utils/interfaces";
import { useEffect, useState } from "react";
import AssignClassCard from "./AssignClassCard";
import SuccessPopUpMessage from "components/main-view/utils/pop-up-panels/SuccessPopUpMessage";
import EmptyViewPopUp from "components/main-view/utils/pop-up-panels/EmptyViewPopUp";

interface ClassesCardsListProps {
  classes: ClassListType[];
  setClasses: React.Dispatch<React.SetStateAction<ClassListType[]>>;
}

const ClassesCardsList: React.FC<ClassesCardsListProps> = ({
  classes,
  setClasses,
}) => {
  // States definition
  const [showSuccessPopUp, setShowSuccessPopUp] = useState(false);
  const [currentClassName, setCurrentClassName] = useState("");

  /**
   * Definition of the Add or remove action
   * @param id of the selected id in the pop up menu
   * @param action "add" or "remove" "selectedData._id" from "id"
   */
  const selectAction = async (index: number, action: "add" | "remove") => {
    // Define a new version of the quizData
    const updatedClasses = [...classes];

    // Add to the current selected contact data ids
    if (action === "add") {
      // Change the "activate" state of this class
      updatedClasses[index].active = true;
      setClasses(updatedClasses);

      // And close the panel
      setShowSuccessPopUp(true);
      setCurrentClassName(updatedClasses[index].name);
    }
    // Or delete from it
    else if (action === "remove") {
      // Change the "activate" state of this class
      updatedClasses[index].active = false;
      setClasses(updatedClasses);

      // And force the close of the success message
      setShowSuccessPopUp(false);
    }
  };

  /**
   * Timer for the warning pop up message
   */
  useEffect(() => {
    if (showSuccessPopUp) {
      // Starting the timer
      const timer = setTimeout(() => {
        // Once finished, close the pop up
        if (setShowSuccessPopUp && setCurrentClassName) {
          setShowSuccessPopUp(false);
          setCurrentClassName("");
        }
      }, 5000);

      return () => clearTimeout(timer);
    }
  }, [showSuccessPopUp]);

  return (
    <div className="w-full max-h-[70vh] grow shrink justify-start items-start gap-[5px] inline-flex overflow-y-auto pop-up-scrollbar">
      <div className="w-full h-fit self-stretch flex-col justify-start items-start gap-4 inline-flex">
        {classes.length > 0 ? (
          classes.map((item: ClassListType, index: number) => (
            <AssignClassCard
              key={index}
              index={index}
              classItem={item}
              selectAction={selectAction}
            />
          ))
        ) : (
          <EmptyViewPopUp />
        )}
      </div>
      <SuccessPopUpMessage
        type="class"
        role="master admin"
        name={currentClassName}
        showSuccessPopUp={showSuccessPopUp}
        setShowSuccessPopUp={setShowSuccessPopUp}
        dismissAction={() => {}}
      />
    </div>
  );
};

export default ClassesCardsList;
