interface JourneyPanelProps {
  selectedIndex: number;
  scrollSnaps: number[];
  onDotButtonClick: (index: number) => void;
}

const DotsNav: React.FC<JourneyPanelProps> = ({
  selectedIndex,
  scrollSnaps,
  onDotButtonClick,
}) => {
  return (
    <div className="w-full h-fit flex justify-center items-center z-20">
      <div className="flex flex-wrap flex-center items-center gap-2">
        {scrollSnaps.map((_, index: number) => (
          <div
            key={index}
            className="cursor-pointer"
            onClick={() => onDotButtonClick(index)}
          >
            {index === selectedIndex ? (
              <div className="w-8 h-2 bg-neutral-500 rounded-xl" />
            ) : (
              <div className="w-2 h-2 bg-neutral-200 rounded-xl" />
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default DotsNav;
