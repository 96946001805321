import { Transition } from "@headlessui/react";
import { Fragment, useRef, useState } from "react";
import OptionsPanel from "components/main-view/utils/pop-up-panels/options-pop-up/OptionsPanel";
import {
  ClassListType,
  ContactListType,
  ParentListType,
  SchoolListType,
  StudentListType,
  TeacherListType,
} from "utils/interfaces";
import AddParentPanel from "./add-parent-pop-up/AddParentPanel";
import AssignClassPanel from "components/main-view/utils/pop-up-panels/assign-class-pop-up/AssignClassPanel";
import AddTeacherPanel from "components/main-view/utils/pop-up-panels/add-teacher-pop-up/AddTeacherPanel";
import { genderList } from "config/optionValues";
import SavedSuccessfulPanel from "components/main-view/utils/pop-up-panels/saved-successful-pop-up/SavedSuccessfulPanel";

interface PopUpPanelProps {
  type:
    | "gender"
    | "assign-class"
    | "saved-successful"
    | "add-parents"
    | "create-class"
    | "edit-class"
    | "edit-teacher"
    | "edit-parent"
    | "view-class-teachers";
  showPopUp: boolean;
  contextSchool?: SchoolListType | null;
  selectedClasses?: ClassListType[];
  selectedContacts?: ContactListType[];
  selectedTeachers?: ContactListType[];
  setShowPopUp: React.Dispatch<React.SetStateAction<boolean>>;
  setOption?: React.Dispatch<React.SetStateAction<string>>;
  setSelectedData?: React.Dispatch<
    React.SetStateAction<
      ClassListType | TeacherListType | ParentListType | StudentListType | null
    >
  >;
  setSelectedClasses?: React.Dispatch<React.SetStateAction<ClassListType[]>>;
  setSelectedContacts?: React.Dispatch<React.SetStateAction<ContactListType[]>>;
  getData?: () => Promise<any>;
  selectedData?:
    | ClassListType
    | StudentListType
    | ContactListType
    | ParentListType
    | TeacherListType
    | null;
  onCreateAnother?: () => void;
  headerRef?: React.MutableRefObject<any>;
}

const PopUpPanel: React.FC<PopUpPanelProps> = ({
  type,
  showPopUp,
  contextSchool,
  selectedClasses,
  selectedContacts,
  selectedTeachers,
  setShowPopUp,
  setOption,
  setSelectedData,
  setSelectedClasses,
  setSelectedContacts,
  getData,
  selectedData,
  onCreateAnother,
  headerRef,
}) => {
  // States defininition
  const [showWarningMessage, setShowWarningMessage] = useState(false);

  // Reference for the assign class panel
  const assignClassPanelRef = useRef();

  /**
   * Handle clicking in the black screen
   */
  const handleClickBlackScreen = () => {
    // Prevent closing for these types
    if (type === "create-class") {
      // Get the current class panel pop up state
      let currentHandler: any;
      if (assignClassPanelRef.current)
        currentHandler = assignClassPanelRef.current;
      const popUpState: "create-new" | "add-teacher" | "main" =
        currentHandler.getPopUpState();
      const showSaveSuccessPopUp: boolean =
        currentHandler.getShowSaveSuccessPopUp();

      // If the pop up state is one of these ones, then don't allow to
      // close when clicking the black screen
      if (
        ["create-new", "add-teacher"].includes(popUpState) &&
        !showSaveSuccessPopUp
      ) {
        // Activate the warning message
        setShowWarningMessage(true);
        // And finish the function
        return;
      }
    }

    // Close the pop up
    setShowPopUp(false);
    // Force close the warning message
    setShowWarningMessage(false);
  };

  return (
    <>
      <Transition
        show={showPopUp}
        as={Fragment}
        enter="transition-opacity ease-out "
        enterFrom="opacity-0 translate-y-1/2"
        enterTo="opacity-100 translate-y-0"
        leave="transition-opacity duration-150"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <div
          className="fixed inset-0 w-full h-full bg-neutral-500 bg-opacity-40 backdrop-blur-[2px] z-20 flex flex-col flex-grow"
          onClick={handleClickBlackScreen}
        />
      </Transition>
      {/* Gender pop-up */}
      <Transition
        show={showPopUp && type === "gender"}
        as={"div"} // Done this way because if we use fragments we won't be able to pass setStates methods.
        className="fixed w-[462px] h-fit max-h-[90%] left-0 right-0 ml-auto mr-auto bottom-4 px-6 pt-6 pb-8 bg-white rounded-3xl flex-col justify-start items-start gap-6 inline-flex z-30"
        enter="transition-opacity ease-out "
        enterFrom="opacity-0 translate-y-1/2"
        enterTo="opacity-100 translate-y-0"
        leave="transition-opacity duration-150"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <OptionsPanel
          options={genderList}
          setShowPopUp={setShowPopUp}
          setOptions={setOption ? setOption : () => {}}
        />
      </Transition>
      {/* Assign to class pop-up */}
      <Transition
        show={showPopUp && type === "assign-class"}
        as={"div"}
        className="fixed w-[462px] h-fit max-h-[90%] left-0 right-0 ml-auto mr-auto px-6 pt-6 pb-8 bottom-5 bg-white rounded-3xl flex-col justify-start items-start gap-6 inline-flex z-30"
        enter="transition-opacity ease-out "
        enterFrom="opacity-0 translate-y-1/2"
        enterTo="opacity-100 translate-y-0"
        leave="transition-opacity duration-150"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <AssignClassPanel
          contextSchool={contextSchool}
          selectedClasses={selectedClasses || []}
          setShowPopUp={setShowPopUp}
          setSelectedClasses={
            setSelectedClasses ? setSelectedClasses : () => {}
          }
        />
      </Transition>
      {/* Saved successful message pop-up */}
      <Transition
        show={showPopUp && type === "saved-successful"}
        as={"div"}
        className="fixed w-[440px] h-[590px] max-h-[90%] left-0 right-0 ml-auto mr-auto p-6 bottom-5 bg-white rounded-3xl flex-col justify-start items-center gap-10 inline-flex z-30"
        enter="transition-opacity ease-out "
        enterFrom="opacity-0 translate-y-1/2"
        enterTo="opacity-100 translate-y-0"
        leave="transition-opacity duration-150"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <SavedSuccessfulPanel
          title="Student Added Successfully"
          paragraphs={[
            "The new student has been successfully added to the system.",
            "If you wish to add another student, click the 'Add Another' button below.",
          ]}
          createText="Add another"
          onCreateAnother={onCreateAnother ? onCreateAnother : () => {}}
          onClose={() => setShowPopUp(false)}
        />
      </Transition>
      {/* Add parent pop-up */}
      <Transition
        show={showPopUp && type === "add-parents"}
        as={"div"}
        className="fixed w-[455px] h-[793px] max-h-[90%] left-0 right-0 ml-auto mr-auto p-6 bottom-5 pb-8 bg-white rounded-3xl flex-col justify-start items-start gap-6 inline-flex z-30"
        enter="transition-opacity ease-out "
        enterFrom="opacity-0 translate-y-1/2"
        enterTo="opacity-100 translate-y-0"
        leave="transition-opacity duration-150"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <AddParentPanel
          contextSchool={contextSchool}
          setShowPopUp={setShowPopUp}
          selectedContacts={selectedContacts || []}
          setSelectedContacts={
            setSelectedContacts ? setSelectedContacts : () => {}
          }
        />
      </Transition>
      {/* Create a class pop-up */}
      <Transition
        show={showPopUp && type === "create-class"}
        as={"div"}
        className="fixed w-[462px] h-fit max-h-[90%] left-0 right-0 ml-auto mr-auto px-6 pt-6 pb-8 bottom-5 bg-white rounded-3xl flex-col justify-start items-start gap-6 inline-flex z-30"
        enter="transition-opacity ease-out "
        enterFrom="opacity-0 translate-y-1/2"
        enterTo="opacity-100 translate-y-0"
        leave="transition-opacity duration-150"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <AssignClassPanel
          ref={assignClassPanelRef}
          contextSchool={contextSchool}
          selectedClasses={selectedClasses || []}
          justCreateNew={true}
          setShowPopUp={setShowPopUp}
          setSelectedClasses={
            setSelectedClasses ? setSelectedClasses : () => {}
          }
          getData={getData}
          showWarningMessage={showWarningMessage}
          setShowWarningMessage={setShowWarningMessage}
        />
      </Transition>
      {/* Edit class pop-up */}
      <Transition
        show={showPopUp && type === "edit-class"}
        as={"div"}
        className="fixed w-[462px] h-fit max-h-[90%] left-0 right-0 ml-auto mr-auto px-6 pt-6 pb-8 bottom-5 bg-white rounded-3xl flex-col justify-start items-start gap-6 inline-flex z-30"
        enter="transition-opacity ease-out "
        enterFrom="opacity-0 translate-y-1/2"
        enterTo="opacity-100 translate-y-0"
        leave="transition-opacity duration-150"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
        afterLeave={() => {
          if (setSelectedData) setSelectedData(null);
        }}
      >
        <AssignClassPanel
          selectedClasses={[]}
          contextSchool={contextSchool}
          selectedData={selectedData as ClassListType}
          justCreateNew={true}
          setShowPopUp={setShowPopUp}
          setSelectedClasses={() => {}}
          getData={getData}
        />
      </Transition>
      {/* Edit teacher pop-up */}
      <Transition
        show={showPopUp && type === "edit-teacher"}
        as={"div"}
        className="fixed w-[462px] h-fit max-h-[90%] left-0 right-0 ml-auto mr-auto px-6 pt-6 pb-8 bottom-5 bg-white rounded-3xl flex-col justify-start items-start gap-6 inline-flex z-30"
        enter="transition-opacity ease-out "
        enterFrom="opacity-0 translate-y-1/2"
        enterTo="opacity-100 translate-y-0"
        leave="transition-opacity duration-150"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
        afterLeave={() => {
          if (setSelectedData) setSelectedData(null);
        }}
      >
        <AddTeacherPanel
          selectedTeachers={[]}
          selectedData={selectedData as TeacherListType}
          getData={getData}
          setShowPopUp={setShowPopUp}
          setSelectedTeachers={() => {}}
        />
      </Transition>
      {/* Edit parent pop-up */}
      <Transition
        show={showPopUp && type === "edit-parent"}
        as={"div"}
        className="fixed w-[455px] h-[793px] max-h-[90%] left-0 right-0 ml-auto mr-auto p-6 bottom-5 pb-8 bg-white rounded-3xl flex-col justify-start items-start gap-6 inline-flex z-30"
        enter="transition-opacity ease-out "
        enterFrom="opacity-0 translate-y-1/2"
        enterTo="opacity-100 translate-y-0"
        leave="transition-opacity duration-150"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
        afterLeave={() => {
          if (setSelectedData) setSelectedData(null);
        }}
      >
        <AddParentPanel
          setShowPopUp={setShowPopUp}
          selectedData={selectedData as ParentListType}
          getData={getData}
          selectedContacts={selectedContacts || []}
          setSelectedContacts={
            setSelectedContacts ? setSelectedContacts : () => {}
          }
        />
      </Transition>
      {/* View all teachers pop-up */}
      <Transition
        show={showPopUp && type === "view-class-teachers"}
        as={"div"}
        className="fixed w-[462px] h-fit max-h-[90%] left-0 right-0 ml-auto mr-auto px-6 pt-6 pb-8 bottom-5 bg-white rounded-3xl flex-col justify-start items-start gap-6 inline-flex z-30"
        enter="transition-opacity ease-out"
        enterFrom="opacity-0 translate-y-1/2"
        enterTo="opacity-100 translate-y-0"
        leave="transition-opacity duration-150"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <AddTeacherPanel
          selectedTeachers={selectedTeachers || []}
          justDisplay={true}
          setShowPopUp={setShowPopUp}
          setSelectedTeachers={() => {}}
        />
      </Transition>
    </>
  );
};

export default PopUpPanel;
