/**
 * Function used to get which elements were added and removed
 * @param referenceArray 
 * @param updatedArray 
 * @returns 
 */
const addRemoveArrayQuery = (
  referenceArray: any[],
  updatedArray: any[]
) => {
  // Transform the arrays into a set
  const referenceSet = new Set(referenceArray);
  const updatedSet = new Set(updatedArray);

  // Apply a filter to get the added ids and the removed ids
  const added = [...updatedSet].filter(id => !referenceSet.has(id));
  const removed = [...referenceSet].filter(id => !updatedSet.has(id));

  return {added, removed};
};

export default addRemoveArrayQuery;
