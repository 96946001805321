import ClassDetailsFormCardBase from "components/main-view/utils/components/ClassDetailsFormCardBase";
import { ClassListType } from "utils/interfaces";

interface ClassDetailsFormCardProps {
  thisClass: ClassListType;
  setSelectedClasses: React.Dispatch<React.SetStateAction<ClassListType[]>>;
}

const ClassDetailsFormCard: React.FC<ClassDetailsFormCardProps> = ({
  thisClass,
  setSelectedClasses,
}) => {
  // Handle delete button
  const handleDelete = () => {
    // Delete the id from the list of selectedClassDataIds
    setSelectedClasses((currentList) =>
      currentList.filter((classElem: ClassListType) => classElem._id !== thisClass._id)
    );
  };

  return (
    <ClassDetailsFormCardBase thisClass={thisClass} onClickDelete={handleDelete} />
  );
};

export default ClassDetailsFormCard;
