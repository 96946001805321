import { forwardRef, useContext, useEffect, useImperativeHandle, useState } from "react";
import { capitaliseFirstChar } from "utils/utils";
import MyProgress from "./MyProgress";
import MyKnowledgePoints from "./MyKnowledgePoints";
import MyBadges from "./MyBadges";
import MyLearningJourney from "./MyLearningJourney";
import Leaderboard from "../utils/Leaderboard";
import { MainContext } from "components/main-view/utils/Contexts";

interface StudentDashboardProps {
  ref?: any;
  userFirstName: string;
  userLastName: string;
  headerRef: React.MutableRefObject<any>;
}

const StudentDashboard: React.FC<StudentDashboardProps> = forwardRef(
  ({ userFirstName, userLastName, headerRef }, ref) => {
    // Get context variables
    const { userClassId } : {userClassId: string} = useContext(MainContext);

    /**
     * Set the header image name
     */
    useEffect(() => {
      // Update the header name when dashboard is selected
      let currentHandler: any;
      if (headerRef.current) currentHandler = headerRef.current;

      // And set the title and subtitle
      currentHandler.setHeaderTitle("My Dashbord");
      currentHandler.setHeaderDescription(
        `Welcome, ${capitaliseFirstChar(userFirstName)}`
      );
    }, []);

    /**
     * Define the list of functions that can be used from external components
     * using a reference
     */
    useImperativeHandle(ref, () => ({
      // Outer function to change the tab
      getFirstName() {
        return userFirstName;
      },
      getLastName() {
        return userLastName;
      },
    }));
    
    return (
      <div className="w-full h-full justify-start items-start gap-5 inline-flex overflow-y-auto custom-scroll">
        {/* Main components */}
        <div className="grow shrink basis-0 flex-col justify-start items-start gap-5 inline-flex">
          <div className="self-stretch justify-start items-end gap-[21px] inline-flex">
            <MyLearningJourney/>
            <MyProgress userFirstName={userFirstName} />
          </div>
          <Leaderboard
            userClassId={userClassId}
            showStatus={false}
          />
        </div>
        {/* Sidebar */}
        <div className="w-[344px] h-full flex-col justify-start items-start gap-5 inline-flex">
          <div className="w-full h-full flex-grow flex flex-col gap-5">
            {/* Search bar */}
            {/* <SearchContentBar
              searchQuery={searchQuery}
              setSearchQuery={setSearchQuery}
            /> */}
            {/* Knowledge points */}
            <MyKnowledgePoints />
            {/* Badge points */}
            <MyBadges />
            {/* <div className="flex justify-start items-start gap-2 text-neutral-400 text-[10px] font-normal font-sans leading-3 tracking-tight">
              <div>
                *Knowledge points are based on many factors.{" "}
                <span className="text-gray-500 underline cursor-pointer">
                  Learn more
                </span>
                ...
              </div>
            </div> */}
          </div>
        </div>
      </div>
    );
  }
);

export default StudentDashboard;
