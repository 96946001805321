import { motion } from "framer-motion";

const loadingContainerVariants = {
  start: {
    transition: {
      staggerChildren: 0.2,
    },
  },
  end: {
    transition: {
      staggerChildren: 0.2,
    },
  },
};

const loadingCircleVariants = {
  start: {
    y: "-50%",
  },
  end: {
    y: "50%",
  },
};

// Defining a dot
const dot = (bgColor: string) => (
  <motion.div
    className={`block w-2 h-2 rounded-full ${bgColor}`}
    variants={loadingCircleVariants}
    transition={{
      duration: 0.5,
      repeat: Infinity,
      ease: "easeInOut",
      repeatType: "reverse",
    }}
  />
);

export const LoadingDots = ({bgColor = "bg-white"}) => {
  return (
    <motion.div
      className="flex justify-center items-center w-fit h-6 gap-1"
      variants={loadingContainerVariants}
      initial="start"
      animate="end"
    >
      {dot(bgColor)}
      {dot(bgColor)}
      {dot(bgColor)}
    </motion.div>
  );
};
