import { GoArrowLeft } from "react-icons/go";
import { MdAddCircleOutline } from "react-icons/md";
import { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import {
  Course,
  Module,
  Program,
  Project,
  QuizListType,
} from "utils/interfaces";
import QuizCheckbox from "./QuizCheckbox";
import CreateNewQuiz from "./CreateNewQuiz";
import EmptyViewPopUp from "components/main-view/utils/pop-up-panels/EmptyViewPopUp";
import WarningPopUpMessage from "components/main-view/utils/pop-up-panels/WarningPopUpMessage";

interface QuizPanelProps {
  ref?: any;
  selectedLessonData?: Program | Course | Project | Module | null;
  selectedQuizToEdit?: QuizListType | null;
  quizzes: QuizListType[];
  showWarningMessage?: boolean;
  setShowPopUp: React.Dispatch<React.SetStateAction<boolean>>;
  setQuizzes: React.Dispatch<React.SetStateAction<QuizListType[]>>;
  setShowWarningMessage?: React.Dispatch<React.SetStateAction<boolean>>;
}

const QuizPanel: React.FC<QuizPanelProps> = forwardRef(
  (
    {
      selectedLessonData,
      selectedQuizToEdit,
      quizzes,
      showWarningMessage = false,
      setShowPopUp,
      setQuizzes,
      setShowWarningMessage,
    },
    ref
  ) => {
    // States definition
    const [quizData, setQuizData] = useState<QuizListType[]>(quizzes);
    const [popUpState, setPopUpState] = useState<
      "main" | "create-new" | "add-question" | "edit-question"
    >(selectedQuizToEdit ? "create-new" : "main");
    const [activateConfirmChanges, setActivateConfirmChanges] = useState(false);

    /**
     * Define the list of functions that can be used from external components
     * using a reference
     */
    useImperativeHandle(ref, () => ({
      getPopUpState() {
        return popUpState;
      },
    }));

    /**
     * Definition of the function when pressed back button
     */
    const handlePressBack = () => {
      // If create new was pressed, then come back to the regular option
      if (["add-question", "edit-question"].includes(popUpState)) {
        setPopUpState("create-new");
      } else if (popUpState === "create-new") {
        // If we are just editing, then close pop up from teacher
        if (selectedQuizToEdit) setShowPopUp(false);
        // Otherwise, just come back
        else setPopUpState("main");
      }
      // Otherwise, close the pop up
      else setShowPopUp(false);
    };

    /**
     * Add the new selected data to the current one
     */
    const handleConfirmChange = () => {
      // And set the new quizzes to state
      setQuizzes(quizData);

      // And close the pop-up
      setShowPopUp(false);
    };

    /**
     * Definition of the Add or remove action
     * @param id of the selected id in the pop up menu
     * @param action "add" or "remove" "selectedData._id" from "id"
     */
    const selectAction = (index: number, action: "add" | "remove") => {
      // Define a new version of the quizData
      const updatedQuizzes = [...quizData];

      if (action === "add") {
        // Change the "activate" state of this quiz
        updatedQuizzes[index].active = true;
      } else {
        // Change the "activate" state of this quiz
        updatedQuizzes[index].active = false;
      }

      // And updating
      setQuizData(updatedQuizzes);
    };

    /**
     * Definition of the function executed after creating a quiz
     * in the database
     */
    const afterCreateEditQuiz = () => {
      // If we are editing, come back to the regular view (depending on the justCreateNew option)
      if (selectedQuizToEdit) {
        // And close the pop-up
        setShowPopUp(false);
      } else {
        // And come back to the main view
        setPopUpState("main");
      }
    };

    /**
     * Timer for the warning pop up message
     */
    useEffect(() => {
      if (showWarningMessage) {
        // Starting the timer
        const timer = setTimeout(() => {
          // Once finished, close the pop up
          if (setShowWarningMessage) setShowWarningMessage(false);
        }, 5000);
        return () => clearTimeout(timer);
      }
    }, [showWarningMessage]);

    /**
     * Update data when quizzes is changed
     */
    useEffect(() => {
      setQuizData(quizzes);
    }, [quizzes]);

    return (
      <div className="relative w-full h-fit max-h-[80vh] flex-col justify-start items-start gap-6 inline-flex">
        <div className="self-stretch justify-between items-center inline-flex">
          <div className="justify-start items-center gap-3 flex">
            <div
              className="p-1.5 bg-neutral-50 rounded-full backdrop-blur-[32px] justify-center items-center gap-2 flex cursor-pointer hover:bg-neutral-100 ease-in-out duration-150"
              onClick={handlePressBack}
            >
              <div className="w-5 h-5 justify-center items-center flex">
                <div className="w-5 h-5 relative">
                  <GoArrowLeft className="w-full h-full fill-dc-secondary-600" />
                </div>
              </div>
            </div>
            <div className="text-neutral-600 text-lg font-normal font-['Poppins'] leading-snug">
              {popUpState === "main"
                ? "Add/Remove Quizzes"
                : popUpState === "create-new"
                ? selectedQuizToEdit
                  ? "Edit Quiz"
                  : "Create new Quiz"
                : popUpState === "add-question"
                ? "Add Question"
                : "Edit Question"}
            </div>
          </div>
          <div className="p-2 opacity-0 bg-neutral-50 rounded-full justify-start items-start gap-2 flex">
            <div className="w-4 h-4 p-[3.61px] justify-center items-center flex" />
          </div>
          {/* Hide create new button when creating parent */}
          {popUpState === "main" && (
            <div
              className="group rounded justify-center items-center gap-2 flex cursor-pointer"
              onClick={() => setPopUpState("create-new")}
            >
              <div className="w-6 h-6 relative">
                <MdAddCircleOutline className="w-full h-full fill-dc-secondary-600 group-hover:fill-dc-secondary-700"/>
              </div>
              <div className="text-dc-secondary-600 group-hover:text-dc-secondary-700 text-lg font-semibold font-['Poppins'] leading-snug">
                Create new
              </div>
            </div>
          )}
        </div>
        {popUpState === "main" && (
          <>
            <div className="self-stretch justify-start items-start gap-[5px] inline-flex overflow-y-auto pop-up-scrollbar">
              <div className="w-full flex-col justify-start items-start gap-4 inline-flex">
                {quizData.length > 0 ? (
                  quizData.map((quiz, index) => (
                    <QuizCheckbox
                      key={index}
                      index={index}
                      quiz={quiz}
                      selectAction={selectAction}
                      setActivateConfirmChanges={setActivateConfirmChanges}
                    />
                  ))
                ) : (
                  <EmptyViewPopUp />
                )}
              </div>
            </div>
            {quizData.length > 0 && (
              <>
                {activateConfirmChanges ? (
                  <div
                    className="self-stretch px-5 py-3 bg-dc-secondary-600 hover:bg-dc-secondary-700 ease-in-out duration-150 rounded justify-center items-center gap-2 inline-flex cursor-pointer"
                    onClick={handleConfirmChange}
                  >
                    <div className="text-white text-lg font-semibold font-['Poppins'] leading-snug select-none">
                      Confirm Changes
                    </div>
                  </div>
                ) : (
                  <div className="self-stretch px-5 py-3 bg-Subtle rounded justify-center items-center gap-2 inline-flex cursor-not-allowed">
                    <div className="text-neutral-400 text-lg font-semibold font-['Poppins'] leading-snug select-none">
                      Confirm Changes
                    </div>
                  </div>
                )}
              </>
            )}
          </>
        )}
        {popUpState !== "main" && (
          <CreateNewQuiz
            quizzes={quizzes}
            selectedLessonData={selectedLessonData}
            selectedQuizToEdit={selectedQuizToEdit}
            popUpState={popUpState}
            setPopUpState={setPopUpState}
            setQuizzes={setQuizzes}
            afterCreateEditFunction={afterCreateEditQuiz}
          />
        )}
        {setShowWarningMessage && (
          <WarningPopUpMessage
            showWarningMessage={showWarningMessage}
            setShowWarningMessage={setShowWarningMessage}
          />
        )}
      </div>
    );
  }
);

export default QuizPanel;
