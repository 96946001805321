import {
  ClassListType,
  ContactListType,
  ParentListType,
  SchoolListType,
  StudentListType,
  TeacherListType,
} from "utils/interfaces";
import {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import ClassesListCards from "../../classes/cards/ClassesListCards";
import ClassDashboard from "./ClassDashboard";
import { AnimatePresence, motion } from "framer-motion";
import PopUpPanel from "../../classes/pop-up-panels/PopUpPanel";
import AddNewStudent from "../../classes/add-new-student-view/AddNewStudent";
import { getCollectionRequest } from "services/apiRequests";
import EmptyView from "components/main-view/classes/utils/EmptyView";
import { LoadingDots } from "utils/loadingAnimation";

interface CustomerAdminDashboard {
  ref?: any;
  userRole: string;
  userFirstName: string;
  userLastName: string;
  userSchool: SchoolListType | null;
  headerRef: React.MutableRefObject<any>;
  subHeaderRef: React.MutableRefObject<any>;
}

const CustomerAdminDashboard: React.FC<CustomerAdminDashboard> = forwardRef(
  (
    {
      userRole,
      userFirstName,
      userLastName,
      userSchool,
      headerRef,
      subHeaderRef,
    },
    ref
  ) => {
    // States definition
    const [classes, setClasses] = useState<ClassListType[]>([]);
    const [searchQuery, setSearchQuery] = useState("");
    const [selectedData, setSelectedData] = useState<
      ClassListType | StudentListType | TeacherListType | ParentListType | null
    >(null);
    const [createNewStudent, setCreateNewStudent] = useState(false);
    const [showCreateNewClass, setShowCreateNewClass] = useState(false);
    const [showSavedSuccessfully, setShowSavedSuccessfully] = useState(false);
    // State for view pop up
    const [selectedTeachers, setSelectedTeachers] = useState<ContactListType[]>(
      []
    );
    const [showTeachersList, setShowTeachersList] = useState(false);
    const [isLoadingData, setIsLoadingData] = useState(true);

    /**
     * Root definition
     */
    useEffect(() => {
      getData();
    }, [userSchool]);

    // States definition
    const addStudentRef = useRef(null);

    /**
     * Define the list of functions that can be used from external components
     * using a reference
     */
    useImperativeHandle(ref, () => ({
      setSelectedData(data: ClassListType) {
        setSelectedData(data);
        if (data.name !== null)
          subHeaderRef.current.setSubHeaderTitle(data.name);
      },
      onDiscard() {
        setSelectedData(null);
      },
      onBackClick() {
        backButtonFunction();
      },
      setShowCreateNewClass(bool: boolean) {
        setShowCreateNewClass(bool);
      },
      setCreateNewStudent(bool: boolean) {
        setCreateNewStudent(bool);
      },
      handleSaveNewStudent() {
        let currentHandler: any;
        if (addStudentRef.current) currentHandler = addStudentRef.current;
        currentHandler.handleSaveNewStudent();
      },
      setSearchQuery(searchQuery: string) {
        setSearchQuery(searchQuery);
      },
      getSelectedData() {
        return selectedData;
      },
      getCreateNewStudent() {
        return createNewStudent;
      }
    }));

    /**
     * Every time is rendered
     */
    useEffect(() => {
      // Display the bar
      subHeaderRef.current.setShow(true);

      // Define the title and description for customer admin and class admin
      if (["customer admin", "class admin"].includes(userRole)) {
        headerRef.current.setHeaderTitle("Dashboard");
        headerRef.current.setHeaderDescription(
          `Welcome, ${userFirstName} ${userLastName}`
        );
      }

      // Set the subheader text
      subHeaderRef.current.setSubHeaderTitle("Overview");
      // Hide the subheader back button
      subHeaderRef.current.setShowBackButton(false);
    }, []);

    /**
     * Function to execute on back click
     */
    const backButtonFunction = () => {
      // And set any selectedData to null
      setSelectedData(null);

      // Set the subheader text
      subHeaderRef.current.setSubHeaderTitle("Overview");
      // Hide the subheader back button
      subHeaderRef.current.setShowBackButton(false);
      // Hide the generate report button
      headerRef.current.dashboard().setShowGenerateReportsButton(false);
    };

    /**
     * Show the create new button based on the selectedData state
     */
    useEffect(() => {
      // Keep it hidden when selectedData is not null or undefined
      headerRef.current.dashboard().setShowCreateNewButton(!selectedData);

      // If we have selected data
      if (selectedData) {
        // Change the sub header title
        subHeaderRef.current.setSubHeaderTitle(
          (selectedData as ClassListType).name
        );
      }
    }, [selectedData]);

    const handleClassClick = (card: ClassListType) => {
      setSelectedData(card);
      subHeaderRef.current.setShowBackButton(true);
      headerRef.current.dashboard().setShowCreateNewButton(false);
      headerRef.current.dashboard().setShowGenerateReportsButton(true);
    };

    /**
     * Function to get the data depending on the class
     * @returns data
     */
    const getData = async () => {
      // Set loading animation
      setIsLoadingData(true);

      const response = await getCollectionRequest(
        "/api/classes",
        ["_id", "name", "teachers"],
        { organisations: { $all: [userSchool && userSchool._id] }, active: true }
      );

      // Check if the response is successful
      if (!response.successful) {
        // alert(response.message);
        return;
      }

      // Get the data
      const data = response.content;

      // Assign the data to the corresponding element
      setClasses(data);

      // Stop loading animation
      setIsLoadingData(false);
    };

    /**
     * Function executed after create or update a student
     */
    const afterCreateStudent = () => {
      // Force unselect data
      setSelectedData(null);

      // Show saved successfully pop up
      setShowSavedSuccessfully(true);

      // And refresh the data
      getData();
    };

    /**
     * Function executed when clicking "add another" after creating a student 
     */
    const handleCreateAnotherStudent = () => {
      // Create another student
      headerRef.current.dashboard().pressCreateNewStudent();

      // And close this pop up
      setShowSavedSuccessfully(false);
    }

    return (
      <>
        <div className={`w-full h-full ${createNewStudent ? "" : "relative overflow-hidden"}`}>
          {!createNewStudent && (
            <>
              {classes && classes.length > 0 ? (
                <AnimatePresence initial={false}>
                  {selectedData === null && (
                    <motion.div
                      key={`${selectedData === null}`}
                      initial={{ x: "calc(-100% - 1.5rem)" }}
                      animate={{ x: 0 }}
                      exit={{ x: "-100%" }}
                      transition={{ ease: "easeInOut", duration: 0.5 }}
                      className="absolute w-full h-full"
                    >
                      <ClassesListCards
                        classes={classes}
                        searchQuery={searchQuery}
                        canEdit={false}
                        setSelectedTeachers={setSelectedTeachers}
                        setShowTeachersList={setShowTeachersList}
                        onCardClick={handleClassClick}
                      />
                    </motion.div>
                  )}
                </AnimatePresence>
              ) : !isLoadingData ? (
                <EmptyView
                  activeClass="classes"
                  headerRef={headerRef}
                  fromDashboard={true}
                />
              ) : (
                <div className="w-full h-full flex justify-center items-center">
                  <LoadingDots bgColor="bg-neutral-300" />
                </div>
              )}
              <AnimatePresence>
                {selectedData !== null && (
                  <motion.div
                    key={`${selectedData === null}`}
                    initial={{ x: "100%" }}
                    animate={{ x: 0 }}
                    exit={{ x: "100%" }}
                    transition={{ ease: "easeInOut", duration: 0.5 }}
                    className="absolute w-full h-full"
                  >
                    <ClassDashboard classData={selectedData as ClassListType} />
                  </motion.div>
                )}
              </AnimatePresence>
            </>
          )}
          {createNewStudent && (
            <AddNewStudent
              ref={addStudentRef}
              userSchool={userSchool}
              selectedData={selectedData as StudentListType}
              afterCreateFunction={afterCreateStudent}
              headerRef={headerRef}
              from="dashboard"
            />
          )}
        </div>
        {/* Definition of the possible pop up panels in this view */}
        <PopUpPanel
          type="create-class"
          contextSchool={userSchool}
          showPopUp={showCreateNewClass}
          getData={getData}
          setShowPopUp={setShowCreateNewClass}
          headerRef={headerRef}
        />
        {/* Saved successful message after  */}
        <PopUpPanel
          type="saved-successful"
          showPopUp={showSavedSuccessfully}
          setShowPopUp={setShowSavedSuccessfully}
          onCreateAnother={handleCreateAnotherStudent}
        />
        {/* When pressing view all on a card */}
        <PopUpPanel
          type="view-class-teachers"
          selectedTeachers={selectedTeachers}
          showPopUp={showTeachersList}
          setShowPopUp={setShowTeachersList}
        />
      </>
    );
  }
);

export default CustomerAdminDashboard;
