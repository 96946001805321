import React, { ReactElement, ReactNode, useState } from "react";
import { MdArrowDropDown } from "react-icons/md";
import { AnimatePresence, motion } from "framer-motion";
import { OverridableComponent } from "@mui/material/OverridableComponent";
import { SvgIconTypeMap } from "@mui/material";

interface ChildProps {
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

interface PopupContainerProps {
  icon: OverridableComponent<SvgIconTypeMap<{}, "svg">>;
  title: string | undefined;
  children: ReactNode;
}

const PopupContainer: React.FC<PopupContainerProps> = ({
  icon: Icon,
  title,
  children,
}) => {
  const [open, setOpen] = useState(false);

  // Helper to pass setOpen as a prop to children
  const childrenWithProps = React.Children.map(children, (child) => {
    if (React.isValidElement(child)) {
      return React.cloneElement(child as ReactElement<ChildProps>, { setOpen });
    }
    return child;
  });

  return (
    <div className="flex-row h-fit w-fit">
      <div
        className={`h-[94px] p-3 rounded-full justify-center items-center gap-1 inline-flex ${
          !open && "cursor-pointer"
        }`}
        onClick={() => setOpen(true)}
      >
        {open && (
          <div
            className="fixed top-0 left-0 w-screen h-screen bg-transparent z-20"
            onClick={(e) => {
              console.log("closing!!!");
              setOpen(false);
              e.stopPropagation();
            }}
          />
        )}
        <div className="flex-col justify-center items-center gap-2 inline-flex">
          <div className="p-3 bg-Canvas hover:bg-neutral-100 ease-in-out duration-150 rounded-full backdrop-blur-[32px] justify-center items-center gap-2 inline-flex">
            <div className="w-6 h-6 justify-center items-center flex">
              <div className="w-6 h-6 relative">
                <Icon className="w-full h-full text-dc-secondary-600" />
              </div>
            </div>
          </div>
          {title && (
            <div className="justify-start items-start gap-2 inline-flex">
              <div className="text-center text-dc-secondary-600 text-xs font-semibold font-['Poppins'] uppercase leading-[14px] tracking-wide select-none">
                {title}
              </div>
            </div>
          )}
        </div>
        <div className="flex-col justify-center items-center gap-2 inline-flex">
          <motion.div
            className="w-4 h-4 relative"
            initial={{ rotate: 0 }}
            animate={{ rotate: open ? -180 : 0 }}
          >
            <MdArrowDropDown className="w-full h-full text-dc-secondary-600" />
          </motion.div>
          <div className="opacity-0 justify-start items-start gap-2 inline-flex">
            <div className="text-center text-dc-secondary-600 text-xs font-semibold font-['Poppins'] uppercase leading-[14px] tracking-wide">
              F
            </div>
          </div>
        </div>
      </div>
      <AnimatePresence>
        {open && (
          <div className="relative w-full h-0 mr-0">{childrenWithProps}</div>
        )}
      </AnimatePresence>
    </div>
  );
};

export default PopupContainer;
