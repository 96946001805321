import _ from "lodash";

/**
 * Function used to check if two arrays are the same
 * @param arr1 
 * @param arr2 
 * @returns 
 */
export const arraysAreEqual = <T>(arr1: Array<T>, arr2: Array<T>) => {
  // Check if the lengths are the same
  if (arr1.length !== arr2.length) {
    return false;
  }

  // Check each element
  for (let i = 0; i < arr1.length; i++) {
    if (arr1[i] !== arr2[i]) {
      return false;
    }
  }

  return true;
}

/**
 * Function used to compare a reference object with a new version of the 
 * same object, that returns just the keys that have changed in comparison
 * with the reference.
 * @param referenceObject 
 * @param objectToCompare 
 * @returns 
 */
const getModifiedKeys = (
  objectToCompare: {[key: string]: any},
  referenceObject: {[key: string]: any},
) => {
  // Define the output object which will contain the keys
  // that are different between the reference and the modified one
  const differences: { [key: string]: any } = {};

  // For each parameter
  for (const key in objectToCompare) {
    // Check if they are not the same
    if (!_.isEqual(objectToCompare[key], referenceObject[key])) {
      // Add to the "difference" object
      differences[key] = objectToCompare[key];
    }
  }

  return differences;
};

export default getModifiedKeys;
