import Cookies from "js-cookie";
import { BACKEND_URL } from "config/getUrl";
import { validateClient } from "./authRequests";

const API_URL = BACKEND_URL;

export const updateResults = async (
  lessonType: string,
  lessonId: string,
  newStatus: "not_started" | "ongoing" | "finished"
) => {
  const resultsData = {
    lessonType,
    lessonId,
    newStatus,
  };

  // Validate the tokens
  const validateResponse = await validateClient();

  // Check if the user is still connected
  if (!validateResponse.connected) {
    return {
      message: "It was not possible to find an access or refresh token.",
      successful: false,
      logout: true,
    };
  }

  const accessToken = Cookies.get("accessToken");

  // Sending to the server
  const options = {
    method: "PUT",
    headers: {
      Authorization: `Bearer ${accessToken}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(resultsData),
  };

  // put the data
  try {
    const response = await fetch(`${API_URL}/api/results`, options);
    if (response.status === 200) {
      // Get the response
      const data = await response.json();
      return data.content;
    }
  } catch (error: any) {
    console.log(error);
  }
};

export const checkQuizAnswer = async (
  quizId: string,
  questionNumber: string,
  selectedAnswer: string
) => {
  // Validate the tokens
  const validateResponse = await validateClient();

  // Check if the user is still connected
  if (!validateResponse.connected) {
    return {
      message: "It was not possible to find an access or refresh token.",
      successful: false,
      logout: true,
    };
  }

  const accessToken = Cookies.get("accessToken");

  // Sending to the server
  const options = {
    method: "POST",
    headers: {
      "Authorization": `Bearer ${accessToken}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ quizId, questionNumber, selectedAnswer }),
  };

  // put the data
  try {
    const response = await fetch(
      `${API_URL}/api/quizzes/check_answer`,
      options
    );
    if (response.status === 200) {
      // Get the response
      const data = await response.json();
      return data;
    }
  } catch (error: any) {
    console.log(error);
  }
};

export const checkResultComplete = async (type: string, itemId: string) => {
  // Validate the tokens
  const validateResponse = await validateClient();

  // Check if the user is still connected
  if (!validateResponse.connected) {
    return {
      message: "It was not possible to find an access or refresh token.",
      successful: false,
      logout: true,
    };
  }
  // Get the access token
  const accessToken = Cookies.get("accessToken");

  // Sending to the server
  const options = {
    method: "POST",
    headers: {
      "Authorization": `Bearer ${accessToken}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ type, itemId }),
  };

  // put the data
  try {
    const response = await fetch(`${API_URL}/api/results/completed`, options);
    if (response.status === 200) {
      // Get the response
      const data = await response.json();
      return data.complete;
    }
  } catch (error: any) {
    console.log(error);
  }
};
