import { MdAddCircleOutline } from "react-icons/md";
import { GoArrowLeft } from "react-icons/go";
import { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import { ClassListType, SchoolListType } from "utils/interfaces";
import ClassesCardsList from "./ClassesCardsList";
import WarningPopUpMessage from "components/main-view/utils/pop-up-panels/WarningPopUpMessage";
import CreateNewClass from "./CreateNewClass";
import SavedSuccessfulPanel from "components/main-view/utils/pop-up-panels/saved-successful-pop-up/SavedSuccessfulPanel";

interface AssignClassPanelProps {
  ref?: any;
  selectedClassToEdit?: ClassListType;
  contextSchool?: SchoolListType | null;
  classes: ClassListType[];
  showWarningMessage?: boolean;
  showActiveClassesOnly?: boolean;
  setShowPopUp: React.Dispatch<React.SetStateAction<boolean>>;
  setClasses: React.Dispatch<React.SetStateAction<ClassListType[]>>;
  setShowWarningMessage?: React.Dispatch<React.SetStateAction<boolean>>;
}

/**
 * Element that represent the pop up panel for adding/creating a class
 * @param selectedClassToEdit Object that contains a selected class. It's used to load the information
 *                            when editing a class.
 * @param classes List of selected classes (based on external decisions. i.e: selected to be assigned
 *                for lessons, for organisations, for students, etc.).
 * @param showWarningMessage Indicates if showing the warning message when click out the pop up window.
 *                           By default is `false`.
 * @param setShowPopUp Function used to define if the pop should be showed or closed.
 * @param setClasses Function used to set the selected classes.
 * @param setShowWarningMessage Function used to define the warning message when click out the
 *                              pop up window.
 * @returns
 */
const AssignClassPanel: React.FC<AssignClassPanelProps> = forwardRef(
  (
    {
      selectedClassToEdit,
      contextSchool,
      classes,
      showWarningMessage = false,
      setShowPopUp,
      setClasses,
      setShowWarningMessage
    },
    ref
  ) => {
    // States definition
    const [popUpState, setPopUpState] = useState<
      "main" | "create-new" | "add-teacher" | "set-password"
    >("main");
    const [showSaveSuccessPopUp, setShowSaveSuccessPopUp] = useState(false);

    /**
     * Define the list of functions that can be used from external components
     * using a reference
     */
    useImperativeHandle(ref, () => ({
      getPopUpState() {
        return popUpState;
      },
      getShowSaveSuccessPopUp() {
        return showSaveSuccessPopUp;
      },
      setPopUpState(
        state: "main" | "create-new" | "add-teacher" | "set-password"
      ) {
        setPopUpState(state);
      },
    }));

    /**
     * Definition of the function when pressed back button
     */
    const handlePressBack = () => {
      // If create new was pressed, then come back to the regular option
      if (popUpState === "add-teacher") setPopUpState("create-new");
      else if (popUpState === "create-new") {
        // If we are using the create new option, then close the pop up
        // once clicked (we will not want to watch the assign menu when
        // just creating)
        setPopUpState("main");
      }
      // Otherwise, close the pop up
      else closePopUpAction();
    };

    const closePopUpAction = () => {
      // Ensure the warning message is closed
      if (setShowWarningMessage) setShowWarningMessage(false);

      // Close the pop up
      setShowPopUp(false);
    };

    /**
     * Definition of the function executed after creating a parent
     * in the database
     */
    const afterCreateClass = () => {
      // Come back to the regular view (depending on the justCreateNew option)
      setPopUpState("main");

      // And display the success message (if it has been created, not when editing)
      if (!selectedClassToEdit) setShowSaveSuccessPopUp(true);
      // But if editing, then close the pop up
      else setShowPopUp(false);
    };

    /**
     * Function to execute once clicked "Create another"
     */
    const handleCreateAnother = () => {
      // Hide the success pop up
      setShowSaveSuccessPopUp(false);

      // And go to create-new state in the pop up
      setPopUpState("create-new");
    };

    /**
     * Timer for the warning pop up message
     */
    useEffect(() => {
      if (showWarningMessage) {
        // Starting the timer
        const timer = setTimeout(() => {
          // Once finished, close the pop up
          if (setShowWarningMessage) setShowWarningMessage(false);
        }, 5000);
        return () => clearTimeout(timer);
      }
    }, [showWarningMessage]);

    /**
     * Adding a listener for keyboard shortcuts
     */
    useEffect(() => {
      // Function to use on escape
      const handleKeyDown = (event: KeyboardEvent) => {
        if (event.key === "Escape") {
          handlePressBack();
        }
      };

      // Adding the lister
      document.addEventListener("keydown", handleKeyDown);

      // Cleanup
      return () => {
        document.removeEventListener("keydown", handleKeyDown);
      };
    }, [popUpState]);

    return (
      <div className="relative w-full h-fit max-h-[80vh] flex-col justify-start items-start gap-6 inline-flex">
        {!showSaveSuccessPopUp && (
          <>
            {popUpState !== "add-teacher" && popUpState !== "set-password" && (
              <div className="self-stretch justify-between items-center inline-flex">
                <div className="justify-start items-center gap-3 flex">
                  <button
                    className="p-1.5 bg-neutral-50 rounded-full backdrop-blur-[32px] justify-center items-center flex cursor-pointer hover:bg-neutral-100 ease-in-out duration-150"
                    onClick={handlePressBack}
                  >
                    <div className="w-5 h-5 justify-center items-center flex">
                      <div className="w-5 h-5 relative">
                        <GoArrowLeft className="w-full h-full fill-dc-secondary-600" />
                      </div>
                    </div>
                  </button>
                  <div className="text-neutral-600 text-lg font-normal font-sans leading-snug">
                    {popUpState === "main"
                      ? "Classes"
                      : selectedClassToEdit
                      ? "Edit Class"
                      : popUpState === "create-new"
                      ? "Create new class"
                      : ""}
                  </div>
                </div>
                {popUpState === "main" && (
                  <button
                    className="group rounded justify-center items-center gap-2 flex cursor-pointer"
                    onClick={() => setPopUpState("create-new")}
                  >
                    <div className="w-6 h-6 relative">
                      <MdAddCircleOutline className="w-full h-full fill-dc-secondary-600 group-hover:fill-dc-secondary-700" />
                    </div>
                    <div className="text-dc-secondary-600 group-hover:text-dc-secondary-700 text-lg font-semibold font-sans">
                      Create new
                    </div>
                  </button>
                )}
              </div>
            )}
            {popUpState === "main" && (
              <ClassesCardsList
                classes={classes || []}
                setClasses={setClasses}
              />
            )}
            {["create-new", "add-teacher", "set-password"].includes(
              popUpState
            ) && (
              <CreateNewClass
                classes={classes}
                selectedClassToEdit={selectedClassToEdit}
                contextSchool={contextSchool}
                popUpState={popUpState}
                setClasses={setClasses}
                setPopUpState={setPopUpState}
                setShowPopUp={setShowPopUp}
                afterCreateFunction={afterCreateClass}
              />
            )}
          </>
        )}
        {showSaveSuccessPopUp && (
          <SavedSuccessfulPanel
            title="Class Created Successfully"
            paragraphs={["The new class has been successfully created."]}
            createText="Create another"
            onCreateAnother={handleCreateAnother}
            onClose={() => setShowSaveSuccessPopUp(false)}
          />
        )}
        {setShowWarningMessage && (
          <WarningPopUpMessage
            showWarningMessage={showWarningMessage}
            setShowWarningMessage={setShowWarningMessage}
          />
        )}
      </div>
    );
  }
);

export default AssignClassPanel;
