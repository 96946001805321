const ResizeableSash = () => {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 64 64"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="drag_handle">
        <path
          id="Vector"
          d="M24 13.3333L24 50.6667C24 52.1333 25.2 53.3333 26.6667 53.3333C28.1333 53.3333 29.3333 52.1333 29.3333 50.6667L29.3333 13.3333C29.3333 11.8667 28.1333 10.6667 26.6667 10.6667C25.2 10.6667 24 11.8667 24 13.3333ZM40 50.6667V13.3333C40 11.8667 38.8 10.6667 37.3333 10.6667C35.8667 10.6667 34.6667 11.8667 34.6667 13.3333V50.6667C34.6667 52.1333 35.8667 53.3333 37.3333 53.3333C38.8 53.3333 40 52.1333 40 50.6667Z"
          fill="#999999"
        />
      </g>
    </svg>
  );
};

export default ResizeableSash;
