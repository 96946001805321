import {
  MdDeleteOutline,
  MdMailOutline,
  MdOutlinePhone,
  MdOutlineSchool,
} from "react-icons/md";
import { ContactListType } from "utils/interfaces";

interface TeacherCardProps {
  contact: ContactListType;
  deleteAction: (contact: ContactListType) => void;
}

const TeacherCard: React.FC<TeacherCardProps> = ({ contact, deleteAction }) => {
  /**
   * Function used to operate on click of the delete button
   */
  const handleDelete = () => {
    deleteAction(contact);
  }

  return (
    <div className="self-stretch p-4 bg-white rounded-lg border border-neutral-200 justify-center items-center gap-3 inline-flex">
      <div className="grow shrink basis-0 h-fit justify-center items-center gap-4 flex">
        <div className="p-1.5 rounded border border-neutral-200 flex-col justify-start items-center gap-1 inline-flex">
          <div className="flex-col justify-start items-center gap-1 flex">
            <div className="w-8 h-8 relative">
              <MdOutlineSchool className="w-full h-full fill-neutral-500" />
            </div>
            <div className="justify-center items-center gap-2 inline-flex">
              <div className="text-neutral-500 text-[10px] font-normal font-sans uppercase leading-3 tracking-wide">
                Teacher
              </div>
            </div>
          </div>
        </div>
        <div className="grow shrink basis-0 flex-col justify-start items-start gap-1.5 inline-flex">
          <div className="self-stretch justify-start items-center gap-2 inline-flex">
            <div className="grow shrink basis-0 text-neutral-700 text-base font-semibold font-sans leading-[19px]">
              {contact.firstName} {contact.lastName}
            </div>
          </div>
          <div className="self-stretch justify-start items-center gap-2 inline-flex">
            <div className="grow shrink basis-0 h-[17px] justify-center items-center gap-1 flex">
              <div className="w-4 h-4 relative">
                <MdOutlinePhone className="w-full h-full fill-neutral-600" />
              </div>
              <div className="grow shrink basis-0 text-neutral-600 text-sm font-normal font-sans leading-[17px]">
                {contact.phone}
              </div>
            </div>
          </div>
          <div className="self-stretch justify-start items-center gap-2 inline-flex">
            <div className="grow shrink basis-0 h-[17px] justify-center items-center gap-1 flex">
              <div className="w-4 h-4 relative">
                <MdMailOutline className="w-full h-full fill-neutral-600" />
              </div>
              <div className="grow shrink basis-0 text-neutral-600 text-sm font-normal font-sans leading-[17px]">
                {contact.email}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="w-10 self-stretch justify-center items-center gap-2 flex">
        <button
          className="p-2.5 bg-neutral-50 hover:bg-neutral-100 ease-in-out duration-150 rounded-full backdrop-blur-[32px] justify-center items-center gap-2 flex cursor-pointer"
          onClick={handleDelete}
        >
          <div className="w-5 h-5 relative">
            <MdDeleteOutline className="w-full h-full fill-neutral-700" />
          </div>
        </button>
      </div>
    </div>
  );
};

export default TeacherCard;
