import { MdOutlineLocationOn, MdDeleteOutline } from "react-icons/md";
import { LocationListType } from "utils/interfaces";

interface LocationCardFormProps {
  index: number;
  location: LocationListType;
  deleteAction: (index: number) => void;
}

const LocationCardForm: React.FC<LocationCardFormProps> = ({
  index, 
  location,
  deleteAction
}) => {
  /**
   * Send the signal to delete that entry in the array
   */
  const handleDelete = () => {
    deleteAction(index);
  }

  return (
    <div className="self-stretch p-4 bg-white rounded-lg border border-Subtle justify-center items-center gap-3 inline-flex">
      <div className="grow shrink basis-0 h-[65px] justify-center items-center gap-4 flex">
        <div className="p-1.5 rounded border border-neutral-200 flex-col justify-start items-center gap-1 inline-flex">
          <div className="flex-col justify-start items-center gap-1 flex">
            <div className="w-8 h-8 relative">
              <MdOutlineLocationOn className="w-full h-full fill-neutral-500" />
            </div>
            <div className="justify-center items-center gap-2 inline-flex">
              <div className="text-neutral-500 text-[10px] font-normal font-sans uppercase leading-3 tracking-wide select-none">
                Location
              </div>
            </div>
          </div>
        </div>
        <div className="grow shrink basis-0 flex-col justify-start items-start gap-1.5 inline-flex">
          <div className="self-stretch justify-start items-center gap-2 inline-flex">
            <div className="grow shrink basis-0 text-neutral-700 text-base font-semibold font-sans leading-[19px]">
              {location.address},
            </div>
          </div>
          <div className="self-stretch justify-start items-center gap-2 inline-flex">
            <div className="grow shrink basis-0 text-neutral-700 text-sm font-normal font-sans leading-[17px]">
              {location.city},
            </div>
          </div>
          <div className="self-stretch justify-start items-center gap-2 inline-flex">
            <div className="grow shrink basis-0 text-neutral-700 text-sm font-normal font-sans leading-[17px]">
              {location.state} {location.postCode}
            </div>
          </div>
        </div>
      </div>
      <div className="w-10 self-stretch justify-center items-center gap-2 flex">
        <button
          className="p-2.5 bg-neutral-50 rounded-full backdrop-blur-[32px] justify-center items-center gap-2 flex cursor-pointer hover:bg-neutral-100 ease-in-out duration-150"
          onClick={handleDelete}
        >
          <div className="w-5 h-5 relative">
            <MdDeleteOutline className="w-full h-full fill-neutral-700" />
          </div>
        </button>
      </div>
    </div>
  );
};

export default LocationCardForm;
