import React from "react";
import { MdOutlinePictureAsPdf, MdOutlineSummarize } from "react-icons/md";

interface GenerateReportPopupProps {
  setShowPopup: React.Dispatch<React.SetStateAction<boolean>>;
  onGenerateCsv: () => void;
  onGeneratePdf: () => void;
}

const GenerateReportPopup: React.FC<GenerateReportPopupProps> = ({
  setShowPopup: setShowCreateNewPopUp,
  onGenerateCsv,
  onGeneratePdf,
}) => {
  return (
    <>
      <div
        className="fixed top-0 left-0 w-screen h-screen bg-transparent z-20"
        onClick={() => setShowCreateNewPopUp(false)}
      />
      <div
        className="absolute w-[186px] top-[90px] right-2 p-3 bg-white rounded-lg shadow flex-col justify-center items-center gap-2 inline-flex cursor-pointer z-30"
      >
        <div
          className="w-[170px] p-3 bg-white rounded-lg justify-start items-center gap-2 inline-flex text-neutral-700 hover:bg-neutral-700 hover:text-white"
          onClick={onGeneratePdf}
        >
          <div className="w-6 h-6 relative fill-neutral-700">
            <MdOutlinePictureAsPdf className="w-full h-full" />
          </div>
          <div className="text-base font-normal font-sans leading-7">
            Export as PDF
          </div>
        </div>
        <div
          className="w-[170px] p-3 bg-white rounded-lg justify-start items-center gap-2 inline-flex text-neutral-700 hover:bg-neutral-700 hover:text-white"
          onClick={onGenerateCsv}
        >
          <div className="w-6 h-6 relative fill-neutral-700">
            <MdOutlineSummarize className="w-full h-full" />
          </div>
          <div className="text-base font-normal font-sans leading-7">
            Export as CSV
          </div>
        </div>
      </div>
    </>
  );
};

export default GenerateReportPopup;
