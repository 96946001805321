/**
 * This file coordinates the workflow for the main view.
 */
import React, { useEffect, useRef, useState } from "react";
import "react-toastify/dist/ReactToastify.css";
import MainHeader from "components/main-view/utils/main-header/MainHeader";
import SideBar from "components/main-view/utils/side-bar/SideBar";
import Lessons from "components/main-view/lessons/Lessons";
import Classes from "components/main-view/classes/Classes";
import SubHeaderBar from "components/main-view/utils/sub-header/SubHeaderBar";
import Dashboard from "components/main-view/dashboard/Dashboard";
import Quizzes from "components/main-view/quizzes/Quizzes";
import Customers from "components/main-view/customers/Customers";
import { getCollectionRequest } from "services/apiRequests";
import { useLocation, useNavigate } from "react-router-dom";
import { SchoolListType } from "utils/interfaces";
import { validateClient } from "services/authRequests";
import { getUserIdFromToken } from "services/userRequest";
import { MainContext } from "components/main-view/utils/Contexts";

interface MainViewProps {}

export const MainView: React.FC<MainViewProps> = () => {
  // States definition
  const [activeItem, setActiveItem] = useState("dashboard");
  const [backPressedToggle, setBackPressedToggle] = useState(false);
  const [showSideBarMenu, setShowSideBarMenu] = useState(true);
  // User defined states
  const [userFirstName, setUserFirstName] = useState("");
  const [userLastName, setUserLastName] = useState("");
  const [userRole, setUserRole] = useState("");
  const [userSchoolId, setUserSchoolId] = useState("");
  const [userSchool, setUserSchool] = useState<SchoolListType | null>(null);
  const [userClassId, setUserClassId] = useState("");

  // Navigate hook
  const navigate = useNavigate();

  // References definition
  const dashboardRef = useRef();
  const lessonsRef = useRef();
  const classesRef = useRef();
  const customersRef = useRef();
  const quizzesRef = useRef();
  const headerRef = useRef();
  const subHeaderRef = useRef();

  // Get a state if we are receiving a state variable from another url
  const { state } = useLocation();

  /**
   * Check if the user has an access token every time we enter this view
   */
  useEffect(() => {
    validateClient().then(
      (response: { connected: boolean; navigateTo: string }) => {
        // Check if the token is validated
        if (!response.connected) {
          navigate("/");
        } else {
          // Get the user _id
          const _id = getUserIdFromToken();

          // And request the information
          getCollectionRequest(
            "/api/users",
            ["firstName", "lastName", "role", "organisations", "classes"],
            { _id }
          ).then((response) => {
            // Check if the response is valid
            if (!(response && response.successful)) {
              navigate("/");
              return;
            }

            // Get the user
            const user = response.content[0];

            // And set the states
            setUserFirstName(user.firstName);
            setUserLastName(user.lastName);
            setUserRole(user.role);
            // Check if the user contains organisations
            if (user.organisations && user.organisations.length > 0) {
              // Set the first one
              setUserSchoolId(user.organisations[0]);
            }
            // Check if the user contains classes
            if (user.classes && user.classes.length > 0) {
              // Set the first one
              setUserClassId(user.classes[0]);
            }
          });

          // And after everything, use the navRegister to comeback
          // to the expected view
          applyNavRegister();
        }
      }
    );
  }, []);

  /**
   * Define the come back behaviour based on an active item if there is
   * a state with an active item defined
   */
  const applyNavRegister = () => {
    if (state) {
      // Set the selected active item
      if (state.activeItem) setActiveItem(state.activeItem);

      // Check first if we have a navRegister defined
      if (state.navRegister && state.navRegister.length > 0) {
        // Wait until lessonsRef is set
        const waitForLessonsRef = new Promise((resolve) => {
          const checkRef = () => {
            if (lessonsRef.current) {
              resolve(true);
            } else {
              requestAnimationFrame(checkRef);
            }
          };
          checkRef();
        });

        // Once the lessons ref is defined, set the navRegister
        waitForLessonsRef.then(() => {
          (lessonsRef.current as any)
            .list()
            .startFromLastNavRegister(state.navRegister);
        });
      }
      // And set the lessons list to the selected lesson if available
      else if (state.activeLesson) {
        // Wait until lessonsRef is set
        const waitForLessonsRef = new Promise((resolve) => {
          const checkRef = () => {
            if (lessonsRef.current) {
              resolve(true);
            } else {
              requestAnimationFrame(checkRef);
            }
          };
          checkRef();
        });

        // Once the lessons ref is defined, set the active lesson
        waitForLessonsRef.then(() => {
          (lessonsRef.current as any).setActiveLesson(state.activeLesson);
        });
      }
    }
  }

  /**
   * Function to get the school data of the user
   * @param filter
   * @returns
   */
  const getSchoolData = async (filter: {[key: string]: any} = {}) => {
    const response = await getCollectionRequest(
      "/api/organisations",
      ["_id", "name", "logo"],
      filter
    );

    // Check if the response is successful
    if (!response.successful) {
      return [];
    }

    // Get the data
    const data = response.content;

    // Assign the data to the corresponding element
    setUserSchool(data[0]);

    return data;
  };

  /**
   * Get data on loading
   */
  useEffect(() => {
    if (["customer admin", "class admin"].includes(userRole)) {
      getSchoolData({ _id: userSchoolId });
    } else if (userRole === "master admin") {
      getSchoolData()
    }
  }, [userRole, userSchoolId]);

  // /**
  //  * Assign the role based on the specified in the login view
  //  */
  // useEffect(() => {
  //   // Get the role cookie
  //   const roleCookie = Cookies.get("role");
  //   // And set the value
  //   setUserRole(roleCookie || "");
  // }, []);

  // Function used to render the main content based on the tab selected
  const renderContent = () => {
    switch (activeItem) {
      case "dashboard":
        return (
          <Dashboard
            ref={dashboardRef}
            headerRef={headerRef}
            subHeaderRef={subHeaderRef}
            customersRef={customersRef}
            userRole={userRole}
            userFirstName={userFirstName}
            userLastName={userLastName}
            userSchool={userSchool}
            backPressedToggle={backPressedToggle}
          />
        );
      case "school-options":
        return (
          <Customers
            ref={customersRef}
            userRole={userRole}
            userFirstName={userFirstName}
            userLastName={userLastName}
            backPressedToggle={backPressedToggle}
            headerRef={headerRef}
            subHeaderRef={subHeaderRef}
            from="main"
          />
        );
      case "classes":
        return (
          <Classes
            ref={classesRef}
            userRole={userRole}
            backPressedToggle={backPressedToggle}
            userSchool={userSchool}
            headerRef={headerRef}
          />
        );
      case "users":
        return (
          <Classes
            ref={classesRef}
            userRole={userRole}
            backPressedToggle={backPressedToggle}
            userSchool={userSchool}
            headerRef={headerRef}
            setUserSchool={setUserSchool}
          />
        );
      case "lessons":
        return (
          <Lessons
            quizzesRef={quizzesRef}
            ref={lessonsRef}
            userRole={userRole}
            backPressedToggle={backPressedToggle}
            setShowSideBarMenu={setShowSideBarMenu}
            headerRef={headerRef}
          />
        );
      case "quizzes":
        return (
          <Quizzes
            ref={quizzesRef}
            userRole={userRole}
            backPressedToggle={backPressedToggle}
            headerRef={headerRef}
            quizzesRef={quizzesRef}
          />
        );
      default:
        return <></>;
    }
  };

  return (
    <MainContext.Provider
      value={{ userSchool, setUserSchool, userClassId }}
    >
      <div className="w-screen h-screen min-h-screen p-8 flex flex-col gap-4">
        <MainHeader
          ref={headerRef}
          userRole={userRole}
          userFirstName={userFirstName}
          userLastName={userLastName}
          activeItem={activeItem}
          backPressedToggle={backPressedToggle}
          setBackPressedToggle={setBackPressedToggle}
          setShowSideBarMenu={setShowSideBarMenu}
          setActiveItem={setActiveItem}
          lessonsRef={lessonsRef}
          classesRef={classesRef}
          customersRef={customersRef}
          dashboardRef={dashboardRef}
          subHeaderRef={subHeaderRef}
        />
        <SubHeaderBar
          ref={subHeaderRef}
          activeItem={activeItem}
          dashboardRef={dashboardRef}
        />
        <div className="w-full h-full grow shrink justify-start items-start gap-8 inline-flex overflow-y-hidden overflow-x-visible">
          {showSideBarMenu && (
            <SideBar
              selected={activeItem}
              setActiveItem={setActiveItem}
              userRole={userRole}
              userFirstName={userFirstName}
              userLastName={userLastName}
              userSchool={userSchool}
              customersRef={customersRef}
            />
          )}
          {renderContent()}
        </div>
      </div>
    </MainContext.Provider>
  );
};

export default MainView;
