import { useState } from "react";
import {
  MdDragIndicator,
  MdCheck,
  MdDeleteOutline,
  MdDriveFileRenameOutline,
  MdDoneAll,
} from "react-icons/md";
import { ResponseOption } from "utils/interfaces";

interface ResponseCardProps {
  index: number;
  text: string;
  correct: boolean;
  responses: ResponseOption[];
  setResponses: React.Dispatch<React.SetStateAction<ResponseOption[]>>;
}

const ResponseCard: React.FC<ResponseCardProps> = ({
  index,
  text,
  correct,
  responses,
  setResponses,
}) => {
  // States definition
  const [selfText, setSelfText] = useState(responses[index].text);
  const [isEditing, setIsEditing] = useState(responses[index].text === "");

  /**
   * Function to define the text defined
   */
  const setValue = () => {
    // Set stop editing
    setIsEditing(false);
    // And send the current value to the main list
    const responsesUpdated = [...responses];

    // Check if the selfText is not void, otherwise delete
    if (selfText) {
      // Update the list of responses
      responsesUpdated[index] = {
        text: selfText,
        correct: responses[index].correct,
      };
      setResponses(responsesUpdated);
    } else {
      // If it's empty, then delete
      handleClickDelete();
    }
  };

  /**
   * Function to check if the key pressed is enter in the input field
   */
  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === "Enter") setValue();
  };

  /**
   * Function to handle right answer button action
   */
  const handleClickRightAnswer = () => {
    // Set everything to false, and toggle the current index
    const responsesUpdated = responses.map(
      (response: ResponseOption, currentIndex: number) => {
        // Set the correct to false
        const toUpdate = { ...response };
        if (currentIndex === index) {
          toUpdate.correct = !toUpdate.correct;
        } else {
          toUpdate.correct = false;
        }
        return toUpdate;
      }
    );
    // And toggle the current correct, setting the rest of the other ones to false
    // responsesUpdated[index].correct = !responsesUpdated[index].correct;
    setResponses(responsesUpdated);
  };

  /**
   * Function to handle delete button action
   */
  const handleClickDelete = () => {
    // Return all the elements except the current one
    const responsesUpdated = responses
      .slice(0, index)
      .concat(responses.slice(index + 1));
    setResponses(responsesUpdated);
  };

  /**
   * Function to handle edit button action
   */
  const handleClickEdit = () => {
    setIsEditing(true);
  };

  return (
    <div className="w-full">
      <div
        className={
          isEditing
            ? "self-stretch pl-3 bg-white rounded-lg border border-neutral-400 justify-center items-center gap-2 inline-flex w-full"
            : "self-stretch p-3 bg-white rounded-lg border border-neutral-400 justify-center items-center gap-2 inline-flex w-full"
        }
      >
        {!isEditing && (
          <div className="w-6 h-6 relative cursor-grab">
            <MdDragIndicator className="w-full h-full fill-neutral-700" />
          </div>
        )}
        <div className="grow shrink align-middle text-neutral-700 text-base font-normal font-sans leading-[19px] select-none">
          {isEditing ? (
            <input
              type="text"
              value={selfText}
              onChange={(event) => setSelfText(event.target.value)}
              className="w-full h-full py-3 text-neutral-700 placeholder:text-neutral-300 text-base bg-transparent border-none focus:outline-none"
              placeholder="Enter response"
              onBlur={() => setValue()}
              onKeyDown={handleKeyDown}
              autoFocus={selfText === ""}
            />
          ) : (
            text
          )}
        </div>
        {!isEditing && responses.length > 1 && (
          <div
            className={
              correct
                ? "p-0.5 bg-success-500 hover:bg-success-600 ease-in-out duration-100 rounded-full justify-start items-center gap-2 flex cursor-pointer"
                : "p-0.5 bg-neutral-50 hover:bg-neutral-200 ease-in-out duration-100 rounded-full justify-start items-center gap-2 flex cursor-pointer"
            }
            onClick={handleClickRightAnswer}
          >
            <div className="w-5 h-5 relative">
              {correct ? (
                <MdDoneAll className="w-full h-full fill-white" />
              ) : (
                <MdCheck className="w-full h-full fill-neutral-700" />
              )}
            </div>
          </div>
        )}
        {!isEditing && (
          <div
            className="p-0.5 bg-neutral-50 hover:bg-neutral-200 ease-in-out duration-100 rounded-full justify-start items-center gap-2 flex cursor-pointer"
            onClick={handleClickDelete}
          >
            <div className="w-5 h-5 relative">
              <MdDeleteOutline className="w-full h-full fill-neutral-700" />
            </div>
          </div>
        )}
        {!isEditing && (
          <div
            className="p-0.5 bg-neutral-50 hover:bg-neutral-200 ease-in-out duration-100 rounded-full justify-start items-center gap-2 flex cursor-pointer"
            onClick={handleClickEdit}
          >
            <div className="w-5 h-5 relative">
              <MdDriveFileRenameOutline className="w-full h-full fill-neutral-700" />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ResponseCard;
