import { Transition } from "@headlessui/react";
import { Fragment } from "react";
import ProfilePanel from "./profile-pop-up/ProfilePanel";

interface PopUpPanelProps {
  type: "profile";
  showPopUp: boolean;
  firstName?: string;
  lastName?: string;
  setShowPopUp: React.Dispatch<React.SetStateAction<boolean>>;
  headerRef?: React.MutableRefObject<any>;
}

const PopUpPanel: React.FC<PopUpPanelProps> = ({
  type,
  showPopUp,
  firstName,
  lastName,
  setShowPopUp,
  headerRef,
}) => {
  return (
    <>
      <Transition
        show={showPopUp}
        as={Fragment}
        enter="transition-opacity ease-out duration-300"
        enterFrom="opacity-0 translate-y-1/2"
        enterTo="opacity-100 translate-y-0"
        leave="transition-opacity duration-150"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <div
          className="fixed inset-0 w-full h-full bg-neutral-500 bg-opacity-40 backdrop-blur-[2px] z-20 flex flex-col flex-grow"
          onClick={() => setShowPopUp(false)}
        />
      </Transition>
      {/* Profile pop-up */}
      <Transition
        show={showPopUp && type === "profile"}
        as={"div"} // Done this way because if we use fragments we won't be able to pass setStates methods.
        className="fixed w-[462px] h-fit max-h-[90%] left-0 right-0 ml-auto mr-auto bottom-4 px-6 pt-6 pb-8 bg-white rounded-3xl flex-col justify-start items-start gap-14 inline-flex z-30 overflow-hidden"
        enter="transition-opacity ease-out duration-300"
        enterFrom="opacity-0 translate-y-1/2"
        enterTo="opacity-100 translate-y-0"
        leave="transition-opacity duration-150"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <ProfilePanel
          firstName={firstName || "-"}
          lastName={lastName || "-"}
          setShowPopUp={setShowPopUp}
        />
      </Transition>
    </>
  );
};

export default PopUpPanel;
