import { useEffect, useState } from "react";
import { GoCheckCircleFill } from "react-icons/go";
import { MdLocationOn } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { checkResultComplete } from "services/resultsRequests";
import { BACKEND_URL } from "config/getUrl";
import { Project } from "utils/interfaces";
import { removeItem } from "utils/utils";
import { AnimatePresence, motion } from "framer-motion";
import { platform } from "config/platform";

interface JourneyBadgeProps {
  index: number;
  project: Project;
  toComplete: boolean;
  setUncompletedIndexes: React.Dispatch<React.SetStateAction<number[]>>;
}

const JourneyBadge: React.FC<JourneyBadgeProps> = ({
  index,
  project,
  toComplete,
  setUncompletedIndexes,
}) => {
  // Status definition
  const [completed, setCompleted] = useState(false);
  const [hovering, setHovering] = useState(false);

  // Define a navigation hook
  const navigate = useNavigate();

  /**
   * Function used to define if a project is completed
   */
  useEffect(() => {
    if (project._id) {
      checkResultComplete("projects", project._id).then((response: boolean) => {
        // Set the current state
        setCompleted(response);
      });
    }
  }, [project]);

  /**
   * Function used when clicking on a badge
   */
  const handleClickBadge = () => {
    navigate(`/editor/projects/${project._id}`);
  };

  /**
   * Function used to update the uncompletedProjects list
   */
  useEffect(() => {
    // Remove from the list if completed
    if (completed) {
      setUncompletedIndexes((currentList) => removeItem(currentList, index));
    }
    // Otherwise add it to the list
    else {
      setUncompletedIndexes((currentList) => [
        ...new Set([...currentList, index]),
      ]);
    }
  }, [completed]);

  return (
    <div
      className={`w-[103px] h-full flex-col justify-center items-center gap-2 inline-flex relative cursor-pointer border border-transparent hover:backdrop-blur-sm ${
        completed
          ? "hover:border-success-500 hover:bg-success-200/20"
          : toComplete
          ? "hover:border-warning-500 hover:bg-warning-200/20"
          : "hover:border-neutral-300 hover:bg-neutral-300/20"
      } ease-in-out duration-150 rounded-lg`}
      onClick={handleClickBadge}
      onMouseEnter={() => setHovering(true)}
      onMouseLeave={() => setHovering(false)}
    >
      {platform === "deadly-coders" && <div className="w-16 h-16 rounded-full border border-neutral-200 bg-white relative overflow-hidden">
        {completed && (
          <div className="w-full h-full absolute rounded-full bg-neutral-50/70 backdrop-blur-sm justify-center items-center inline-flex">
            <div className="w-8 h-8 relative">
              <GoCheckCircleFill className="w-full h-full fill-success-700" />
            </div>
          </div>
        )}
        <img
          src={
            `${BACKEND_URL}/imgs/projects/${project._id}/badgeImage` ||
            "logo.svg"
          }
          className="w-full h-full rounded-full select-none"
          alt="badge"
        />
      </div>}
      {platform === "codeflix" && <div className="w-20 h-20 rounded-full relative overflow-hidden">
        {completed && (
          <div className="w-full h-full absolute rounded-full bg-neutral-50/70 backdrop-blur-sm justify-center items-center inline-flex">
            <div className="w-8 h-8 relative">
              <GoCheckCircleFill className="w-full h-full fill-success-700" />
            </div>
          </div>
        )}
        <img
          src={
            `${BACKEND_URL}/imgs/projects/${project._id}/badgeImage` ||
            "logo.svg"
          }
          className="w-full h-full rounded-full select-none"
          alt="badge"
        />
      </div>}
      <div className="grow shrink text-center text-neutral-500 text-[10px] font-semibold font-sans leading-3 tracking-tight overflow-auto select-none">
        {project.title}
      </div>
      <AnimatePresence>
        {toComplete && !hovering && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.15, ease: "easeInOut" }}
            className={platform === "deadly-coders" ? "absolute right-1 -top-3.5" : "absolute -right-1 -top-2.5"}
          >
            <div className="w-8 h-8 relative">
              <MdLocationOn className="w-full h-full fill-warning-500" />
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

export default JourneyBadge;
