import { MdCheckCircle, MdClose, MdRadioButtonUnchecked } from "react-icons/md";
import Cookies from "js-cookie";
import Jakita from "assets/imgs/Jakita.png";
import LittleC from "assets/imgs/LittleC.png";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { logoutRequest } from "services/authRequests";

interface ProfilePanelProps {
  firstName: string;
  lastName: string;
  setShowPopUp: React.Dispatch<React.SetStateAction<boolean>>;
}

const ProfilePanel: React.FC<ProfilePanelProps> = ({
  firstName,
  lastName,
  setShowPopUp,
}) => {
  // States definition
  const [guide, setGuide] = useState<"jakita" | "littleC">("jakita"); // Probably set this to the database

  // Navigate hook
  const navigate = useNavigate();

  /**
   * Handle logout
   */
  const handleLogout = async () => {
    // Remove the cookies
    Cookies.remove("accessToken");
    // Send the request to delete the refreshToken from the backend
    const response = await logoutRequest();

    // And go to the home page
    navigate("/");
  };

  return (
    <>
      <div className="self-stretch justify-between items-center inline-flex">
        <div className="grow shrink basis-0 text-neutral-700 text-[23px] font-semibold font-sans leading-7">
          My Profile
        </div>
        <button
          className="p-2 bg-neutral-50 rounded-full backdrop-blur-[32px] justify-center items-center gap-2 flex cursor-pointer hover:bg-neutral-100 ease-in-out duration-150"
          onClick={() => setShowPopUp(false)}
        >
          <div className="w-5 h-5 relative">
            <MdClose className="w-full h-full fill-neutral-700" />
          </div>
        </button>
      </div>
      <div className="self-stretch h-fit flex-col justify-start items-start gap-14 flex">
        <div className="self-stretch h-fit flex-col justify-start items-start gap-5 flex">
          <div className="self-stretch text-neutral-500 text-base font-semibold font-sans leading-[19px]">
            Profile details
          </div>
          <div className="self-stretch h-fit flex-col justify-start items-start gap-4 flex">
            <div className="self-stretch h-fit flex-col justify-start items-start flex">
              <div className="rounded justify-start items-start gap-2 inline-flex">
                <div className="text-neutral-500 text-xs font-normal font-sans uppercase leading-[14px] tracking-wide">
                  First Name
                </div>
              </div>
              <div className="self-stretch py-1 justify-center items-center gap-2 inline-flex">
                <div className="grow shrink basis-0 h-[19px] text-neutral-700 text-lg font-semibold font-sans leading-snug">
                  {firstName}
                </div>
              </div>
            </div>
            <div className="self-stretch h-fit flex-col justify-start items-start flex">
              <div className="rounded justify-start items-start gap-2 inline-flex">
                <div className="text-neutral-500 text-xs font-normal font-sans uppercase leading-[14px] tracking-wide">
                  Last Name
                </div>
              </div>
              <div className="self-stretch py-1 justify-center items-center gap-2 inline-flex">
                <div className="grow shrink basis-0 h-[19px] text-neutral-700 text-lg font-semibold font-sans leading-snug">
                  {lastName}
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <div className="self-stretch h-fit flex-col justify-start items-start gap-5 flex">
          <div className="self-stretch text-neutral-500 text-base font-semibold font-sans leading-[19px]">
            Platform guide
          </div>
          <div className="self-stretch h-fit flex-col justify-start items-start gap-4 flex">
            <div
              className={
                guide === "jakita"
                  ? "self-stretch p-4 bg-neutral-50 rounded-xl border-2 border-neutral-700 justify-start items-center gap-4 inline-flex cursor-pointer"
                  : "self-stretch p-4 bg-neutral-50 rounded-xl border-2 border-transparent justify-start items-center gap-4 inline-flex cursor-pointer"
              }
              onClick={() => setGuide("jakita")}
            >
              <img className="w-10 h-10 rounded-full" src={Jakita} />
              <div className="grow shrink basis-0 h-[19px] text-neutral-700 text-lg font-semibold font-sans leading-snug">
                Jakita
              </div>
              <div className="w-8 h-8 justify-center items-center flex">
                <div className="w-8 h-8 relative">
                  {guide === "jakita" ? (
                    <MdCheckCircle className="w-full h-full fill-neutral-700" />
                  ) : (
                    <MdRadioButtonUnchecked className="w-full h-full fill-neutral-500" />
                  )}
                </div>
              </div>
            </div>
            <div
              className={
                guide === "littleC"
                  ? "self-stretch p-4 bg-neutral-50 rounded-xl border-2 border-neutral-700 justify-start items-center gap-4 inline-flex cursor-pointer"
                  : "self-stretch p-4 bg-neutral-50 rounded-xl border-2 border-transparent justify-start items-center gap-4 inline-flex cursor-pointer"
              }
              onClick={() => setGuide("littleC")}
            >
              <img className="w-10 h-10 rounded-full" src={LittleC} />
              <div className="grow shrink basis-0 h-[19px] text-neutral-700 text-lg font-semibold font-sans leading-snug">
                Little C
              </div>
              <div className="w-8 h-8 relative">
                {guide === "littleC" ? (
                  <MdCheckCircle className="w-full h-full fill-neutral-700" />
                ) : (
                  <MdRadioButtonUnchecked className="w-full h-full fill-neutral-500" />
                )}
              </div>
            </div>
          </div>
          <div
            className="self-stretch px-5 py-3 bg-dc-secondary-600 rounded justify-center items-center gap-2 inline-flex cursor-pointer"
            onClick={handleLogout}
          >
            <div className="text-white text-lg font-semibold font-sans leading-snug">
              Logout
            </div>
          </div>
        </div> */}
      </div>
    </>
  );
};

export default ProfilePanel;
