import EmptyIcon from "assets/icons/admin-view/EmptyIcon";

const EmptyViewPopUp = () => {
  return (
    <div className="w-full h-[400px] justify-center items-center gap-[5px] inline-flex">
      <div className="grow shrink basis-0 flex-col justify-start items-center gap-10 inline-flex">
        <div className="w-fit h-fit relative">
          <EmptyIcon width="244" height="183" />
        </div>
        <div className="self-stretch h-[51px] flex-col justify-start items-center gap-3 flex">
          <div className="self-stretch text-center text-neutral-600 text-lg font-semibold font-sans leading-snug">
            This area is empty
          </div>
          <div className="self-stretch text-center text-neutral-700 text-sm font-normal font-sans leading-[17px]">
            Just click on the "Create New" button, and let's get started
          </div>
        </div>
      </div>
    </div>
  );
};

export default EmptyViewPopUp;
