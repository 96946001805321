import LoginForm from "./LoginForm";
import windowsLogo from "assets/imgs/windowsLogo.svg";
import { FcGoogle } from "react-icons/fc";
import { getOAuthLogin } from "services/loginRequests";
import { externalNavigate } from "utils/utils";
import { MdLogin } from "react-icons/md";
import LandingPageBackground from "components/login/LandingPageBackground";
import MainWindow from "components/login/MainWindow";

const Login: React.FC = () => {
  const handleGoogleLogin = async () => {
    let loginUrl = await getOAuthLogin("google");
    externalNavigate(loginUrl);
  };

  const handleMicrosoftLogin = async () => {
    let loginUrl = await getOAuthLogin("microsoft");
    externalNavigate(loginUrl);
  };

  return (
    <LandingPageBackground>
      <MainWindow>
        <div className="min-w-[300px] max-w-[414px] w-full self-stretch flex-col justify-start items-center gap-8 inline-flex">
          <div className="self-stretch h-fit flex-col justify-start items-start gap-6 flex">
            <div className="self-stretch justify-start items-center gap-2 inline-flex">
              <div className="grow shrink basis-0 text-neutral-700 text-3xl font-semibold font-sans leading-[34px]">
                Sign In to your account
              </div>
            </div>
            <div className="self-stretch justify-start items-center gap-2 inline-flex">
              <div className="grow shrink basis-0 text-neutral-700 text-base font-normal font-sans leading-[21px]">
                Welcome back! Sign in to access your account
              </div>
            </div>
          </div>
          <LoginForm />
          {/* <div className="self-stretch justify-center items-center gap-4 inline-flex">
              <hr className="flex-1 border-neutral-700" />
              <span className="flex-shrink mx-4 text-neutral-700 uppercase">
                or
              </span>
              <hr className="flex-1 border-neutral-700" />
            </div>
            <div className="self-stretch h-[100px] flex-col justify-start items-center gap-3 inline-flex">
              <div className="self-stretch text-center text-neutral-700 text-sm font-semibold font-sans leading-[17px]">
                Sign in with
              </div>
              <div className="self-stretch px-28 justify-between items-start inline-flex">
                <div className="flex-col justify-start items-start gap-1 inline-flex">
                  <div className="self-stretch px-5 py-3 rounded border-2 border-dc-secondary-600 justify-center items-center gap-2 inline-flex cursor-pointer" onClick={handleMicrosoftLogin}>
                    <img src={windowsLogo} className="w-6 h-6" />
                  </div>
                  <div className="self-stretch h-[19px] text-center text-neutral-700 text-xs font-semibold font-sans leading-[14.40px]">
                    Microsoft
                  </div>
                </div>
                <div className="flex-col justify-start items-start gap-1 inline-flex">
                  <div className="self-stretch px-5 py-3 rounded border-2 border-dc-secondary-600 justify-center items-center gap-2 inline-flex cursor-pointer" onClick={handleGoogleLogin}>
                    <div className="w-6 h-6 relative">
                      <FcGoogle className="w-full h-full" />
                    </div>
                  </div>
                  <div className="self-stretch h-[19px] text-center text-neutral-700 text-xs font-semibold font-sans leading-[14.40px]">
                    Google
                  </div>
                </div>
                <div className="flex-col justify-start items-start gap-1 inline-flex">
                  <div className="self-stretch px-5 py-3 rounded border-2 border-dc-secondary-600 justify-center items-center gap-2 inline-flex cursor-pointer">
                    <div className="w-6 h-6 relative">
                      <MdLogin className="w-full h-full fill-neutral-700" />
                    </div>
                  </div>
                  <div className="self-stretch h-[19px] text-center text-neutral-700 text-xs font-semibold font-sans leading-[14.40px] cursor-pointer">
                    SSO
                  </div>
                </div>
              </div>
            </div> */}
        </div>
      </MainWindow>
    </LandingPageBackground>
  );
};

export default Login;
