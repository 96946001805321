const SideIndicator = () => {
  return (
    <svg
      width="8"
      height="36"
      viewBox="0 0 8 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        id="side-indicator"
        d="M0.597965 18.3054C0.534253 18.1068 0.534253 17.8932 0.597965 17.6946L6.04778 0.703728C6.39626 -0.382706 8 -0.131805 8 1.00915L8 34.9909C8 36.1318 6.39626 36.3827 6.04778 35.2963L0.597965 18.3054Z"
        fill="#353E47"
      />
    </svg>
  );
};

export default SideIndicator;
