import { useEffect, useRef, useState } from "react";
import { StudentListType, StudentResultsType } from "utils/interfaces";
import LeaderboardItem from "./LeaderboardItem";
import { getCollectionRequest } from "services/apiRequests";
import { platform } from "config/platform";

interface LeaderboardProps {
  userClassId: string;
  showStatus: boolean;
}

const Leaderboard: React.FC<LeaderboardProps> = ({
  userClassId,
  showStatus,
}) => {
  // States definition
  const [students, setStudents] = useState<StudentResultsType[]>([]);
  const [isOverflow, setIsOverflow] = useState(false);

  // Define a ref for the list
  const listRef = useRef<HTMLDivElement>(null);

  /**
   * Function to get the students of a class
   */
  const getData = async () => {
    // Get the students
    const response = await getCollectionRequest(
      `/api/users/students?classId=${userClassId}`
    );

    // Check if the response is successful
    if (!response.successful) {
      return;
    }

    // Get the data
    const data = response.content;

    // Get the statistics
    const results = await Promise.all(
      data.map(async (student: StudentListType) => {
        // Get the completed results
        const response = await getCollectionRequest(
          `/api/results/student?id=${student._id}`
        );

        // Check if the request was successful
        if (!response.successful) {
          return;
        }

        // Get the data
        const data = response.content;

        return { ...student, ...data };
      })
    );

    // Ordering the data
    const sortedResults = results.sort(
      (a: StudentResultsType, b: StudentResultsType) => {
        // Order the results based on the sum of the values
        // (they are on the same scale)
        if (
          a.courseCompleted + a.avgQuizScore >
          b.courseCompleted + b.avgQuizScore
        )
          return -1;
        else return 1;
      }
    );

    // Update the students
    setStudents(sortedResults);

    return response;
  };

  useEffect(() => {
    getData();
  }, []);

  /**
   * Function to fit the header based on the presence of the scrollbar
   */
  useEffect(() => {
    const checkOverflow = () => {
      const listElement = listRef.current;
      if (listElement) {
        // Check if element is overflowing vertically
        const hasOverflow = listElement.scrollHeight > listElement.clientHeight;
        setIsOverflow(hasOverflow);
      }
    };

    // Run on mount and whenever the window resizes
    checkOverflow();
    window.addEventListener("resize", checkOverflow);

    // Clean up listener on unmount
    return () => window.removeEventListener("resize", checkOverflow);
  }, [students, listRef]);

  return (
    <div className="w-full h-fit max-h-[417px] p-6 bg-neutral-25 rounded-3xl border-neutral-400 flex flex-col gap-3">
      <div className="self-stretch justify-between items-center flex">
        <div className="text-neutral-600 text-lg font-normal">{platform === "codeflix" ? "Leader Board" : "Deadly Board"}</div>
      </div>
      <div
        className={`h-11 px-8 ${
          isOverflow ? "pr-11" : ""
        } py-4 justify-between items-start inline-flex`}
      >
        <div className="w-[128px] h-3 justify-center items-center gap-2 flex">
          <div className="grow shrink basis-0 text-neutral-500 text-[10px] font-semibold font-inter leading-3 tracking-tight">
            Name
          </div>
        </div>
        <div className="w-[35px] h-3 justify-center items-center gap-2 flex">
          <div className="text-neutral-500 text-[10px] font-semibold font-inter leading-3 tracking-tight">
            Progress
          </div>
        </div>
        <div className="w-[35px] h-3 justify-center items-center gap-2 flex">
          <div className="text-neutral-500 text-[10px] font-semibold font-inter leading-3 tracking-tight">
            Aptitude
          </div>
        </div>
      </div>
      <div
        ref={listRef}
        className="w-full h-full flex flex-col gap-3 overflow-y-auto pop-up-scrollbar"
      >
        {students.map((student: StudentResultsType, index: number) => (
          <LeaderboardItem
            key={index}
            student={student}
            status={showStatus ? student.status || "Inactive" : ""}
            showStatus={showStatus}
          />
        ))}
      </div>
    </div>
  );
};

export default Leaderboard;
