import { motion } from "framer-motion";
import PlayIcon from "assets/icons/editor/PlayIcon";
import { FaCheck } from "react-icons/fa6";
import { FaStopCircle } from "react-icons/fa";
import { useState } from "react";
import { Transition } from "@headlessui/react";
import React from "react";

interface RunCodeButtonType {
  showRunCode: boolean;
  handleRunCode?: () => void;
  prefixId: string;
}

const RunCodeButton: React.FC<RunCodeButtonType> = ({
  showRunCode,
  handleRunCode,
  prefixId
}) => {
  // States definition
  const [animationState, setAnimationState] = useState<number>(0);

  const runCodeAnimation = () => {
    // Set the new state of runCodePressed
    setAnimationState(1);

    // Check the handleRunCode is defined, then execute
    if (handleRunCode !== undefined) {
      handleRunCode();
    }

    // After finish the run code, show the successful animation
    setAnimationState(2)

    // Return to the original state after 2 seconds
    const timer = setTimeout(() => {
      setAnimationState(0)
    }, 2000);

    // And clear the timer
    return () => clearTimeout(timer);
  };

  return (
    <div className={showRunCode ? "top-0" : "top-0 invisible"}>
      <button
        className="flex relative justify-center items-center w-11 h-11 bg-dc-secondary-600 hover:bg-dc-secondary-700 ease-in-out duration-150 rounded-full cursor-pointer"
        onClick={runCodeAnimation}
        title="Run code"
        id={`${prefixId}-run-code`}
      >
        
        <Transition
          show={animationState === 0}
          enter="transition-opacity ease-out duration-300"
          enterFrom="opacity-0 translate-y-1/2"
          enterTo="opacity-100 translate-y-0"
          leave="transition-opacity duration-150"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <PlayIcon colour="neutral-50" />
        </Transition>
        <Transition
          as={'div'}
          show={animationState === 1}
          enter="transition-opacity ease-out duration-300"
          enterFrom="opacity-0 translate-y-1/2"
          enterTo="opacity-100 translate-y-0"
          leave="transition-opacity duration-150"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
          className='absolute flex justify-center items-center w-full h-full'
        >
          <div className="absolute flex justify-center items-center w-full h-full">
            <motion.div
              className="w-9 h-9 border-[3px] rounded-full border-neutral-50 border-b-transparent"
              animate={{ rotate: 360 }}
              transition={{
                repeat: Infinity,
                duration: 2,
                ease: "linear",
              }}
            />
          </div>
          <FaStopCircle className="text-neutral-50 w-6 h-6" />
        </Transition>
        <Transition
          as='div'
          show={animationState === 2}
          enter="transition-opacity ease-out duration-300"
          enterFrom="opacity-0 translate-y-1/2"
          enterTo="opacity-100 translate-y-0"
          leave="transition-opacity duration-300"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
          className='absolute flex justify-center items-center w-full h-full'
          >
          <div className="absolute flex justify-center items-center w-full h-full">
          <motion.div
            className="w-9 h-9 border-[3px] rounded-full border-neutral-50"
            transition={{
              repeat: 1,
              duration: 2,
              ease: "linear",
            }}
          />
        </div>
        <FaCheck className="text-neutral-50 w-6 h-6"/>
        </Transition>
      </button>
    </div>
  );
};

export default RunCodeButton;
