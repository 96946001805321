import error404logo from "assets/imgs/error404.svg";
import { Link } from "react-router-dom";

const RouteError = () => {
  return (
    <div className="w-screen h-screen flex-col justify-center items-center gap-14 inline-flex">
      <img className="w-[469px] h-[312px] relative" src={error404logo} />
      <div className="w-fit h-fit flex-col justify-start items-center gap-10 flex">
        <div className="self-stretch h-fit flex-col justify-start items-center gap-3 flex">
          <div className="self-stretch text-center text-neutral-700 text-[23px] font-semibold font-['Poppins'] leading-7">
            Oops! This Page Seems to Have Wandered Off
          </div>
          <div className="self-stretch text-center text-neutral-700 text-base font-normal font-['Poppins'] leading-[19px]">
            Don't worry, our team is on the case. Let's get you back on track.
          </div>
        </div>
        <div className="self-stretch justify-center items-center gap-2 inline-flex">
          <Link to="/home">
            <div className="w-[452px] h-[39px] px-[18px] py-2.5 bg-dc-secondary-600 hover:bg-dc-secondary-700 ease-in-out duration-150 rounded justify-center items-center gap-2 flex cursor-pointer">
              <div className="text-white text-base font-semibold font-['Poppins'] leading-[19px]">
                Go to Dashboard
              </div>
            </div>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default RouteError;
