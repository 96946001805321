import ClassesTabPopUp from "components/main-view/classes/utils/ClassesTabPopUp";
import { BACKEND_URL } from "config/getUrl";
import { MdArrowDropDown } from "react-icons/md";
import { LoadingDots } from "utils/loadingAnimation";
import { SchoolListType } from "utils/interfaces";
import { useContext, useEffect, useState } from "react";
import { ViewHeaderContext } from "../Contexts";
import { AnimatePresence, motion } from "framer-motion";

interface SchoolsDropdownProps {
  showSchoolListTab: boolean;
  schoolData: SchoolListType[];
  userSchool?: SchoolListType | null;
  setUserSchool?: React.Dispatch<React.SetStateAction<SchoolListType | null>>;
  setShowSchoolListTab: React.Dispatch<React.SetStateAction<boolean>>;
}

const SchoolsDropdown: React.FC<SchoolsDropdownProps> = ({
  showSchoolListTab,
  schoolData,
  userSchool,
  setUserSchool,
  setShowSchoolListTab,
}) => {
  // Get the panel width
  const { format } = useContext(ViewHeaderContext);

  return (
    <motion.div
      variants={{
        shrinked: { width: "fit-content", minWidth: "fit-content" },
        expanded: { width: 250 },
      }}
      initial={false}
      animate={format}
      transition={{ ease: "easeInOut" }}
      tabIndex={0}
      className={`max-w-[250px] w-[250px] self-stretch grow shrink px-3 py-1 bg-white rounded-full justify-between text-start items-center gap-2 flex ${
        showSchoolListTab ? "" : "cursor-pointer"
      } relative`}
      onClick={() => setShowSchoolListTab(!showSchoolListTab)}
    >
      <div className="w-6 h-6 min-w-6 rounded-full border border-Subtle justify-start items-end gap-2 flex overflow-hidden">
        {
          <img
            alt=""
            src={`${BACKEND_URL}/imgs/organisations/logo/${userSchool?._id}`}
            onError={({ currentTarget }) => {
              currentTarget.onerror = null;
              currentTarget.src = "/logo.svg";
            }}
            className="w-full h-full"
          />
        }
      </div>
      <AnimatePresence mode="popLayout">
        {format === "expanded" && (
          <motion.div
            initial={{ opacity: 0, width: 0 }}
            animate={{ opacity: 1, width: "100%" }}
            exit={{ opacity: 0, width: 0 }}
            transition={{ duration: 0.3, ease: "linear" }}
            className="max-w-[180px] text-neutral-500 text-sm text-center font-semibold font-sans leading-[17px] overflow-hidden break-keep whitespace-nowrap text-ellipsis"
          >
            {userSchool && userSchool.name ? (
              userSchool.name
            ) : (
              <LoadingDots bgColor="bg-neutral-500" />
            )}
          </motion.div>
        )}
      </AnimatePresence>
      <div className="w-4 h-4 relative">
        <MdArrowDropDown className="w-full h-full fill-neutral-700" />
      </div>
      {showSchoolListTab && (
        <ClassesTabPopUp
          schoolData={schoolData}
          setShowSchoolListTab={setShowSchoolListTab}
          setUserSchool={setUserSchool}
        />
      )}
    </motion.div>
  );
};

export default SchoolsDropdown;
