import React from "react";
import {
  MdOutlineAutoStories,
  MdOutlineBallot,
  MdOutlineCollectionsBookmark,
  MdOutlineFeaturedPlayList,
} from "react-icons/md";

interface CreateNewPopUpProps {
  setShowCreateNewPopUp: React.Dispatch<React.SetStateAction<boolean>>;
  onCreateFunction: (lesson: "programs" | "courses" | "projects" | "modules") => void;
}

const CreateNewPopUp: React.FC<CreateNewPopUpProps> = ({
  setShowCreateNewPopUp,
  onCreateFunction,
}) => {
  return (
    <>
      <div
        className="fixed top-0 left-0 w-screen h-screen bg-transparent z-20"
        onClick={() => setShowCreateNewPopUp(false)}
      />
      <div
        className="absolute w-[154px] top-[90px] right-2 p-3 bg-white rounded-lg shadow flex-col justify-center items-center gap-2 inline-flex z-30"
      >
        <div
          className="w-[138px] p-3 bg-white rounded-lg justify-start items-center gap-2 inline-flex text-neutral-700 hover:bg-neutral-700 hover:text-white cursor-pointer"
          onClick={() => onCreateFunction("programs")}
        >
          <div className="w-6 h-6 relative fill-neutral-700">
            <MdOutlineFeaturedPlayList className="w-full h-full" />
          </div>
          <div className="text-base font-normal font-sans leading-7">
            Program
          </div>
        </div>
        <div
          className="w-[138px] p-3 bg-white rounded-lg justify-start items-center gap-2 inline-flex text-neutral-700 hover:bg-neutral-700 hover:text-white cursor-pointer"
          onClick={() => onCreateFunction("courses")}
        >
          <div className="w-6 h-6 relative fill-neutral-700">
            <MdOutlineCollectionsBookmark className="w-full h-full" />
          </div>
          <div className="text-base font-normal font-sans leading-7">
            Course
          </div>
        </div>
        <div
          className="w-[138px] p-3 bg-white rounded-lg justify-start items-center gap-2 inline-flex text-neutral-700 hover:bg-neutral-700 hover:text-white cursor-pointer"
          onClick={() => onCreateFunction("projects")}
        >
          <div className="w-6 h-6 relative fill-neutral-700">
            <MdOutlineAutoStories className="w-full h-full" />
          </div>
          <div className="text-base font-normal font-sans leading-7">
            Project
          </div>
        </div>
        <div
          className="w-[138px] p-3 bg-white rounded-lg justify-start items-center gap-2 inline-flex text-neutral-700 hover:bg-neutral-700 hover:text-white cursor-pointer"
          onClick={() => onCreateFunction("modules")}
        >
          <div className="w-6 h-6 relative fill-neutral-700">
            <MdOutlineBallot className="w-full h-full" />
          </div>
          <div className="text-base font-normal font-sans leading-7">
            Module
          </div>
        </div>
      </div>
    </>
  );
};

export default CreateNewPopUp;
