/**
 * Function used to transform the length of the video
 * into the estimated time according to the definitions
 * @param time
 * @returns 
 */
const processEstTime = (time: number) => {
  // Define a constant "x" for the estimation
  const mult = 4;

  const estTime = mult * time;

  // Define the minutes and seconds
  let minutes = Math.floor(estTime / 60);
  const seconds = estTime % 60;

  // Approximmate
  if (seconds >= 30) return minutes + 1
  else return minutes;
}

export default processEstTime;