import { ImperativePanelGroupHandle } from "react-resizable-panels";

/*
 * smooth resize the console size when closing either console or editor
 */
const smoothResize = (
  targetSizes: number[],
  currentSizes: number[],
  splitLayourRef: React.RefObject<ImperativePanelGroupHandle>,
  duration = 100
) => {
  const startTime = performance.now();
  var initialSizes: number[];
  initialSizes = [...currentSizes];

  const updateSizes = (currentTime: number) => {
    const elapsedTime = currentTime - startTime;
    const fraction = Math.min(elapsedTime / duration, 1);

    const newSizes = initialSizes.map((size, index) => {
      const distance = targetSizes[index] - size;
      return size + distance * fraction;
    });

    // Set the states
    if (splitLayourRef.current) splitLayourRef.current.setLayout(newSizes)//;(newSizes);

    // Continue the animation
    if (fraction < 1) {
      requestAnimationFrame(updateSizes);
    }
  };

  // Start the animation
  requestAnimationFrame(updateSizes);
};

export default smoothResize;
