import { MdTrendingDown, MdTrendingFlat, MdTrendingUp } from "react-icons/md";

// Definition of the percentage limits
const percLimits = [33, 66];

/**
 * Function used to get the status icon based on the score of completed
 * quizzes, defined as a percentage
 * @param percentage: Number between 0 and 100
 * @returns: String representing the color asociated to each status
 */
export const getProgressBarColor = (percentage: number): string => {
  if (percentage < percLimits[0]) return "bg-error-400";
  if (percentage >= percLimits[0] && percentage < percLimits[1])
    return "bg-warning-400";
  return "bg-success-400";
};

/**
 * Function used to get the status icon based on the score of completed
 * quizzes, defined as a percentage
 * @param percentage: Number between 0 and 100
 * @returns: String representing the color asociated to each status
 */
export const getProgressBarColorRaw = (percentage: number): string => {
  if (percentage < percLimits[0]) return "#F27979";
  if (percentage >= percLimits[0] && percentage < percLimits[1])
    return "#F9BF5C";
  return "#33EDB0";
};

/**
 * Function used to get the status icon based on the score of completed
 * quizzes, defined as a percentage
 * @param percentage: Number between 0 and 100
 * @returns: Icon indicating the status of the student/class
 */
export const getStatusIcon = (percentage: number) => {
  if (percentage < percLimits[0])
    return <MdTrendingDown className="w-full h-full fill-error-400" />;
  else if (percentage >= percLimits[0] && percentage < percLimits[1])
    return <MdTrendingFlat className="w-full h-full fill-warning-400" />;
  else return <MdTrendingUp className="w-full h-full fill-success-400" />;
};

/**
 * Function used to get the status text based on the score of completed
 * quizzes, defined as a percentage
 * @param percentage: Number between 0 and 100
 * @returns: String representing the status of the student/class
 */
export const getIconText = (percentage: number) => {
  if (percentage < percLimits[0]) return "Emerging";
  else if (percentage >= percLimits[0] && percentage < percLimits[1])
    return "Progressing";
  else return "Excelling";
};

export const calculateStatus = (
  enrolmentRate: number,
  averageProgress: number,
  aptitude: number
): string => {
  const sum = enrolmentRate + averageProgress + aptitude;
  if (sum > 150) return "Excellent";
  else if (sum > 100) return "Progressing";
  else return "Emerging";
};
