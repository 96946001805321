import React, { useState, useRef } from "react";

interface VideoPIPProps {
  className?: string;
  isOpen: boolean;
  onClose: () => void;
  videoUrl: string;
  videoTitle: string;
  width: number;
  iframeRef: React.RefObject<HTMLIFrameElement>;
}

const Video: React.FC<VideoPIPProps> = ({
  iframeRef,
  className = "",
  isOpen,
  onClose,
  videoUrl,
  videoTitle,
  width,
}) => {
  // To be improved: change for a regex expression
  if (!isOpen || !videoUrl.includes("vimeo.com/")) return null;

  const embedUrl = videoUrl.replace("vimeo.com/", "player.vimeo.com/video/");

  return (
    <div className={className}>
      <iframe
        ref={iframeRef}
        title="video"
        src={embedUrl}
        allow="autoplay; fullscreen; picture-in-picture"
        style={{ width: `${width}px`, height: `${(width * 9) / 16}px` }}
      ></iframe>
    </div>
  );
};

export default Video;
