import { MdCheckCircle } from "react-icons/md";
import { capitaliseFirstChar } from "utils/utils";

interface SavedSuccessfulPanelProps {
  selectedLesson: string;
  setShowPopUp: React.Dispatch<React.SetStateAction<boolean>>;
  headerRef?: React.MutableRefObject<any>;
}

const SavedSuccessfulPanel: React.FC<SavedSuccessfulPanelProps> = ({
  selectedLesson,
  setShowPopUp,
  headerRef
}) => {
  // Function for "Create another" option
  const handleCreateAnother = () => {
    // First of all, close the pop up
    setShowPopUp(false);
    
    // Check if the header reference is included
    if (!headerRef) return;

    switch(selectedLesson){
      case "programs":
        headerRef.current.lessons().pressCreateNewProgram();
        break;
      case "courses":
        headerRef.current.lessons().pressCreateNewCourse();
        break;
      case "projects":
        headerRef.current.lessons().pressCreateNewProject();
        break;
      case "modules":
        headerRef.current.lessons().pressCreateNewModule();
        break;
      default:
        break;
    }
  };

  return (
    <>
      <div className="self-stretch px-3.5 py-9 justify-between items-start inline-flex">
        <div className="w-8 h-8 p-[7.21px] opacity-0 justify-center items-center flex" />
        <div className="w-[108px] h-[108px] relative">
          <MdCheckCircle className="w-full h-full fill-success-500" />
        </div>
        <div className="w-8 h-8 p-[7.21px] opacity-0 justify-center items-center flex" />
      </div>
      <div className="h-[332px] flex-col justify-start items-start gap-6 flex">
        <div className="self-stretch h-48 flex-col justify-start items-start gap-6 flex">
          <div className="self-stretch h-7 flex-col justify-start items-start gap-3 flex">
            <div className="self-stretch justify-start items-start gap-2 inline-flex">
              <div className="grow shrink basis-0 text-neutral-700 text-[23px] font-semibold font-sans leading-7">
                {capitaliseFirstChar(selectedLesson.slice(0, -1))} saved
                successfully
              </div>
            </div>
          </div>
          <div className="self-stretch justify-start items-start gap-2 inline-flex">
            <div className="grow shrink basis-0 text-neutral-700 text-base font-normal font-sans leading-7">
              The {selectedLesson.slice(0, -1)} details you entered have been
              saved without a hitch.
            </div>
          </div>
          <div className="self-stretch justify-start items-start gap-2 inline-flex">
            <div className="grow shrink basis-0 text-neutral-700 text-base font-normal font-sans leading-7">
              This is a significant milestone in crafting a cohesive and
              engaging learning experience.
            </div>
          </div>
        </div>
        <div
          className="self-stretch h-[46px] px-5 py-3 bg-dc-secondary-600 hover:bg-dc-secondary-700 ease-in-out duration-100 rounded justify-center items-center gap-2 inline-flex cursor-pointer"
          onClick={handleCreateAnother}
        >
          <div className="text-white text-lg font-semibold font-sans leading-snug">
            Create another
          </div>
        </div>
        <div className="self-stretch h-[46px] px-5 py-3 rounded justify-center items-center gap-2 inline-flex">
          <div
            className="text-dc-secondary-600 hover:underline text-lg font-semibold font-sans leading-snug cursor-pointer"
            onClick={() => setShowPopUp(false)}
          >
            Close
          </div>
        </div>
      </div>
    </>
  );
};

export default SavedSuccessfulPanel;
