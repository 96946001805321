import { platform } from "config/platform";
import {
  MdOutlineArrowCircleLeft,
  MdRadioButtonChecked,
  MdRadioButtonUnchecked,
} from "react-icons/md";

interface AdditionalDetailsProps {
  isAboriginalOrTorresStraitIslander: boolean;
  notes: string;
  setIsAboriginalOrTorresStraitIslander: React.Dispatch<
    React.SetStateAction<boolean>
  >;
  setNotes: React.Dispatch<React.SetStateAction<string>>;
  setStage: React.Dispatch<React.SetStateAction<number>>;
  handleSave: () => void;
}

const AdditionalDetails: React.FC<AdditionalDetailsProps> = ({
  isAboriginalOrTorresStraitIslander,
  notes,
  setIsAboriginalOrTorresStraitIslander,
  setNotes,
  setStage,
  handleSave,
}) => {
  // Define handle previous button
  const handlePrev = () => {
    setStage(2);
  };

  return (
    <div className="w-[562px] h-full p-6 bg-white rounded-3xl border-neutral-200 justify-start items-start gap-2 inline-flex">
      <div className="w-full flex-col justify-start items-start gap-12 inline-flex">
        <div className="self-stretch h-7 flex-col justify-start items-start flex text-neutral-700 text-[23px] font-semibold font-sans leading-7">
          Additional details
        </div>
        {platform === "deadly-coders" && <div className="self-stretch h-fit flex-col justify-start items-start gap-3 flex">
          <div className="rounded justify-start items-start gap-2 inline-flex">
            <div className="text-neutral-500 text-xs font-normal font-sans uppercase leading-[14px] tracking-wide">
              Are you of Aboriginal or Torres Strait Islander origin?*
            </div>
          </div>
          <div className="w-fit h-fit flex-col justify-start items-start gap-2 flex">
            <button
              className="group self-stretch rounded-lg justify-start items-center gap-3 inline-flex cursor-pointer"
              onClick={() => setIsAboriginalOrTorresStraitIslander(true)}
            >
              <div className="w-6 h-6 relative">
                {isAboriginalOrTorresStraitIslander ? (
                  <MdRadioButtonChecked className="w-full h-full fill-neutral-700 group-hover:fill-neutral-400" />
                ) : (
                  <MdRadioButtonUnchecked className="w-full h-full fill-neutral-700 group-hover:fill-neutral-400" />
                )}
              </div>
              <div className="grow shrink basis-0 h-fit py-1 justify-start items-center flex text-neutral-700 text-base font-normal font-sans leading-[19px]">
                Yes
              </div>
            </button>
            <button
              className="group self-stretch rounded-lg justify-start items-center gap-3 inline-flex cursor-pointer"
              onClick={() => setIsAboriginalOrTorresStraitIslander(false)}
            >
              <div className="w-6 h-6 relative">
                {!isAboriginalOrTorresStraitIslander ? (
                  <MdRadioButtonChecked className="w-full h-full fill-neutral-700 group-hover:fill-neutral-400" />
                ) : (
                  <MdRadioButtonUnchecked className="w-full h-full fill-neutral-700 group-hover:fill-neutral-400" />
                )}
              </div>
              <div className="grow shrink basis-0 h-fit py-1 justify-start items-center flex text-neutral-700 text-base font-normal font-sans leading-[19px]">
                No
              </div>
            </button>
          </div>
        </div>}
        <div className="w-full h-fit flex-col justify-start items-start gap-1.5 flex">
          <div className="rounded justify-start items-start inline-flex text-neutral-500 text-xs font-normal font-sans uppercase leading-[14px] tracking-wide">
            Additional notes (optional)
          </div>
          <div className="self-stretch grow shrink basis-0 bg-neutral-50 rounded-lg flex-col justify-start items-start flex">
            <div className="w-full h-[151px] justify-start items-start gap-2 inline-flex">
              <textarea
                value={notes}
                onChange={(event) => setNotes(event.target.value)}
                className="p-3 grow basis-0 self-stretch bg-neutral-50 text-neutral-700 text-base font-normal font-sans leading-[19px] resize-none placeholder:text-neutral-300 focus:outline-none custom-scroll"
                placeholder="Please enter any additional notes"
              />
            </div>
          </div>
        </div>
        <div className="self-stretch h-[46px] justify-between items-center inline-flex">
          <button
            className="group rounded justify-center items-center gap-2 flex cursor-pointer"
            onClick={handlePrev}
          >
            <div className="w-6 h-6 relative">
              <MdOutlineArrowCircleLeft className="w-full h-full fill-dc-secondary-600 group-hover:fill-dc-secondary-700" />
            </div>
            <div className="text-dc-secondary-600 group-hover:text-dc-secondary-700 text-lg font-semibold font-sans leading-snug">
              Prev
            </div>
          </button>
          <button
            className="w-[219px] h-full px-5 py-3 bg-dc-secondary-600 hover:bg-dc-secondary-700 ease-in-out duration-150 rounded justify-center items-center gap-2 flex cursor-pointer"
            onClick={handleSave}
          >
            <div className="text-white text-lg font-semibold font-sans leading-snug">
              Save and create
            </div>
          </button>
        </div>
      </div>
    </div>
  );
};

export default AdditionalDetails;
