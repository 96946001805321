import EmptyIcon from "assets/icons/admin-view/EmptyIcon";
import OrDivision from "components/main-view/utils/pop-up-panels/add-contact-pop-up/OrDivision";
import { useState } from "react";
import { GoArrowLeft } from "react-icons/go";
import { MdAddCircleOutline } from "react-icons/md";
import SearchAndCardsList, {
  defaultContact,
} from "components/main-view/utils/pop-up-panels/add-contact-pop-up/SearchAndCardsList";
import {
  ContactListType,
  ParentListType,
  SchoolListType,
} from "utils/interfaces";
import ConfirmPrimaryPopUp from "components/main-view/utils/pop-up-panels/add-contact-pop-up/ConfirmPrimaryPopUp";
import CreateNewContact from "components/main-view/utils/pop-up-panels/add-contact-pop-up/CreateNewContact";
import SuccessPopUpMessage from "components/main-view/utils/pop-up-panels/SuccessPopUpMessage";

interface AddParentPanelProps {
  contextSchool?: SchoolListType | null;
  selectedData?: ParentListType;
  setShowPopUp: React.Dispatch<React.SetStateAction<boolean>>;
  selectedContacts: ContactListType[];
  setSelectedContacts: React.Dispatch<React.SetStateAction<ContactListType[]>>;
  getData?: () => Promise<any>;
}

const AddParentPanel: React.FC<AddParentPanelProps> = ({
  contextSchool,
  selectedData,
  setShowPopUp,
  selectedContacts,
  setSelectedContacts,
  getData,
}) => {
  // States definition
  const [numberSearch, setNumberSearch] = useState("");
  const [createNewParent, setCreateNewParent] = useState(!!selectedData);
  const [showSuccessPopUp, setShowSuccessPopUp] = useState(false);
  const [showConfirmPopUp, setShowConfirmPopUp] = useState(false);
  const [lastSelectedContact, setLastSelectedContact] =
    useState<ContactListType>(defaultContact);
  const [isLoadingData, setIsLoadingData] = useState(true);

  /**
   * Definition of the function when pressed back button
   */
  const handlePressBack = () => {
    // If create new was pressed, then come back to the regular option
    if (createNewParent) {
      // If we are editing a parent, then close the pop up once clicked
      // the press button
      if (selectedData) setShowPopUp(false);
      else setCreateNewParent(false);
    }
    // Otherwise, close the pop up
    else setShowPopUp(false);
  };

  /**
   * Definition of the function executed after creating a parent
   * in the database
   */
  const afterCreateParent = () => {
    // If editing, just close the pop up
    if (selectedData) {
      // Close the pop up
      setShowPopUp(false);
      // And refresh the list of teachers
      if (getData) getData();
    } else {
      // Come back to the regular view
      setCreateNewParent(false);
      // And display the confirm pop up
      // And display the confirm primary contact pop up
      if (selectedContacts.length > 0) {
        setShowConfirmPopUp(true);
      }
    }
  };

  return (
    <div
      className={
        createNewParent
          ? "w-full h-full flex-col justify-start items-start gap-8 inline-flex relative"
          : "w-full h-full flex-col justify-start items-start gap-6 inline-flex relative"
      }
    >
      <div className="self-stretch justify-between items-center inline-flex">
        <div className="justify-start items-center gap-3 flex">
          <button
            className="p-1.5 bg-neutral-50 rounded-full backdrop-blur-[32px] justify-center items-center gap-2 flex cursor-pointer hover:bg-neutral-100 ease-in-out duration-150"
            onClick={handlePressBack}
          >
            <div className="w-5 h-5 relative">
              <GoArrowLeft className="w-full h-full fill-dc-secondary-600" />
            </div>
          </button>
          <div className="text-neutral-600 text-lg font-normal font-sans leading-snug">
            {selectedData
              ? "Edit Parent/Guardian"
              : createNewParent
              ? "Create new Parent/Guardian"
              : "Add Parent/Guardian"}
          </div>
        </div>
        {/* Hide create new button when creating parent */}
        {!createNewParent && (
          <button
            className="group rounded justify-center items-center gap-2 flex cursor-pointer"
            onClick={() => setCreateNewParent(true)}
          >
            <div className="w-6 h-6 relative">
              <MdAddCircleOutline className="w-full h-full fill-dc-secondary-600 group-hover:fill-dc-secondary-700" />
            </div>
            <div className="text-dc-secondary-600 group-hover:text-dc-secondary-700 text-lg font-semibold font-sans leading-snug">
              Create new
            </div>
          </button>
        )}
      </div>
      {/* Regular view when not creating parents */}
      {!createNewParent && (
        <div
          className={
            numberSearch || selectedContacts.length > 0
              ? "w-full h-full flex-col justify-start items-start gap-8 inline-flex overflow-hidden"
              : "w-full h-full flex-col justify-center items-center gap-8 inline-flex overflow-hidden"
          }
        >
          {
            <>
              {!numberSearch &&
                !isLoadingData &&
                selectedContacts.length === 0 && (
                  <>
                    <div className="self-stretch h-fit flex-col justify-start items-center gap-10 flex">
                      <div className="w-[244px] h-[183px] relative">
                        <EmptyIcon width="244" height="183" />
                      </div>
                      <div className="self-stretch h-fit flex-col justify-start items-center gap-3 flex">
                        <div className="self-stretch text-center text-neutral-600 text-lg font-semibold font-sans leading-snug">
                          This area is empty
                        </div>
                        <div className="self-stretch text-center text-neutral-700 text-sm font-normal font-sans leading-[17px]">
                          Just click on the "Create New" button, and let's get
                          started
                        </div>
                      </div>
                    </div>
                    <OrDivision />
                  </>
                )}
              <SearchAndCardsList
                contextSchool={contextSchool}
                role="parent"
                numberSearch={numberSearch}
                showSuccessPopUp={showSuccessPopUp}
                lastSelectedContact={lastSelectedContact}
                isLoadingData={isLoadingData}
                setNumberSearch={setNumberSearch}
                selectedContacts={selectedContacts}
                setSelectedContacts={setSelectedContacts}
                setShowSuccessPopUp={setShowSuccessPopUp}
                setLastSelectedContact={setLastSelectedContact}
                setShowConfirmPopUp={setShowConfirmPopUp}
                setIsLoadingData={setIsLoadingData}
              />
            </>
          }
        </div>
      )}
      {createNewParent && (
        <CreateNewContact
          role="parent"
          contextSchool={contextSchool}
          contactToEdit={selectedData as ContactListType}
          selectedContacts={selectedContacts}
          confirmButtonText={
            selectedData ? "Save Parent/Guardian" : "Create Parent/Guardian"
          }
          // Cancel the primary option when editing a parent
          showPrimaryOption={!selectedData}
          setSelectedContacts={setSelectedContacts}
          afterCreateEditContact={afterCreateParent}
          setLastSelectedContact={setLastSelectedContact}
        />
      )}
      <ConfirmPrimaryPopUp
        showPopUp={showConfirmPopUp}
        selectedContacts={selectedContacts}
        setShowPopUp={setShowConfirmPopUp}
        setSelectedContacts={setSelectedContacts}
        setShowSuccessPopUp={setShowSuccessPopUp}
      />
      <SuccessPopUpMessage
        role="parent"
        name={
          lastSelectedContact.firstName + " " + lastSelectedContact.lastName
        }
        showSuccessPopUp={showSuccessPopUp}
        setShowSuccessPopUp={setShowSuccessPopUp}
        dismissAction={() => {}}
      />
    </div>
  );
};

export default AddParentPanel;
