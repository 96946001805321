import InputBadgeImage from "components/main-view/utils/placeholders/InputBadgeImage";
import ContactCard from "components/main-view/utils/pop-up-panels/add-contact-pop-up/ContactCard";
import { useEffect, useState } from "react";
import {
  MdAdd,
  MdAddCircleOutline,
  MdOutlineAccountBalanceWallet,
  MdOutlineArrowCircleRight,
  MdOutlinePersonAddAlt,
  MdRemove,
} from "react-icons/md";
import { ContactListType } from "utils/interfaces";
import PopUpPanel from "../pop-up-panels/PopUpPanel";

interface BasicDetailsProps {
  userRole: string;
  schoolName: string;
  logo: string | null;
  assignedLicenses: number;
  selectedContacts: ContactListType[];
  setSchoolName: React.Dispatch<React.SetStateAction<string>>;
  setLogo: React.Dispatch<React.SetStateAction<string | null>>;
  setAssignedLicenses: React.Dispatch<React.SetStateAction<number>>;
  setSelectedContacts: React.Dispatch<React.SetStateAction<ContactListType[]>>;
  setStage: React.Dispatch<React.SetStateAction<number>>;
}

const BasicDetails: React.FC<BasicDetailsProps> = ({
  userRole,
  schoolName,
  logo,
  assignedLicenses,
  selectedContacts,
  setSchoolName,
  setLogo,
  setAssignedLicenses,
  setSelectedContacts,
  setStage,
}) => {
  // States definition
  const [nextAvailable, setNextAvailable] = useState(true);
  const [showAddContactsPopUp, setShowAddContactsPopUp] = useState(false);
  const [showEditContact, setShowEditContact] = useState(false);
  // State for edit contact
  const [selectedData, setSelectedData] = useState<ContactListType | null>(
    null
  );

  /**
   * Condition to activate the next button
   */
  useEffect(() => {
    setNextAvailable(!!schoolName && selectedContacts.length > 0);
  }, [schoolName, selectedContacts]);

  /**
   * Go to the second stage
   */
  const handleNext = () => {
    if (nextAvailable) setStage(2);
  };

  /**
   * Function to apply when click on contacts
   */
  const handleClickAddContacts = () => {
    setShowAddContactsPopUp(true);
  };

  return (
    <div className="w-[562px] h-full p-6 rounded-3xl justify-start items-start gap-2 inline-flex">
      <div className="w-full flex-col justify-start items-start gap-12 inline-flex">
        <div className="self-stretch h-fit flex-col justify-start items-start gap-12 flex">
          <div className="self-stretch text-neutral-700 text-[23px] font-semibold font-sans leading-7">
            Basic details
          </div>
        </div>
        <div className="self-stretch h-fit flex-col justify-start items-start gap-5 flex">
          <div className="self-stretch text-neutral-500 text-base font-semibold font-sans leading-[19px]">
            School details
          </div>
          <InputBadgeImage
            text="Upload a school logo image"
            badgeImage={logo}
            setBadgeImage={setLogo}
          />
          <div className="self-stretch h-fit flex-col justify-start items-start gap-1.5 flex">
            <div className="text-neutral-500 text-xs font-normal font-sans uppercase leading-[14px] tracking-wide">
              School Name*
            </div>
            <div className="self-stretch bg-neutral-50 rounded-lg justify-start items-center">
              <input
                type="text"
                value={schoolName}
                onChange={(event) => setSchoolName(event.target.value)}
                className="w-full h-full p-3 text-neutral-700 placeholder:text-neutral-300 text-base font-sans leading-[19px] bg-transparent border-none focus:outline-none"
                placeholder="Enter school name"
              />
            </div>
          </div>
          <div
            className={`flex-col justify-start items-start flex ${
              ["customer admin", "class admin"].includes(userRole) ? "gap-3" : "gap-1.5"
            }`}
          >
            <div className="self-stretch h-fit flex-col justify-start items-start flex">
              <div className="text-neutral-500 text-xs font-normal font-sans uppercase leading-[14px] tracking-wide">
                Assigned Licenses*
              </div>
              {["customer admin", "class admin"].includes(userRole) && (
                <div className="self-stretch py-1 justify-center items-center gap-2 inline-flex">
                  <div className="h-6 justify-center items-center gap-2 flex">
                    <div className="w-6 h-6 relative">
                      <MdOutlineAccountBalanceWallet className="w-full h-full fill-neutral-500" />
                    </div>
                  </div>
                  <div className="grow shrink basis-0 text-neutral-700 text-lg font-semibold font-sans leading-snug">
                    {assignedLicenses}
                  </div>
                </div>
              )}
            </div>
            {userRole === "master admin" && (
              <div className="justify-center items-center gap-3 inline-flex">
                <div
                  className="p-2 bg-neutral-50 hover:bg-neutral-100 ease-in-out duration-150 rounded-full justify-start items-end gap-2 flex cursor-pointer"
                  onClick={() =>
                    setAssignedLicenses(Math.max(0, assignedLicenses - 10))
                  }
                >
                  <div className="w-4 h-4 relative">
                    <MdRemove className="w-full h-full fill-neutral-700" />
                  </div>
                </div>
                <div className="self-stretch bg-neutral-25 rounded-lg justify-center items-center gap-2 flex">
                  <input
                    className="w-[59px] h-full rounded-lg text-center text-neutral-500 text-base bg-transparent font-normal font-sans leading-[19px]"
                    type="text"
                    value={assignedLicenses}
                    onChange={(event) =>
                      setAssignedLicenses(parseInt(event.target.value) || 0)
                    }
                  />
                </div>
                <div
                  className="p-2 bg-neutral-50 hover:bg-neutral-100 ease-in-out duration-150  rounded-full justify-start items-end gap-2 flex cursor-pointer"
                  onClick={() => setAssignedLicenses(assignedLicenses + 10)}
                >
                  <div className="w-4 h-4 relative">
                    <MdAdd className="w-full h-full fill-neutral-700" />
                  </div>
                </div>
              </div>
            )}
            {userRole === "customer admin" && (
              <div
                className="group rounded justify-center items-center gap-2 inline-flex cursor-pointer"
                onClick={() => {}}
              >
                <div className="w-6 h-6 relative">
                  <MdAddCircleOutline className="w-full h-full fill-dc-secondary-600 group-hover:fill-dc-secondary-700" />
                </div>
                <div className="text-dc-secondary-600 group-hover:text-dc-secondary-700 text-lg font-semibold font-sans leading-snug">
                  Request Additional Licenses
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="self-stretch h-fit flex-col justify-start items-start gap-12 flex">
          <div className="self-stretch h-fit flex-col justify-start items-start gap-5 flex">
            <div className="self-stretch h-fit flex-col justify-start items-start gap-3 flex text-neutral-500 text-base font-semibold font-sans leading-[19px]">
              Contact details
            </div>
            {selectedContacts.length === 0 ? (
              <button
                className="w-full px-2 py-3 rounded-lg border border-dashed border-neutral-300 hover:bg-neutral-25 ease-in-out duration-150 justify-center items-center gap-2 inline-flex cursor-pointer"
                onClick={handleClickAddContacts}
              >
                <div className="w-6 h-6 relative">
                  <MdOutlinePersonAddAlt className="w-full h-full fill-neutral-500" />
                </div>
                <div className="text-neutral-500 text-base font-normal font-sans leading-[19px]">
                  Add a contact
                </div>
              </button>
            ) : (
              <>
                {selectedContacts.map(
                  (contact: ContactListType, index: number) => (
                    <ContactCard
                      key={index}
                      contact={contact}
                      selectedContacts={selectedContacts}
                      setSelectedContacts={setSelectedContacts}
                      setSelectedData={setSelectedData}
                      setShowEditContact={setShowEditContact}
                      type="inside-form"
                    />
                  )
                )}
                <div
                    className="group rounded justify-center items-center gap-2 flex cursor-pointer"
                    onClick={handleClickAddContacts}
                  >
                    <div className="w-6 h-6 relative">
                      <MdAddCircleOutline className="w-full h-full fill-dc-secondary-600 group-hover:fill-dc-secondary-700" />
                    </div>
                    <div className="text-dc-secondary-600 group-hover:text-dc-secondary-700 text-lg font-semibold font-sans leading-snug">
                      Add additional contact
                    </div>
                  </div>
              </>
            )}
          </div>
        </div>
        <div className="self-stretch h-fit justify-end items-right inline-flex">
          <button
            className={`group rounded justify-center items-center gap-2 flex ${nextAvailable ? "cursor-pointer" : "cursor-not-allowed"} `}
            onClick={handleNext}
          >
            <div
              className={
                nextAvailable
                  ? "text-dc-secondary-600 group-hover:text-dc-secondary-700 text-lg font-semibold font-sans leading-snug"
                  : "text-neutral-200 text-lg font-semibold font-sans leading-snug"
              }
            >
              Next
            </div>
            <div className="w-6 h-6 relative">
              <MdOutlineArrowCircleRight
                className={
                  nextAvailable
                    ? "w-full h-full fill-dc-secondary-600 group-hover:fill-dc-secondary-700"
                    : "w-full h-full fill-neutral-200"
                }
              />
            </div>
          </button>
        </div>
      </div>
      <PopUpPanel
        type="add-contacts"
        showPopUp={showAddContactsPopUp}
        setShowPopUp={setShowAddContactsPopUp}
        selectedContacts={selectedContacts}
        setSelectedContacts={setSelectedContacts}
      />
      <PopUpPanel
        type="edit-contact"
        selectedData={selectedData}
        showPopUp={showEditContact}
        selectedContacts={selectedContacts}
        setSelectedContacts={setSelectedContacts}
        setShowPopUp={setShowEditContact}
      />
    </div>
  );
};

export default BasicDetails;
