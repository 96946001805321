import { useCallback, useEffect, useState } from "react";
import watermarkImage from "assets/imgs/watermark1.png";
import { Course } from "utils/interfaces";
import { getCollectionRequest } from "services/apiRequests";
import { useNavigate } from "react-router-dom";
import useEmblaCarousel from "embla-carousel-react";
import { EmblaCarouselType } from "embla-carousel";
import DotsNav from "./cards/DotsNav";
import MyLearningJourneyPanel from "./cards/MyLearningJourneyPanel";
import { platform } from "config/platform";

interface MyLearningJourneyProps {}

const MyLearningJourney: React.FC<MyLearningJourneyProps> = () => {
  // States definition
  const [courses, setCourses] = useState<Course[]>([]);
  const [isLoadingData, setIsLoadingData] = useState(false);
  const [cursorType, setCursorType] = useState("cursor-grab");
  // Carousel states
  const [emblaRef, emblaApi] = useEmblaCarousel({ loop: false });
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [scrollSnaps, setScrollSnaps] = useState<number[]>([]);

  // Define a navigation hook
  const navigate = useNavigate();

  /**
   * Get the data everytime the dashboard is rendered
   */
  useEffect(() => {
    getData();
  }, []);

  /**
   * Function to init and update the emblaAPI
   */
  useEffect(() => {
    // Check of the emblaApi is defined
    if (!emblaApi) return;

    onInit(emblaApi);
    onSelect(emblaApi);
    emblaApi.on("reInit", onInit).on("reInit", onSelect).on("select", onSelect);
  }, [emblaApi]);

  /**
   * Function to apply when a navigation dot is clicked
   */
  const onDotButtonClick = useCallback(
    (index: number) => {
      // Check of the emblaApi is defined
      if (!emblaApi) return;

      // Move to the index of interest
      emblaApi.scrollTo(index);
    },
    [emblaApi]
  );

  /**
   * Function used to init the scroll list
   */
  const onInit = useCallback((emblaApi: EmblaCarouselType) => {
    setScrollSnaps(emblaApi.scrollSnapList());
  }, []);

  /**
   * Function used to update the index of the point when a
   * different "tab" is selected
   */
  const onSelect = useCallback((emblaApi: EmblaCarouselType) => {
    setSelectedIndex(emblaApi.selectedScrollSnap());
  }, []);

  /**
   * Function for getting the data
   */
  const getData = useCallback(async () => {
    // First of all, set the isLoading state
    setIsLoadingData(true);

    // If there is a selected data, get the ids to show
    const response = await getCollectionRequest("/api/courses", [], {}, [
      {
        path: "projects",
        select: ["title"],
        options: { sort: { updatedAt: "desc" } },
      },
    ]);

    // Check if the response was right
    if (!response.successful) {
      // Check if it's not successful because of a token expiration
      if (response.logout) {
        navigate("/");
      }

      return;
    }

    // Get the data
    const data = response.content.filter(
      (course: Course) =>
        course && course.projects && course.projects.length > 0
    );

    // Set the state
    setCourses(data);

    // Set state false again
    setIsLoadingData(false);

    return data;
  }, [setIsLoadingData, setCourses, navigate]);

  return (
    <div className="w-full h-[555px] p-6 bg-neutral-25 rounded-3xl flex-col justify-between items-start inline-flex overflow-hidden relative">
      {platform === "deadly-coders" && (
        <img
          className="absolute top-1/2 left-1/2 min-w-[1000px] max-w-[1000px] rotate-45 transform -translate-x-1/2 -translate-y-1/2 opacity-10 select-none"
          src={watermarkImage}
        />
      )}
      <div className="w-full h-full overflow-visible flex-col justify-between items-start inline-flex relative gap-4">
        <div className="w-full h-full flex-col justify-start items-start inline-flex overflow-auto">
          <div className="self-stretch text-neutral-700 text-lg font-normal font-sans leading-snug select-none">
            My Learning Journey
          </div>
          <div
            className={`w-full h-full overflow-x-hidden ${cursorType}`}
            ref={emblaRef}
            onMouseDown={() => setCursorType("cursor-grabbing")}
            onMouseUp={() => setCursorType("cursor-grab")}
          >
            <div className="w-full h-full flex gap-12 touch-pan-y">
              {courses.map((course: Course, index: number) => (
                <MyLearningJourneyPanel key={index} course={course} />
              ))}
            </div>
          </div>
        </div>
        <DotsNav
          selectedIndex={selectedIndex}
          scrollSnaps={scrollSnaps}
          onDotButtonClick={onDotButtonClick}
        />
      </div>
    </div>
  );
};

export default MyLearningJourney;
