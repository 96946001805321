import React, { useEffect, useState } from 'react'
import { QuestionType, QuizListType } from 'utils/interfaces'
import StartQuizView from './utils/StartQuizView';
import { MdCheckCircle, MdInfo, MdOutlineArrowCircleLeft, MdOutlineArrowCircleRight, MdRadioButtonChecked, MdRadioButtonUnchecked } from 'react-icons/md';
import QuizResponse from './utils/QuizResponse';
import { checkQuizAnswer, updateResults } from 'services/resultsRequests';





interface TakeQuizProps {
  quiz: QuizListType;
  // currentQuestion: number;
  exitQuiz: () => void;
  // onBack: () => void;
  // onContinue: () => void;
  // onSubmit: () => void;
  // onRestart: () => void;
}

const TakeQuiz: React.FC<TakeQuizProps> = ({
  quiz,
  // currentQuestion,
  exitQuiz,
  // onBack,
  // onContinue,
  // onSubmit,
  // onRestart,
}) => {
  const [selectedOption, setSelectedOption] = useState<number | undefined>(undefined);
  const [answeredCorrectly, setAnsweredCorrectly] = useState<boolean | undefined>(undefined);
  const [answerHistory, setAnswerHistory] = useState<number[][]>([[]]); // where answerHistory[0] is first answer, answerHistory[0][...] is incorrect answers
  const [reviewingPerformance, setReviewingPerformance] = useState(false);
  const [currentQuestion, setCurrentQuestion] = useState(-1);

  const thisQuestion = (questionIndex: number) => quiz.questions[questionIndex];

  const isCorrectAnswer = (questionIndex: number, responseIndex: number) => {
    const _thisQuestion = thisQuestion(questionIndex);
    if (!_thisQuestion || !_thisQuestion.responses) return false;
    if (responseIndex >= _thisQuestion.responses.length) return false;
    return _thisQuestion.responses.map(response => response.correct)[responseIndex];
  }

  const getQuestionCorrectIndex = (questionIndex: number) => {
    const _thisQuestion = thisQuestion(questionIndex);
    if (!_thisQuestion || !_thisQuestion.responses) return undefined;
    const out = _thisQuestion.responses.map(response => response.correct).indexOf(true);
    return out >= 0 ? out : undefined;
  }

  const checkAnswer = async () => {
    const quizId = quiz._id;
    const questionText = quiz.questions[currentQuestion].title;
    const selectedText = quiz.questions[currentQuestion].responses[selectedOption || 0].text;
    const apiAnswer = await checkQuizAnswer(quizId, questionText, selectedText);
    if (selectedOption === undefined || selectedOption < 0 || selectedOption >= quiz.questions[currentQuestion].responses.length) return;

    if (apiAnswer.correct) {
      setAnsweredCorrectly(true);
      setAnswerHistory(history => [...history, []]);
      if ((quiz.questions ? quiz.questions.length : 0) === currentQuestion + 1) {
        submitAnswers();
      }
    } else {
      setAnswerHistory((history: number[][]) => {
        if (history.length === currentQuestion || history.length === 0) {
          return [
            ...history,
            [selectedOption]
          ];
        }
        let current: number[] = history.pop() || [];
        if (current) {
          current.push(selectedOption);
          history.push(current);
          return history;
        }
        return [[]];
      })
      setSelectedOption(undefined);
      setAnsweredCorrectly(false);
    }
  }

  const nextQuestion = () => {
    if (answerHistory.length === currentQuestion + 3) { // if question answered already
      setAnsweredCorrectly(true);
      setSelectedOption(getQuestionCorrectIndex(currentQuestion+1));
    } else {
      setSelectedOption(undefined);
      setAnsweredCorrectly(undefined);
      // updateResults("quizzes", quiz._id, "finished"); // TODO: REMOVE THIS, THIS SHOULD BE AND THE END OF THE QUIZ>>>>>
    }
    setCurrentQuestion( value => value + 1);
  }

  const startQuiz = () => {
    updateResults("quizzes", quiz._id, "ongoing");
    setCurrentQuestion(0);
  }

  const previousQuestion = () => {
    setCurrentQuestion( value => value - 1 );
    setAnsweredCorrectly(true);
    setSelectedOption(getQuestionCorrectIndex(currentQuestion-1));
  }

  const submitAnswers = () => {
    // todo: post data to backend....
  }

  const retakeQuiz = () => {
    setAnsweredCorrectly(undefined);
    setSelectedOption(undefined);
    setAnswerHistory([[]]);
    setReviewingPerformance(false);
    setCurrentQuestion(0);
  }

  const renderQuestion = (questionId: number, quizComplete: boolean = false) => {
    if (questionId < 0 || questionId >= (quiz.questions ? quiz.questions.length : 0)) return <></>;
    const question: QuestionType = quiz.questions[questionId];
    return (
      <>
        <div className="w-[542px] mx-auto h-10 flex-col justify-start items-start gap-1 flex">
          <div className="text-neutral-500 text-xs font-normal uppercase leading-[14px] tracking-wide">Question {questionId+1} of {(quiz.questions ? quiz.questions.length : 0)}</div>
          <div className="h-[22px] flex-col justify-start items-start gap-2 flex">
            <div className="text-neutral-600 text-lg font-normal leading-snug">{question.title}</div>
          </div>
        </div>
        <div className="w-[542px] mx-auto h-56 flex-col justify-start items-start gap-4 flex">
          {question.responses.map((response, index) => {
            return (
              <QuizResponse
                index={index}
                text={response.text}
                status={
                  quizComplete ? (
                    isCorrectAnswer(questionId, index)
                      ? 'correct'
                      : answerHistory[questionId].includes(index)
                        ? 'incorrect'
                        : 'grey-disabled'
                  ): (
                    selectedOption === index
                      ? 'selected'
                      : answerHistory[questionId].includes(index)
                        ? 'grey-disabled'
                        : currentQuestion === answerHistory.length - 1
                          ? 'unselected'
                          : 'disabled'
                  )
                }
                onClick={setSelectedOption}
              />
            );
          })}
        </div>
      </>
    );
  }

  const numCorrect = () => answerHistory.filter((response: number[]) => response.length === 0).length -1;

  return (
    <>
      {currentQuestion === -1 ? (
        <StartQuizView quiz={quiz}
          onStartClick={startQuiz}
          onBackClick={exitQuiz}
        />
      ): !reviewingPerformance ? (
        <div className="w-full h-full mx-auto pb-40 flex-col justify-center items-center gap-12 inline-flex font-['Poppins']">
          {/* pop up message*/}
          {answeredCorrectly === true ? (
            <div className="h-14 p-3 bg-success-100 rounded-lg justify-start items-center gap-3 inline-flex">
              <div className="w-8 h-8 relative">
                <MdCheckCircle className="w-full h-full text-neutral-700"/>
              </div>
              <div className="w-[474px] text-neutral-700 text-base font-semibold leading-[19px]">Good job!</div>
            </div>
          ): answeredCorrectly === false && (
            <div className="h-14 p-3 bg-error-100 rounded-lg justify-start items-center gap-3 inline-flex">
              <div className="w-8 h-8 relative" >
                <MdInfo className="w-full h-full text-neutral-700"/>
              </div>
              <div className="w-[474px] text-neutral-700 text-base font-semibold leading-[19px]">Incorrect answer. Please try again.</div>
            </div>
          )}

          {/* quiz question */}
          <div className="w-[542px] h-[304px] flex-col justify-start items-start gap-10 flex">
            {renderQuestion(currentQuestion)}
          </div>
          {/* navigation & buttons */}
          <div className="w-[542px] h-[46px] justify-between items-center inline-flex">
            {currentQuestion > 0 && (
              <div className="rounded justify-center items-center gap-2 flex select-none cursor-pointer" onClick={previousQuestion}>
                <div className="w-6 h-6">
                  <MdOutlineArrowCircleLeft className="w-full h-full text-dc-secondary-600" />
                </div>
                  <div className="text-dc-secondary-600 text-lg font-semibold leading-snug">Prev</div>
              </div>
            )}
            {answeredCorrectly === true && (
              currentQuestion < (quiz.questions ? quiz.questions.length : 0) - 1 ?
              (
                <div className="h-[46px] px-0 py-3 rounded justify-center items-center gap-2 inline-flex ml-auto mr-0 select-none cursor-pointer" onClick={nextQuestion}>
                  <div className="text-dc-secondary-600 text-lg font-semibold leading-snug">Next</div>
                  <div className="w-6 h-6">
                    <MdOutlineArrowCircleRight className="w-full h-full text-dc-secondary-600" />
                  </div>
                </div>
              ): (
                <div
                  className="h-[46px] px-5 py-3 bg-dc-secondary-600 hover:bg-dc-secondary-700 ease-in-out duration-150 rounded justify-center items-center gap-2 inline-flex ml-auto mr-0 select-none cursor-pointer"
                  onClick={() => setReviewingPerformance(true)}
                >
                  <div className="text-white text-lg font-semibold font-['Poppins'] leading-snug">Review my performance</div>
                </div>
              )
            )}
            {answeredCorrectly !== true && (
              <div
                className={`h-[46px] px-5 py-3 rounded justify-center items-center gap-2 inline-flex ml-auto mr-0 select-none ${
                  selectedOption === undefined
                    ? "bg-Subtle cursor-not-allowed"
                    : "bg-dc-secondary-600 hover:bg-dc-secondary-700 ease-in-out duration-150 cursor-pointer"
                }`}
                onClick={() => selectedOption !== undefined && checkAnswer()}
              >
                <div className={`text-lg font-semibold leading-snug ${selectedOption === undefined ? 'text-neutral-500': 'text-white'}`}>Check answer</div>
              </div>
            )}
          </div>
        </div>
      ): (
        // review answers after finished with quiz
        <div className="w-full h-[calc(100vh-172px)] flex-1 flex flex-col mx-auto justify-center items-center gap-12 font-['Poppins']">
          {/* "subheader" */}
          <div className="flex-col justify-start items-center gap-6 inline-flex">
            <div className="text-center text-neutral-600 text-lg font-semibold font-['Poppins'] leading-snug">
              You answered {numCorrect()} out of {(quiz.questions ? quiz.questions.length : 0)} correctly
            </div> 
            <div className="justify-start items-center gap-6 inline-flex">
              <div className="h-[46px] justify-start items-center gap-6 inline-flex">
                {/* retake quiz */}
                <div
                  className="px-5 py-3 rounded justify-center items-center gap-2 inline-flex cursor-pointer"
                  onClick={retakeQuiz}
                >
                  <div className="text-dc-secondary-600 hover:underline text-lg font-semibold font-['Poppins'] leading-snug">Retake Quiz</div>
                </div>
                {/* continue course */}
                <div
                  className="px-5 py-3 bg-dc-secondary-600 hover:bg-dc-secondary-700 ease-in-out duration-150 rounded justify-center items-center gap-2 inline-flex cursor-pointer"
                  onClick={exitQuiz}
                >
                  <div className="text-white text-lg font-semibold font-['Poppins'] leading-snug">Continue course</div>
                </div>
              </div>
            </div>
          </div>
          {/* questions */}
          <div className="py-0 px-8 mb-12 flex-1 overflow-y-auto custom-scroll flex-col justify-start items-start gap-10 flex">
            {answerHistory.map((wrongAnswers: number[], index: number) => renderQuestion(index, true))}
          </div>
        </div>
      )}
    </>
  )
}

export default TakeQuiz