/**
 * apiRequests.ts
 * -----------------
 * This file contains general functions used to perform
 * the requests to the server.
 */
import Cookies from "js-cookie";
import mongoose from "mongoose";
import { BACKEND_URL } from "config/getUrl";
import { validateClient } from "./authRequests";
import { Message } from "components/assistant/Assistant";

const API_URL = BACKEND_URL;

export const getCollectionRequest = async (
  urlAddress: string,
  fields: string[] = [],
  filter: Object = {},
  populateOptions: mongoose.PopulateOptions[] = [],
  customQuery: { [key: string]: string } = {}
) => {
  // Validate the tokens
  const validateResponse = await validateClient();

  // Check if the user is still connected
  if (!validateResponse.connected) {
    return {
      message: "It was not possible to find an access or refresh token.",
      successful: false,
      logout: true,
    };
  }

  // Get the accessToken
  const accessToken = Cookies.get("accessToken");

  // Check that the access token is not undefined
  if (!accessToken) {
    return {
      message: "It was not possible to find an access token.",
      successful: false,
      logout: true,
    };
  }

  // Sending to the server
  const options: RequestInit = {
    headers: {
      "Authorization": `Bearer ${accessToken}`,
    },
  };

  // Define the field query
  const queryObj: {
    fields?: string;
    filter?: string;
    populateOptions?: string;
  } = { ...customQuery };

  // Transform fields into a string
  const fieldsString = fields.join(" ");
  // Transform filter into a string
  const filterString = JSON.stringify(filter);
  // Transform populate option into string
  const populateString = JSON.stringify(populateOptions);

  if (fieldsString) queryObj.fields = fieldsString;
  if (filterString !== "{}") queryObj.filter = filterString;
  if (populateString !== "[]") queryObj.populateOptions = populateString;

  // Define the field query
  const query = new URLSearchParams(queryObj);

  // Perform the requests
  const response = await fetch(
    `${API_URL}${urlAddress}${query.toString() ? "?" : ""}${query}`,
    options
  );

  // Check if the status of the answer is ok
  if (!(response.status === 200))
    return {
      message: `Request status: ${response.status}`,
      successful: false,
      logout: false,
    };

  // Get the response
  const data = await response.json();

  // Return the data
  return data;
};

export const createRecordRequest = async (
  record: object,
  url: string,
  contentType: string = "application/json"
) => {
  // Validate the tokens
  const validateResponse = await validateClient();

  // Check if the user is still connected
  if (!validateResponse.connected) {
    return {
      message: "It was not possible to find an access or refresh token.",
      successful: false,
      logout: true,
    };
  }

  // Get the accessToken
  const accessToken = Cookies.get("accessToken");

  // Check that the access token is not undefined
  if (!accessToken) {
    return {
      message: "It was not possible to find an access token.",
      successful: false,
      logout: true,
    };
  }

  // Set up the options for the request
  const options = {
    method: "POST",
    headers: {
      "Authorization": `Bearer ${accessToken}`,
      "Content-Type": contentType,
    },
    body: JSON.stringify(record),
  };

  // Post the record to the specified
  const response = await fetch(`${API_URL}${url}`, options);

  // Check if the status of the answer is ok
  if (response.status === 200) {
    // Get the response
    return await response.json();
  }
};

export const updateRecordRequest = async (
  infoToUpdate: object,
  url: string
) => {
  // Validate the tokens
  const validateResponse = await validateClient();

  // Check if the user is still connected
  if (!validateResponse.connected) {
    return {
      message: "It was not possible to find an access or refresh token.",
      successful: false,
      logout: true,
    };
  }

  // Get the accessToken
  const accessToken = Cookies.get("accessToken");

  // Check that the access token is not undefined
  if (!accessToken) {
    return {
      message: "It was not possible to find an access token.",
      successful: false,
      logout: true,
    };
  }

  // Sending to the server
  const options = {
    method: "PUT",
    headers: {
      "Authorization": `Bearer ${accessToken}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(infoToUpdate),
  };

  // Post the user
  const response = await fetch(`${API_URL}${url}`, options);

  // Check if the status of the answer is ok
  if (response.status === 200) {
    // Get the response
    return await response.json();
  }
};

// Populates code editor with AWS files
export const populateEditor = async (
  type: string | undefined,
  moduleId: string
) => {
  // Validate the tokens
  const validateResponse = await validateClient();

  // Check if the user is still connected
  if (!validateResponse.connected) {
    return {
      message: "It was not possible to find an access or refresh token.",
      successful: false,
      logout: true,
    };
  }

  // Get the accessToken
  const accessToken = Cookies.get("accessToken");

  // Check that the access token is not undefined
  if (!accessToken) {
    return {
      message: "It was not possible to find an access token.",
      successful: false,
      logout: true,
    };
  }

  // Sending to the server
  const options: RequestInit = {
    headers: {
      "Authorization": `Bearer ${accessToken}`,
    },
  };
  
  if (type === undefined || (type !== "modules" && type !== "projects")) {
    throw new Error("type must be 'modules' or 'projects'");
  }
  try {
    const response = await fetch(`${API_URL}/api/${type}/${moduleId}/code`, options);

    if (response.status === 200) {
      const data = await response.json();

      // Map files received
      const filesFromApi = data.files.map((file: any) => ({
        name: file.name,
        content: file.content,
      }));

      return {
        title: data.title,
        videoUrl: data.videoUrl,
        files: filesFromApi,
        planName: data.planName,
        language: data.language,
      };
    } else {
      throw new Error(`Failed to fetch courses: ${response.statusText}`);
    }
  } catch (error) {
    console.error("Fetch error:", error);
  }
};


export const getChatMessages = async (
  type: string,
  id: string
): Promise<
  [error: string] |
  [error: undefined, messages: Message[]]
> => {
  // Validate the tokens
  const validateResponse = await validateClient();

  // Check if the user is still connected
  if (!validateResponse.connected) {
    return ["It was not possible to find an access or refresh token."]
  }

  // Get the accessToken
  const accessToken = Cookies.get("accessToken");

  // Check that the access token is not undefined
  if (!accessToken) {
    return ["It was not possible to find an access token."];
  }

  const editorSession = process.env.REACT_APP_DEMO
    ? Cookies.get("editorSession") || ""
    : "";

  // Sending to the server
  const options: RequestInit = {
    method: "POST",
    headers: {
      "Authorization": `Bearer ${accessToken}`,
      "Content-Type": "application/json"
    },
    body: JSON.stringify({
      type,
      id,
      session: editorSession
    })
  };

  const response = await fetch(`${BACKEND_URL}/api/assistant/messages`, options);
  if (!response.ok) {
    const error = await response.json();
    console.error(error);
    return [error];
  }
  return [undefined, await response.json()];
}