/**
 * resetPasswordValidation.ts
 * -----------------
 * Contains all validation settings for each required entry
 * in the form. It follows the "react-hook-form" register structure.
 * For more information, visit: https://react-hook-form.com/docs/useform/register
 */
import { emailRegex } from "utils/regex";

export const emailValidation = {
  required: {
    value: true,
    message: "Please enter a valid email address",
  },
  pattern: {
    value: emailRegex,
    message: "This is not a valid email format.",
  },
};

