import { useEffect, useState } from "react";
import {
  MdBolt,
  MdCheckBox,
  MdCheckBoxOutlineBlank,
  MdOutlineQuiz,
} from "react-icons/md";
import { QuizListType } from "utils/interfaces";

interface QuizCheckboxProps {
  index: number;
  quiz: QuizListType;
  selectAction: (index: number, action: "add" | "remove") => void;
  setActivateConfirmChanges: React.Dispatch<React.SetStateAction<boolean>>;
}

const QuizCheckbox: React.FC<QuizCheckboxProps> = ({
  index,
  quiz,
  selectAction,
  setActivateConfirmChanges,
}) => {
  // Parameters definition
  const pointsPerQuestion = 5;

  // States definition
  const [selected, setSelected] = useState(false);

  /**
   * Check if the current id is included in the selected list
   */
  useEffect(() => {
    setSelected(quiz.active);
  }, [quiz.active]);

  /**
   * Function used to operate on click of the checkbox
   */
  const handleOnClick = () => {
    // If it's not currently selected, then add it
    if (!selected) selectAction(index, "add");
    // And if it's currently selected, then remove it
    else selectAction(index, "remove");

    // And activate the ability to do changes
    setActivateConfirmChanges(true);
  };

  return (
    <div
      className={
        selected
          ? "self-stretch p-3 bg-neutral-25 hover:bg-neutral-50 ease-in-out duration-100 rounded-xl border border-neutral-600 justify-start items-center gap-3 inline-flex cursor-pointer"
          : "self-stretch p-3 bg-neutral-25 hover:bg-neutral-50 ease-in-out duration-100 rounded-xl border border-transparent justify-start items-center gap-3 inline-flex cursor-pointer"
      }
      onClick={handleOnClick}
    >
      <div className="grow shrink basis-0 flex-col justify-center items-start gap-2 inline-flex">
        <div className="self-stretch justify-start items-center gap-2 inline-flex">
          <div className="grow shrink basis-0 text-neutral-700 text-sm font-semibold font-sans leading-[17px]">
            {quiz.title}
          </div>
        </div>
        <div className="self-stretch justify-start items-center gap-3 inline-flex">
          <div className="justify-center items-center gap-1 flex">
            <div className="w-4 h-4 relative">
              <MdBolt className="w-full h-full fill-neutral-500" />
            </div>
            <div className="text-neutral-500 text-xs font-normal font-inter leading-[14px]">
              {pointsPerQuestion * (quiz.questions ? quiz.questions.length : 0)}
            </div>
          </div>
          <div className="self-stretch justify-start items-start gap-2 flex">
            <div className="w-px self-stretch bg-neutral-200" />
          </div>
          <div className="grow shrink basis-0 h-4 justify-center items-center gap-1 flex">
            <div className="w-4 h-4 relative">
              <MdOutlineQuiz className="w-full h-full fill-neutral-500" />
            </div>
            <div className="grow shrink basis-0 text-neutral-500 text-xs font-normal font-inter leading-[14px]">
              {(quiz.questions ? quiz.questions.length : 0)}{" "}
              {(quiz.questions ? quiz.questions.length : 0) === 1 ? "question" : "questions"}
            </div>
          </div>
        </div>
      </div>
      <div className="self-stretch relative flex flex-col items-start">
        {selected ? (
          <MdCheckBox className="w-6 h-6 fill-neutral-700" />
        ) : (
          <MdCheckBoxOutlineBlank className="w-6 h-6 fill-neutral-500" />
        )}
      </div>
    </div>
  );
};

export default QuizCheckbox;
