import { Transition } from "@headlessui/react";
import { Fragment } from "react";
import { MdTaskAlt } from "react-icons/md";

interface SuccessPopUpMessageProps {
  type?: "user" | "class";
  role: "parent" | "master admin" |  "customer admin" | "class admin";
  name: string;
  showSuccessPopUp: boolean;
  setShowSuccessPopUp: React.Dispatch<React.SetStateAction<boolean>>;
  dismissAction: () => void;
}

const SuccessPopUpMessage: React.FC<SuccessPopUpMessageProps> = ({
  type = "user",
  role,
  name,
  showSuccessPopUp,
  setShowSuccessPopUp,
  dismissAction,
}) => {
  // Definition of the dismiss button on click
  const handleOnClickDismiss = () => {
    // Undo the action
    dismissAction();

    // And remove the pop up window
    setShowSuccessPopUp(false);
  };

  /**
   * Text used for the output of this message
   * @returns 
   */
  const outputMessage = () => {
    switch (type) {
      case "user":
        return role === "parent"
          ? "Parent/Guardian"
          : role === "customer admin"
          ? "Contact"
          : role === "class admin"
          ? "Teacher"
          : "";
      case "class":
        return "Class";
    }
  };

  return (
    <Transition
      show={showSuccessPopUp}
      as={Fragment}
      enter="transition-opacity ease-out duration-300"
      enterFrom="opacity-0 translate-y-1/2"
      enterTo="opacity-100 translate-y-0"
      leave="transition-opacity duration-300"
      leaveFrom="opacity-100"
      leaveTo="opacity-0"
    >
      <div className="absolute -bottom-3 w-full h-12 p-3 bg-success-500 rounded-2xl justify-start items-center gap-3 inline-flex">
        <div className="grow shrink basis-0 h-6 justify-center items-center gap-1.5 flex">
          <div className="w-6 h-6 relative">
            <MdTaskAlt className="w-full h-full fill-white" />
          </div>
          <div className="grow shrink basis-0 text-white text-sm font-sans leading-[17px]">
            <span className="font-semibold">{name} </span>
            <span className="font-normal">
              has been added as a{" "}
              {outputMessage()}
            </span>
          </div>
        </div>
        <div
          className="px-2 py-1 bg-success-600 hover:bg-success-700 ease-in-out duration-150 rounded justify-center items-center gap-2 flex cursor-pointer"
          onClick={handleOnClickDismiss}
        >
          <div className="text-white text-xs font-normal font-inter uppercase leading-[14px] tracking-wide">
            Dismiss
          </div>
        </div>
      </div>
    </Transition>
  );
};

export default SuccessPopUpMessage;
