import { Transition } from "@headlessui/react";
import { Fragment, useRef, useState } from "react";
import {
  ClassListType,
  ContactListType,
  LocationListType,
  SchoolListType,
} from "utils/interfaces";
import AddContactsPanel from "./add-contact-pop-up/AddContactsPanel";
import AddLocationPanel from "./add-location-pop-up/AddLocationPanel";
import AssignClassPanel from "./assign-class-pop-up/AssignClassPanel";
import SavedSuccessfulPanel from "components/main-view/utils/pop-up-panels/saved-successful-pop-up/SavedSuccessfulPanel";

interface PopUpPanelProps {
  type:
    | "add-contacts"
    | "add-location"
    | "assign-class"
    | "saved-successful"
    | "edit-contact";
  showPopUp: boolean;
  selectedData?: ContactListType | null;
  classes?: ClassListType[];
  selectedContacts?: ContactListType[];
  contextSchool?: SchoolListType | null;
  setShowPopUp: React.Dispatch<React.SetStateAction<boolean>>;
  setClasses?: React.Dispatch<React.SetStateAction<ClassListType[]>>;
  setSelectedContacts?: React.Dispatch<React.SetStateAction<ContactListType[]>>;
  setLocations?: React.Dispatch<React.SetStateAction<LocationListType[]>>;
  setSelectedData?: React.Dispatch<
    React.SetStateAction<ContactListType | null>
  >;
  onCreateAnotherSchool?: () => void;
  headerRef?: React.MutableRefObject<any>;
}

const PopUpPanel: React.FC<PopUpPanelProps> = ({
  type,
  showPopUp,
  selectedData,
  classes,
  selectedContacts,
  contextSchool,
  setShowPopUp,
  setClasses,
  setSelectedContacts,
  setLocations,
  setSelectedData,
  onCreateAnotherSchool,
  headerRef,
}) => {
  // States defininition
  const [showWarningMessage, setShowWarningMessage] = useState(false);

  // Reference for the assign class panel
  const assignClassPanelRef = useRef();

  /**
   * Handle clicking in the black screen
   */
  const handleClickBlackScreen = () => {
    // Prevent closing for these types
    if (type === "assign-class") {
      // Get the current class panel pop up state
      let currentHandler: any;
      if (assignClassPanelRef.current)
        currentHandler = assignClassPanelRef.current;
      const popUpState: "create-new" | "add-teacher" | "main" =
        currentHandler.getPopUpState();
      const showSaveSuccessPopUp: boolean =
        currentHandler.getShowSaveSuccessPopUp();

      // If the pop up state is one of these ones, then don't allow to
      // close when clicking the black screen
      if (
        ["create-new", "add-teacher"].includes(popUpState) &&
        !showSaveSuccessPopUp
      ) {
        // Activate the warning message
        setShowWarningMessage(true);
        // And finish the function
        return;
      }
    }

    // Close the pop up
    setShowPopUp(false);
    // Force close the warning message
    setShowWarningMessage(false);
  };

  return (
    <>
      <Transition
        show={showPopUp}
        as={Fragment}
        enter="transition-opacity ease-out duration-300"
        enterFrom="opacity-0 translate-y-1/2"
        enterTo="opacity-100 translate-y-0"
        leave="transition-opacity duration-150"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <div
          className="fixed inset-0 w-full h-full bg-neutral-500 bg-opacity-40 backdrop-blur-[2px] z-10 flex flex-col flex-grow"
          onClick={handleClickBlackScreen}
        />
      </Transition>
      {/* Add contacts pop-up */}
      <Transition
        show={showPopUp && type === "add-contacts"}
        as={"div"} // Done this way because if we use fragments we won't be able to pass setStates methods.
        className="fixed w-[462px] h-fit left-0 right-0 ml-auto mr-auto bottom-5 p-6 bg-white rounded-3xl flex-col justify-start items-start gap-6 inline-flex z-20 ease-in-out duration-150"
        enter="transition-opacity ease-out duration-300"
        enterFrom="opacity-0 translate-y-1/2"
        enterTo="opacity-100 translate-y-0"
        leave="transition-opacity duration-150"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <AddContactsPanel
          selectedContacts={selectedContacts || []}
          setSelectedContacts={
            setSelectedContacts ? setSelectedContacts : () => {}
          }
          setShowPopUp={setShowPopUp}
        />
      </Transition>
      {/* Add contacts pop-up */}
      <Transition
        show={showPopUp && type === "add-location"}
        as={"div"} // Done this way because if we use fragments we won't be able to pass setStates methods.
        className="fixed w-[451px] h-[90%] max-h-[90%] left-0 right-0 ml-auto mr-auto bottom-5 px-6 pt-6 pb-8 bg-white rounded-3xl flex-col justify-start items-start gap-8 inline-flex z-20"
        enter="transition-opacity ease-out duration-300"
        enterFrom="opacity-0 translate-y-1/2"
        enterTo="opacity-100 translate-y-0"
        leave="transition-opacity duration-150"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <AddLocationPanel
          setLocations={setLocations ? setLocations : () => {}}
          setShowPopUp={setShowPopUp}
        />
      </Transition>
      {/* Saved successful message pop-up */}
      <Transition
        show={showPopUp && type === "saved-successful"}
        as={"div"}
        className="fixed w-[440px] h-fit max-h-[90vh] left-0 right-0 ml-auto mr-auto p-6 bottom-5 bg-white rounded-3xl flex-col justify-start items-center gap-10 inline-flex z-30"
        enter="transition-opacity ease-out duration-300"
        enterFrom="opacity-0 translate-y-1/2"
        enterTo="opacity-100 translate-y-0"
        leave="transition-opacity duration-150"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <SavedSuccessfulPanel
          title="School Added Successfully"
          paragraphs={[
            "The new school has been successfully added to the system.",
            "Feel free to dive in to start customizing the school's profile, setting up classes, and inviting staff, students, and parents to join the platform.",
          ]}
          createText="Add another"
          onCreateAnother={onCreateAnotherSchool ? onCreateAnotherSchool : () => {}}
          onClose={() => setShowPopUp(false)}
        />
      </Transition>
      {/* Assign to class pop-up */}
      <Transition
        show={showPopUp && type === "assign-class"}
        as={"div"}
        className="fixed w-[462px] h-fit left-0 right-0 ml-auto mr-auto px-6 pt-6 pb-8 bottom-5 bg-white rounded-3xl flex-col justify-start items-start gap-6 inline-flex z-30"
        enter="transition-opacity ease-out duration-300"
        enterFrom="opacity-0 translate-y-1/2"
        enterTo="opacity-100 translate-y-0"
        leave="transition-opacity duration-150"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <AssignClassPanel
          ref={assignClassPanelRef}
          setShowPopUp={setShowPopUp}
          contextSchool={contextSchool}
          showActiveClassesOnly={false} // Ability to decide if include or not an unactive class
          classes={classes || []}
          setClasses={setClasses ? setClasses : () => {}}
          showWarningMessage={showWarningMessage}
          setShowWarningMessage={setShowWarningMessage}
        />
      </Transition>
      {/* Edit contact pop-up */}
      <Transition
        show={showPopUp && type === "edit-contact"}
        as={"div"}
        className="fixed w-[455px] h-fit left-0 right-0 ml-auto mr-auto p-6 bottom-5 pb-8 bg-white rounded-3xl flex-col justify-start items-start gap-6 inline-flex z-30"
        enter="transition-opacity ease-out duration-300"
        enterFrom="opacity-0 translate-y-1/2"
        enterTo="opacity-100 translate-y-0"
        leave="transition-opacity duration-150"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <AddContactsPanel
          selectedData={selectedData as ContactListType}
          selectedContacts={selectedContacts || []}
          setSelectedContacts={
            setSelectedContacts ? setSelectedContacts : () => {}
          }
          setShowPopUp={setShowPopUp}
        />
      </Transition>
    </>
  );
};

export default PopUpPanel;
