interface EmptyIconProps {
  width?: string;
  height?: string;
}

const EmptyIcon: React.FC<EmptyIconProps> = ({
  width = "309",
  height = "231",
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 309 231"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_3349_154864)">
        <path
          d="M225.631 219.485H219.483L216.557 195.765H225.631V219.485Z"
          fill="#9E616A"
        />
        <path
          d="M228.876 230.14H224.466L223.679 225.976L221.664 230.14H209.97C209.409 230.14 208.863 229.961 208.412 229.628C207.96 229.296 207.627 228.828 207.46 228.293C207.293 227.758 207.302 227.183 207.485 226.653C207.667 226.123 208.015 225.665 208.476 225.347L217.815 218.896V214.687L227.637 215.273L228.876 230.14Z"
          fill="#2F2E41"
        />
        <path
          d="M262.157 211.724L257.223 215.393L240.725 198.106L248.006 192.689L262.157 211.724Z"
          fill="#9E616A"
        />
        <path
          d="M271.118 218.337L267.579 220.969L264.464 218.097L265.33 222.642L255.946 229.621C255.497 229.956 254.952 230.138 254.391 230.141C253.831 230.144 253.284 229.967 252.831 229.637C252.378 229.307 252.042 228.841 251.872 228.307C251.703 227.772 251.708 227.198 251.888 226.667L255.534 215.916L253.022 212.539L261.254 207.146L271.118 218.337Z"
          fill="#2F2E41"
        />
        <path
          d="M232.788 97.1025C232.788 97.1025 244.454 107.183 239.072 135.921L232.501 164.078L253.93 199.638L247.097 207.952L219.239 169.463L209.143 145.777L206.311 103.621L232.788 97.1025Z"
          fill="#2F2E41"
        />
        <path
          d="M214.708 152.527L214.006 163.396L216.153 209.712L226.257 207.952L228.188 174.976L214.708 152.527Z"
          fill="#2F2E41"
        />
        <path
          d="M85.0476 225.062L90.546 225.062L93.1614 203.85L85.0469 203.85L85.0476 225.062Z"
          fill="#FFB6B6"
        />
        <path
          d="M83.6445 223.266L85.1921 223.266L91.2341 220.809L94.4725 223.266H94.4729C96.303 223.266 98.0581 223.993 99.3521 225.288C100.646 226.582 101.373 228.337 101.373 230.168V230.392L83.6448 230.393L83.6445 223.266Z"
          fill="#2F2E41"
        />
        <path
          d="M106.354 221.597L111.717 222.808L118.94 202.693L111.025 200.905L106.354 221.597Z"
          fill="#FFB6B6"
        />
        <path
          d="M105.382 219.536L106.891 219.877L113.326 218.811L115.944 221.921L115.944 221.921C117.729 222.324 119.281 223.42 120.258 224.968C121.235 226.516 121.558 228.388 121.155 230.174L121.106 230.393L103.812 226.487L105.382 219.536Z"
          fill="#2F2E41"
        />
        <path
          d="M78.0837 95.3809C75.5058 103.157 75.7784 111.599 78.7033 120.658L79.9536 171.666C79.9536 171.666 78.0907 205.116 84.102 216.887H92.82L97.1364 168.612L97.6785 136.209L116.369 163.546L104.464 216.098L115.194 217.057L134.512 160.026L110.678 104.696L78.0837 95.3809Z"
          fill="#2F2E41"
        />
        <path
          d="M178.622 73.2309C185.29 67.2206 195.209 64.1985 203.528 67.5687C194.994 72.308 188.411 79.9139 184.943 89.0408C183.619 92.5655 182.513 96.6238 179.255 98.5109C177.228 99.6851 174.706 99.7424 172.433 99.1756C170.16 98.6087 168.077 97.4724 166.022 96.3475L165.438 96.3342C168.062 87.7483 171.954 79.2413 178.622 73.2309Z"
          fill="#E6E6E6"
        />
        <path
          d="M203.482 67.7512C196.114 68.7036 189.232 71.9541 183.816 77.041C182.631 78.1154 181.622 79.3699 180.827 80.7585C180.096 82.1362 179.748 83.6844 179.818 85.2424C179.854 86.6963 180.059 88.1793 179.741 89.6167C179.567 90.35 179.241 91.0384 178.782 91.6366C178.324 92.2348 177.744 92.7293 177.082 93.0877C175.453 94.0239 173.586 94.319 171.75 94.5441C169.711 94.7941 167.59 95.0214 165.825 96.1652C165.611 96.3037 165.409 95.9638 165.622 95.8254C168.692 93.8354 172.578 94.5595 175.879 93.2314C177.42 92.6116 178.783 91.5238 179.265 89.8791C179.687 88.4408 179.477 86.9113 179.428 85.4395C179.337 83.9207 179.62 82.4028 180.251 81.0185C180.973 79.5939 181.932 78.3026 183.086 77.1997C185.647 74.6754 188.588 72.5695 191.802 70.959C195.46 69.1006 199.409 67.8818 203.479 67.3554C203.73 67.3229 203.732 67.7189 203.482 67.7512Z"
          fill="white"
        />
        <path
          d="M185.463 75.3008C184.734 74.2759 184.392 73.025 184.499 71.7712C184.606 70.5175 185.155 69.3427 186.048 68.4566C186.23 68.2779 186.516 68.5514 186.334 68.7303C185.501 69.5539 184.989 70.6483 184.892 71.816C184.795 72.9836 185.118 74.1476 185.803 75.0979C185.952 75.3046 185.611 75.5062 185.463 75.3008Z"
          fill="white"
        />
        <path
          d="M179.702 84.5079C182.234 84.9566 184.842 84.4343 187.007 83.045C187.221 82.9071 187.424 83.2471 187.209 83.3847C184.955 84.8263 182.239 85.3643 179.605 84.8916C179.354 84.8463 179.452 84.4629 179.702 84.5079Z"
          fill="white"
        />
        <path
          d="M195.492 69.6244C195.692 70.0798 196.002 70.4784 196.394 70.7844C196.786 71.0905 197.247 71.2944 197.738 71.3778C197.989 71.4199 197.891 71.8033 197.641 71.7615C197.1 71.6668 196.59 71.441 196.156 71.1038C195.722 70.7666 195.378 70.3283 195.153 69.8272C195.127 69.7818 195.12 69.7284 195.133 69.678C195.146 69.6277 195.177 69.584 195.221 69.5559C195.266 69.5293 195.32 69.5215 195.371 69.5344C195.422 69.5472 195.465 69.5795 195.492 69.6244Z"
          fill="white"
        />
        <path
          d="M212.689 96.5532C212.532 96.5315 212.374 96.5097 212.214 96.4903C210.091 96.2085 207.949 96.0997 205.809 96.1649C205.643 96.1673 205.476 96.1722 205.31 96.1794C200.156 96.3914 195.085 97.5588 190.356 99.6223C188.476 100.445 186.66 101.41 184.926 102.508C182.53 104.025 180.054 105.861 177.376 106.479C177.097 106.547 176.815 106.597 176.53 106.63L165.466 97.0484C165.452 97.014 165.435 96.982 165.421 96.9475L164.962 96.5851C165.047 96.5222 165.137 96.4589 165.223 96.396C165.272 96.3593 165.324 96.3247 165.374 96.288C165.407 96.2642 165.441 96.2405 165.47 96.2172C165.482 96.2092 165.493 96.2013 165.502 96.1959C165.531 96.1725 165.561 96.1539 165.587 96.133C166.09 95.7772 166.595 95.4244 167.104 95.0745C167.107 95.072 167.107 95.072 167.111 95.0716C170.964 92.3681 175.125 90.1313 179.505 88.4082C179.636 88.3585 179.769 88.3062 179.906 88.261C181.878 87.5274 183.908 86.9562 185.973 86.5533C187.105 86.3363 188.247 86.1768 189.395 86.0753C192.361 85.8188 195.349 86.0305 198.25 86.7028C204.032 88.0439 209.321 91.2197 212.454 96.1725C212.534 96.2992 212.612 96.424 212.689 96.5532Z"
          fill="#E6E6E6"
        />
        <path
          d="M212.543 96.672C206.087 92.9951 198.636 91.4466 191.249 92.2464C189.656 92.3903 188.095 92.7845 186.625 93.4146C185.212 94.0746 184.002 95.1009 183.12 96.3869C182.274 97.5695 181.545 98.8772 180.426 99.8334C179.846 100.314 179.17 100.667 178.444 100.869C177.718 101.07 176.958 101.116 176.213 101.003C174.35 100.77 172.681 99.8813 171.079 98.9553C169.301 97.9271 167.47 96.831 165.373 96.6815C165.119 96.6634 165.161 96.27 165.415 96.2881C169.065 96.5482 171.731 99.4661 175.166 100.394C176.77 100.827 178.513 100.779 179.888 99.7564C181.091 98.8622 181.843 97.5143 182.69 96.3095C183.532 95.0423 184.671 94.0007 186.009 93.2755C187.442 92.5726 188.985 92.1189 190.571 91.9338C194.135 91.46 197.751 91.5497 201.286 92.1995C205.326 92.9186 209.213 94.3237 212.779 96.3539C212.999 96.4796 212.762 96.797 212.543 96.672Z"
          fill="white"
        />
        <path
          d="M193.612 91.849C193.646 90.5911 194.126 89.3864 194.967 88.4499C195.807 87.5134 196.952 86.906 198.199 86.7364C198.451 86.7029 198.515 87.0936 198.262 87.1271C197.101 87.2829 196.034 87.8487 195.254 88.7224C194.473 89.5961 194.03 90.7201 194.006 91.8915C194 92.1461 193.607 92.1021 193.612 91.849Z"
          fill="white"
        />
        <path
          d="M183.471 95.7312C185.222 97.6144 187.619 98.768 190.183 98.962C190.438 98.981 190.395 99.3744 190.141 99.3554C187.473 99.1485 184.981 97.9429 183.162 95.9793C182.989 95.792 183.298 95.5449 183.471 95.7312Z"
          fill="white"
        />
        <path
          d="M205.037 93.3563C204.922 93.8402 204.929 94.345 205.058 94.8254C205.187 95.3058 205.433 95.7466 205.774 96.1084C205.949 96.2934 205.64 96.5403 205.466 96.3565C205.091 95.955 204.819 95.4678 204.676 94.9374C204.533 94.407 204.521 93.8495 204.643 93.3137C204.65 93.2622 204.677 93.2155 204.717 93.1829C204.758 93.1503 204.809 93.1343 204.861 93.1383C204.913 93.1442 204.961 93.1704 204.994 93.2112C205.026 93.252 205.042 93.3042 205.037 93.3563Z"
          fill="white"
        />
        <path
          d="M18.3649 224.676C17.8455 226.625 16.4808 228.248 14.8332 229.431C14.5646 229.624 14.2888 229.803 14.0094 229.972C13.9235 230.022 13.8375 230.075 13.748 230.122C13.6513 230.179 13.5545 230.233 13.4578 230.283H5.71048C5.57079 230 5.43469 229.714 5.29858 229.431C1.97829 222.452 -0.353443 214.921 0.0441331 207.251C0.201866 204.276 0.826768 201.344 1.8959 198.563C4.02347 193.021 7.90611 188.224 13.2465 185.809C13.379 185.748 13.5187 185.687 13.6548 185.63C13.6119 185.784 13.5689 185.934 13.5259 186.088C12.953 188.153 12.5481 190.26 12.3153 192.39C12.2938 192.555 12.2759 192.72 12.2615 192.888C11.7527 198.025 12.2045 203.211 13.5939 208.182C13.5975 208.193 13.6011 208.207 13.6047 208.218C13.9092 209.318 14.2626 210.401 14.6649 211.467C14.9801 212.309 15.3204 213.141 15.6929 213.957C17.2474 217.386 19.3319 221.04 18.3649 224.676Z"
          fill="#E6E6E6"
        />
        <path
          d="M13.7515 185.791C13.6763 185.892 13.601 185.988 13.5258 186.089C11.9526 188.174 10.6168 190.428 9.5429 192.81C9.48559 192.928 9.43186 193.046 9.3853 193.168C7.9139 196.493 6.96894 200.027 6.58436 203.643C6.5802 203.668 6.57781 203.693 6.5772 203.719C6.48765 204.564 6.43393 205.417 6.41244 206.269C6.33279 207.868 6.50564 209.468 6.92463 211.013C7.30666 212.274 7.97611 213.429 8.88027 214.388C8.96982 214.484 9.05936 214.581 9.15607 214.674C9.2277 214.749 9.3065 214.825 9.38172 214.896C10.1052 215.588 10.8968 216.229 11.5773 216.971C11.8893 217.303 12.1709 217.663 12.4191 218.045C12.815 218.687 13.0708 219.404 13.1696 220.152C13.2684 220.899 13.2079 221.658 12.9921 222.38C12.505 224.193 11.3911 225.723 10.2521 227.181C9.68259 227.912 9.09517 228.65 8.58656 229.431C8.40033 229.71 8.22839 229.993 8.07082 230.284H7.61951C7.76994 229.993 7.93471 229.71 8.1138 229.431C9.42114 227.371 11.2801 225.601 12.2436 223.333C12.8955 221.804 13.0925 220.07 12.2686 218.569C12.0272 218.132 11.7351 217.726 11.3983 217.358C10.7428 216.616 9.95841 215.982 9.24563 215.312C9.06655 215.144 8.89104 214.972 8.72269 214.792C7.83019 213.885 7.14148 212.798 6.70258 211.604C6.20553 210.087 5.97055 208.496 6.00772 206.9C6.00772 205.883 6.06145 204.858 6.16173 203.841C6.17248 203.712 6.18681 203.579 6.20113 203.446C6.93362 197.044 9.36626 190.954 13.2465 185.809C13.3181 185.709 13.3933 185.612 13.4685 185.516C13.6225 185.315 13.9055 185.594 13.7515 185.791Z"
          fill="white"
        />
        <path
          d="M6.34593 203.874C5.10531 203.666 3.97916 203.023 3.16859 202.061C2.35802 201.099 1.91582 199.879 1.92094 198.621C1.92163 198.57 1.9422 198.521 1.97832 198.484C2.01444 198.448 2.06329 198.427 2.11458 198.426C2.16587 198.425 2.21559 198.444 2.25327 198.478C2.29095 198.513 2.31364 198.561 2.31657 198.613C2.30963 199.784 2.72166 200.92 3.47829 201.814C4.23492 202.709 5.28632 203.303 6.44271 203.491C6.69396 203.532 6.59574 203.915 6.34593 203.874Z"
          fill="white"
        />
        <path
          d="M8.78102 214.459C10.8888 212.986 12.3638 210.772 12.9119 208.258C12.966 208.009 13.3496 208.107 13.2955 208.355C12.7202 210.969 11.1806 213.27 8.98384 214.799C8.77436 214.945 8.57267 214.604 8.78102 214.459Z"
          fill="white"
        />
        <path
          d="M9.4246 192.767C9.88782 192.948 10.3887 193.011 10.8821 192.95C11.3756 192.889 11.8463 192.707 12.2518 192.419C12.4594 192.271 12.6608 192.612 12.4547 192.759C12.0051 193.075 11.485 193.276 10.94 193.344C10.3949 193.412 9.84139 193.346 9.32781 193.151C9.27786 193.137 9.23523 193.104 9.2086 193.059C9.18196 193.015 9.17331 192.962 9.1844 192.911C9.19747 192.86 9.23004 192.817 9.27501 192.79C9.31999 192.763 9.37374 192.755 9.4246 192.767Z"
          fill="white"
        />
        <path
          d="M40.6222 199.645C40.4969 199.742 40.3679 199.839 40.2426 199.939C38.542 201.241 36.9501 202.678 35.4824 204.238C35.3678 204.356 35.2532 204.478 35.1421 204.6L35.1385 204.603C31.6585 208.382 28.9068 212.772 27.0223 217.551L27.0115 217.583C27.0079 217.594 27.0044 217.601 27.0008 217.612C26.2538 219.525 25.6515 221.491 25.1991 223.494C24.7622 225.45 24.404 227.546 23.7199 229.431C23.616 229.721 23.5014 230.007 23.3796 230.283H7.45508C7.5088 230 7.56611 229.714 7.62342 229.431C8.43349 225.279 9.69956 221.23 11.3986 217.357C11.4559 217.228 11.5132 217.099 11.5777 216.97C12.4548 215.057 13.4862 213.218 14.6616 211.471L14.6618 211.47L14.6626 211.469L14.6638 211.468L14.6652 211.468C15.3106 210.515 16.004 209.595 16.7426 208.713C18.6582 206.433 20.921 204.47 23.4477 202.894C23.462 202.884 23.4799 202.877 23.4942 202.866C28.523 199.745 34.4831 198.259 40.1817 199.545H40.1852C40.3321 199.577 40.4754 199.609 40.6222 199.645Z"
          fill="#E6E6E6"
        />
        <path
          d="M40.6036 199.835C40.4819 199.867 40.3601 199.903 40.2419 199.939C37.7316 200.656 35.3087 201.65 33.0175 202.902C32.9028 202.962 32.7882 203.027 32.6736 203.091C29.5072 204.859 26.6329 207.106 24.1526 209.751C24.1506 209.753 24.1494 209.756 24.149 209.759C24.1204 209.787 24.0881 209.82 24.063 209.848C23.49 210.464 22.9384 211.106 22.4154 211.765C21.3925 212.994 20.5683 214.376 19.9727 215.86C19.5175 217.097 19.3569 218.423 19.5035 219.733C19.5142 219.865 19.5321 219.994 19.55 220.127C19.5643 220.231 19.5787 220.334 19.5966 220.442C19.833 221.878 20.2413 223.319 20.1267 224.784C20.0569 225.535 19.8291 226.262 19.4581 226.919C19.087 227.575 18.5813 228.145 17.974 228.592C17.5613 228.911 17.12 229.192 16.656 229.431C15.9924 229.773 15.301 230.058 14.5893 230.283H13.1816C13.3715 230.229 13.5577 230.176 13.7476 230.122C14.4212 229.932 15.0827 229.701 15.7283 229.431C16.0971 229.276 16.456 229.099 16.8028 228.9C18.2427 228.073 19.4426 226.805 19.6933 225.11C19.9118 223.627 19.4891 222.144 19.2384 220.693C19.1954 220.442 19.156 220.195 19.1345 219.947C18.9635 218.691 19.0674 217.412 19.439 216.2C19.9543 214.688 20.7248 213.275 21.717 212.023C22.3307 211.208 22.9859 210.424 23.6798 209.676C23.7658 209.579 23.8553 209.483 23.9484 209.386C26.4809 206.721 29.4051 204.458 32.6199 202.676H32.6235C35.0138 201.345 37.5498 200.294 40.181 199.545H40.1846C40.3028 199.509 40.4245 199.473 40.5427 199.441C40.7899 199.376 40.8472 199.767 40.6036 199.835Z"
          fill="white"
        />
        <path
          d="M23.8076 209.812C22.9425 208.899 22.4302 207.707 22.3622 206.451C22.2943 205.194 22.675 203.955 23.4365 202.953C23.5913 202.751 23.9126 202.982 23.7576 203.184C23.0468 204.116 22.6922 205.271 22.7579 206.44C22.8236 207.61 23.3052 208.718 24.1158 209.564C24.2918 209.748 23.9825 209.995 23.8076 209.812Z"
          fill="white"
        />
        <path
          d="M19.3803 219.731C21.9502 219.823 24.4606 218.944 26.411 217.267C26.6041 217.101 26.8519 217.41 26.659 217.576C24.6262 219.316 22.0118 220.227 19.3377 220.124C19.0828 220.114 19.1268 219.721 19.3803 219.731Z"
          fill="white"
        />
        <path
          d="M32.9509 202.798C33.212 203.221 33.5741 203.573 34.0047 203.822C34.4353 204.07 34.9209 204.208 35.4179 204.223C35.6728 204.229 35.6285 204.623 35.3753 204.616C34.8263 204.597 34.2902 204.445 33.8138 204.171C33.3375 203.897 32.9354 203.511 32.6427 203.046C32.6115 203.005 32.5972 202.953 32.6028 202.901C32.6083 202.849 32.6334 202.802 32.6728 202.768C32.7138 202.735 32.766 202.72 32.8181 202.726C32.8702 202.731 32.9179 202.757 32.9509 202.798Z"
          fill="white"
        />
        <path
          d="M163.465 62.0251C163.098 62.5087 162.629 62.9059 162.091 63.1886C161.553 63.4712 160.96 63.6325 160.354 63.6611C159.747 63.6897 159.141 63.585 158.579 63.3541C158.018 63.1233 157.513 62.7721 157.102 62.3252L143.562 68.215L144.881 60.6552L157.536 56.2211C158.333 55.5795 159.337 55.2524 160.358 55.3017C161.379 55.3511 162.347 55.7735 163.078 56.489C163.809 57.2044 164.252 58.1631 164.323 59.1834C164.395 60.2038 164.089 61.2149 163.465 62.0251Z"
          fill="#FFB8B8"
        />
        <path
          d="M154.431 64.3857L122.984 78.3966L122.916 78.3725L87.0747 65.5974C84.4049 64.2018 82.3696 61.8382 81.3853 58.9906C80.401 56.1429 80.5423 53.0266 81.7802 50.2798C82.4664 48.7621 83.4661 47.407 84.7136 46.3037C85.9611 45.2004 87.4281 44.3738 89.018 43.8785C90.6079 43.3831 92.2847 43.2301 93.9379 43.4297C95.5912 43.6292 97.1835 44.1767 98.6099 45.0361L125.283 61.1073L151.147 57.7544L154.431 64.3857Z"
          fill="#E6E6E6"
        />
        <path
          d="M309 230.574C309 230.63 308.989 230.685 308.968 230.737C308.946 230.789 308.915 230.836 308.875 230.876C308.836 230.915 308.789 230.947 308.737 230.968C308.685 230.989 308.63 231 308.574 231H0.437944C0.324901 231 0.216492 230.955 0.136558 230.875C0.0566244 230.795 0.0117188 230.687 0.0117188 230.574C0.0117188 230.461 0.0566244 230.352 0.136558 230.272C0.216492 230.192 0.324901 230.147 0.437944 230.147H308.574C308.63 230.147 308.685 230.158 308.737 230.18C308.789 230.201 308.836 230.232 308.875 230.272C308.915 230.312 308.946 230.359 308.968 230.41C308.989 230.462 309 230.518 309 230.574Z"
          fill="#CCCCCC"
        />
        <path
          d="M112.232 106.33L80.5313 101.258C79.6999 100.991 78.936 100.548 78.2919 99.9587C77.6479 99.3692 77.1391 98.6472 76.8003 97.8424C76.4615 97.0376 76.3009 96.1691 76.3294 95.2963C76.3579 94.4236 76.5749 93.5673 76.9655 92.7864L78.4031 89.9101L77.8447 59.6922C77.7773 55.7616 78.7461 51.8826 80.6536 48.4454C82.561 45.0081 85.3398 42.134 88.7104 40.1121L91.8018 38.2632L93.3641 34.2949L107.77 34.6464L107.812 39.8509L112.971 48.0795L112.971 48.1136L112.647 92.9008L111.225 97.5228L113.375 102.899L112.232 106.33Z"
          fill="#E6E6E6"
        />
        <path
          d="M101.886 29.3603C109.115 29.3603 114.975 23.4987 114.975 16.268C114.975 9.03738 109.115 3.17578 101.886 3.17578C94.6571 3.17578 88.7969 9.03738 88.7969 16.268C88.7969 23.4987 94.6571 29.3603 101.886 29.3603Z"
          fill="#FFB8B8"
        />
        <path
          d="M88.257 19.123C87.8666 17.4779 88.0458 15.163 88.2665 13.5066C88.8449 9.16402 91.2559 5.03565 94.919 2.59027C95.6135 2.06262 96.4389 1.73456 97.3061 1.64144C98.1726 1.60526 99.112 2.06926 99.378 2.89499C99.5963 2.35001 99.9239 1.85556 100.341 1.44217C100.758 1.02877 101.255 0.705204 101.801 0.49143C102.899 0.0706042 104.08 -0.0885422 105.251 0.0266061C107.519 0.213631 109.695 1.01144 111.546 2.33538C113.398 3.65931 114.857 5.45997 115.768 7.54626C116.118 8.37699 117.094 5.3085 117.537 6.09342C117.944 6.9093 118.654 7.53342 119.516 7.8322C120.383 8.07445 120.736 11.6514 121.078 10.8173C121.343 11.4337 121.447 12.1071 121.382 12.7749C121.317 13.4427 121.084 14.0832 120.704 14.6366C120.325 15.1899 119.812 15.6383 119.213 15.9399C118.613 16.2415 117.948 16.3865 117.277 16.3614C116.394 16.3284 115.549 16.0034 114.676 15.8634C111.555 15.3632 108.243 17.6272 107.576 20.7181C107.337 19.8242 106.955 18.9748 106.446 18.2027C106.187 17.8187 105.84 17.502 105.434 17.279C105.029 17.056 104.576 16.9332 104.113 16.9206C103.225 16.9539 102.444 17.5347 101.868 18.2114C101.291 18.8882 100.859 19.6778 100.278 20.3509C98.5749 22.326 96.2711 26.932 93.8437 26.5961C91.9281 26.331 88.79 21.3691 88.257 19.123Z"
          fill="#2F2E41"
        />
        <path
          d="M181.009 76.8611C181.101 76.2643 180.978 75.6542 180.663 75.1392C180.348 74.6241 179.861 74.2374 179.288 74.0475L144.038 62.3048C143.7 62.1918 143.342 62.1507 142.988 62.1842L134.153 63.0199L129.083 63.5008L119.728 64.3851C119.163 64.4392 118.632 64.6792 118.218 65.0676C117.805 65.4559 117.531 65.9706 117.442 66.531L112.692 96.2083C112.602 96.7871 112.712 97.3794 113.004 97.8871C113.296 98.3949 113.752 98.7879 114.298 99.0012L149.915 112.753C150.082 112.818 150.256 112.864 150.433 112.892C150.691 112.934 150.954 112.936 151.212 112.898L174.227 109.486C174.77 109.407 175.273 109.156 175.662 108.769C176.05 108.381 176.304 107.879 176.384 107.337L181.009 76.8611Z"
          fill="white"
        />
        <path
          d="M115.106 96.9104L150.723 110.661C150.775 110.682 150.831 110.688 150.887 110.68L173.898 107.269C173.967 107.26 174.03 107.228 174.079 107.18C174.128 107.131 174.159 107.068 174.169 107L178.796 76.5239C178.807 76.4494 178.791 76.3737 178.752 76.3095C178.713 76.2454 178.653 76.1968 178.582 76.1722L178.345 76.092L143.33 64.4322C143.314 64.4265 143.298 64.4225 143.281 64.4203C143.254 64.4153 143.226 64.4139 143.199 64.416L121.649 66.4556L119.939 66.6161C119.869 66.6236 119.803 66.6539 119.752 66.7022C119.7 66.7506 119.666 66.8144 119.654 66.884L114.905 96.5602C114.894 96.6326 114.907 96.7069 114.944 96.7707C114.98 96.8344 115.037 96.8837 115.106 96.9104Z"
          fill="#F28E1C"
        />
        <path
          d="M121.648 66.4556L143.198 64.416C143.225 64.4139 143.253 64.4153 143.28 64.4203C143.297 64.4225 143.314 64.4265 143.329 64.4322L178.344 76.092L169.108 77.0699L165.651 77.4342L155.816 78.4735C155.787 78.475 155.758 78.4746 155.729 78.4725C155.712 78.4672 155.694 78.462 155.673 78.4563L121.648 66.4556Z"
          fill="#2F2E41"
        />
        <path
          d="M114.297 99.0033L149.914 112.754C150.082 112.819 150.256 112.864 150.434 112.89C150.692 112.933 150.955 112.936 151.214 112.9L174.225 109.488C174.769 109.409 175.272 109.157 175.661 108.77C176.05 108.383 176.303 107.88 176.385 107.338L181.009 76.8613C181.077 76.412 181.025 75.9526 180.859 75.5297C180.835 75.4735 180.812 75.4173 180.785 75.3643C180.601 74.9783 180.322 74.6456 179.974 74.3979C179.764 74.2473 179.533 74.1293 179.288 74.048L179.246 74.0332L144.04 62.3047C143.896 62.2588 143.749 62.2231 143.601 62.1979C143.397 62.1691 143.191 62.1651 142.986 62.186L134.153 63.0189L129.084 63.5025L119.727 64.3861C119.344 64.4219 118.974 64.5441 118.646 64.7435C118.62 64.7558 118.596 64.7703 118.573 64.7869C118.557 64.7976 118.541 64.8093 118.527 64.8221C118.24 65.0181 117.996 65.2704 117.809 65.5638C117.623 65.8573 117.498 66.1855 117.443 66.5287L112.69 96.2081C112.6 96.7874 112.71 97.3801 113.002 97.8882C113.295 98.3964 113.751 98.7897 114.297 99.0033ZM118.073 66.6322C118.131 66.2764 118.289 65.9445 118.528 65.6751C118.6 65.5885 118.682 65.5094 118.77 65.4391C119.012 65.2437 119.297 65.1104 119.602 65.0508C119.662 65.0384 119.723 65.0298 119.785 65.0251L135.539 63.5364L143.048 62.8217C143.099 62.8158 143.146 62.8166 143.193 62.8139C143.306 62.8116 143.42 62.82 143.532 62.8392C143.635 62.8525 143.737 62.8767 143.835 62.9113L179.082 74.6546C179.11 74.6644 179.134 74.6739 179.162 74.6838C179.483 74.8031 179.764 75.008 179.976 75.2762C180.144 75.4816 180.266 75.72 180.335 75.976C180.405 76.2319 180.419 76.4995 180.378 76.7614L175.75 107.241C175.689 107.647 175.499 108.024 175.209 108.314C174.918 108.604 174.541 108.793 174.135 108.854L151.12 112.265C150.79 112.312 150.455 112.274 150.144 112.153L114.527 98.4062C114.119 98.2453 113.777 97.9504 113.559 97.5698C113.34 97.1893 113.257 96.7457 113.324 96.3119L118.073 66.6322Z"
          fill="#3F3D56"
        />
        <path
          d="M118.451 64.9839C118.47 64.9299 118.503 64.8821 118.547 64.8456C118.561 64.8339 118.577 64.8233 118.593 64.814C118.608 64.8082 118.623 64.7989 118.638 64.7931C118.71 64.7644 118.789 64.763 118.862 64.7893L119.603 65.0509L155.82 77.8297L164.297 76.934L169.04 76.4336L179.079 75.3705L179.977 75.2763L180.152 75.257C180.227 75.2501 180.302 75.2698 180.364 75.3125C180.427 75.3553 180.472 75.4185 180.493 75.4911C180.497 75.5079 180.501 75.5251 180.502 75.5424C180.509 75.6114 180.494 75.6809 180.459 75.7405C180.423 75.8 180.369 75.8464 180.305 75.8727C180.277 75.8845 180.247 75.8925 180.216 75.8966L178.995 76.0246L169.109 77.0699L165.652 77.4342L155.816 78.4736C155.788 78.475 155.759 78.4746 155.73 78.4725C155.713 78.4672 155.695 78.462 155.674 78.4564L118.771 65.4392L118.645 65.3948C118.617 65.3854 118.59 65.3706 118.567 65.3513C118.511 65.3103 118.47 65.2527 118.449 65.1866C118.428 65.1205 118.429 65.0496 118.451 64.9839Z"
          fill="#3F3D56"
        />
        <path
          d="M150.531 112.983C150.448 112.97 150.373 112.924 150.323 112.856C150.274 112.788 150.253 112.702 150.265 112.619L155.465 78.1065C155.478 78.0225 155.523 77.947 155.591 77.8966C155.66 77.8461 155.745 77.8249 155.829 77.8376L155.832 77.838C155.915 77.8514 155.99 77.8971 156.04 77.9652C156.09 78.0334 156.111 78.1184 156.098 78.2019L150.898 112.714C150.886 112.798 150.84 112.874 150.772 112.924C150.704 112.975 150.618 112.996 150.534 112.983L150.531 112.983Z"
          fill="#3F3D56"
        />
        <path
          d="M163.711 110.794L160.253 111.159L164.298 76.9329C164.298 76.9329 168.916 76.0965 169.04 76.4319C169.116 76.6321 163.744 110.574 163.711 110.794Z"
          fill="#3F3D56"
        />
        <path
          d="M118.646 65.3936L118.553 81.0018L146.177 91.5303L156.098 78.2016L118.646 65.3936Z"
          fill="#3F3D56"
        />
        <path
          d="M179.978 75.2763C179.747 74.986 179.436 74.7698 179.083 74.6542L143.837 62.9113C143.738 62.878 143.636 62.8533 143.533 62.8373C143.42 62.818 143.306 62.8095 143.192 62.8118L143.25 62.4545L143.602 62.199L151.972 56.1377L179.925 64.7062L179.973 74.3976L179.978 75.2763Z"
          fill="#3F3D56"
        />
        <path
          d="M120.054 90.4506C120.093 90.4545 120.133 90.4627 120.171 90.4751L130.985 94.0279C131.121 94.0724 131.233 94.1688 131.297 94.296C131.361 94.4232 131.373 94.5707 131.328 94.7062C131.284 94.8416 131.187 94.9538 131.06 95.0182C130.933 95.0825 130.785 95.0937 130.65 95.0492L119.835 91.4964C119.71 91.4552 119.604 91.3691 119.538 91.2546C119.472 91.1401 119.45 91.0053 119.477 90.8759C119.504 90.7465 119.578 90.6316 119.684 90.5532C119.791 90.4747 119.922 90.4382 120.054 90.4506Z"
          fill="white"
        />
        <path
          d="M163.688 155.927C166.062 155.927 167.986 154.003 167.986 151.628C167.986 149.254 166.062 147.329 163.688 147.329C161.314 147.329 159.39 149.254 159.39 151.628C159.39 154.003 161.314 155.927 163.688 155.927Z"
          fill="#F2F2F2"
        />
        <path
          d="M54.0862 171.69C56.46 171.69 58.3843 169.765 58.3843 167.391C58.3843 165.017 56.46 163.092 54.0862 163.092C51.7124 163.092 49.7881 165.017 49.7881 167.391C49.7881 169.765 51.7124 171.69 54.0862 171.69Z"
          fill="#F2F2F2"
        />
        <path
          d="M143.63 29.4615C146.004 29.4615 147.928 27.5367 147.928 25.1624C147.928 22.7881 146.004 20.8633 143.63 20.8633C141.256 20.8633 139.332 22.7881 139.332 25.1624C139.332 27.5367 141.256 29.4615 143.63 29.4615Z"
          fill="#F2F2F2"
        />
        <path
          d="M150.682 96.7737C150.125 97.0158 149.522 97.1328 148.914 97.1164C148.307 97.1 147.711 96.9506 147.168 96.6788C146.625 96.4071 146.148 96.0195 145.77 95.5433C145.393 95.0672 145.125 94.5141 144.984 93.923L130.295 92.4386L135.141 86.4893L148.348 88.811C149.356 88.6409 150.392 88.8466 151.258 89.3893C152.125 89.9319 152.763 90.7737 153.051 91.7552C153.339 92.7367 153.256 93.7897 152.82 94.7147C152.383 95.6396 151.623 96.3722 150.682 96.7737Z"
          fill="#FFB8B8"
        />
        <path
          d="M141.647 94.4127L107.366 91.2535L107.319 91.1994L82.3004 62.5257C80.6539 60.0026 80.034 56.9453 80.5676 53.9799C81.1012 51.0145 82.7479 48.3654 85.1706 46.5748C86.5111 45.5865 88.0456 44.8935 89.6732 44.5412C91.3008 44.189 92.9845 44.1855 94.6135 44.531C96.2425 44.8765 97.7799 45.5632 99.1245 46.5459C100.469 47.5285 101.59 48.7849 102.414 50.2322L117.824 77.2967L142.024 87.0223L141.647 94.4127Z"
          fill="#E6E6E6"
        />
        <path
          d="M227.689 39.2711C226.838 33.0253 225.77 26.3195 221.366 21.8107C219.969 20.3801 218.296 19.2474 216.449 18.4808C214.602 17.7142 212.619 17.3297 210.619 17.3504C208.62 17.3711 206.645 17.7966 204.815 18.6013C202.984 19.4059 201.335 20.573 199.967 22.0323C196.761 25.4532 195.346 30.2567 195.216 34.9438C195.086 39.631 196.13 44.266 197.288 48.8097C203.415 48.9321 209.496 47.7348 215.12 45.299C216.526 44.6885 217.91 43.9983 219.395 43.6201C220.881 43.2418 221.94 44.1936 223.322 44.8573L224.081 43.4044C224.702 44.5603 226.625 44.2177 227.322 43.1062C228.02 41.9947 227.867 40.5713 227.689 39.2711Z"
          fill="#2F2E41"
        />
        <path
          d="M203.402 74.1324L178.374 74.6678C177.99 74.0032 177.421 73.4647 176.736 73.1178C176.051 72.7709 175.28 72.6305 174.517 72.7138C173.754 72.797 173.032 73.1003 172.438 73.5867C171.844 74.0731 171.405 74.7216 171.173 75.4534C170.941 76.1852 170.926 76.9687 171.131 77.7085C171.336 78.4484 171.752 79.1127 172.327 79.6206C172.903 80.1285 173.614 80.4581 174.373 80.5694C175.132 80.6806 175.908 80.5687 176.605 80.2472C179.924 80.4359 205.894 84.1226 207.443 81.7467C209.015 79.3352 217.409 70.8248 217.409 70.8248L210.957 61.6377L203.402 74.1324Z"
          fill="#9E616A"
        />
        <path
          d="M209.501 45.4511C215.995 45.4511 221.26 40.1852 221.26 33.6894C221.26 27.1936 215.995 21.9277 209.501 21.9277C203.007 21.9277 197.742 27.1936 197.742 33.6894C197.742 40.1852 203.007 45.4511 209.501 45.4511Z"
          fill="#9E616A"
        />
        <path
          d="M228.335 56.3195C226.949 53.5609 226.276 50.1531 223.322 49.2588C221.327 48.6549 213.258 49.5599 211.44 50.5793C208.416 52.275 211.156 55.4525 209.372 58.4259C207.432 61.6584 202.161 68.1454 200.222 71.3778C198.888 73.6001 203.38 80.1227 202.659 82.6121C201.938 85.1015 201.903 87.8955 203.131 90.1775C204.231 92.2201 202.64 94.027 203.746 96.0662C204.895 98.187 206.306 106.116 205.236 108.278L204.52 110.428C211.628 110.846 217.606 102.351 224.67 101.457C226.425 101.234 228.247 100.944 229.693 99.9253C231.822 98.4246 232.664 95.7017 233.191 93.1496C235.723 80.6748 234.014 67.7112 228.335 56.3195Z"
          fill="#3F3D56"
        />
        <path
          d="M238.993 85.4464C238.003 82.7431 236.21 70.9238 236.21 70.9238L224.991 70.5947L230.476 84.1269L215.508 105.086C215.508 105.086 215.535 105.132 215.586 105.214C214.799 105.088 213.992 105.202 213.271 105.542C212.55 105.882 211.948 106.432 211.545 107.119C211.141 107.807 210.955 108.6 211.009 109.395C211.064 110.191 211.357 110.951 211.851 111.577C212.345 112.202 213.016 112.665 213.777 112.903C214.538 113.141 215.352 113.143 216.115 112.91C216.877 112.678 217.551 112.22 218.049 111.598C218.547 110.975 218.846 110.217 218.906 109.422C218.961 109.453 219.025 109.465 219.088 109.454C219.151 109.443 219.208 109.411 219.249 109.363C220.915 107.272 239.983 88.1497 238.993 85.4464Z"
          fill="#9E616A"
        />
        <path
          d="M240.133 77.9822C239.104 73.6348 238.066 69.2638 236.33 65.1474C234.594 61.0309 232.108 57.1428 228.612 54.3618C227.495 53.4727 226.245 52.6879 224.844 52.4115C223.443 52.1351 221.864 52.4444 220.88 53.4796C219.306 55.136 219.78 57.7949 220.357 60.0063C222.06 66.5268 223.763 73.0472 225.466 79.5676C230.383 79.0463 235.301 78.5249 240.218 78.0035L240.133 77.9822Z"
          fill="#3F3D56"
        />
        <path
          d="M223.91 35.3204C223.477 32.4751 223.025 29.606 222.007 26.9269C220.99 24.2477 219.35 21.7384 216.998 20.2207C213.273 17.8178 208.451 18.2813 204.275 19.6635C201.046 20.7324 197.893 22.3571 195.616 24.969C193.338 27.581 192.059 31.308 192.906 34.7232C197.361 33.6705 201.815 32.6179 206.27 31.5652L205.782 31.9098C207.245 32.6393 208.522 33.6925 209.517 34.9894C210.512 36.2863 211.198 37.7929 211.524 39.3948C211.842 41.0162 211.797 42.6881 211.39 44.2896C210.983 45.8911 210.226 47.3822 209.172 48.655C212.21 47.5771 215.248 46.4991 218.286 45.4212C220.161 44.7559 222.168 43.9893 223.28 42.2827C224.569 40.3041 224.27 37.6816 223.91 35.3204Z"
          fill="#2F2E41"
        />
      </g>
      <defs>
        <clipPath id="clip0_3349_154864">
          <rect width="309" height="231" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default EmptyIcon;
