import { AnimatePresence, motion } from "framer-motion";
import AIAvatarLittleC from "assets/imgs/AIAvatar_LittleC.png";
import AIAvatarJakitaC from "assets/imgs/AIAvatar_Jakita.png";
import AIAvatarCody from "assets/imgs/AIAvatar_Cody.svg";
import tw from "twin.macro";
import AssistantPopupContents from "./AssistantPopupContents";
import { AssistantAllButtonTypes, CodeFile } from "utils/interfaces";
import Assistant from "components/assistant/Assistant";
import AssistantHeader from "./AssistantHeader";
import { platform } from "config/platform";

const bgPrimary100 = tw`bg-dc-primary-100`.backgroundColor as string;
const bgPrimary300 = tw`bg-dc-primary-300`.backgroundColor as string;
const bgWhite = tw`bg-white`.backgroundColor as string;

const parentVariants = {
  closed: {
    width: 84,
    height: 92,
    top: -12,
    right: 0,
    paddingTop: 4,
    paddingBottom: 4,
    paddingLeft: 4,
    paddingRight: 4,
    borderRadius: 0,
    backgroundColor: "rgba(255, 255, 255, 0)",
  },
  popup: {
    width: 341,
    height: platform === "codeflix" ? 352 : 332,
    top: -12,
    right: 0,
    paddingTop: 26,
    paddingBottom: 26,
    paddingLeft: 20,
    paddingRight: 20,
    borderRadius: 16,
    backgroundColor: "rgba(255, 255, 255, 1)",
  },
  popupNoCode: {
    width: 341,
    height: 550,
    top: -12,
    right: 0,
    paddingTop: 26,
    paddingBottom: 26,
    paddingLeft: 20,
    paddingRight: 20,
    borderRadius: 16,
    backgroundColor: "rgba(255, 255, 255, 1)",
  },
  expanded: {
    width: 341,
    height: platform === "codeflix" ? 720 : 703,
    top: -12,
    right: 0,
    paddingTop: 26,
    paddingBottom: 26,
    paddingLeft: 20,
    paddingRight: 20,
    borderRadius: 16,
    backgroundColor: "rgba(255, 255, 255, 1)",
  },
  fullscreen: {
    width: "100vw",
    height: "100vh",
    top: -22,
    right: -256,
    paddingTop: 26,
    paddingBottom: 26,
    paddingLeft: 20,
    paddingRight: 20,
    borderRadius: 0,
    backgroundColor: "rgba(255, 255, 255, 1)",
  },
};

const assistantIconVariants = {
  closed: {
    opacity: 1,
    borderColor: bgWhite,
    borderRadius: 0,
    borderWidth: 5,
    backgroundColor: bgWhite,
    borderOpacity: 0,
    height: 82,
    width: 82,
    top: 0,
    right: -4,
    "--tw-bg-opacity": 0,
  },
  popup: {
    opacity: 1,
    borderColor: bgPrimary300,
    borderRadius: 29,
    borderWidth: 5,
    backgroundColor: bgPrimary100,
    borderOpacity: 1,
    height: 48,
    width: 48,
    top: 12,
    right: 18,
    "--tw-bg-opacity": 1,
  },
  popupNoCode: {
    opacity: 1,
    borderColor: bgPrimary300,
    borderRadius: 29,
    borderWidth: 5,
    backgroundColor: bgPrimary100,
    borderOpacity: 1,
    height: 48,
    width: 48,
    top: 12,
    right: 18,
    "--tw-bg-opacity": 1,
  },
  expanded: {
    opacity: 1,
    borderColor: bgPrimary300,
    borderRadius: 29,
    borderWidth: 5,
    backgroundColor: bgPrimary100,
    borderOpacity: 1,
    height: 48,
    width: 48,
    top: 12,
    right: 18,
    "--tw-bg-opacity": 1,
  },
  fullscreen: {
    opacity: 0,
    borderColor: bgPrimary300,
    borderRadius: 29,
    borderWidth: 1,
    backgroundColor: bgPrimary100,
    borderOpacity: 1,
    height: 48,
    width: 48,
    top: 12,
    right: 18,
    "--tw-bg-opacity": 1,
  },
};

const popupVariants = {
  closed: {
    opacity: 0,
  },
  popup: {
    opacity: 1,
  },
  popupNoCode: {
    opacity: 1,
  },
  expanded: {
    opacity: 1,
  },
  fullscreen: {
    opacity: 1,
  },
};

const backDropVariants = {
  closed: {
    backdropFilter: "blur(0px) brightness(100%)",
  },
  popup: {
    backdropFilter: "blur(5px) brightness(50%)",
  },
  popupNoCode: {
    backdropFilter: "blur(5px) brightness(50%)",
  },
  expanded: {
    backdropFilter: "blur(5px) brightness(50%)",
  },
  fullscreen: {
    backdropFilter: "blur(5px) brightness(50%)",
  },
};

const assistantVariants = {
  closed: {
    height: "calc(50vh)",
    width: "calc(50vw)",
    scale: 0.5,
    opacity: 0,
  },
  popup: {
    height: "calc(50vh)",
    width: "calc(50vw)",
    scale: 0.5,
    opacity: 0,
  },
  popupNoCode: {
    height: "calc(50vh)",
    width: "calc(50vw)",
    scale: 0.5,
    opacity: 0,
  },
  expanded: {
    height: "calc(50vh)",
    width: "calc(50vw)",
    scale: 0.5,
    opacity: 0,
  },
  fullscreen: {
    height: "calc(100vh)",
    width: "calc(100vw)",
    scale: 1,
    opacity: 1,
  },
};

interface AssistantPopupProps {
  assistant: "littleC" | "jakita" | "cody";
  state: "closed" | "popup" | "popupNoCode" | "expanded" | "fullscreen";
  setState: React.Dispatch<
    React.SetStateAction<
      "closed" | "popup" | "popupNoCode" | "expanded" | "fullscreen"
    >
  >;
  clickedButton: AssistantAllButtonTypes;
  setClickedButton: React.Dispatch<
    React.SetStateAction<AssistantAllButtonTypes>
  >;
  codeFiles: CodeFile[] | undefined;
  onAssistantClick?: () => void;
  goToDashboard: () => void;
  language: string;
}

const AssistantPopup: React.FC<AssistantPopupProps> = ({
  assistant,
  state,
  setState,
  clickedButton,
  setClickedButton,
  codeFiles,
  onAssistantClick,
  goToDashboard,
  language,
}) => {
  const popupClick = () => {
    if (state === "popup" || state === "expanded") {
      setState("closed");
    } else if (codeFiles === undefined) {
      setState("popupNoCode");
      onAssistantClick?.();
    } else {
      setState("popup");
      onAssistantClick?.();
    }
  };

  const handleButtonClick = (id: AssistantAllButtonTypes) => {
    setClickedButton(id);
  };

  const open = state !== "closed";
  const title =
    assistant === "jakita"
      ? "Ask Big J"
      : assistant === "littleC"
      ? "Ask Little C"
      : "Ask Cody";

  return (
    <div className="flex-row h-fit w-fit">
      <div
        className={`h-fit w-[84px] p-3 rounded-full justify-center items-center gap-1 inline-flex overflow-visible ${
          !open && "cursor-pointer"
        }`}
      >
        <AnimatePresence>
          {(state === "popup" ||
            state === "popupNoCode" ||
            state === "expanded") && (
            <motion.div
              className="left-0 top-0 fixed w-screen h-screen cursor-default z-10"
              variants={backDropVariants}
              initial="closed"
              animate={state}
              exit="closed"
              onClick={() => {
                console.log("closing!");
                setState("closed");
              }}
            />
          )}
        </AnimatePresence>
        <div className="relative flex-col justify-center items-center gap-2 inline-flex h-[92px]">
          <div className="h-[49px] w-[50px]" />
          <motion.div
            className={`absolute max-h-[90vh] z-20 p-1 flex-col justify-center items-center gap-2 inline-flex ${
              state !== "closed" && state !== "fullscreen" && state !== "popup"
                ? "overflow-y-auto overflow-x-hidden custom-scroll"
                : "overflow-hidden"
            }`}
            initial="closed"
            variants={parentVariants}
            animate={state}
          >
            <motion.div
              className="absolute p-0 right-[18px] top-3 group-hover:bg-primary-200 box-content overflow-visible"
              initial="closed"
              variants={assistantIconVariants}
              animate={state}
              onClick={popupClick}
            >
              <img
                src={
                  assistant === "jakita"
                    ? AIAvatarJakitaC
                    : assistant === "littleC"
                    ? AIAvatarLittleC
                    : AIAvatarCody
                }
                alt="AI Avatar"
                className={`m-auto w-full h-full`}
              />
            </motion.div>

            <AnimatePresence>
              {state === "fullscreen" && (
                <motion.div
                  className="fixed bg-white top-0"
                  variants={assistantVariants}
                  initial="closed"
                  animate={state}
                  exit="closed"
                >
                  <AssistantHeader
                    handleClickBack={() => setState("closed")}
                    assistant={assistant}
                  />
                  <div className="w-full h-[calc(100vh-155px)]">
                    <Assistant
                      AIAssistant={assistant}
                      files={codeFiles}
                      setActiveItem={() => {}}
                      returnToIDE={() => setState("closed")}
                      moveToNextLesson={goToDashboard}
                      newMessage={clickedButton}
                      setNewMessage={setClickedButton}
                      language={language}
                    />
                  </div>
                </motion.div>
              )}
            </AnimatePresence>

            <AnimatePresence>
              {state !== "closed" && state !== "fullscreen" && (
                <motion.div
                  className="relative h-full"
                  initial="closed"
                  variants={popupVariants}
                  animate={state}
                  exit="closed"
                >
                  <AssistantPopupContents
                    state={state}
                    setState={setState}
                    clickedButton={clickedButton}
                    setClickedButton={handleButtonClick}
                    hasCode={codeFiles !== undefined}
                    assistant={assistant}
                  />
                </motion.div>
              )}
            </AnimatePresence>
          </motion.div>
          <motion.div
            className="justify-center items-start gap-2 inline-flex w-[84px]"
            initial={{
              opacity: 1,
            }}
            animate={{
              opacity: state === "closed" ? 1 : 0,
            }}
          >
            <div className="text-center text-dc-secondary-600 text-xs font-semibold font-['Poppins'] uppercase leading-[14px] tracking-wide select-none">
              {title}
            </div>
          </motion.div>
        </div>
      </div>
    </div>
  );
};

export default AssistantPopup;
