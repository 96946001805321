import { ChangeEvent, useState } from "react";
import { MdOutlinePublish } from "react-icons/md";
import { Course, Program, Project, Module } from "utils/interfaces";
import { BACKEND_URL } from "config/getUrl";
import { fetchPdf } from "services/filesRequests";
import ProjectPlanCard from "./ProjectPlanCard";
import base64toBlob from "utils/base64toBlob";

const API_URL = BACKEND_URL;

const acceptToFormats = (accept: string) => {
  const formats: string[] = [];
  const acceptItems = accept.split(",");
  if (acceptItems.includes(".js")) {
    formats.push(
      ...[
        "text/javascript",
        "application/javascript",
        "text/x-javascript",
        "application/x-javascript",
      ]
    );
  }
  if (acceptItems.includes(".py")) {
    formats.push(...["text/x-python", "text/x-python-script"]);
  }
  if (acceptItems.includes(".sb3")) formats.push("");
  if (acceptItems.includes(".pdf")) formats.push("application/pdf");
  return formats;
};

interface InputPlanFileProps {
  selectedLesson: string;
  selectedData: Project | Program | Course | Module | null;
  planDocument: string | null;
  planName: string;
  planSize: string;
  fileType: string;
  accept?: string;
  maxMb?: number;
  setPlanName: React.Dispatch<React.SetStateAction<string>>;
  setPlanSize: React.Dispatch<React.SetStateAction<string>>;
  setPlanDocument: React.Dispatch<React.SetStateAction<string | null>>;
  displayFileType?: string;
  keyName: string;
}

const InputFile: React.FC<InputPlanFileProps> = ({
  selectedLesson,
  selectedData,
  planName,
  planSize,
  fileType,
  setPlanName,
  setPlanSize,
  planDocument,
  accept = ".pdf",
  maxMb = 10,
  setPlanDocument,
  displayFileType,
  keyName,
}) => {
  // Parameters definition
  const formatsAllowed = acceptToFormats(accept);
  const maxFileSize = maxMb * 1024 * 1024; // 10 MB

  // States definition
  const [unit, setUnit] = useState<"B" | "KB" | "MB" | "GB">("B");
  const [isDragging, setIsDragging] = useState(false);

  /**
   * Function to handle PDF plan uploading
   * @param event
   */
  const handleFileUpload = (event: ChangeEvent<HTMLInputElement>) => {
    // Get the file
    const file = event.target.files?.[0];

    // Process the file
    processFileUpload(file);
  };

  /**
   * Function used when pressing the delete button on ProjectPlanCard
   */
  const removePlan = () => {
    setPlanDocument("");
    setPlanName("");
    setPlanSize("0");
  };

  /**
   * Function to process file uploading
   * @param event
   */
  const processFileUpload = (file: File | undefined) => {
    // Check if there is an inputted file
    if (!file) {
      alert("No file was selected.");
      return;
    }

    // Check if the of the uploaded file is the defined in the
    // formats list allowed for this placeholder
    if (!formatsAllowed.includes(file.type)) {
      alert(`Format "${file.type}" is not allowed.`);
      return;
    }

    // Check that the size is the allowed one
    if (file.size > maxFileSize) {
      const sizeInMb = (file.size / 1024 / 1024).toFixed(2);
      alert(`File size (${sizeInMb} MB) is larger than allowed.`);
      return;
    }

    // Perform the loading
    const reader = new FileReader();
    reader.onloadend = () => {
      setPlanDocument(reader.result as string);
    };
    reader.readAsDataURL(file);

    // Set the filename of the file uploaded
    setPlanName(file.name);

    // And set the size of the file
    const fileSizeKB = file.size / 1024;
    const fileSizeMB = fileSizeKB / 1024;

    // Format to display file size
    if (fileSizeMB >= 1) {
      setPlanSize(fileSizeMB.toFixed(1)); // toFixed returns a string
      setUnit("MB");
    } else if (fileSizeKB >= 1) {
      setPlanSize(fileSizeKB.toFixed(1)); // toFixed returns a string
      setUnit("KB");
    } else {
      setPlanSize(file.size.toFixed(1)); // toFixed returns a string
      setUnit("B");
    }
  };

  /**
   * Function used on drop the area
   * @param event
   */
  const handleDrop = (event: React.DragEvent) => {
    // Prevent opening the file on another tab
    event.preventDefault();

    // Set dragging state to false
    setIsDragging(false);

    // Get the list of files dropped
    const droppedFiles = event.dataTransfer.files;

    // Check if the drop contain files
    if (droppedFiles.length > 0) {
      // Transform into an array
      const newFiles = Array.from(droppedFiles);

      // And use just the first file
      processFileUpload(newFiles[0]);
    }
  };

  /**
   * Function to apply on click name
   */
  const onClickNameFunction = () => {
    if (planDocument) {
      const blob = base64toBlob(planDocument.split(",")[1]);
      console.log(blob);
      const blobUrl = URL.createObjectURL(blob);
      window.open(blobUrl);
    } else if (!!selectedData) {
      // Get the pdf
      fetchPdf(selectedLesson, selectedData._id).then((url: string) => {
        // And open the pdf in a new window
        window.open(url);
      });
    }
  };

  /**
   * Function used to control drag and drop behaviour when leaving
   * @param event
   */
  const handleDragLeave = (event: React.DragEvent) => {
    event.preventDefault();
    event.stopPropagation();
    setIsDragging(false);
  };

  /**
   * Function used to control drag and drop behaviour when leaving
   * @param event
   */
  const handleDragOver = (event: React.DragEvent) => {
    event.preventDefault();
    event.stopPropagation();
  };

  /**
   * Function used to control drag and drop behaviour when entering
   * @param event
   */
  const handleDragEnter = (event: React.DragEvent) => {
    event.preventDefault();
    event.stopPropagation();
    setIsDragging(true);
  };

  /**
   * Function used to allow opening the upload file window when pressing
   * enter after being selected by tab button.
   * @param event
   */
  const pressEnter = (event: React.KeyboardEvent<HTMLButtonElement>) => {
    if (event.key === "Enter") {
      const inputFile =
        document && document.getElementById(`project-plan-upload-${keyName}`);
      if (inputFile) {
        inputFile.click();
      }
    }
  };

  return (
    <>
      {planName ? (
        <ProjectPlanCard
          filename={planName}
          planSize={planSize}
          unit={unit}
          removePlan={removePlan}
          onClickNameFunction={onClickNameFunction}
        />
      ) : (
        <button
          className={`self-stretch rounded-xl border ${
            isDragging
              ? "border-dc-secondary-500 border-solid"
              : "border-neutral-300 border-dashed"
          } hover:bg-neutral-25 ease-in-out duration-150`}
          onKeyDown={pressEnter}
        >
          <div
            className={`w-full h-full px-2 py-4 justify-center items-center gap-2 inline-flex relative`}
            onDrop={handleDrop}
            onDragEnter={handleDragEnter}
            onDragLeave={handleDragLeave}
            onDragOver={handleDragOver}
          >
            <div className="w-10 h-10 relative">
              <MdOutlinePublish className="w-full h-full fill-neutral-500" />
            </div>
            <input
              type="file"
              accept={accept}
              onChange={handleFileUpload}
              className="hidden"
              id={`project-plan-upload-${keyName}`}
            />
            {/* Label used to hide the on-hover text popup, also helps fix some alignment issues */}
            <label
              id={`project-plan-upload-label-${keyName}`}
              htmlFor={`project-plan-upload-${keyName}`}
              className="absolute inset-0 w-full h-full opacity-0 cursor-pointer"
            />
            <div className="flex-col justify-center items-start gap-0.5 inline-flex">
              <div className="justify-start items-center gap-2 inline-flex">
                <div className="text-neutral-600 text-xs font-normal font-sans leading-[14px]">
                  Upload a {selectedLesson.slice(0, -1)} {displayFileType}
                </div>
              </div>
              <div className="justify-start items-center gap-2 inline-flex">
                <div className="text-neutral-500 text-[10px] font-sans leading-3 tracking-tight">
                  <span className="font-normal">(File type: </span>
                  <span className="font-semibold">{accept}</span>
                  <span className="font-normal">, Max. file size: </span>
                  <span className="font-semibold">{maxMb} MB</span>
                  <span className="font-normal">)</span>
                </div>
              </div>
            </div>
          </div>
        </button>
      )}
    </>
  );
};

export default InputFile;
