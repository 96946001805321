import addRemoveArrayQuery from "./addRemoveArrayQuery";
import getModifiedKeys from "./getModifiedKeys";
import {
  ClassListType,
  ContactListType,
  Course,
  LessonListType,
  LocationListType,
  Module,
  ParentListType,
  Program,
  Project,
  QuizListType,
  SchoolListType,
  StudentListType,
  TeacherListType,
} from "./interfaces";

/**
 * Function used to format the school data in case we are updating.
 * Otherwise, just return the provided data.
 * @param selectedData
 * @param dataToProvide
 * @returns
 */
export const formatDataSchool = (
  selectedData: SchoolListType | null | undefined,
  dataToProvide: {
    name: string;
    logo: string | null;
    licenses: number;
    contacts: string[];
    // classes: ClassListType[] | string[],
    locations: LocationListType[];
    students?: StudentListType[];
  }
) => {
  // If we are not editing, just return the provided data
  if (!selectedData) return dataToProvide;

  // Get just the ids of the contacts and classes arrays from the selectedData
  const { _id, classes, contacts, ...selectedDataPlain } =
    selectedData;
  // Add just the ids of the contacts
  (selectedDataPlain as typeof dataToProvide).contacts = contacts.map(
    (contact) => contact._id
  );
  // // And just the ids of the classes
  // (selectedDataPlain as typeof dataToProvide).classes = classes.map(
  //   (classItem) => classItem._id
  // );

  // Return the states that have changed
  const toUpdate = getModifiedKeys(dataToProvide, selectedDataPlain);

  // Add added and removed
  toUpdate.added = { contacts: [] ,classes: [] };
  toUpdate.removed = { contacts: [] ,classes: [] };
  
  // Check if "contacts" has changed
  if (toUpdate.contacts) {
    // Append the information of what to add
    const { added, removed } = addRemoveArrayQuery(
      (selectedDataPlain as typeof dataToProvide).contacts,
      toUpdate.contacts
    );

    // Adding the contacts to each list
    toUpdate.added.contacts = added;
    toUpdate.removed.contacts = removed;
  }

  // // Check if "classes" has changed
  // if (toUpdate.classes) {
  //   // Append the information of what to add
  //   const { added, removed } = addRemoveArrayQuery(
  //     (selectedDataPlain as typeof dataToProvide).classes,
  //     toUpdate.classes
  //   );

  //   // Adding the classes to each list
  //   toUpdate.added.classes = added;
  //   toUpdate.removed.classes = removed;
  // }

  return toUpdate;
};

/**
 * Function used to format the class data in case we are updating.
 * Otherwise, just return the provided data.
 * @param selectedData
 * @param dataToProvide
 * @returns
 */
export const formatUpdateClass = (
  selectedData: ClassListType | undefined,
  dataToProvide: {
    name: string;
    teachers: string[];
    active?: boolean;
  }
) => {
  // If we are not editing, just return the provided data
  if (!selectedData) return dataToProvide;

  // Get just the ids of the teachers from the selectedData
  const { _id, teachers, ...selectedDataPlain } = selectedData;
  // Add just the ids of the teachers
  (selectedDataPlain as typeof dataToProvide).teachers = teachers.map(
    (contact) => contact._id
  );

  // Return the states that have changed
  const toUpdate = getModifiedKeys(dataToProvide, selectedDataPlain);

  // Add added and removed
  toUpdate.added = { teachers: [] };
  toUpdate.removed = { teachers: [] };

  // Check if "teachers" has changed
  if (toUpdate.teachers) {
    // Append the information of what to add
    const { added, removed } = addRemoveArrayQuery(
      (selectedDataPlain as typeof dataToProvide).teachers,
      toUpdate.teachers
    );

    // Adding the contacts to each list
    toUpdate.added.teachers = added;
    toUpdate.removed.teachers = removed;
  }

  return toUpdate;
};

/**
 * Function used to format the student data in case we are updating.
 * Otherwise, just return the provided data.
 * @param selectedData
 * @param dataToProvide
 * @returns
 */
export const formatUpdateStudent = (
  selectedData: StudentListType | undefined,
  dataToProvide: {
    firstName: string;
    middleName: string;
    lastName: string;
    dateOfBirth: string;
    gender: string;
    classes: string[];
    parents: string[];
    heritage: boolean;
    notes: string[];
    role: string;
  }
) => {
  // If we are not editing, just return the provided data
  if (!selectedData) return dataToProvide;

  // Get just the ids from the classes and parents arrays from the selectedData
  const { _id, classes, parents, ...selectedDataPlain } = selectedData;

  // Check if the selected data has classes
  if (classes) {
    // Add just the ids of the classes
    (selectedDataPlain as typeof dataToProvide).classes = classes.map(
      (classItem) => classItem._id
    );
  }

  if (parents) {
    // Add just the ids of the parents
    (selectedDataPlain as typeof dataToProvide).parents = parents.map(
      (parent) => parent._id
    );
  }
  // Remove the role from the dataToProvide
  const { role, ...dataToCheck } = dataToProvide;

  // Return the states that have changed
  const toUpdate = getModifiedKeys(dataToCheck, selectedDataPlain);

  // Add added and removed
  toUpdate.added = { classes: [], parents: [] };
  toUpdate.removed = { classes: [], parents: [] };

  // Check if "classes" has changed
  if (toUpdate.classes) {
    // Append the information of what to add
    const { added, removed } = addRemoveArrayQuery(
      (selectedDataPlain as typeof dataToCheck).classes,
      toUpdate.classes
    );

    // Adding the contacts to each list
    toUpdate.added.classes = added;
    toUpdate.removed.classes = removed;
  }

  // Check if "parents" has changed
  if (toUpdate.parents) {
    // Append the information of what to add
    const { added, removed } = addRemoveArrayQuery(
      (selectedDataPlain as typeof dataToCheck).parents,
      toUpdate.parents
    );

    // Adding the contacts to each list
    toUpdate.added.parents = added;
    toUpdate.removed.parents = removed;
  }

  return toUpdate;
};

/**
 * Function used to format the student data in case we are updating.
 * Otherwise, just return the provided data.
 * @param selectedData
 * @param dataToProvide
 * @returns
 */
export const formatUpdateContact = (
  selectedData:
    | ContactListType
    | TeacherListType
    | ParentListType
    | undefined
    | null,
  dataToProvide: {
    firstName: string;
    middleName: string;
    lastName: string;
    email: string;
    phone: string;
    role: string;
    addAsPrimaryContact: boolean;
    organisations?: string[];
  }
) => {
  // If we are not editing, just return the provided data
  if (!selectedData) return dataToProvide;

  // Get just the ids from the classes and parents arrays from the selectedData
  const { _id, ...selectedDataPlain } = selectedData;

  // Remove the role from the dataToProvide
  const { role, addAsPrimaryContact, organisations, ...dataToCheck } =
    dataToProvide;

  // Return the states that have changed
  const toUpdate = getModifiedKeys(dataToCheck, selectedDataPlain);

  return toUpdate;
};

/**
 * Function used to format the lesson data in case we are updating.
 * Otherwise, just return the provided data.
 * @param selectedData
 * @param dataToProvide
 * @returns
 */
export const formatUpdateLesson = (
  selectedData: Program | Course | Project | Module | null | undefined,
  dataToProvide: LessonListType
) => {
  // If we are not editing, just return the provided data
  if (!selectedData) return dataToProvide;

  // Get just the ids of the lessons from the selectedData
  const { _id, ...selectedDataPlain } = selectedData;

  // Check if the selectedData has quizzes
  if ((selectedDataPlain as Course).quizzes) {
    // Get the quizzes
    const quizzes = (selectedDataPlain as Course).quizzes;

    // Add just the ids of the quizzes
    (selectedDataPlain as typeof dataToProvide).quizzes = quizzes.map(
      (quiz) => quiz._id
    );
  }

  // Return the states that have changed
  const toUpdateRaw = getModifiedKeys(dataToProvide, selectedDataPlain);

  // Separate files from the rest of the modified keys
  const {
    headerImage,
    badgeImage,
    planDocument,
    initialCode,
    finalCode,
    ...toUpdate
  } = toUpdateRaw;

  // Check if files are null or undefined, and do not include them in the update data
  if (planDocument !== null && planDocument !== undefined)
    toUpdate.planDocument = planDocument;
  if (headerImage !== null && headerImage !== undefined)
    toUpdate.headerImage = headerImage;
  if (badgeImage !== null && badgeImage !== undefined)
    toUpdate.badgeImage = badgeImage;
  if (initialCode !== null && initialCode !== undefined)
    toUpdate.initialCode = initialCode;
  if (finalCode !== null && finalCode !== undefined)
    toUpdate.finalCode = finalCode;

  // Add added and removed
  toUpdate.added = { quizzes: [] };
  toUpdate.removed = { quizzes: [] };

  // Check if "quizzes" has changed
  if (toUpdate.quizzes) {
    // Append the information of what to add
    const { added, removed } = addRemoveArrayQuery(
      (selectedDataPlain as typeof dataToProvide).quizzes || [],
      toUpdate.quizzes
    );

    // Adding the contacts to each list
    toUpdate.added.quizzes = added;
    toUpdate.removed.quizzes = removed;
  }

  return toUpdate;
};

/**
 * Function used to format the lesson data in case we are updating.
 * Otherwise, just return the provided data.
 * @param selectedData
 * @param dataToProvide
 * @returns
 */
export const formatUpdateQuiz = (
  selectedQuiz: QuizListType | null | undefined,
  dataToProvide: LessonListType
) => {
  // If we are not editing, just return the provided data
  if (!selectedQuiz) return dataToProvide;

  // Return the states that have changed
  const toUpdate = getModifiedKeys(dataToProvide, selectedQuiz);

  return toUpdate;
};
