import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { fetchPdf } from "services/filesRequests";

interface PDFViewerType {
  isPaning: boolean;
  isVisible: boolean;
}

const PDFViewer: React.FC<PDFViewerType> = ({ isPaning, isVisible }) => {
  // States definition
  const [pdfUrl, setPdfUrl] = useState("");

  // Parameters retrieval
  const { moduleOrProject, id } = useParams();

  /**
   * Function used to fetch the pdf file
   */
  useEffect(() => {
    if (isVisible) {
      fetchPdf(moduleOrProject || "", id || "").then((url: string) => {
        setPdfUrl(url);
      });
    }

    return () => {
      if (pdfUrl) {
        URL.revokeObjectURL(pdfUrl);
      }
    };
  }, [moduleOrProject, id, isVisible]);

  return (
    <>
      {isVisible && (
        <div
          className={`${isPaning ? "pointer-events-none" : ""} ${
            isVisible
              ? "w-full h-full p-4 pt-0 custom-scroll"
              : "w-0 h-0 p-0 invisible"
          }`}
        >
          {/* More reference parameters for pdf: https://stackoverflow.com/questions/20562543/zoom-to-fit-pdf-embedded-in-html*/}
          {pdfUrl && (
            <iframe
              id="pdf-iframe"
              src={pdfUrl + "#view=FitH&toolbar=0"}
              className="w-full h-full"
            />
          )}
        </div>
      )}
    </>
  );
};
export default PDFViewer;
