import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getCollectionRequest } from "services/apiRequests";
import { SchoolListType } from "utils/interfaces";
import SchoolsDropdown from "./SchoolsDropdown";

interface ViewTabBarProps {
  activeItem: string;
  tabOptions: string[];
  showClassesDropdown?: boolean;
  userSchool?: SchoolListType | null;
  setActiveItem: React.Dispatch<React.SetStateAction<string>>;
  setUserSchool?: React.Dispatch<React.SetStateAction<SchoolListType | null>>;
  headerRef: React.MutableRefObject<any>;
}

const ViewTabBar: React.FC<ViewTabBarProps> = ({
  activeItem,
  tabOptions,
  showClassesDropdown = false,
  userSchool,
  setActiveItem,
  setUserSchool,
  headerRef,
}) => {
  // States definition
  const [showSchoolListTab, setShowSchoolListTab] = useState(false);
  // State used for classes dropdown list in the "master admin" view on classes tab
  const [schoolData, setSchoolData] = useState<SchoolListType[]>([]);

  // Define a navigation hook
  const navigate = useNavigate();

  // Function used when option is clicked
  const handleOnClick = (option: string) => {
    return () => {
      // Set the active lesson
      setActiveItem(option.toLowerCase());
      // And the header names
      headerRef.current.setHeaderTitle(option);
      headerRef.current.setHeaderDescription(
        `List of current ${option.toLowerCase()}`
      );
    };
  };

  /**
   * Function to get the school data
   * @returns data
   */
  const getSchoolData = async () => {
    // Get the school data
    const response = await getCollectionRequest("/api/organisations", [
      "_id",
      "name",
    ]);

    // Check if the response is successful
    if (!response.successful) {
      // Check if it's not successful because of a token expiration
      if (response.logout) {
        // alert(response.message);
        navigate("/");
      }

      return [];
    }

    // Get the data
    const data = response.content;

    // Assign the data to the corresponding element
    setSchoolData(data);

    // And assign selected data as the first school
    if (setUserSchool && !userSchool) setUserSchool(data[0]);

    return data;
  };

  /**
   * Root definition
   */
  useEffect(() => {
    if (showClassesDropdown) getSchoolData();
  }, []);

  return (
    <div className="p-1.5 bg-Tab rounded-full justify-start items-center gap-1.5 flex">
      {showClassesDropdown && (
        <>
          <SchoolsDropdown
            showSchoolListTab={showSchoolListTab}
            schoolData={schoolData}
            userSchool={userSchool}
            setUserSchool={setUserSchool}
            setShowSchoolListTab={setShowSchoolListTab}
          />
          <div className="self-stretch py-1 flex-col opacity-50 justify-center items-start gap-2 inline-flex">
            <div className="grow shrink basis-0 w-px self-stretch bg-neutral-300" />
          </div>
        </>
      )}
      {tabOptions.map((option) => (
        <button
          key={option}
          className={
            activeItem === option.toLowerCase()
              ? "px-3 py-1.5 bg-white rounded-full justify-center items-center gap-2 flex cursor-pointer"
              : "px-3 py-1.5 rounded-full justify-center items-center gap-2 flex cursor-pointer hover:bg-neutral-200 ease-in-out duration-300"
          }
          onClick={handleOnClick(option)}
        >
          <div className="text-dc-secondary-600 text-sm font-semibold font-sans leading-[17px] select-none">
            {option}
          </div>
        </button>
      ))}
    </div>
  );
};

export default ViewTabBar;
