import { platform } from "config/platform";
import React from "react";
import { GoArrowLeft } from "react-icons/go";
import { useNavigate } from "react-router-dom";

interface AssistantHeaderProps {
  handleClickBack: () => void;
  assistant: "littleC" | "jakita" | "cody";
}

const AssistantHeader: React.FC<AssistantHeaderProps> = ({
  handleClickBack,
  assistant,
}) => {
  // Define a navigate hook
  const navigate = useNavigate();

  /**
   * Function used when clicking on the logo
   */
  const handleLogoClick = () => {
    navigate("/home");
  };

  return (
    <div className="w-full h-[155px] px-10 relative flex items-center z-20">
      {/* Logo and back button */}
      <div className="left-10 flex items-center gap-8">
        <img
          className={`${
            platform === "codeflix" ? "w-[124px] h-10" : "w-[97.80px] h-16"
          } cursor-pointer`}
          src="/logo.svg"
          onClick={handleLogoClick}
        />
        <div className="w-px self-stretch bg-neutral-200" />
        <div className="justify-start items-center gap-3 flex">
          <div className="flex-col justify-start items-center gap-2 inline-flex">
            <div
              className="w-12 h-12 p-3 bg-neutral-50 hover:bg-neutral-100 ease-in-out duration-150 rounded-full justify-center items-center inline-flex cursor-pointer z-10"
              onClick={handleClickBack}
            >
              <div className="w-6 h-6 relative">
                <GoArrowLeft className="w-full h-full fill-dc-secondary-600" />
              </div>
            </div>
            <div className="justify-start items-start gap-2 inline-flex">
              <div className="text-center text-dc-secondary-600 text-xs font-semibold font-sans uppercase leading-[14px] tracking-wide">
                Back
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Title */}
      <div className="flex-grow flex flex-col justify-center items-center">
        <div className="text-center text-neutral-600 text-[23px] font-bold font-sans leading-7">
          Feedback
        </div>
        <div className="text-center text-neutral-500 text-base font-normal font-sans leading-[19px]">
          Tutoring with {assistant === "littleC" ? "Little C" : assistant === "jakita" ? "Jakita" : "Cody"}
        </div>
      </div>

      {/* spacing to centre title */}
      <div className="w-[210px]" />
    </div>
  );
};

export default AssistantHeader;
