import React, { useContext, useEffect, useState } from "react";
import { Course, Module } from "utils/interfaces";
import {
  MdArrowForward,
  MdBolt,
  MdCheck,
  MdOutlineAddToPhotos,
  MdOutlineEdit,
  MdStackedBarChart,
} from "react-icons/md";
import CouponLineIcon from "assets/icons/admin-view/CouponLineIcon";
import { BACKEND_URL } from "config/getUrl";
import { checkResultComplete } from "services/resultsRequests";
import { LessonsContext } from "components/main-view/utils/Contexts";

export interface CourseCardProps {
  userRole: string;
  course: Course;
  modules?: Module[];
  handleExpand?: () => void;
  handleEdit?: (event: React.MouseEvent) => void;
  handleAddToClasses: (event: React.MouseEvent) => void;
}

const CourseCard: React.FC<CourseCardProps> = ({
  userRole,
  course,
  handleEdit,
  handleExpand,
  handleAddToClasses,
}) => {
  const [isComplete, setIsComplete] = useState();
  const updateComplete = async () => {
    if (course._id && ["student", "parent"].includes(userRole)) {
      setIsComplete(await checkResultComplete("courses", course._id));
    }
  };
  useEffect(() => {
    updateComplete();
  }, [course._id]);

  // Get the context to prevent caching
  const { version } = useContext(LessonsContext);

  return (
    <div
      role="button"
      tabIndex={0}
      className={`w-full h-[133px] px-6 py-4 bg-neutral-25 rounded-xl justify-between items-center inline-flex cursor-pointer hover:shadow-card ease-in-out duration-150 relative ${
        isComplete ? "border-2 border-success-500" : ""
      }`}
      onClick={handleExpand}
    >
      {isComplete && (
        <div className="absolute left-0 top-0 w-8 h-8">
          <div className="relative w-full h-full p-1 rounded-tl-lg rounded-br-xl bg-success-500">
            <MdCheck className="w-full h-full text-white" />
          </div>
        </div>
      )}
      <div className="w-[300px] h-[77px] justify-start items-center gap-3 flex">
        <div className="w-[77px] h-[77px] rounded-lg justify-start items-end gap-2 flex">
          <img
            src={`${BACKEND_URL}${course.headerImage}?v=${version}`}
            onError={({ currentTarget }) => {
              currentTarget.onerror = null;
              currentTarget.src = "/logo.svg";
            }}
            alt={course.title}
            className="w-[77px] h-[77px] object-fit"
          />
        </div>
        <div className="grow shrink basis-0 flex-col justify-start items-start gap-2 inline-flex">
          <div className="self-stretch text-neutral-700 text-base font-semibold font-sans leading-tight">
            {course.title || "-"}
          </div>
          <div className="self-stretch text-neutral-600 text-xs font-normal font-sans leading-[18px]">
            {course.description || "-"}
          </div>
        </div>
      </div>
      <div className="w-[87px] self-stretch flex-col justify-center items-center gap-1 inline-flex">
        <div className="p-2 rounded-full justify-start items-end gap-2 inline-flex">
          <div className="w-8 h-8 relative">
            <MdStackedBarChart className="w-full h-full fill-neutral-700" />
          </div>
        </div>
        <div className="flex-col justify-center items-center gap-1 flex">
          <div className="self-stretch text-center text-neutral-700 text-lg font-semibold font-sans leading-snug">
            {course.level || "-"}
          </div>
          <div className="pb-[3px] flex-col justify-center items-end gap-2 flex">
            <div className="justify-center items-end gap-2 inline-flex">
              <div className="text-center text-neutral-500 text-[10px] font-normal font-sans uppercase leading-3 tracking-wide">
                Program Level
              </div>
            </div>
          </div>
        </div>
        {/* <div className="self-stretch h-0.5 bg-neutral-200 flex-col justify-start items-start gap-2 flex">
          <div className="w-[21px] h-0.5 bg-success-500" />
        </div> */}
      </div>
      <div className="w-[106px] self-stretch flex-col justify-center items-center gap-1 inline-flex">
        <div className="p-2 rounded-full justify-start items-end gap-2 inline-flex">
          <div className="w-8 h-8 relative">
            <MdBolt className="w-full h-full fill-neutral-700" />
          </div>
        </div>
        <div className="flex-col justify-center items-center gap-1 flex">
          <div className="w-[41px] text-center text-neutral-700 text-lg font-semibold font-sans leading-snug">
            {course.experiencePoints || 0}
          </div>
          <div className="pb-[3px] flex-col justify-center items-end gap-2 flex">
            <div className="justify-center items-end gap-2 inline-flex">
              <div className="text-center text-neutral-500 text-[10px] font-normal font-sans uppercase leading-3 tracking-wide">
                Knowledge points
              </div>
            </div>
          </div>
        </div>
        {/* <div className="self-stretch h-0.5 bg-neutral-200 flex-col justify-start items-start gap-2 flex">
          <div className="w-[79px] h-0.5 bg-success-500" />
        </div> */}
      </div>
      <div className="p-1 rounded flex-col justify-start items-start gap-3 inline-flex">
        <div className="self-stretch h-[93px] flex-col justify-start items-center gap-1 flex">
          <div className="p-2 rounded-full justify-start items-end gap-2 inline-flex">
            <div className="w-8 h-8 justify-center items-center flex">
              <div className="w-8 h-8 relative">
                <CouponLineIcon></CouponLineIcon>
              </div>
            </div>
          </div>
          <div className="flex-col justify-center items-center gap-1 flex">
            <div className="w-[41px] text-center text-neutral-700 text-lg font-semibold font-sans leading-snug">
              {course.projects ? course.projects.length : 0}
            </div>
            <div className="pb-[3px] flex-col justify-center items-end gap-2 flex">
              <div className="justify-center items-end gap-2 inline-flex">
                <div className="text-center text-neutral-500 text-[10px] font-normal font-sans uppercase leading-3 tracking-wide">
                  Number of Projects
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {userRole === "master admin" && (
        <div className="w-12 self-stretch justify-center items-center gap-3 flex">
          <button
            className="p-3 bg-neutral-100 rounded-full justify-start items-start gap-2 flex cursor-pointer hover:bg-neutral-200 ease-in-out duration-300"
            onClick={handleEdit}
          >
            <div className="w-6 h-6">
              <MdOutlineEdit className="w-full h-full fill-neutral-700" />
            </div>
          </button>
        </div>
      )}
      {["customer admin", "class admin"].includes(userRole) && (
        <div className="w-12 self-stretch justify-center items-center gap-3 flex">
          <button
            className="p-3 bg-neutral-100 rounded-full justify-start items-start gap-2 flex cursor-pointer hover:bg-neutral-200 ease-in-out duration-300"
            onClick={handleAddToClasses}
          >
            <div className="w-6 h-6">
              <MdOutlineAddToPhotos className="w-full h-full fill-neutral-700" />
            </div>
          </button>
        </div>
      )}
      {["parent", "student"].includes(userRole) && (
        <div className="w-fit self-stretch justify-center items-center gap-3 flex">
          <button
            className="p-3 bg-neutral-100 rounded-full justify-start items-start gap-2 flex cursor-pointer hover:bg-neutral-200 ease-in-out duration-300"
            onClick={() => {}}
          >
            <div className="w-6 h-6">
              <MdArrowForward className="w-full h-full fill-neutral-700" />
            </div>
          </button>
        </div>
      )}
    </div>
  );
};

export default CourseCard;
