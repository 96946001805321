import CreateNewPopUp from "components/main-view/customers/utils/CreateNewPopUp";
import { forwardRef, ReactElement, useImperativeHandle, useState } from "react";
import {
  MdOutlineSave,
  MdAdd,
  MdClose,
  MdCorporateFare,
  MdOutlineExpandMore,
} from "react-icons/md";

interface CustomersHeaderOptionsProps {
  ref: any;
  userRole: string;
  setActiveItem: React.Dispatch<React.SetStateAction<string>>;
  setShowSideBarMenu: React.Dispatch<React.SetStateAction<boolean>>;
  setTitle: React.Dispatch<React.SetStateAction<string>>;
  setDescription: React.Dispatch<React.SetStateAction<ReactElement | string>>;
  setShowBackButton: React.Dispatch<React.SetStateAction<boolean>>;
  customersRef: React.MutableRefObject<any>;
}

const CustomersHeaderOptions: React.FC<CustomersHeaderOptionsProps> =
  forwardRef(
    (
      {
        userRole,
        setActiveItem,
        setShowSideBarMenu,
        setTitle,
        setDescription,
        setShowBackButton,
        customersRef,
      },
      ref
    ) => {
      // States definition
      const [showCreateNewPopUp, setShowCreateNewPopUp] = useState(false);
      const [showCreateNew, setShowCreateNew] = useState(true);
      const [saveButton, setSaveButton] = useState<{
        show: boolean;
        disabled: boolean;
      }>({ show: false, disabled: true });
      // State for controlling where to redirect on customer admin school logo pressing
      const [activeItemOnBack, setActiveItemOnBack] = useState("");

      /**
       * Define the list of functions that can be used from external components
       * using a reference
       */
      useImperativeHandle(ref, () => ({
        setShowCreateNewButton(bool: boolean) {
          setShowCreateNew(bool);
        },
        setSaveButton(options: { show: boolean; disabled: boolean }) {
          setSaveButton(options);
        },
        pressCreateNewSchool() {
          onCreateEditSchool();
        },
        pressEditSchool(title: string = "", description: string = "") {
          onCreateEditSchool(title, description);
        },
        onDiscard() {
          onDiscard();
        },
        setActiveItemOnBack(activeItem: string) {
          setActiveItemOnBack(activeItem);
        },
      }));

      /**
       * CreateNewPopUp functions definition
       */
      // Create Student
      const onCreateEditSchool = (
        title = "New School",
        description = "School"
      ) => {
        // Regarding the header
        setShowCreateNewPopUp(false);
        setShowBackButton(true);
        setShowSideBarMenu(false);
        setShowCreateNew(false);
        setSaveButton({ show: true, disabled: true });
        setTitle(title);
        setDescription(
          <div className="w-fit flex gap-1">
            <MdCorporateFare />
            <div>{description}</div>
          </div>
        );

        // And set the status
        customersRef.current.setCreateSchool(true);
      };

      /**
       * Function that defines the root view
       */
      const onDiscard = () => {
        // And set the status
        customersRef.current.setCreateSchool(false);
        // And force unselect data
        customersRef.current.setSelectedData(null);

        // Activate the side bar menu
        setShowSideBarMenu(true);

        // Change the tab name
        setTitle("Schools");
        setDescription("List of current schools");
        // Delete back button
        setShowBackButton(false);
        // And turn off the other buttons
        setSaveButton({ show: false, disabled: true });
        // Activating the create new button
        setShowCreateNew(true);

        // If there is a specified tab to come back, then come back
        if (activeItemOnBack) {
          setActiveItem(activeItemOnBack);
        }
      };

      const handleSave = () => {
        // If the save button is available, then make it work
        if (!saveButton.disabled) customersRef.current.pressSaveButton();
      };

      return (
        <>
          {userRole === "master admin" && showCreateNew && (
            <div className="p-3 rounded-full flex-col justify-center items-center gap-2 inline-flex relative">
              <div
                className="justify-center items-center gap-0.5 inline-flex cursor-pointer"
                onClick={() => {
                  if (!showCreateNewPopUp)
                    setShowCreateNewPopUp(!showCreateNewPopUp);
                }}
              >
                <div className="p-3 bg-neutral-50 rounded-full backdrop-blur-[32px] justify-center items-center gap-2 flex hover:bg-neutral-100 ease-in-out duration-300">
                  <div className="w-6 h-6 relative">
                    <MdAdd className="w-full h-full fill-dc-secondary-600" />
                  </div>
                </div>
                <div className="w-4 h-4 relative">
                  <MdOutlineExpandMore className="w-4 h-4 text-dc-secondary-600" />
                </div>
              </div>
              <div className="justify-center items-center gap-0.5 inline-flex">
                <div className="text-center text-dc-secondary-600 text-xs font-semibold font-sans uppercase leading-[14px] tracking-wide whitespace-nowrap select-none">
                  Create
                </div>
                <div className="w-4 h-4 px-[4.28px] opacity-0 justify-center items-center flex" />
              </div>
              {showCreateNewPopUp && (
                <CreateNewPopUp
                  setShowCreateNewPopUp={setShowCreateNewPopUp}
                  onCreateEditSchool={() => onCreateEditSchool()}
                />
              )}
            </div>
          )}
          {saveButton.show && (
            <div className="justify-start items-start gap-1 flex">
              <div className="p-3 rounded-full flex-col justify-center items-center gap-2 inline-flex">
                <div className="justify-center items-center gap-0.5 inline-flex">
                  <div
                    className="p-3 bg-neutral-50 rounded-full backdrop-blur-[32px] justify-center items-center gap-2 flex cursor-pointer hover:bg-neutral-100 ease-in-out duration-300"
                    onClick={onDiscard}
                  >
                    <div className="w-6 h-6 relative">
                      <MdClose className="w-full h-full fill-dc-secondary-600" />
                    </div>
                  </div>
                </div>
                <div className="justify-center items-center gap-0.5 inline-flex">
                  <div className="text-center text-dc-secondary-600 text-xs font-semibold font-sans uppercase leading-[14px] tracking-wide">
                    Discard
                  </div>
                </div>
              </div>
              <div className="p-3 rounded-full flex-col justify-center items-center gap-2 inline-flex">
                <div
                  className={
                    saveButton.disabled
                      ? "justify-center items-center gap-0.5 inline-flex"
                      : "justify-center items-center gap-0.5 inline-flex cursor-pointer"
                  }
                  onClick={handleSave}
                >
                  <div
                    className={
                      saveButton.disabled
                        ? "p-3 bg-neutral-50 rounded-full backdrop-blur-[32px] justify-center items-center gap-2 flex"
                        : "p-3 bg-neutral-50 rounded-full backdrop-blur-[32px] justify-center items-center gap-2 flex hover:bg-neutral-100 ease-in-out duration-300"
                    }
                  >
                    <div className="w-6 h-6 relative">
                      <MdOutlineSave
                        className={
                          saveButton.disabled
                            ? "w-full h-full fill-neutral-300"
                            : "w-full h-full fill-dc-secondary-600"
                        }
                      />
                    </div>
                  </div>
                </div>
                <div className="justify-center items-center gap-0.5 inline-flex">
                  <div
                    className={
                      saveButton.disabled
                        ? "text-center text-neutral-300 text-xs font-semibold font-sans uppercase leading-[14px] tracking-wide whitespace-nowrap"
                        : "text-center text-dc-secondary-600 text-xs font-semibold font-sans uppercase leading-[14px] tracking-wide whitespace-nowrap"
                    }
                  >
                    Save
                  </div>
                </div>
              </div>
            </div>
          )}
        </>
      );
    }
  );

export default CustomersHeaderOptions;
